import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LeadFormService } from '../services/lead.form.service';
import { emptyAddress } from 'src/app/shared/enum/empty-address';
import { AddressCompComponent } from '../address-comp/address-comp.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'embedform',
  templateUrl: './embedform.component.html',
  styleUrls: ['./embedform.component.scss']
})
export class EmbedformComponent implements OnInit {
  allInputField: any[] = [];
  droppedInput: any[] = [];
  @ViewChild('textareaRef') textareaRef: ElementRef;
  @Input() embedFromData: { data: any[], style: any };
  embedFromData1: any;
  myLeadForm: FormGroup;
  checkedValues: string[] = [];
  selectedFiles: File[] = [];
  isDragOver = false;
  subscriptions: Subscription[] = [];
  countryListArr: any[] = [];
  resolvedData: any = {};
  phonenumber:any[] = [{ 'countryId': '', 'mobile_number': '', 'name': '', 'order': 0 }];
  staticLogo: string = 'https://osmos-bucket-prod.s3.us-east-1.amazonaws.com/upload_note_attachment/1699445669424_placeholder_logo.png';
  protected onDestroy = new Subject<void>();
  customerAddressArr: any = [new emptyAddress()];
  labelName1='address';
  moduleName='Lead Form Address';
  @ViewChild(AddressCompComponent, { static: false }) AddressCompComponent: AddressCompComponent;
  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private leadForm_service: LeadFormService,
    private translateService: TranslateService
  ) {
    this.myLeadForm = this.fb.group({});
    this.route.data.subscribe(data => {
      this.resolvedData = data['viewTemplateData'];
    });
  }

  ngOnInit(): void {
    this.getTemplate();
    this.leadForm_service?.countryList().subscribe((val: any) => {
      this.countryListArr = val.data.slice(0, 170);
    })
  }

  get getColor1() { return this.embedFromData1?.style?.color; }
  get getFmly1() { return this.embedFromData1?.style?.font_family; }
  get getFsize1() { return this.embedFromData1?.style?.font_size; }
  get getCheckbox1() {const value = this.embedFromData1?.data?.find((el: any) => el?.form_name === 'Checkbox');
    return value?.custom_value ? value?.custom_value?.value : value?.value;
  }

  get getData() { return this.embedFromData?.data; }
  get getColor() { return this.embedFromData?.style?.color; }
  get getFmly() { return this.embedFromData?.style?.font_family; }
  get getFsize() { return this.embedFromData?.style?.font_size; }
  get getCheckbox() {const value = this.embedFromData?.data?.find((el: any) => el?.form_name === 'Checkbox');
    return value?.custom_value ? value?.custom_value?.value : value?.value;
  }
  get getAttechValue() { return this.embedFromData?.data?.find((el: any) => el?.form_name === 'Attachment')?.value }
  get acceptFile(): string {
    const acceptedTypes = this.getAttechValue?.filter((fileType: any) => fileType?.value)?.map((fileType: any) => `.${fileType?.name?.toLowerCase()}`)?.join(',');
    return acceptedTypes;
  }
  onSubmit() {
    const address = this.AddressCompComponent?.addressItems?.lineItems[0];
    this.myLeadForm?.controls['Address']?.setValue(address);
    if (this.myLeadForm.invalid) {
      console.log('myLeadForm.invalid', this.myLeadForm.value);
      return
    }
    console.log('myLeadForm.valid', this.myLeadForm.value);
  }

  createForm(embed: any): FormGroup {
    if (!this.myLeadForm) {
      this.myLeadForm = this.fb.group({});
    }
    if (embed?.type !== 'content') {
      if (embed?.form_name !== 'Submit') {
        if (embed?.required) {
          this.myLeadForm.addControl(embed?.form_name, this.fb.control(null, Validators.required));
        } else {
          this.myLeadForm.addControl(embed?.form_name, this.fb.control(null));
        }
      }
    }
    return this.myLeadForm;
  }

  onCheckboxChange(event: any, data: any) {
    const valueAt = this.getCheckbox?.findIndex((el: any) => el?.name == data?.name);
    const checked = event?.target?.checked;
    if (valueAt !== -1) {
      this.getCheckbox[valueAt].selected = checked;
      this.myLeadForm?.controls['Checkbox']?.setValue(this.getCheckbox);
    }
  }

  ngAfterViewInit(): void {
    if (this.myLeadForm?.controls['Checkbox'] !== undefined) {
      this.myLeadForm?.controls['Checkbox']?.setValue(this.getCheckbox);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
    if (this.isInDropZone(event)) {
      event.dataTransfer.dropEffect = 'copy';
    } else {
      event.dataTransfer.dropEffect = 'none';
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.handleFiles(event.dataTransfer.files);
  }

  private isInDropZone(event: DragEvent): boolean {
    const targetElement = event.target as HTMLElement;
    return targetElement.matches('#attachment_icon');
  }

  onFileSelected(event: any) {
    this.handleFiles(event.target.files);
  }

  handleFiles(files: FileList | File[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.isValidFile(file)) {
        this.selectedFiles.push(file);
        this.myLeadForm?.controls['Attachment']?.setValue(this.selectedFiles);
      }
    }
  }

  isValidFile(file: File): boolean {
    let accepted: string[] = [];
    const fileTypeMap = new Map<string, string[]>([['DOX', ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']], ['PDF', ['application/pdf']], ['PNG', ['image/png']], ['JPG', ['image/jpeg']]]);
    this.getAttechValue.forEach(fileType => { if (fileType?.value && fileTypeMap?.has(fileType?.name)) { accepted.push(...fileTypeMap.get(fileType?.name)!); } });
    const maxSize = 10 * 1024 * 1024;
    if (!accepted.includes(file.type)) {
      this.errorSnackBar('Invalid file type.', 'OK');
      return false;
    }
    if (file.size > maxSize) {
      this.errorSnackBar('File size exceeds the limit.', 'OK');
      return false;
    }
    return true;
  }

  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }

  errorSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass = ['notif-error'];
    this.snackbar.open(msg, tag, config);
  }

  ngOnDestroy(): void {
    this.onDestroy.next(null);
    this.onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTemplate() {
    if (this.embedFromData1) {
      this.embedFromData1 = null;
    }
    if (this.resolvedData && this.resolvedData.leadFormData) {
      const embedFrom = this.resolvedData.leadFormData?.data?.quotes_templates_fields;
      if (embedFrom) {
        this.embedFromData1 = {
          data: embedFrom?.map((el: any) => el?.field_styles),
          style: {
            color: embedFrom[0]?.field_styles?.colors,
            font_family: embedFrom[0]?.field_styles?.fontFamily,
            font_size: {
              head: embedFrom[0]?.field_styles?.headingSize,
              body: embedFrom[0]?.field_styles?.bodySize,
              helptxt: embedFrom[0]?.field_styles?.helpTextSize
            },
            logo: embedFrom[0]?.field_styles?.imageURL
          }
        }
        console.log(this.embedFromData1,'jjjjjjjjjjj');
        
      }
    }
  }
  getValue(name: string) {
    console.log('lllllllllll');
    
    const textareaValue = this.textareaRef?.nativeElement?.value;
    const index = this.allInputField.findIndex(item => item?.form_name === name);
    const index1 = this.droppedInput.findIndex(item => item?.form_name === 'Submit');
    console.log('kkkkkkkk', this.droppedInput)
if(name == 'Submit'){
  if (index1 !== -1){
    {
      this.droppedInput[index1].value = textareaValue 
    }
  }
}  else if (index !== -1) {
  if (name === 'Phone') {
      this.droppedInput[index].data = textareaValue;
  } else if (name === 'Address') {
    this.droppedInput[index].data = textareaValue;
  } else if (name === 'Text') {
     this.droppedInput[index].data = textareaValue;
  }
  else if (name === 'Client Type') {
     this.droppedInput[index].data = textareaValue;
  }
  else if (name === 'Favorite Color') {
     this.droppedInput[index].data = textareaValue;
   
  }
  else if (name === 'Organization') {
     this.droppedInput[index].data = textareaValue;
  }
  else if (name === 'Email') {
     this.droppedInput[index].data = textareaValue;
  }
  else if (name === 'Your birthday') {
    this.droppedInput[index].data = textareaValue;
 }
 else if (name === 'First Name') {
  this.droppedInput[index].data = textareaValue;
}
else if (name === 'Last Name') {
  this.droppedInput[index].data = textareaValue;
}
else if (name === 'Do you send quotes?') {
  this.droppedInput[index].data = textareaValue;
}
else if (name === 'Heading') {
  this.droppedInput[index].data = textareaValue;
}
else if (name === 'Submit') {
  this.droppedInput[index].data = textareaValue;
}
  else {
    this.droppedInput[index].data = textareaValue;
  }
} else{
  if (index !== -1) { this.allInputField[index].data = textareaValue}

}

  }
  conorglead(el: string): boolean { return el === 'First Name' || el === 'Last Name' || el === 'Email' || el === 'Date' || el === 'Color' || el === 'Address' || el === 'Organization' || el === 'Website'; }
  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }
}
