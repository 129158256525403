<div class="d-flex flex-column">
  <label class="menu-label">{{"COMMON.Filter" | translate}}</label>
  <div class="d-flex">
    <mat-form-field appearance="outline" [ngClass]="(selectedOption!==undefined)?'date_range':''"
      class="filter-input-btn">
      <p *ngIf="selectedOption !==undefined" class="selected-date m-0">{{selectedOption}}</p>
      <mat-select [placeholder]="'HOME.Current_month' | translate" [(value)]="value" (selectionChange)="filterbtn($event)">
        <mat-option value="current_month">{{"HOME.Current_month" | translate}}</mat-option>
        <mat-option value="last_month">{{"HOME.Last_month" | translate}}</mat-option>
        <mat-option value="last_3_month">{{"HOME.Last_3_months" | translate}}</mat-option>
        <mat-option value="last_12_month">{{"HOME.Last_12_months" | translate}}</mat-option>
        <mat-option value="Next_3_months">{{"HOME.Next_3_months" | translate}}</mat-option>
        <mat-option value="Next_6_months">{{"HOME.Next_6_months" | translate}}</mat-option>
        <mat-option value="Next_12_months">{{"HOME.Next_12_months" | translate}}</mat-option>
        <mat-option value="custom_range" [ngClass]="{'select-width':true}"
          (click)="filterbtnonclick(startDate, endDate)">
          <mat-form-field appearance="none" class="date-range-picker resp_div" (click)="picker.open()">
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Custom" #startDate />
              <input matEndDate placeholder="range" (dateChange)="endDateChange()" #endDate />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker" (click)="picker.open()"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="d-flex ml-2" *ngIf="showSaveFilterFlag">
      <div class="d-flex gap-2 align-items-center" type="button">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2925_58626)">
            <path
              d="M5.74377 13.1026C5.60059 13.2466 5.40523 13.327 5.20231 13.327C4.99938 13.327 4.80403 13.2466 4.66085 13.1026L0.336574 8.77761C-0.112191 8.32884 -0.112191 7.60114 0.336574 7.15322L0.878037 6.61162C1.32694 6.16285 2.0538 6.16285 2.50257 6.61162L5.20231 9.3115L12.4974 2.01626C12.9463 1.5675 13.6739 1.5675 14.1219 2.01626L14.6634 2.55786C15.1122 3.00663 15.1122 3.73419 14.6634 4.18225L5.74377 13.1026Z"
              fill="#22BC31" />
          </g>
          <defs>
            <clipPath id="clip0_2925_58626">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p class="color-2 m-0" type="button" (click)="saveFilter(value)">{{"COMMON.Save_Filter" | translate}}</p>
      </div>
    </div>
  </div>
</div>