<h2 class="text-center">{{"LEADS.deal_detail" | translate}}</h2>
<div>
    <div class="modal-content">
        <div class="modal-body bg-gris-1">
            <div class="p-3">
                <div class="row">
                    <div class="col-lg-6 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"COMMON.Deal_Title" | translate}}</label>
                            <input type="text" value="{{deal_Title}}" class="form-control"
                                placeholder='{{"COMMON.Deal_Title" | translate}}' disabled>
                        </div>
                    </div>
                    <div class="col-lg-6 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"LEADS.Related_To" | translate}}</label>
                            <select class="form-select w-100" disabled>
                                <option selected>{{related_To}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"COMMON.dealtype" | translate}}</label>
                            <select class="form-select w-100" disabled>
                                <option selected>{{deal_Type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"LEADS.Lead_Stage" | translate}}</label>
                            <select class="form-select w-100" disabled>
                                <option selected>{{lead_Stage}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"EMAIL_CATEGORY.Deal description" | translate}}</label>
                            <textarea class="form-control" value="{{deal_Des}}"
                                placeholder='{{"EMAIL_CATEGORY.Deal description" | translate}}'
                                style="height: 100px; resize: none;" disabled></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"COMMON.Tags" | translate}}</label>
                            <mat-chip-list class="form-control" *ngIf="tags?.length">
                                <mat-chip *ngFor="let tag of tags" class="mat-focus-indicator mx-1 mt-2 mb-0 tag-input">
                                    <div>{{tag?.name}}</div>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                    <div class="col-lg-6 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"COMMON.forecastdate" | translate}}</label>
                            <input type="date" class="form-control" value="{{forecast_Date}}" disabled>
                        </div>
                    </div>
                    <div class="col-lg-6 my-2">
                        <div class="form-group">
                            <label class="text-3 my-2">{{"COMMON.Enter_Deal_Value" | translate}}</label>
                            <input type="number" class="form-control" value="{{deal_Value}}" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>