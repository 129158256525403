<div class="modal-content border-0 cnt-dashboard border-r2"  data-backdrop="static" *ngIf="inputData?.type !=='quote'">
  <form (ngSubmit)="cateogryForm.form.valid" #cateogryForm="ngForm" novalidate>
    <div class="modal-body bg-gris-1 p-5 d-flex align-items-center flex-column">
      <h2 class="text-1 fs-4 text-center mb-4">
        {{ inputData?.label === 'Address' ? ('COMMON.Update Address Name' | translate) : (inputData?.label === 'phone_name' ? ('COMMON.Update Phone Name' | translate) : (inputData?.name ? ('ITEMS.Update Category' | translate) : ('COMMON.ADDNEWCATEGORY' | translate))) | translate }}
      </h2>
            <i type="button" #closebutton class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
        data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></i>
      <input matInput class="form-control p-2 text-center w-75" type="text" [placeholder]="'QUOTE_TEMPLATE.Enter Category' | translate"
        [(ngModel)]="categoryAddModel.name" name="name" #name="ngModel" required>
      <div *ngIf="name.invalid && throwError" class="alert error_message mb-0">
        <div *ngIf="throwError">
          {{"COMMON.ERRORNAMEREQUIRED" | translate}}
        </div>
      </div>
      <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.itemsEditButtonId == 'categoryId'" (click)="addCateogry(cateogryForm)"><i
          class="fa fa-plus"></i>&nbsp;&nbsp;{{"COMMON.Add" | translate }}</button>
          <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.itemsEditButtonId !== 'categoryId' && !inputData.label" (click)="updateCateogry(cateogryForm)">&nbsp;&nbsp;{{"COMMON.UPDATE" | translate }}</button>
          <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.itemsEditButtonId !== 'categoryId' && inputData.label" (click)="updateAddress(cateogryForm)">&nbsp;&nbsp;{{"COMMON.UPDATE" | translate }}</button>

  
    </div>
  </form>
</div>

<div class="modal-content border-0 cnt-dashboard border-r2"  data-backdrop="static" *ngIf="inputData?.type =='quote'">
  <form (ngSubmit)="cateogryForm.form.valid" #cateogryForm="ngForm" novalidate>
    <div class="modal-body bg-gris-1 p-5 d-flex align-items-center flex-column">
      <h2 class="text-1 fs-4 text-center mb-4">
        {{getTranslatedText ("QUOTE_TEMPLATE." +  inputData?.title ) ||  inputData?.title}}
      </h2>
            <i type="button" #closebutton class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
        data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></i>
      <input matInput class="form-control p-2 text-center w-75" type="text" [placeholder]="'COMMON.Enter Name' | translate"
        [(ngModel)]="categoryAddModel.name" name="name" #name="ngModel" required>
      <div *ngIf="name.invalid && throwError" class="alert error_message mb-0">
        <div *ngIf="throwError">
          {{"COMMON.ERRORNAMEREQUIRED" | translate}}
        </div>
      </div>
      <ng-container *ngIf="inputData.title == 'UPDATE TITLE' || inputData.title == 'ADD TITLE'">

        <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.title == 'ADD TITLE' && inputData.title !== 'UPDATE TITLE'" (click)="addTitle(cateogryForm)"><i
          class="fa fa-plus"></i>&nbsp;&nbsp;{{"COMMON.Add" | translate }}</button>
          <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.title !== 'ADD TITLE' && inputData.title == 'UPDATE TITLE'" (click)="updateTitle(cateogryForm)">&nbsp;&nbsp;{{"COMMON.UPDATE" | translate }}</button>

      </ng-container>

      <ng-container *ngIf="inputData.title == 'UPDATE NAME' || inputData.title == 'ADD METHOD'">

        <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.title == 'ADD METHOD' && inputData.title !== 'UPDATE NAME'" (click)="addTitle(cateogryForm)"><i
          class="fa fa-plus"></i>&nbsp;&nbsp;{{"COMMON.Add" | translate }}</button>
          <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.title !== 'ADD METHOD' && inputData.title == 'UPDATE NAME'" (click)="updateTitle(cateogryForm)">&nbsp;&nbsp;{{"COMMON.UPDATE" | translate }}</button>

      </ng-container>

  
    </div>
  </form>
</div>