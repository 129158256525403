import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-create-equote-dialog',
  templateUrl: './create-equote-dialog.component.html',
  styleUrls: ['./create-equote-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CreateEquoteDialogComponent implements OnInit {
  template_name = new FormControl('', [Validators.required]);
  permission
  uid: string;
  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<CreateEquoteDialogComponent>, private router: Router,
    public QuoteTemplateServices:QuoteTemplateService,  private permissionService: PermissionService
    ) { }

  ngOnInit(): void {
    this.permission = this.permissionService.retrivePermission();
    if (!this.permission.includes('eqoute_form_create')) {
      this.router.navigate(['/dash']);
    }
  }
  closeDialog(): void {
    this.dialogRef.close(); 
  }
  generateUID(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  onSubmit(): void {
    if (!this.template_name.invalid) {
      const templateName = this.template_name.value;
      const payload = {
        template_name:templateName,
        template_sub_types: 'custom',
        template_types:'eQuote',
        html: '',
        eQuotes_templates_fields:[],
      };
      this.QuoteTemplateServices.create_eQuote_Form(payload).subscribe(
        (res: any) => {
          if (res) {
            this.dialogRef.close();
            this.router.navigate([`dash/e-quote-formbuilder/${res?.data.id}`]);

          }
        },
        (error) => {
        }
      );
    } else {
      console.log('Form is invalid.');
    }
  }


 
}


