<div class="modal-content cnt-dashboard border-0" *ngIf="!data.categoryId">
  <form (ngSubmit)="subcateogryForm.form.valid" #subcateogryForm="ngForm" novalidate>
    <div class="modal-body bg-gris-1 p-5 pb-3 d-flex align-items-center flex-column">

      <h2 class="text-1 fs-4 text-center mb-4">{{"COMMON.ADDNEWSUBCATEGORY" | translate}}</h2>
      <i #closebutton type="button" class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
        (click)="closeDialog()"></i>
      <mat-form-field class="example-full-width mb-3" appearance="outline" *ngIf="!data.categoryId">
        <mat-label>{{"COMMON.SELECTCATEGORY" | translate}}</mat-label>
        <mat-select [(ngModel)]="subCategoryAddModel.categoryId" name="categoryId" #categoryId="ngModel" required>
          <mat-option *ngFor="let category of categoryList" [value]="category.id">
            {{category.name}}
          </mat-option>
        </mat-select>
        <mat-error class="mt-3" *ngIf="categoryId.invalid && (categoryId.dirty || categoryId.touched)">
          {{"COMMON.ERRORCATEGORYREQUIRED" | translate}}
        </mat-error>
        <!-- <div *ngIf="categoryId.invalid && (categoryId.dirty || categoryId.touched)" class="alert error_message">
          <div *ngIf="categoryId.errors?.required">
            {{"ITEMS.ERRORCATEGORYREQUIRED" | translate}}
          </div>
        </div> -->
      </mat-form-field>
      <mat-form-field  appearance="outline" *ngIf="!data.categoryId">
        <mat-label>{{"ITEMS.ENTERSUBCATEGORYNAME" | translate}}</mat-label>
        <input matInput class="text-1" type="text" placeholder="" [(ngModel)]="subCategoryAddModel.name" name="name"
          #name="ngModel" required>
        <mat-error class="mt-3" *ngIf="name.invalid && (name.dirty || name.touched)">
          {{"COMMON.ERRORSUBCATEGORYREQUIRED" | translate}}
        </mat-error>
        <!-- <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert error_message">
          <div *ngIf="name.errors?.required">
            {{"ITEMS.ERRORSUBCATEGORYREQUIRED" | translate}}
          </div>
        </div> -->
      </mat-form-field>
      <button class="btn btn-1 mt-4 py-2" (click)="addSubCateogry(subcateogryForm)"><i
          class="fa fa-plus"></i>&nbsp;&nbsp;{{"COMMON.Add" | translate}}</button>
    </div>
  </form>
</div>

<div class="modal-content border-0 cnt-dashboard border-r2"  data-backdrop="static" *ngIf="data.categoryId">
  <form (ngSubmit)="subcateogryForm.form.valid" #subcateogryForm="ngForm" novalidate>
    <div class="modal-body bg-gris-1 p-5 d-flex align-items-center flex-column">
      <h2 class="text-1 fs-4 text-center mb-4">{{(data.name?"Update Subcategory" : "COMMON.ADDNEWSUBCATEGORY") | translate}}</h2>
      <i type="button" #closebutton class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
        data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></i>
      <input matInput class="form-control p-2 text-center w-75" type="text" placeholder="Enter Subcategory"
      [(ngModel)]="subCategoryAddModel.name" name="name" #name="ngModel" required>
        <mat-error class="mt-3" *ngIf="name.invalid && subcateogryFormInvalid">
          {{"COMMON.ERRORSUBCATEGORYREQUIRED" | translate}}
        </mat-error>
        <button class="btn btn-1 mt-4 py-2" *ngIf="!data.name" (click)="addSubCateogry(subcateogryForm)"><i
          class="fa fa-plus"></i>&nbsp;&nbsp;{{"COMMON.Add" | translate}}</button>

          <button class="btn btn-1 mt-4 py-2" *ngIf="data.name" (click)="updateSubCateogry(subcateogryForm)">&nbsp;&nbsp;{{"COMMON.UPDATE" | translate}}</button>
    </div>
  </form>
</div>