import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { parsePhoneNumberFromString, isValidNumber, parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import { ModalComponent, ModalModel } from '../modal/modal.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditContactService } from 'src/app/pages/contact-profile/edit-contact/services/edit-contact.service';
import { Contacts } from 'src/app/pages/contact-profile/edit-contact/modal/add-contact-model';
import { AddCategoryComponent } from 'src/app/pages/items/add-category/add-category.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PhoneNumberComponent implements OnInit, OnChanges {
  selectedCountry: any;
  addContactForm: FormGroup;
  formOfContactForm1: FormGroup;
  formOfContactFormSubmit = false;
  openBulkAction:boolean = false;
  // color:any;
  currentIndex:any
  contactModel = new Contacts;
  @Input() isIconShow: boolean;
  @Input() addedNew: boolean=false;
  @Input() table: boolean;
  @Input() businessContact: any
  @Input() countryListArr: any;
  @Input() defaultMerchantCountry: any
  @Input() paddingLeft: boolean;
  @Input() typeOfCustom: any;
  @Input() moduleName: boolean;
  @Input() labelName: any;
  @Input() flag: boolean;
  @Input() notFromEQuote: boolean=true;
  @Input() notFromEQuoteOrg: boolean=false;
  @Input() phoneRequired: boolean=false;
  @Input() fields: any;
  @Output() removeContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() isLabel: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendSelectedContacts: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidNumber: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('content', { static: false }) modal;
  @ViewChild('closeformOfConForm1') closeformOfConForm1;
  @ViewChild('clientTypesForm') clientTypesForm;
  @ViewChild('roleForm') roleForm;
  @ViewChild('customFieldForm') customFieldForm;
  @ViewChild('socialLinkForm') socialLinkForm;
  @ViewChild('closesocialbutton') closesocialbutton;
  @ViewChild('addFormOfContactButton1') addFormOfContactButton1;
  @ViewChild('addFormClientTypeButton') addFormClientTypeButton;
  @ViewChild('addRoleContactButton') addRoleContactButton;
  @ViewChild('fnameRef') fnameRef: ElementRef;
   selectedPhoneNumber: any;
  phoneDataArr: any;
  formContactListArr: any;
  formContactListArrData: any;
  subscriptions: any;
  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private editContactService: EditContactService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,


  ) {
    // this.isIconShow = true;
  }

  async ngOnInit() {
    this.contactBasicInfoFormVal()
    setTimeout(async () => {
      if(this.notFromEQuote){
        await this.getAllListing()
      }
    
      if(this.defaultMerchantCountry){
        
        
        this.selectedCountry = this.defaultMerchantCountry?.country_code || 'US';
        this.businessContact.forEach((value,index)=>{
          if(!value.countryId){
            this.businessContact[index].countryId = this.defaultMerchantCountry?.id
            this.businessContact[index].country_code = this.defaultMerchantCountry?.country_code;
          } else {
            this.businessContact[index].countryId = value.countries.id;
            this.businessContact[index].country_code = value.country_code;
          }
          
        })
      }else{
        this.cdr.detectChanges()
      
      }
    }, 500);
   
    
    
  }
  ngOnChanges(changes): void {
    if(changes?.addedNew?.currentValue==true){
      this.getAllListing();
    }
    this.businessContact.forEach((value, index) => {      
      if (value.mobile_number) {
        let phoneNumber: any = parsePhoneNumberFromString(value.mobile_number, value?.country?.country_code || this.selectedCountry);
        if (phoneNumber) {
          this.selectedPhoneNumber = phoneNumber.number;
          this.businessContact[index].mobile_number = phoneNumber.formatNational();
          // this.businessContact[index].mobile_number = value?.country?.id;
        }
      }
    });
  }

  addFormOfContact(event: any) {
    if (event == 'addFormOfContact') {
      this.addFormOfContactButton1.nativeElement.click();
    }
  }

  contactBasicInfoFormVal() {
      this.addContactForm = this.formBuilder.group(
        {
          formContactId: [''],
        },
      );
      this.formOfContactForm1 = this.formBuilder.group(
        {
          name: ['', Validators.required],
        
        },
      );
  }
  get formOfContact(): { [key: string]: AbstractControl } {
    return this.formOfContactForm1?.controls;
  }

  openBulkActionPopup(event,i){
  this.currentIndex = i
    event.stopPropagation();
    this.formContactListArr.map((x,index)=>{
      if(i === index){
        this.openBulkAction = !this.openBulkAction
      } 
    })
    
  }

  addFormOfContactList1() {
    this.formOfContactFormSubmit = true;
    if (this.formOfContactForm1.invalid) {
      return
    } else {
      const addformOfContactTypesSubs = this.editContactService.addformOfContactTypes({...this.formOfContactForm1.value,label:this.labelName,module_name:this.moduleName}).subscribe((res) => {
        this.closeformOfConForm1.nativeElement.click();
        this.formOfContactForm1.reset();
        this.formOfContactFormSubmit = false;
        this.getAllListing();
      })
      this.subscriptions.push(addformOfContactTypesSubs);
    }
  }

  // async removeItem(i, type) {
  //   const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
  //   const dialogRef = this.dialog.open(ModalComponent, {
  //     width: "450px",
  //     data: dialogData
  //   });
  //   dialogRef.afterClosed().subscribe(async (dialogResult) => {
  //     if (dialogResult) {

  //       if (type == 'removeSocialInfo') {
  //         this.contactSocialInfoArr.splice(i, 1);
  //         this.socialInfo.removeAt(i);
  //       }

  //       if (type == 'removeSocialLink') {
  //         this.addsocialLinkArr.splice(i, 1);
  //       }

  //       if (type == 'removeCustomField') {
  //         this.deleteCustomField(i);
  //       }
  //       if (type == 'deleteFormClientType') {
  //         this.deleteFormClientType(i)
  //       }
  //       if (type == 'deleteformContact') {
  //         this.deleteformContact(i)
  //       }
  //       if (type == 'deleteRoleForm') {
  //         this.deleteRoleForm(i)
  //       }
  //       if (type == 'Delete_Address') {
  //         this.customerAddressArr.splice(i, 1);
  //       }
  //     }
  //   });
  // }


  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }


  /**
   * Change Phone Code on Select item from dropdown.
   */

  changephonenumberselect(event,type:any='') {    
    // this.businessContact[event.index].mobile_number = ""; 
    if (event.index >= 0) {
      if (event.type == 'countryId') {
       
        
        this.selectedCountry = this.countryListArr.find(country => country.id == event.value)?.country_code
 
        
        this.businessContact[event.index].country_code = this.selectedCountry;
        this.businessContact[event.index].countryId = Number(event.value);
      }
      if (event.type == 'countryIdOrg') {
        
        this.selectedCountry = this.countryListArr.find(country => country.id == event.value)?.country_code
 

        this.businessContact[event.index].country_code = this.selectedCountry;
        this.businessContact[event.index].countryId = Number(event.value);
      }
    }
  }
  // add phone number row with blank value
  // addPhoneRow() {
  //   console.log(this.fields,'kkkk')
  //   this.businessContact.push({ value: "" });
  //   this.sendSelectedContacts.emit(this.businessContact)
  // }

  addPhoneRow() {
    // Get the field_length from the received data
    // let fieldLength
    // if(this.fields?.field_length){
    //    fieldLength = this.fields.field_length;

    // }
  
    // Check the current number of rows in businessContact
    // if(fieldLength){
    //   if (this.businessContact.length < fieldLength) {
    //     this.businessContact.push({ value: "" });
    //     this.sendSelectedContacts.emit(this.businessContact);
    //   } else {
    //     this.snackBar.open(`You cannot add more then ${fieldLength} phone numbers` , 'Close', {
    //       duration: 2000,
    //       panelClass: ['custom-snackbar']
    //     });
    //   }
    // }else{
        this.businessContact.push({ value: "" });
        this.sendSelectedContacts.emit(this.businessContact);
      
    // }
   
  }
  


  async getAllListing() {
    try {
      let merchantId = localStorage.getItem('merchant_id');
      console.log("merchant id : ",merchantId);
      const formContactListSubs = await this.editContactService.formContactList({label:this.labelName}).toPromise()
      console.log("formContactListSubs",formContactListSubs);
      this.formContactListArr = formContactListSubs.data;
      this.formContactListArrData = formContactListSubs.data
    } catch (error) {
      console.log("Listing error",error);
    }
  }

  changephonenumberinput(event, i, type) {    
    if (type == 'mobile_number') {
      this.businessContact[i].mobile_number = event;
      this.formatPhoneNumber(event, i);
      this.businessContact[i].order = i;
    }
    if (type == 'name') {
      this.businessContact[i].name = event;
      this.businessContact[i].order = i;
    }
  }

  removeItem(index, type) {
    const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "450px",
      data: dialogData,
      panelClass: 'my-dialog-panel',
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (type == 'phonenumber') {
          this.businessContact.splice(index, 1);
        }
        if (type == 'deleteformContact') {
          this.deleteformContact(index)
        }
        this.openBulkAction = false;
      }
    })

  }

  updateSubcategories(obj) {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: "800px",
      data: {...obj,label:this.labelName,module_name:this.moduleName}
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if(dialogResult){
        this.getAllListing()
      }
      this.openBulkAction = false;
    });
  }

  deleteformContact(id) {
    const deleteformOfContactTypesSubs = this.editContactService.deleteformOfContactTypes(id).subscribe((res) => {
      this.getAllListing();
    })
  }

  formatPhoneNumber(value: any, index): void {

    let inputValue: any = value;    
    if(this.defaultMerchantCountry != undefined){
    this.businessContact[index].country_code = this.defaultMerchantCountry?.country_code
    }
    const selectedCountry = this.businessContact[index]?.country_code || this.businessContact[index]?.country_code
    if (isValidNumber(inputValue, selectedCountry) && parsePhoneNumber(inputValue, (selectedCountry || 'CA') as CountryCode).isPossible()) {
      let phoneNumber: any = parsePhoneNumberFromString(inputValue, selectedCountry);
      this.selectedPhoneNumber = phoneNumber.number;
      this.businessContact[index].mobile_number = phoneNumber.formatNational();
    }
    this.sendSelectedContacts.emit(this.businessContact)
  }

  // changeColor(event) {
  //   this.color = event?.target?.value;
  //   this.formOfContactForm1?.controls['color'].setValue(event?.target?.value);
  // }

  // changeColor1(event) {
  //   this.color = event?.target?.value ? ('#' + event?.target?.value.replace(/#/g, "")) : null;
  //   this.formOfContactForm1?.controls['color'].setValue(this.color);
  // }

}
 