<app-dash-header (dataEvent)="receiveDataFromChild($event)"></app-dash-header>
<div class="container body" style="max-width: none" >
    <div class="row flex-nowrap" style="min-height: 86.6vh;overflow: hidden;" [ngClass]="{'div_resp':openDrawer}">
        <div class="col-h dash_bar_div" (mouseover)="mouseOver()" (mouseout)="mouseLeave()">
            <div class="position-sticky top-0 bg-col-h">
                <i (click)="tach()" class="fas fa-thumbtack ico-absolute"></i>
                <i (click)="noTach()" class="fas fa-slash ico-absolute-2" style="display: none;"></i>
                <div class="d-flex flex-column gap-3 text-center py-4">
                    <a class="rounded-circle mx-auto" routerLink="/dash/profile">
                      <ng-container *ngIf="!userProfilePic">
                        <ng-lottie
                            [options]="options">
                        </ng-lottie>
                      </ng-container>
                       
                        <img *ngIf="userProfilePic" style="width: 60px;height: 60px;"
                            class="rounded-circle img-h object-cover" src="{{userProfilePic}}"></a>
                    <p class="text-white">{{username}}</p>
                </div>
            </div>
            <div class="position-absolute end-0 start-0" style="padding: 0 15px 0 12px;">
                <!-- menu New 12/8/2021 -->

                <!-- listing -->
                <div class="cnt-collapse mb-3">
                    <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-1" aria-expanded="true" aria-controls="collapse-dash-1">
                        <p class="p-h">{{"HOME.Listings" | translate}}</p>
                    </div>
                    <div id="collapse-dash-1" class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-users"></i></div>
                            <a class="align-self-center text-h" href="#">
                                {{"HOME.Overview" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-home"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/home">
                                {{"HOME.DASHBOARD" | translate}}
                            </a>
                        </div> -->
                        <a routerLink="/dash/home" class="d-flex cnt-h position-relative text-decoration-none">
                            <div class="ico-h">
                                <i class="fas fa-home"></i>
                            </div>
                            <span class="align-self-center text-h">
                                {{"HOME.Dashboard Modules" | translate}}
                            </span>
                        </a>
                        <!-- <div *ngIf="permission.includes('access_contact_listing')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-id-badge"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/contacts">
                                {{"HOME.Contact" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission?.includes('access_contact_listing')" routerLink="/dash/contacts" class="d-flex cnt-h position-relative text-decoration-none">
                            <div class="ico-h">
                                <i class="fas fa-id-badge"></i>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Contact" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div *ngIf="permission.includes('access_organization_listing')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fa fa-sitemap"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/organization">
                                {{"HOME.Organization" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('access_organization_listing')" routerLink="/dash/organization" class="d-flex cnt-h position-relative text-decoration-none">
                            <div class="ico-h">
                                <i class="fa fa-sitemap"></i>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.organization" | translate}}
                            </span>
                        </a>
                        
                        
                        <!-- <div *ngIf="permission.includes('access_lead_listing')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-th-large"></i></div>
                            <a class="text-h align-self-center" routerLink="/dash/leads">
                                {{"HOME.Lead" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('access_lead_listing')" routerLink="/dash/leads" class="d-flex cnt-h position-relative text-decoration-none">
                            <div class="ico-h">
                                <i class="fas fa-th-large"></i>
                            </div>
                            <span class="align-self-center text-h">
                                {{"HOME.Lead_and_Deals" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div  class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/listing.png" class="menu-img"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/quote-listing">
                                {{"QUOTE_TEMPLATE.Quotes" |translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative" >
                            <div class="ico-h"><img src="../../../assets/img/menu/list.png" class="menu-img" style="border-radius: 0% !important;"/></div>
                            <a class="align-self-center text-h" routerLink="/dash/quote-listing">
                                {{"QUOTE_TEMPLATE.Quotes" |translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/quote-listing">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/list.png" class="menu-img" style="border-radius: 0% !important;" />
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Quotes" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-dollar-sign mx-2"></i></div>
                            <a class="text-h align-self-center" routerLink="/dash/quote-listing">
                                {{"HOME.Quotes" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-hand-holding-heart"></i></div>
                            <a class="text-h align-self-center" routerLink="#">
                                {{"HOME.Sales_Orders" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-file-contract"></i></div>
                            <a class="text-h align-self-center" routerLink="#">
                                {{"HOME.Invoices" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-hand-holding-usd "></i></div>
                            <a class="text-h align-self-center" routerLink="#">
                                {{"HOME.Payments" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-envelope"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/mail-box">
                                {{"HOME.Email" | translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/payment-listing">
                            <div class="ico-h">
                                <i class="fas fa-money-check-alt"></i>
                            </div>
                            <span class="align-self-center text-h">
                                {{"CONTACT_PROFILE.Payment" | translate}}
                            </span>
                        </a>
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/mail-box">
                            <div class="ico-h">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Email" | translate}}
                            </span>
                        </a>
                        
                    </div>
                </div>
                <!-- listing -->

                <!-- Create -->
                <div class="cnt-collapse mb-3">
                    <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-2" aria-expanded="true" aria-controls="collapse-dash-2">
                        <p class="p-h">{{"HOME.Create" | translate}}</p>
                    </div>
                    <div id="collapse-dash-2" class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <!-- <div class="d-flex cnt-h position-relative" *ngIf="permission.includes('goal_view')">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/goals.png" class="menu-img"/>
                            </div>
                            <a class="align-self-center text-h" routerLink="/dash/tasks">
                                {{"HOME.Goals" | translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/tasks" *ngIf="permission.includes('goal_view')">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/goals.png" class="menu-img"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"HOME.Goals_tasks_reminder" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative" *ngIf="permission.includes('task_view')">
                            <div class="ico-h"><img src="../../../assets/img/menu/task.png" class="menu-img"/></div>
                            <a class="align-self-center text-h" routerLink="/dash/tasks">
                                {{"HOME.Tasks" | translate}}
                            </a>
                        </div> -->
                        <!-- <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/tasks" *ngIf="permission.includes('task_view')">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/task.png" class="menu-img"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"HOME.Tasks" | translate}}
                            </span>
                        </a> -->
                        
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/reminder.png" class="menu-img"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/tasks">
                                {{"HOME.Reminders" | translate}}
                            </a>
                        </div> -->
                        <!-- <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/tasks">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/reminder.png" class="menu-img"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"HOME.Reminders" | translate}}
                            </span>
                        </a> -->
                        
                        <!-- <div class="d-flex cnt-h position-relative" >
                            <div class="ico-h"><img src="../../../assets/img/menu/select-all.png" class="menu-img"/></div>
                            <a class="align-self-center text-h" routerLink="/dash/items">
                                {{"QUOTE_TEMPLATE.Items" |translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/items">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/select-all.png" class="menu-img"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Items" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative" >
                            <div class="ico-h"><img src="../../../assets/img/menu/automation.png" class="menu-img"/></div>
                            <a class="align-self-center text-h" routerLink="/dash/automation">
                                {{"AUTOMATION.Automations" |translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/automation">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/automation.png" class="menu-img"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"AUTOMATION.Automations" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-th"></i></div>
                            <a class="text-h align-self-center" routerLink="/dash/items">
                                {{"HOME.Items" | translate}}
                            </a>
                        </div> -->
                    </div>
                </div>
                <!-- Create -->

                <!-- Reports -->
                <!-- <div class="cnt-collapse mb-3">
                    <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-3" aria-expanded="true" aria-controls="collapse-dash-3">
                        <p class="p-h">{{"HOME.Reports" | translate}}</p>
                    </div>
                    <div id="collapse-dash-3" class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-dollar-sign mx-2"></i></div>
                            <a class="text-h align-self-center" [routerLink]="['quote-template']">
                                {{"HOME.Reports" | translate}}
                            </a>
                        </div>
                    </div>
                </div> -->
                <!-- Reports -->

                <!-- Users -->
                <div class="cnt-collapse mb-3">
                    <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-4" aria-expanded="false" aria-controls="collapse-dash-4">
                        <p class="p-h">{{"COMMON.Users" | translate}}</p>
                    </div>
                    <div id="collapse-dash-4" class="accordion-collapse collapse " *ngIf="permission.includes('system_user_team_access')"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-users"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/system-users">
                                {{"HOME.Team_management" | translate}}
                            </a>
                        </div> -->
                        <!-- <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/system-users">
                            <div class="ico-h"><i class="fas fa-users"></i></div>
                            <span class="align-self-center text-h">
                                {{"HOME.Team_management" | translate}}
                            </span>
                        </a> -->

                        <a class="d-flex cnt-h position-relative text-decoration-none" href="/dash/system-users">
                            <div class="ico-h"><i class="fas fa-users"></i></div>
                            <span class="align-self-center text-h">
                                {{"HOME.Team_management" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div  *ngIf="permission.includes('system_user_role_access')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-bullseye"></i></div>
                            <a class="text-h align-self-center" routerLink="/dash/role/role">
                                {{"HOME.Roles" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('system_user_role_access')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/role/role">
                            <div class="ico-h"><i class="fas fa-bullseye"></i></div>
                            <span class="align-self-center text-h">
                                {{"HOME.Roles" | translate}}
                            </span>
                        </a>
                        
                        
                        <!-- <div *ngIf="permission.includes('system_user_create')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-th"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/systemuser/user">
                                {{"HOME.Create_a_User" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('system_user_create')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/systemuser/user">
                            <div class="ico-h"><i class="fas fa-th"></i></div>
                            <span class="align-self-center text-h">
                                {{"HOME.Create_a_User" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div *ngIf="permission.includes('system_user_team_access')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"> <img src="../../../assets/img/menu/team.png" class="team-img"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/systemuser/team" >
                                {{"HOME.Create_a_Team" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('system_user_team_access')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/systemuser/team">
                            <div class="ico-h"> <img src="../../../assets/img/menu/team.png" class="team-img"/></div>
                            <span class="align-self-center text-h">
                                {{"HOME.Create_a_Team" | translate}}
                            </span>
                        </a>
                        
                    </div>
                </div>
                <!-- Users -->

                <!-- Templates -->
                <div class="cnt-collapse mb-3">
                    <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-5" aria-expanded="false" aria-controls="collapse-dash-5">
                        <p class="p-h">{{"HOME.Templates" | translate}}</p>
                    </div>
                    <div id="collapse-dash-5" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-dollar-sign mx-2"></i></div>
                            <a class="align-self-center text-h" routerLink="/dash/quote-template">
                                {{"HOME.Quote" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-copy"></i></div>
                            <a class="align-self-center text-h" routerLink="#">
                                {{"HOME.eQuote" | translate}}
                            </a>
                        </div> -->
                        <!-- <div *ngIf="permission.includes('email_template')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"> <img src="../../../assets/img/menu/email-template.png" class="menu-img"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/edit-qoute-template">
                                Edit Template
                            </a>
                        </div> -->
                        <!-- <div *ngIf="permission.includes('email_template')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"> <img src="../../../assets/img/menu/email-template.png" class="menu-img"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/email-listing">
                                {{"HOME.Email_template" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('email_template')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/email-listing">
                            <div class="ico-h"> <img src="../../../assets/img/menu/email-template.png" class="menu-img"/></div>
                            <span class="align-self-center text-h">
                                {{"HOME.Email_template" | translate}}
                            </span>
                        </a>
                        
                        
                        <!-- <div *ngIf="permission.includes('email_template')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"> <img src="assets/img/menu/lead_form_icon.jpg" class="menu-img" style="border-radius: 30% !important;" /></div>
                            <a class="text-h align-self-center" routerLink="/dash/lead-form">
                                {{"HOME.Lead Form" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('access_lead_form')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/lead-form">
                            <div class="ico-h"> 
                                <img src="assets/img/menu/lead_form_icon.jpg" class="menu-img" style="border-radius: 30% !important;" />
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Lead Form" | translate}}
                                <span id="tooltipId" class="ml-2" matTooltip="Lead forms help you collect lead information into the CRM." matTooltipPosition="right">
                                    <app-custom-icon  icon="view"></app-custom-icon>
                                    </span>
                            </span>
                            
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative" >
                            <div class="ico-h"><img src="../../../assets/img/menu/template.png" class="menu-img img-plans" style="position: relative;border-radius: 12% !important; width:31px; height:31px;" /></div>
                            <a class="align-self-center text-h" routerLink="/dash/quote-template">
                                {{"QUOTE_TEMPLATE.Quotes Template" |translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" *ngIf="permission.includes('access_quote_templates')" routerLink="/dash/quote-template">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/template.png" class="menu-img img-plans" style="position: relative; border-radius: 12% !important; width: 31px; height: 31px;" />
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Quote Templates" | translate}}
                            </span>
                        </a> 
                        <a class="d-flex cnt-h position-relative text-decoration-none"  *ngIf="permission.includes('access_eqoute_listing')" routerLink="/dash/e-quote-templates">
                            <div class="ico-h">
                                <img src="../../../assets/img/eQuoteForms.png" class="menu-img img-plans" style="position: relative; border-radius: 12% !important; width: 31px; height: 31px;" />
                            </div>
                            <span class="align-self-center text-h">
                                {{"QUOTE_TEMPLATE.eQuote Forms" | translate}}
                                <span id="tooltipId" class="ml-2" matTooltip="eQuote forms are extremely useful for clients, prospects, and sales teams to submit quote requests in a simpler way than traditional contact forms. They resemble eCommerce pages but are specifically designed for requesting quotes.
                                You can use eQuotes on your website to capture quote requests, share them via WhatsApp, Facebook, or email, and access them from any internet-connected device. Additionally, eQuotes can be utilized by your sales team to create quotes on the fly." matTooltipPosition="right">
                                    <app-custom-icon  icon="view"></app-custom-icon>
                                </span>
                            </span>
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-cog"></i></div>
                            <a class="text-h align-self-center" routerLink="#">
                                {{"HOME.Automations" | translate}}
                            </a>
                        </div> -->
                    </div>
                </div>
                <!-- Templates -->

                <!-- Configuration -->
                <div class="cnt-collapse mb-3">
                    <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-6" aria-expanded="false" aria-controls="collapse-dash-6">
                        <p class="p-h">{{"COMMON.Settings" | translate}}</p>
                    </div>
                    <div id="collapse-dash-6" class="accordion-collapse collapse "
                        aria-labelledby="panelsStayOpen-headingOne">
                        <!-- <div *ngIf="permission.includes('business_profile')"  class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/businessProfile.png" class="business-profile-img img-plans" style=" border-radius: 15% !important;"/></div>
                            <a class="align-self-center text-h" routerLink="/dash/business-profile">
                                {{"HOME.Business_Profile" | translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/business-profile">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/businessProfile.png" class="business-profile-img img-plans" style="border-radius: 15% !important;" />
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Business Profile" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/user_profile.png" class="menu-img img-plans" style="width:31px; height:31px;"/></div>
                            <a class="align-self-center text-h" routerLink="/dash/profile">
                                {{"HOME.User_Profile" | translate}}
                            </a>
                        </div> -->
                        <a class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/profile">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/user_profile.png" class="menu-img img-plans" style="width:31px; height:31px;"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.User_Profile" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div *ngIf="permission.includes('billing')"  class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/billing.png" class="menu-img img-plans" style="width:31px; height:31px;"/></div>
                            <a class="text-h align-self-center"  routerLink="/dash/profile" [queryParams]="{tab: 'nav-Billing-tab'}">
                                {{"HOME.Billing" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('billing')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/profile" [queryParams]="{tab: 'nav-Billing-tab'}">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/billing.png" class="menu-img img-plans" style="width:31px; height:31px;"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Billing" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div *ngIf="permission.includes('plan')"  class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/plans.png" class="menu-img img-plans" style="width:31px; height:31px;"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/profile" [queryParams]="{tab: 'nav-plan-tab'}">
                                {{"HOME.Plan" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('plan')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/profile" [queryParams]="{tab: 'nav-plan-tab'}">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/plans.png" class="menu-img img-plans" style="width:31px; height:31px;"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"COMMON.Plan" | translate}}
                            </span>
                        </a>
                        
                        <!-- <div *ngIf="permission.includes('dns') || permission.includes('verify_email')" class="d-flex cnt-h position-relative">
                            <div class="ico-h"><img src="../../../assets/img/menu/dns.png" class="menu-img"/></div>
                            <a class="text-h align-self-center" routerLink="/dash/mail-server">
                                {{"HOME.DNS" | translate}}
                            </a>
                        </div> -->
                        <a *ngIf="permission.includes('dns') || permission.includes('verify_email')" class="d-flex cnt-h position-relative text-decoration-none" routerLink="/dash/mail-server">
                            <div class="ico-h">
                                <img src="../../../assets/img/menu/dns.png" class="menu-img"/>
                            </div>
                            <span class="align-self-center text-h">
                                {{"HOME.DNS" | translate}}
                            </span>
                        </a>                        
                       
                    </div>
                </div>
                <!-- Configuration -->

                <!-- API's -->
                <!-- <div class="cnt-collapse mb-3"> -->
                    <!-- <div class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-dash-7" aria-expanded="true" aria-controls="collapse-dash-7">
                        <p class="p-h">{{"HOME.API's" | translate}}</p>
                    </div>
                    <div id="collapse-dash-7" class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fab fa-medium-m"></i></div>
                            <a class="align-self-center text-h" routerLink="#">
                                {{"HOME.Mexico_CFDI" | translate}}
                            </a>
                        </div>
                        <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-stopwatch"></i></div>
                            <a class="align-self-center text-h" routerLink="#">
                                {{"HOME.Quickbooks" | translate}}
                            </a>
                        </div> -->
                      
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fas fa-envelope"></i></div>
                            <a class="text-h align-self-center" routerLink="/dash/mail-server">
                                {{"HOME.OutGoing_server" | translate}}
                            </a>
                        </div> -->
                        <!-- <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fab fa-stripe-s"></i></div>
                            <a class="text-h align-self-center" routerLink="#">
                                {{"HOME.Stripe" | translate}}
                            </a>
                        </div>
                        <div class="d-flex cnt-h position-relative">
                            <div class="ico-h"><i class="fab fa-stripe-s"></i></div>
                            <a class="text-h align-self-center" routerLink="/dash/meetings-schedules">
                                {{"HOME.Meeting_schedule" | translate}}
                            </a>
                        </div>
                    </div> -->
                <!-- </div> -->
                <!-- API's -->
            </div>
        </div>
       <div id="drawer"  class="menu_bar p-0 h-100" style="width: 0px;">
        <!-- <div class="menu-btn p-2"  (click)="openSideBar()">
            <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83333 22H31.1667C32.175 22 33 21.175 33 20.1667C33 19.1583 32.175 18.3333 31.1667 18.3333H1.83333C0.825 18.3333 0 19.1583 0 20.1667C0 21.175 0.825 22 1.83333 22ZM1.83333 12.8333H31.1667C32.175 12.8333 33 12.0083 33 11C33 9.99167 32.175 9.16667 31.1667 9.16667H1.83333C0.825 9.16667 0 9.99167 0 11C0 12.0083 0.825 12.8333 1.83333 12.8333ZM0 1.83333C0 2.84167 0.825 3.66667 1.83333 3.66667H31.1667C32.175 3.66667 33 2.84167 33 1.83333C33 0.825 32.175 0 31.1667 0H1.83333C0.825 0 0 0.825 0 1.83333Z" fill="#CBDBE4"/>
            </svg>        
        </div> -->
      
   
           
            <mat-drawer [(opened)]="openDrawer" from="left" position="start" #drawerAddTask mode="over" style="min-height: 86.6vh;z-index:99999;">           
                <div class="">
                    <div class="position-sticky top-0 bg-col-h">
                        <i (click)="tach()" class="fas fa-thumbtack ico-absolute"></i>
                        <i (click)="noTach()" class="fas fa-slash ico-absolute-2" style="display: none;"></i>
                        <div class="d-flex flex-column gap-3 text-center py-4">
                            <a class="rounded-circle mx-auto xs-6:w-[303px] xs-6:h-[303px]" (click)="closeSideBar()" routerLink="/dash/profile">
                              <ng-container *ngIf="!userProfilePic">
                                <ng-lottie
                                    [options]="options">
                                </ng-lottie>
                              </ng-container>
                               
                                <img *ngIf="userProfilePic" class="rounded-circle object-cover w-[60px] h-[60px] xs-6:!w-[303px] xs-6:!h-[303px]" src="{{userProfilePic}}"></a>
                            <p class="text-white">{{username}}</p>
                        </div>
                    </div>
                    <div class="position-absolute bg_blue end-0 start-0" style="padding: 0 15px 0 12px;">
                        <!-- menu New 12/8/2021 -->
        
                        <!-- listing -->
                        <div class="cnt-collapse mb-3">
                            <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-1" aria-expanded="true" aria-controls="collapse-dash-1">
                                <p class="p-h">{{"HOME.Listings" | translate}}</p>
                            </div>
                            <div id="collapse-dash-1" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h"><i class="fas fa-users"></i></div>
                                    <a class="align-self-center text-h" href="#">
                                        {{"HOME.Overview" | translate}}
                                    </a>
                                </div> -->
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-home"></i></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/home">
                                        {{"HOME.Dashboard Modules" | translate}}
                                    </a>
                                </div>
                                <div *ngIf="permission.includes('access_contact_listing')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-id-badge"></i></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/contacts">
                                        {{"COMMON.Contact" | translate}}
                                    </a>
                                </div>
                                <div *ngIf="permission.includes('access_organization_listing')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fa fa-sitemap"></i></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/organization">
                                        {{"COMMON.organization" | translate}}
                                    </a>
                                </div>
                                
                                <div *ngIf="permission.includes('access_lead_listing')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-th-large"></i></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/leads">
                                        {{"COMMON.Lead" | translate}}
                                    </a>
                                </div>
                                <!-- <div  class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/listing.png" class="menu-img2"/></div>
                                    <a class="text-h2 align-self-center" routerLink="/dash/quote-listing">
                                        {{"QUOTE_TEMPLATE.Quotes" |translate}}
                                    </a>
                                </div> -->
                                <div class="d-flex gap-3 cnt-h2 position-relative" *ngIf="permission.includes('access_quote_listing')">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/list.png" class="menu-img2" style="border-radius: 0% !important;"/></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/quote-listing">
                                        {{"COMMON.Quotes" |translate}}
                                    </a>
                                </div>
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-dollar-sign mx-2"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="/dash/quote-listing">
                                        {{"HOME.Quotes" | translate}}
                                    </a>
                                </div> -->
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-hand-holding-heart"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="#">
                                        {{"HOME.Sales_Orders" | translate}}
                                    </a>
                                </div> -->
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-file-contract"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="#">
                                        {{"HOME.Invoices" | translate}}
                                    </a>
                                </div> -->
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-hand-holding-usd "></i></div>
                                    <a class="text-h2 align-self-center" routerLink="#">
                                        {{"HOME.Payments" | translate}}
                                    </a>
                                </div> -->
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-envelope"></i></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/mail-box">
                                        {{"COMMON.Email" | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- listing -->
        
                        <!-- Create -->
                        <div class="cnt-collapse mb-3">
                            <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-2" aria-expanded="true" aria-controls="collapse-dash-2">
                                <p class="p-h">{{"HOME.Create" | translate}}</p>
                            </div>
                            <div id="collapse-dash-2" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <div class="d-flex gap-3 cnt-h2 position-relative" *ngIf="permission.includes('goal_view')">
                                    <div class="ico-h2">
                                        <img src="../../../assets/img/menu/goals.png" class="menu-img2"/>
                                    </div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/tasks">
                                        {{"COMMON.Goals" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative" *ngIf="permission.includes('task_view')">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/task.png" class="menu-img2"/></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/tasks">
                                        {{"COMMON.Tasks" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/reminder.png" class="menu-img2"/></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/tasks">
                                        {{"COMMON.Reminders" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative hide_menu" >
                                    <div class="ico-h2"><img src="../../../assets/img/menu/select-all.png" class="menu-img2"/></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/items">
                                        {{"COMMON.Items" |translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative hide_menu" >
                                    <div class="ico-h2"><img src="../../../assets/img/menu/automation.png" class="menu-img2"/></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/automation">
                                        {{"AUTOMATION.Automations" |translate}}
                                    </a>
                                </div>
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-th"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="/dash/items">
                                        {{"HOME.Items" | translate}}
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <!-- Create -->
        
                        <!-- Reports -->
                        <!-- <div class="cnt-collapse mb-3">
                            <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-3" aria-expanded="true" aria-controls="collapse-dash-3">
                                <p class="p-h">{{"HOME.Reports" | translate}}</p>
                            </div>
                            <div id="collapse-dash-3" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-dollar-sign mx-2"></i></div>
                                    <a class="text-h2 align-self-center" [routerLink]="['quote-template']">
                                        {{"HOME.Reports" | translate}}
                                    </a>
                                </div>
                            </div>
                        </div> -->
                        <!-- Reports -->
        
                        <!-- Users -->
                        <div class="cnt-collapse mb-3 hide_menu">
                            <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-4" aria-expanded="true" aria-controls="collapse-dash-4">
                                <p class="p-h">{{"COMMON.Users" | translate}}</p>
                            </div>
                            <div id="collapse-dash-4" class="accordion-collapse collapse show" *ngIf="permission.includes('system_user_team_access')"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-users"></i></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/system-users">
                                        {{"HOME.Team_management" | translate}}
                                    </a>
                                </div>
                                <div  *ngIf="permission.includes('system_user_role_access')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-bullseye"></i></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/role/role">
                                        {{"HOME.Roles" | translate}}
                                    </a>
                                </div>
                                <div *ngIf="permission.includes('system_user_create')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-th"></i></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/systemuser/user">
                                        {{"HOME.Create_a_User" | translate}}
                                    </a>
                                </div>
                                <div *ngIf="permission.includes('system_user_team_access')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"> <img src="../../../assets/img/menu/team.png" class="team-img"/></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/systemuser/team" >
                                        {{"HOME.Create_a_Team" | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Users -->
        
                        <!-- Templates -->
                        <div class="cnt-collapse mb-3 hide_menu">
                            <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-5" aria-expanded="true" aria-controls="collapse-dash-5">
                                <p class="p-h">{{"HOME.Templates" | translate}}</p>
                            </div>
                            <div id="collapse-dash-5" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-dollar-sign mx-2"></i></div>
                                    <a class="align-self-center text-h2" routerLink="/dash/quote-template">
                                        {{"HOME.Quote" | translate}}
                                    </a>
                                </div> -->
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-copy"></i></div>
                                    <a class="align-self-center text-h2" routerLink="#">
                                        {{"HOME.eQuote" | translate}}
                                    </a>
                                </div> -->
                                <!-- <div *ngIf="permission.includes('email_template')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"> <img src="../../../assets/img/menu/email-template.png" class="menu-img2"/></div>
                                    <a class="text-h2 align-self-center" routerLink="/dash/edit-qoute-template">
                                        Edit Template
                                    </a>
                                </div> -->
                                <div *ngIf="permission.includes('email_template')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"> <img src="../../../assets/img/menu/email-template.png" class="menu-img2"/></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/email-listing">
                                        {{"HOME.Email_template" | translate}}
                                    </a>
                                </div>
                                
                                <div *ngIf="permission.includes('email_template')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"> <img src="assets/img/menu/lead_form_icon.jpg" class="menu-img2" style="border-radius: 30% !important;" /></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/lead-form">
                                        {{"COMMON.Lead Form" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative" >
                                    <div class="ico-h2"><img src="../../../assets/img/menu/template.png" class="menu-img2" style="position: relative;top: 6px;" /></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/quote-template">
                                        {{"COMMON.Quote Templates" |translate}}
                                    </a>
                                </div>
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-cog"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="#">
                                        {{"HOME.Automations" | translate}}
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <!-- Templates -->
        
                        <!-- Configuration -->
                        <div class="cnt-collapse mb-3 hide_menu">
                            <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-6" aria-expanded="true" aria-controls="collapse-dash-6">
                                <p class="p-h">{{"COMMON.Settings" | translate}}</p>
                            </div>
                            <div id="collapse-dash-6" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <div *ngIf="permission.includes('business_profile')"  class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/businessProfile.png" class="business-profile-img img-plans"/></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/business-profile">
                                        {{"COMMON.Business Profile" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/user_profile.png" class="menu-img2 img-plans"/></div>
                                    <a class="align-self-center text-h2" (click)="closeSideBar()" routerLink="/dash/profile">
                                        {{"COMMON.User_Profile" | translate}}
                                    </a>
                                </div>
                                <div *ngIf="permission.includes('billing')"  class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/billing.png" class="menu-img2"/></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()"  routerLink="/dash/profile" [queryParams]="{tab: 'nav-Billing-tab'}">
                                        {{"COMMON.Billing" | translate}}
                                    </a>
                                </div>
                                
                                <div *ngIf="permission.includes('plan')"  class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/plans.png" class="menu-img2 img-plans"/></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/profile" [queryParams]="{tab: 'nav-plan-tab'}">
                                        {{"COMMON.Plan" | translate}}
                                    </a>
                                </div>
                                <div *ngIf="permission.includes('dns') || permission.includes('verify_email')" class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><img src="../../../assets/img/menu/dns.png" class="menu-img2"/></div>
                                    <a class="text-h2 align-self-center" (click)="closeSideBar()" routerLink="/dash/mail-server">
                                        {{"HOME.DNS" | translate}}
                                    </a>
                                </div>

                               
                            </div>
                        </div>
                        <!-- Configuration -->
        
                        <!-- API's -->
                        <!-- <div class="cnt-collapse mb-3"> -->
                            <!-- <div class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-dash-7" aria-expanded="true" aria-controls="collapse-dash-7">
                                <p class="p-h">{{"HOME.API's" | translate}}</p>
                            </div>
                            <div id="collapse-dash-7" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fab fa-medium-m"></i></div>
                                    <a class="align-self-center text-h2" routerLink="#">
                                        {{"HOME.Mexico_CFDI" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-stopwatch"></i></div>
                                    <a class="align-self-center text-h2" routerLink="#">
                                        {{"HOME.Quickbooks" | translate}}
                                    </a>
                                </div> -->
                              
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fas fa-envelope"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="/dash/mail-server">
                                        {{"HOME.OutGoing_server" | translate}}
                                    </a>
                                </div> -->
                                <!-- <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fab fa-stripe-s"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="#">
                                        {{"HOME.Stripe" | translate}}
                                    </a>
                                </div>
                                <div class="d-flex gap-3 cnt-h2 position-relative">
                                    <div class="ico-h2"><i class="fab fa-stripe-s"></i></div>
                                    <a class="text-h2 align-self-center" routerLink="/dash/meetings-schedules">
                                        {{"HOME.Meeting_schedule" | translate}}
                                    </a>
                                </div>
                            </div> -->
                        <!-- </div> -->
                        <!-- API's -->
                    </div>
                </div>
            </mat-drawer>
       
        </div>
        <router-outlet style="width: 0; padding: 0; flex-shrink: 1"></router-outlet>

       <!-- <div class="col-h-2 p-0 cnt-quote-template"> -->
           <!--   <div class="d-flex position-sticky top-0 bg-col-h-2">
                <div class="cnt-ico">
                    <i class="far fa-comment-alt"></i>
                </div>
                <div class="col justify-content-center div-switch-button d-none">
                    <div class="switch-button shadow-sm align-self-center">
                        <input class="switch-button-checkbox" type="checkbox">
                        <label class="switch-button-label" for="">
                            <span class="switch-button-label-span">{{"PROFILE.Clients" | translate}}</span>
                            <span class="switch-button-label-span2">{{"PROFILE.Team" | translate}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center end-0 start-0 position-absolute" style="height: 92.5%;">
                <div class="cnt-chat">
                    <div (click)="seeChat()" class="position-relative rounded-circle burble-contact"
                        style="cursor: pointer;">
                        <div class="rounded-pill ico-noti">99+</div>
                        <img class="rounded-circle" style="width: 50px;" src="assets/img/home/cr7.jpg">
                        <div class="rounded-circle ico-status"></div>
                    </div>
                    <div class="position-relative">
                        <div class="rounded-pill ico-noti">1</div>
                        <img class="rounded-circle" style="width: 50px;" src="assets/img/home/cr7.jpg">
                        <div class="rounded-circle ico-status" style="background: #767676;"></div>
                    </div>
                    <div class="position-relative">
                        <div class="rounded-pill  ico-noti">5</div>
                        <img class="rounded-circle" style="width: 50px;" src="assets/img/home/cr7.jpg">
                        <div class="rounded-circle ico-status" style="background: #ffb800;"></div>
                    </div>
                </div>
                <div class="cnt-sms col">
                    <div class="align-self-center text-center p-4 text-sms">
                        <i class="far fa-comment-alt" style="font-size: 100px"></i>
                        <p>{{"PROFILE.Select a contact" | translate}}</p>
                    </div>
                    <div class="cnt-message" style="display: none">
                        <div class="cnt-chat-user">
                            <img class="rounded-circle" width="50px" height="50px" src="assets/img/home/cr7.jpg">
                            <p class="align-self-center text-1 ms-2">Mark zukaritas</p>
                            <i class="btn-points fas fa-ellipsis-h"></i>
                            <a class="fs-4 text-7 position-absolute m-2 p-1 top-0 end-0 " role="button">✕</a>
                        </div>
                        <div class="d-flex align-items-end m-3 mb-4 position-relative">
                            <img class="rounded-circle me-2" width="30px" height="30px" src="assets/img/home/cr7.jpg">
                            <div class="position-relative">
                                <div class="burble-chat">
                                    Sos un crack!
                                </div>
                                <i class="fas fa-caret-down ico-burble"></i>
                            </div>
                            <p class="text-2 position-absolute" style="bottom: -15px; left: 55px; font-size: 13px">
                                DEC 15nd 2020, 10:34 bm
                            </p>
                        </div>
                        <div class="d-flex align-items-end m-3 mb-4 justify-content-end position-relative">
                            <div class="position-relative">
                                <div class="burble-chat">
                                    a
                                </div>
                                <i class="fas fa-caret-down ico-burble-2"></i>
                            </div>
                            <p class="text-2 position-absolute" style="bottom: -15px; right: 15px; font-size: 13px">
                                DEC 16nd 2020, 08:25 am
                            </p>
                        </div>
                        <div class="d-flex align-items-end m-3 mb-4 position-relative">
                            <img class="rounded-circle me-2" width="30px" height="30px" src="assets/img/home/cr7.jpg">
                            <div class="position-relative">
                                <div class="burble-chat">
                                    SIUUUUUU
                                </div>
                                <i class="fas fa-caret-down ico-burble"></i>
                            </div>
                            <p class="text-2 position-absolute" style="bottom: -15px; left: 55px; font-size: 13px">
                                DEC 16nd 2020, 08:25 am
                            </p>
                        </div>
                        <div class="d-flex align-items-end m-3 mb-4 justify-content-end position-relative">
                            <div class="position-relative">
                                <div class="burble-chat">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                    mollit
                                    anim id est laborum."
                                </div>
                                <i class="fas fa-caret-down ico-burble-2"></i>
                            </div>
                            <p class="text-2 position-absolute" style="bottom: -15px; right: 15px; font-size: 13px">
                                DEC 16nd 2020, 08:25 am
                            </p>
                        </div>
                        <div class="d-flex m-3">
                            <form class="input-group align-self-center">
                                <input type="text" class="form-control rounded-pill border-end-0 rounded-end"
                                    placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <button type="submit"
                                    class="fas fa-paper-plane input-group-text text-white border-start-0 rounded-pill rounded-start"
                                    style="background: #03c0fc !important"></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>-->
        <!-- </div>  -->
    </div>
</div>



<app-dash-footer></app-dash-footer>