<div class="row">
  <form name="form" [formGroup]="fg" class="w-100-p" (mouseenter)="openBulkAction = false">
    <div formArrayName="lineItems" *ngFor="let item of fg?.get('lineItems')['controls']; let i = index">
      <div class="row" id="address-container-{{ i }}">
        <div class="col-lg-12 mb-3 mt-4">
          <div class="d-flex align-items-center gap-2">
            <h3 class="color-1 m-0 edit-address-title" id="AddressTitle{{ i }}">
              {{ getTranslatedText('COMMON.' + customerAddressArr[i]?.title) || customerAddressArr[i]?.title }}
            </h3>
            <div fxFlex="25" class="mobile_name" *ngIf="dropdown">
              <mat-form-field appearance="outline" floatLabel="always" id="AddressTitleInput{{ i }}">
                <mat-select class="text-1" placeholder="Add Address Name" (selectionChange)="addFormOfContact($event)"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="customerAddressArr[i].title"
                  (ngModelChange)="changeAddField($event, 'addTitle', i)">
                  <!-- [(ngModel)]="contact?.name" -->

                  <mat-option class="text-1" value="">
                    {{"CONTACT_PROFILE.select_address_name" | translate}}
                  </mat-option>
                  <mat-option id="option_num" class="text-1 " *ngFor="let formContactList of formContactListArr; let i = index"
                    [value]="formContactList.name">
                    <div class="dropdown navbar text-right p-0 px-2" style="background: transparent">
                      <button class="menu-btn2 fs-2 text-right" id="sideMenuDropDown"
                        style="background: none; border:none;" aria-expanded="false"
                        (click)="openBulkActionPopup($event,i)">
                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16.6802" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="11.9986" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="7.31693" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="12" cy="12" r="11.25" stroke="#03C0FC" stroke-width="1.5" />
                        </svg>
                      </button>
                      <div class="shadow-sm dropdown-menu bg-0 dropdown-1 flex-column gap-3 show"
                        aria-labelledby="sideMenuDropDown" style="top: 55%; width: 10%;
                    left: 24%;" *ngIf="openBulkAction && currentIndex === i">
                        <div class="px-2 flex flex-col gap-1">
                          <div class="flex items-center gap-3"
                            (click)="removeItem(formContactList.id,'deleteformContact')">
                            <mat-icon class="vertical-align-baseline m-0">
                              <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                                src="assets/img/home/delete.svg">
                            </mat-icon> {{"COMMON.Delete" | translate}}

                          </div>
                          <div class="flex items-center gap-3 px-1" (click)="updateSubcategories(formContactList)">
                            <i class="fa fa-edit edit-icon" style="color: #03C0FC;"></i>
                            {{"COMMON.Edit" | translate}}
                          </div>

                        </div>

                      </div>
                    </div>
                    <div (click)="openBulkAction = false">{{getTranslatedText ("COMMON." +
                      formContactList.name) || formContactList.name}} </div>
                  </mat-option>
                  <!-- <mat-option id="option_num" class="text-1"
                    *ngFor="let formContactList of formContactListArr"
                    [value]="formContactList.name">
                    <mat-icon class="vertical-align-baseline">
                        <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                            src="assets/img/home/delete.svg"
                            (click)="removeItem(formContactList.id,'deleteformContact')">
                    </mat-icon>
                    <i class="fa fa-edit edit-icon mr-2" style="color: #03C0FC;" (click)="updateSubcategories(formContactList)"></i>
                    {{getTranslatedText ("CONTACT_PROFILE." + formContactList.name) || formContactList.name}} 
                </mat-option> -->
                  <mat-option class="text-1" value="addFormOfContact">
                    <mat-icon class="vertical-align-baseline">
                      <svg width="30" height="35" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="27.5" cy="27.5" r="27.5" fill="#03C0FC" />
                        <path
                          d="M38.2143 29.2857H29.2857V38.2143C29.2857 39.1964 28.4821 40 27.5 40C26.5179 40 25.7143 39.1964 25.7143 38.2143V29.2857H16.7857C15.8036 29.2857 15 28.4821 15 27.5C15 26.5179 15.8036 25.7143 16.7857 25.7143H25.7143V16.7857C25.7143 15.8036 26.5179 15 27.5 15C28.4821 15 29.2857 15.8036 29.2857 16.7857V25.7143H38.2143C39.1964 25.7143 40 26.5179 40 27.5C40 28.4821 39.1964 29.2857 38.2143 29.2857Z"
                          fill="white" />
                      </svg>
                    </mat-icon>
                    {{"COMMON.Add" | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button class="nav-link add-btn color-1 me-4" (click)="editAddField(i)" id="editButton{{ i }}">
              <i class="fas fa-edit"></i>
            </button>
            <button class="nav-link add-btn color-1 me-4 d-none" (click)="saveAddField(i)" id="checkButton{{ i }}">
              <i class="fas fa-check"></i>
            </button>


          </div>
        </div>
      </div>



      <!-- 
      <div class="row" id="address-container-{{ i }}" >
        <div class="col-lg-12 mb-3 mt-4">
          <div class="d-flex align-items-center gap-2">
            <h3 class="color-1 m-0 edit-address-title" id="AddressTitle{{ i }}">
              {{ getTranslatedText('CONTACT_PROFILE.' + customerAddressArr[i]?.title) || customerAddressArr[i]?.title }}
            </h3>
            <input
              type="text"
              class="form-control d-none col-lg-4"
              (change)="changeAddField($event, 'addTitle', i)"
              value="{{ address.title }}"
              id="AddressTitleInput{{ i }}"
            />
            <button
              class="nav-link add-btn color-1 me-4"
              (click)="editAddField(i)"
              id="editButton{{ i }}"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              class="nav-link add-btn color-1 me-4 d-none"
              (click)="saveAddField(i)"
              id="checkButton{{ i }}"
            >
              <i class="fas fa-check"></i>
            </button>
          </div>
        </div>
      </div> -->
      <div [formGroupName]="i" fxLayout="row" [ngClass]="{ 'ms-4': paddingLeft }" fxLayoutGap.gt-xs="5px"
        fxLayout.lt-sm="column">
        <mat-form-field class="mat-100" appearance="outline" floatLabel="always" fxFlex="100">
          <input matInput formControlName="formattedAddress" googlePlaces type="text" class="google-place-input"
          [placeholder]="'COMMON.Type something...' | translate" #address
            [country]="fg.get('lineItems').get(i.toString()).get('country')"
            [region]="fg.get('lineItems').get(i.toString()).get('state')"
            [province]="fg.get('lineItems').get(i.toString()).get('province')"
            [city]="fg.get('lineItems').get(i.toString()).get('city')" [postal_code]="
              fg.get('lineItems').get(i.toString()).get('postal_code')
            " [street_name]="
              fg.get('lineItems').get(i.toString()).get('street_name')
            " [streetNumber]="
              fg.get('lineItems').get(i.toString()).get('house_number')
            " [latitude]="fg.get('lineItems').get(i.toString()).get('latitude')"
            [longitude]="fg.get('lineItems').get(i.toString()).get('longitude')" [formattedAddress]="
              fg.get('lineItems').get(i.toString()).get('formattedAddress')
            " />
          <mat-icon matSuffix appAddressMap [country]="fg.get('lineItems').get(i.toString()).get('country')"
            [region]="fg.get('lineItems').get(i.toString()).get('state')"
            [province]="fg.get('lineItems').get(i.toString()).get('province')"
            [city]="fg.get('lineItems').get(i.toString()).get('city')" [postalCode]="
              fg.get('lineItems').get(i.toString()).get('postal_code')
            " [street_name]="
              fg.get('lineItems').get(i.toString()).get('street_name')
            " [streetNumber]="
              fg.get('lineItems').get(i.toString()).get('house_number')
            " [latitude]="fg.get('lineItems').get(i.toString()).get('latitude')"
            [longitude]="fg.get('lineItems').get(i.toString()).get('longitude')" [address]="
              fg.get('lineItems').get(i.toString()).get('formattedAddress')
            " [showButton]="true" class="secondary-text">map
          </mat-icon>
        </mat-form-field>
      </div>

      <div class="row" [formGroupName]="i" [ngClass]="{ 'ms-3': paddingLeft }">
        <ng-container *ngFor="let addressform of addressFormateArrView">
          <ng-container *ngIf="addressform == 'Country Name'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.Country Name" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always" readonly>
                  <input matInput name="country" formControlName="country"
                    placeholder="{{'GENRIC_PLACEHOLDER.COUNTRY' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="addressform == 'City Name'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.State" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput name="state" formControlName="state"
                    placeholder="{{'GENRIC_PLACEHOLDER.STATE' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="addressform == 'City Name'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.City Name" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput name="city" formControlName="city"
                    placeholder="{{'GENRIC_PLACEHOLDER.CITY' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="addressform == 'Postal Code'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.Postal Code" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput name="postal_code" formControlName="postal_code"
                    placeholder="{{'COMMON.Postal Code' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="addressform == 'Street Name'">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 col-xl-6 col-xxl-12 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.Street Name" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput name="street_name" formControlName="street_name"
                    placeholder="{{'COMMON.Street Name' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="addressform == 'House Number'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.House Number" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput name="house_number" formControlName="house_number"
                    placeholder="{{'COMMON.House Number' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="addressform == 'Interior Number'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2">
              <p class="d-inline-block text-1 fw-500 align-top">
                {{ "COMMON.Interior Number" | translate }}
              </p>
              <div class="mt-2">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput name="interior_number" formControlName="interior_number"
                    placeholder="{{'COMMON.Interior Number' | translate}}" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container>
          <div class="d-flex mt-2 align-items-center gap-3 w-full">
            <button class="nav-link view-address add-btn color-1" appAddressMap
              [country]="fg.get('lineItems').get(i.toString()).get('country')"
              [region]="fg.get('lineItems').get(i.toString()).get('state')"
              [province]="fg.get('lineItems').get(i.toString()).get('province')"
              [city]="fg.get('lineItems').get(i.toString()).get('city')" [postalCode]="
                fg.get('lineItems').get(i.toString()).get('postal_code')
              " [street_name]="
                fg.get('lineItems').get(i.toString()).get('street_name')
              " [streetNumber]="
                fg.get('lineItems').get(i.toString()).get('house_number')
              " [latitude]="fg.get('lineItems').get(i.toString()).get('latitude')" [longitude]="
                fg.get('lineItems').get(i.toString()).get('longitude')
              " [address]="
                fg.get('lineItems').get(i.toString()).get('formattedAddress')
              " [showButton]="false">
              <app-custom-icon icon="maker"></app-custom-icon>
              {{ "COMMON.View_Address" | translate }}
            </button>
            <label class="nav-link add-btn f-w-500 cursor-p" [ngClass]="{
                'color-2': customerAddressArr[i]?.default_address == '1',
                'color-5': customerAddressArr[i]?.default_address == '0'
              }">
              <input type="checkbox" (change)="defaultAddress(i)" hidden />
              <i class="fas fa-check me-1"></i>{{ "COMMON.Mark_as_Default" | translate }}
            </label>
            <button class="nav-link add-btn  color-4 f-w-500" (click)="removeAddress(i)"
              *ngIf="customerAddressArr.length > 1">
              <i class="fas fa-times me-1"></i>{{ "COMMON.Delete_Address" | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
  <div class="col-lg-12">
    <button hidden type="button" class="btn bg-1 btn-circle fs-3 " #addFormOfContactButton2
      data-bs-target="#add_form_of_contact2" data-bs-toggle="modal" aria-expanded="false">+</button>
    <div class="modal fade" id="add_form_of_contact2" data-bs-backdrop="false" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" style="max-width: 600px;">
        <div class="modal-content cnt-dashboard border-r2 border shadow">
          <div class="modal-header pt-5">
            <h2 class="text-1 fs-4 text-center">
              {{"GENRIC_PLACEHOLDER.Address_Name" | translate}}</h2>
            <i type="button" data-bs-dismiss="modal" #closeformOfConForm2 aria-label="Close"
              class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
          </div>
          <form [formGroup]="formOfContactForm2" (ngSubmit)="addFormOfContactList2()">
            <div class="modal-body bg-gris-1 px-5">
              <label class=" mb-2">
                {{"COMMON.NAME" | translate}}
                <span class="color-6">*</span>
              </label>
              <!-- <mat-form-field 
                            appearance="outline" 
                            floatLabel="always"> -->
              <input type="text" style="width: 500px !important;" class="form-control"
                placeholder="{{'GENRIC_PLACEHOLDER.Enter_address_name' | translate}}" formControlName="name"
                [ngClass]="{ 'is-invalid': formOfContactFormSubmit && formOfContact.name.errors , 'is-valid': formOfContactFormSubmit && formOfContact.name.valid }">
              <!-- <mat-error *ngIf="formOfContactFormSubmit && formOfContact.name.errors && formOfContact.name.errors.required">
                                {{"CONTACT_PROFILE.ERRONAMEREQUIRED" | translate}}
                            </mat-error> -->
              <!-- </mat-form-field>                                                              -->
              <!-- <div class="mt-3" v>
                            <label class="text-3 mb-2">{{"CONTACT_PROFILE.COLOR"
                                | translate}}
                                <span class="color-6">*</span>
                            </label>
                            <div class="position-relative">
                                <mat-form-field appearance="outline"
                                    floatLabel="always">
                                    <input matInput type="text"
                                        class="source-color-text"
                                        formControlName="color"
                                        [value]="color"
                                        (input)="changeColor1($event)">
                                    <input matInput
                                        class="p-0 position-absolute end-0 top-0 m-2 text-1"
                                        (input)="changeColor($event)"
                                        [value]="color"
                                        formControlName="color"
                                        style="width: 15px; height: 15px;margin-top: 3px!important"
                                        type="color" id="colorPicker"  />
                                    <mat-error>
                                        {{"CONTACT_PROFILE.Please_enter_color_code"
                                        | translate}}
                                    </mat-error>
                                    <mat-hint class="mt-3">{{"CONTACT_PROFILE.Color_hint" | translate}}</mat-hint>
                                </mat-form-field>
                                
                            </div>
                        </div> -->
            </div>

            <div class="modal-footer j-c-center mt-4 pb-4">
              <button type="button" class="btn btn-skyblue-s-1 standard-btn" id="" data-bs-toggle="modal"
                data-bs-dismiss="modal" data-bs-target="#modal-new" #closebutton>{{"COMMON.Cancel" | translate}}
              </button>
              <button type="submit" class="btn btn-p-2 standard-btn mx-2">{{"COMMON.Add" | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="address-action">
  <button type="button" class="add-btn add-btn-plain-link mt-3 add-address" (click)="addAdditionalAddress($event)">
    <i class="fas fa-plus-circle me-1"></i>
    {{ "COMMON.Add_Additional_Address" | translate }}
  </button>
</div>