<div class="modal-header pt-5">
  <h2 class="text-1 fs-4 text-center">{{"WORKSPACE.CURRENT_WORKSPACE" | translate}}</h2>
</div>
<div class="modal-body bg-gris-1 px-5">
    <p>{{"WORKSPACE.NEED_LOGOUT" | translate}}</p>
  <p>{{"WORKSPACE.WANT_LOGOUT_WORKSPACE" | translate}}</p>
</div>
<div class="modal-footer j-c-center mt-4 pb-4">
  <button class="btn btn-2 mx-2" (click)="closeDialog(false)">
    {{ "COMMON.Cancel" | translate }}
  </button>
  <button type="submit" class="btn btn-1 mx-2" (click)="closeDialog(true)">
    {{"COMMON.Confirm" | translate}}
  </button>
</div>
