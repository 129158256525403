<div class="modal-body bg-gris-1 p-5 d-flex h-100 flex-column justify-content-center ">
  <h2 class="text-1 fs-4 text-center mb-2">{{"ITEMS.Upload_images" | translate}}</h2>
  <div class="d-flex justify-content-center" *ngIf="!(selectedFiles.length>0)" style="height: 131px;">
    <div class="bg-gris-2 p-2 ms-2 mb-3 d-flex justify-content-center rounded-circle rounded-2"
      style="width: 118px; height: 125px; background-color: rgba(3, 194, 252, 0);">
      <input type="file" name="file-6" id="file-6" class="inputfile" 
        style="padding: 4px 0px!important ;text-align: center!important;border-collapse: collapse!important;outline: none!important;border: none !important;"
        data-multiple-caption="{count} archivos seleccionados" (change)="onFileSelected($event)" multiple />
      <label for="file-6">
        <img class="uploadFileImage cursor-pointer" width="103px" height="129.22px" src="assets/svg-icons/Group (2).svg">
      </label>
    </div>
  </div>
  
  <div class="d-flex justify-content-center" *ngIf="selectedFiles.length>0">
    <div class="bg-gris-2 p-2 ms-2 mb-3 d-flex justify-content-center rounded-circle rounded-2"
      style="width: 45px; height: 45px; background-color: rgb(3, 192, 252, 10%);">
      <input type="file" name="file-6" id="file-6" class="inputfile"
        style="padding: 4px 0px!important ;text-align: center!important;border-collapse: collapse!important;outline: none!important;border: none !important;"
        data-multiple-caption="{count} archivos seleccionados" (change)="onFileSelected($event)" multiple />
      <label for="file-6">
        <img class="uploadFileImage" width="33px" height="29.22px" src="assets/svg-icons/Group (2).svg">
      </label>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-center gap-3">
    <div class="all images">
      <div class="single_image"  *ngIf="file?.preview">
        <img class="w-100 h-100" style="object-fit: contain;" *ngIf="file?.preview" [src]="file.preview">
        <!-- <img *ngIf="!file?.preview" selectedFiles.length class="w-100 h-100" src="assets/img/home/images.png" > -->
      </div>
      <div class="all_Images d-flex overflow-auto align-items-center justify-content-center" *ngIf="imagesToShow?.length>0">
        <i [ngStyle]="{color:!(startIndex>0)? '#b7aeae':'black'}" style="padding: 5px; font-size: 27px;cursor: pointer;"
          (click)="currentImages('previous')">&lt;</i>
        <div class="item_other_images" *ngFor="let image of imagesToShow">
          <img [src]="image.preview" alt="" (click)="file=image">
        </div>
        <i [ngStyle]="{color: !(startIndex + imagesPerline < selectedFiles.length)?'#b7aeae;':'black'}"
          style="padding: 5px; font-size: 27px;cursor: pointer;" (click)="currentImages('next')">&gt;</i>
      </div>
    </div>
  </div>
  <!-- <div class="d-flex gap-2 mt-5" style="margin-top: 20px; max-height: 400px;">
    <div class="item-image-box cursor-pointer gap-2" *ngFor="let file of selectedFiles; let i = index"
      (click)="changeImage(i, image)" style="width: 80px; height: 70px;">
      <img class="edit-item-image-zoom" [src]="file.preview">
      <img class="edit-item-images" [src]="file.preview">
    </div>
  </div> -->



  <div class="d-flex justify-content-center mt-4 gap-3">
    <button (click)="closeModel()" class="btn btn-2 mx-2">{{"COMMON.Cancel" |
      translate}}</button>

    <button class="btn btn-1 mx-2" [disabled]="!imageButton" (click)="saveImages()">
      {{"ITEMS.Done" | translate}}</button>
  </div>

</div>
<!-- <div class="modal-body bg-gris-1 p-5 d-flex h-100 flex-column justify-content-center ">
  <div class="bg-gris-2 p-2 ms-2 mb-3 d-flex justify-content-center rounded-circle rounded-2"
    style="width: 45px; height: 45px; background-color: rgb(3, 192, 252, 10%);">

    <input type="file" name="file-6" id="file-6" class="inputfile"
      style="padding: 4px 0px!important ;text-align: center!important;border-collapse: collapse!important;outline: none!important;border: none !important;"
      data-multiple-caption="{count} archivos seleccionados" (change)="onFileSelected($event)" multiple />
    <label for="file-6">
      <img class="" width="33px" height="29.22px" src="assets/svg-icons/Group (2).svg">
    </label>
  </div>

  <div class="d-flex align-items-center justify-content-center gap-3">
    <div class="all images">
      <div class="single_image">
        <img class="w-100 h-100" style="object-fit: contain;" *ngIf="file?.preview" [src]="file.preview">
        <img *ngIf="!file?.preview" class="w-100 h-100" src="assets/img/home/images.png">
      </div>
      <div class="all_Images d-flex overflow-auto align-items-center" *ngIf="imagesToShow?.length>0">
        <i [ngStyle]="{color:!(startIndex>0)? '#b7aeae':'black'}" style="padding: 5px; font-size: 27px;cursor: pointer;"
          (click)="currentImages('previous')">&lt;</i>
        <div class="item_other_images" *ngFor="let image of imagesToShow">
          <img [src]="image.preview" alt="" (click)="file=image">
        </div>
        <i [ngStyle]="{color: !(startIndex + imagesPerline < selectedFiles.length)?'#b7aeae;':'black'}"
          style="padding: 5px; font-size: 27px;cursor: pointer;" (click)="currentImages('next')">&gt;</i>
      </div>
    </div>
  </div>
  
  <div class="d-flex justify-content-center mt-4 gap-3">
    <button (click)="closeModel()" class="btn btn-2 mx-2">{{"PROFILE.cancel" |
      translate}}</button>

    <button class="btn btn-1 mx-2" [disabled]="!imageButton" (click)="saveImages()">
      {{"ITEMS.Done" | translate}}</button>
  </div>

</div> -->
<!-- <div class="modal-body bg-gris-1 p-5 d-flex h-100 flex-column justify-content-center ">
  <div class="bg-gris-2 p-2 ms-2 mb-3 d-flex justify-content-center rounded-circle rounded-2"
    style="width: 45px; height: 45px; background-color: rgb(3, 192, 252, 10%);">

    <input type="file" name="file-6" id="file-6" class="inputfile"
      style="padding: 4px 0px!important ;text-align: center!important;border-collapse: collapse!important;outline: none!important;border: none !important;"
      data-multiple-caption="{count} archivos seleccionados" (change)="onFileSelected($event)" multiple />
    <label for="file-6">
      <img class="" width="33px" height="29.22px" src="assets/svg-icons/Group (2).svg">
    </label>
  </div>

  <div class="d-flex align-items-center justify-content-center gap-3">
    <div class="all images">
      <div class="single_image">
        <img class="w-100 h-100" style="object-fit: contain;" *ngIf="file?.preview" [src]="file.preview">
        <img *ngIf="!file?.preview" class="w-100 h-100" src="assets/img/home/images.png">
      </div>
      <div class="all_Images d-flex overflow-auto align-items-center" *ngIf="imagesToShow?.length>0">
        <i [ngStyle]="{color:!(startIndex>0)? '#b7aeae':'black'}" style="padding: 5px; font-size: 27px;cursor: pointer;"
          (click)="currentImages('previous')">&lt;</i>
        <div class="item_other_images" *ngFor="let image of imagesToShow">
          <img [src]="image.preview" alt="" (click)="file=image">
        </div>
        <i [ngStyle]="{color: !(startIndex + imagesPerline < selectedFiles.length)?'#b7aeae;':'black'}"
          style="padding: 5px; font-size: 27px;cursor: pointer;" (click)="currentImages('next')">&gt;</i>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4 gap-3">
    <button (click)="closeModel()" class="btn btn-2 mx-2">{{"PROFILE.cancel" |
      translate}}</button>

    <button class="btn btn-1 mx-2" [disabled]="!imageButton" (click)="saveImages()">
      {{"ITEMS.Done" | translate}}</button>
  </div>

</div> -->