import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/services/permission.service';
import { AnimationOptions } from 'ngx-lottie';
import { CrudService } from 'src/app/services/crud.service';
import {ComSerService} from '../../shared/commanservice/com-ser.service';
declare var $: any;
@Component({
  selector: 'app-dash-layout',
  templateUrl: './dash-layout.component.html',
  styleUrls: ['./dash-layout.component.scss']
})
export class DashLayoutComponent implements OnInit {
  onLoopCompleteCalledTimes = 0;

  public imageUrl = environment.IMAGE_BACKET_URL;
  public username;
  public userProfilePic;  
  public permission;
  public openDrawer:boolean = false;

    // This is the option that uses the package's AnimationOption interface  
    options: AnimationOptions = {    
      path: "../../../assets/lottie/main_dp.json"
    };  

  //Coo
  constructor(
    private ComSerService: ComSerService,
    public permissionService: PermissionService, private crudService: CrudService ) {
    if (localStorage.getItem('fname') != 'null') {
      this.username = localStorage.getItem('fname') + ' ' + localStorage.getItem('lname');
    } else {
      this.username = '';
    }
    
    this.userProfilePic = (!localStorage.getItem('profile_pic') || localStorage.getItem('profile_pic').includes('image_not_available')) ? '': this.imageUrl + localStorage.getItem('profile_pic');    
  }

  ngOnInit() {
    this.permission = this.permissionService.retrivePermission();

    this.crudService.updateProfileImage.subscribe(res => {
      if (res) {
        this.userProfilePic = this.imageUrl + res;
      }
    })
  }

  tach(){
    $('.ico-absolute-2').css('display', 'block');
    $('.col-h').addClass('hover');
    $('.ico-absolute').addClass('hover');
    $('.img-h').addClass('hover');
    $('.p-h').addClass('hover');
    $('.ico-h').addClass('hover');
    $('.text-h').addClass('hover');
    $('.cnt-h').addClass('hover');
    $('.accordion-button').addClass('hover');
    $('.accordion-button::after').addClass('hover');
  }

  noTach(){
    $('.ico-absolute-2').css('display', 'none');
    $('.col-h').removeClass('hover');
    $('.ico-absolute').removeClass('hover');
    $('.img-h').removeClass('hover');
    $('.p-h').removeClass('hover');
    $('.ico-h').removeClass('hover');
    $('.text-h').removeClass('hover');
    $('.cnt-h').removeClass('hover');
    $('.accordion-button').removeClass('hover');
    $('.accordion-button::after').removeClass('hover');
  }

  seeChat(){
    $('.cnt-message').css('display', 'block');
    $('.text-sms').css('display', 'none');
  }
  openSideBar(){
    this.openDrawer = !this.openDrawer;
  }
  closeSideBar(){
    this.openDrawer = false;
  }
  receiveDataFromChild(data:boolean) {
    console.log('data',data);
    if(data){
      this.openDrawer = !this.openDrawer;
    }
  }
  mouseOver(){
    this.ComSerService.setScreenWidth(true);
  }
  mouseLeave(){
    this.ComSerService.setScreenWidth(false);
  }
}
