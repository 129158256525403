<div class="customer_delete">
<mat-dialog-content>
   
    <!-- <div>
        <i type="button" data-bs-dismiss="modal" aria-label="Close" (click)="closeModel()"
    class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
    </div> -->
    <div class="text-center">
        <p *ngIf="msgFromDeal && !itemDelete">{{titleMsg}} </p>
        <p *ngIf="msgFromAgenda && !itemDelete">{{titleMsg}} </p>
        <p *ngIf="itemDelete">{{titleMsgs}} </p>

        <p *ngIf="ItemsDeleteModule && !tableDelete && !titleMsgNew && !itemDelete">{{"QUOTE_TEMPLATE.Are You Sure You Want To Remove This Page" | translate}} {{titleMsg}} </p>
        <p *ngIf="tableDelete && titleMsgNew && !itemDelete">{{"QUOTE_TEMPLATE.Do You Want To Remove This Table ?" | translate}}</p>


        <p *ngIf="!msgFromDeal && !msgFromAgenda && !ItemsDeleteModule && !tableDelete && !itemDelete" > {{"SYSTEM_USER.Invited Users deleted" | translate}} </p>
    </div>
	
    
</mat-dialog-content>
<mat-dialog-actions align ="center">
	<button  class="btn btn-cancel standard-btn me-3 mb-3"
    mat-dialog-close 
    tabindex="-1">{{"COMMON.Cancel" | translate}}</button>
    <button   class="btn btn-cancel standard-btn me-3 mb-3"
    (click)="onConfirmClick()" tabindex="1">{{"COMMON.YES" | translate}}</button>
</mat-dialog-actions>
</div>