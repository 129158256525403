import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BusinessProfileDetailsModel } from 'src/app/pages/business-profile/model/business-profile-details-model';
import { BusinessProfileService } from 'src/app/pages/business-profile/services/business-profile.service';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-quote-id',
  templateUrl: './quote-id.component.html',
  styleUrls: ['./quote-id.component.scss']
})
export class QuoteIdComponent implements OnInit {
  type: string;
  subscriptions: Subscription[] = [];
  quoteNumberForm:FormGroup
  quoteNumber: number | null = null;
  currentNumber = new FormControl('A-23')
  quoteValue = new FormControl('A1');
  form: FormGroup;
  permission
  constructor(private spinnerService: NgxSpinnerService,
    private businessProfileService: BusinessProfileService,
    public dialogRef: MatDialogRef<QuoteIdComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public QuoteTemplateServices: QuoteTemplateService,
    private formBuilder:FormBuilder,
    private fb: FormBuilder,
    private permissionService: PermissionService,
    private _router: Router,
    ) {
      this.type = inputData.type;
      this.quoteNumberForm=this.formBuilder.group({
        quote_prefix:[''],
        quote_number:['']
      })
     }

  async ngOnInit(){
    this.permission = this.permissionService.retrivePermission();
    if (!this.permission.includes('change_quoteId') || !this.permission.includes('change_invoiceId')) {
      this._router.navigate(['/dash']);
    }else{
      const val = await this.QuoteTemplateServices.get_Quote_id({type:this.type}).toPromise();
      if (val?.data?.length > 0) {
        this.quoteNumberForm.patchValue({
          quote_prefix:val.data[0].quote_prefix,
          quote_number:val.data[0].quote_number
        })
      }
    }
   
  }

  get quoteForm(): { [key: string]: AbstractControl } {
    return this.quoteNumberForm?.controls;
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = Number(input.value);
  
    if (value <= 0) {
      input.value = ''; // Clear the input or set it to a valid value
      // Optionally, you can display an error message or alert
      console.log('Please enter a number greater than 0.');
    }
  }
  

  async addFormValue() {
    this.spinnerService.show();
      const changeMerchantDetailsSubs = this.businessProfileService.update_Quote_Id({...this.quoteNumberForm.value,type:this.type}).subscribe((val: any) => {
        this.closeDialog();
        this.spinnerService.hide();
      });
      this.subscriptions.push(changeMerchantDetailsSubs);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

}
