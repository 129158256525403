<div class="modal-header pt-5">
    <h2 class="text-1 fs-4 text-center">{{"BUSINESS_PROFILE.REACTIVE_USER" | translate}}</h2>
</div>
<form [formGroup]="fg">
    <div class="modal-body bg-gris-1 px-5 text-center">
        <p>{{"BUSINESS_PROFILE.WANT_REACTIVE" | translate}}</p>
    </div>
    <div class="modal-footer j-c-center mt-4 pb-4">
        <button class="btn btn-2 mx-2" (click)="dialogRef.close()">{{"COMMON.Cancel" | translate}}
        </button>
        <button type="submit" class="btn btn-1 mx-2" (click)="closeDialog()">{{"COMMON.Confirm" | translate}}</button>
    </div>
</form>