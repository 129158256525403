
        <div class="modal-body bg-gris-1 pt-5">
            <h2 class="text-1 fs-4 text-center">{{"COMMON.Add" | translate}} {{ getTranslatedText('COMMON.' + data.name) || data.name }}</h2>
            <div class="d-flex j-c-end">
                <i type="button"
                    aria-label="Close" data-bs-dismiss="modal" (click)="closeDialog()"
                    class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
           </div>         
        </div>
        <form [formGroup]="fg"
            (ngSubmit)="addFormOfContactList()">
            <div class="modal-body bg-gris-1 px-5">
                <div class="mt-1">
                    <label class="text-3 mb-2">{{"COMMON.NAME" | translate}}<span class="color-6">
                            *</span></label>
                    <input *ngIf="data.name !=='Unit'  && data.name !== 'Category'" type="text" class="form-control"
                        placeholder='{{"COMMON.Tax Name" | translate}}'
                        formControlName="name">
                        <input *ngIf="data.name =='Unit'  && data.name !== 'Category'" type="text" class="form-control"
                        placeholder='{{"COMMON.Unit" | translate}}!'
                        formControlName="name">
                        <input *ngIf="data.name !=='Unit'  && data.name == 'Category'" type="text" class="form-control"
                        placeholder='{{"ITEMS.Unit Name" | translate}}'
                        formControlName="name">
                </div>
                <div class="mt-1" *ngIf="data.name !=='Unit' && data.name !== 'Category'">
                    <label class="text-3 mb-2">{{"ITEMS.Enter Tax Percentage" | translate}}<span class="color-6">
                            *</span></label>
                    <input *ngIf="data.name !=='Unit'  && data.name !== 'Category'" type="number" class="form-control"
                        placeholder='{{"ITEMS.Enter Tax Percentage" | translate}}'
                        formControlName="percent" (input)="preventNegative($event)">
                </div>
            </div>
            <div class="modal-footer j-c-center mt-4 pb-4">
                <button class="btn btn-2 mx-2" (click)="closeDialog()">{{"COMMON.Cancel" | translate}}
                </button>
                <button type="submit"
                    class="btn btn-1 mx-2">{{"COMMON.Add" | translate}}</button>
            </div>
        </form>
