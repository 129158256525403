
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, Inject, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { AddValueInDropdownComponent } from 'src/app/Dialogs/add-value-in-dropdown/add-value-in-dropdown.component';
import { environment } from 'src/environments/environment';
import { ModalComponent, ModalModel } from '../../../shared/components/modal/modal.component';
import { CategoryAddModel } from '../model/category-add-model';
import { CategoryListModel } from '../model/category-list-model';
import { ItemAddModel } from '../model/item-add-model';
import { ItemEditModel } from '../model/item-edit-model';
import { ItemViewModel } from '../model/item-view-model';
import { ItemBundlesAddModel } from '../model/item_bundles-model';
import { SubCategoryAddModel } from '../model/sub-category-add-model';
import { ItemsService } from '../services/items.service';
import { Subject, Subscription } from 'rxjs';
import { EditContactService } from '../../contact-profile/edit-contact/services/edit-contact.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { Category, SubCategory } from '../items.component';
import { AddSubCategoryComponent } from '../add-sub-category/add-sub-category.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LeadService } from '../../lead/services/lead.service';

export interface Comment {
  text: string;
}
declare var $: any;

@Component({
  selector: 'add-edit-item',
  templateUrl: './add-edit-item.component.html',
  styleUrls: ['./add-edit-item.component.scss'],
  providers: [CurrencyPipe] // Add CurrencyPipe to the providers array

})

export class AddEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class') componentCssClass: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  @ViewChild('closeWarehouse') closeWarehouse;
  @ViewChild('closeAddWarehouse') closeAddWarehouse;
  @ViewChild('closeAddUnithouse') closeAddUnithouse;
  @ViewChild('closeAddBundle') closeAddBundle;
  showMe:boolean= false;
  openBulkAction:boolean = false;
  currentIndex:any
  public userMerchants;
  public merchantCurrency;
  filteredCateogry: any;
  filteredSubCateogry: any;
  allCategory: any;
  categoryCount: any;
  filteredSubCategories: any;
  selectCatSubmit: boolean;
  nextItemBtn: any;
  selectedOption: boolean;
  subCategoryId: any;
  priceB:any

  toggle(){
    this.showMe=!this.showMe
    // this.hideMe = false
  }

  hideMe:boolean= false;
  show(){
    this.hideMe=!this.hideMe
  }

  myImage:HTMLImageElement[] = [] 

  addOnBlur = true;
  readonly separatorKeyCodes = [ENTER, COMMA] as const;

  add(event : MatChipInputEvent): void{
    const value = (event.value || '').trim();
    if (value){
      this.comments.push({text:value});
    }

    if (event.input) {
      event.input.value = '';
     }
  }
  remove(comment: Comment): void {
    const index = this.comments.indexOf(comment);
    if (index >= 0) {
      this.comments.splice(index, 1);
    }
  }

  public hidePrice: boolean;
  public trackInv: boolean;
  public outOfStoc: boolean;
  userBasicInfoForm: FormGroup;
  editItemCodeForm: FormGroup;
  editItemBundleForm: FormGroup;
  itemDiscountForm: FormGroup;
  itemPriceForm: FormGroup;
  itemValueForm: FormGroup;
  addNewUnitForm: FormGroup;
  newWarehouseForm: FormGroup;
  itemwarehouseListForm: FormGroup;
  addNewItemBundle: FormGroup;
  asignUser: FormGroup;
  addNewTexListForm: FormGroup;
  itemPriceTaxForm: FormGroup;
  unitSelection:FormGroup;
  numberField:FormGroup;
  enableInput:boolean=false
  public advanceUnitCal: boolean;
  categoryAddModel = new CategoryAddModel();
  subCategoryAddModel = new SubCategoryAddModel();
  categoryListModel = new CategoryListModel();
  itemAddModel = new ItemAddModel();
  itemViewModel:ItemViewModel;
  itemEditModel = new ItemEditModel();
  itemBundlesAddModel = new ItemBundlesAddModel();
  public Editor = ClassicEditor;
  public config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo'
    ]
  };
  public imageUrl = environment.IMAGE_BACKET_URL;
  public profitMargn:any = 0
  public selectedCurrency: any = "";
  // advance Calculations
  calculations={
    height:0,
    width:0,
    result:"0",
    sign:"+",
  }
  signsArray=['+',"-","x","/"];
// advance Calculations ends 
  subscriptions: Subscription[] = [];
  crSymbol; // currency symbol
  crCode; // currency code
  constructor(
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private itemsService: ItemsService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    config: NgbModalConfig,
    private editContactService: EditContactService,
    public overlayContainer: OverlayContainer,
    public currencyPipe: CurrencyPipe,
    public dialogRef: MatDialogRef<ModalComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private decimalPipe: DecimalPipe,
    private snackbar: MatSnackBar,
    public leadService: LeadService,

  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    let theme = window.localStorage.getItem("theme")
    if (theme) {
      this.onSettheme(theme)
    } else {
      this.onSettheme("ligth-theme")
    }
    this.unitSelection = new FormGroup({
       unitList1: new FormControl('Area')
    });
    this.numberField = new FormGroup({
      numberValue: new FormControl(1,Validators.min(0))
    })
    this.isUserSearching = false
  }
  @ViewChild('content', { static: false }) modal;
  public onSettheme(e: string) {
    this.overlayContainer.getContainerElement().classList.add(e);
    this.componentCssClass = e;
    window.localStorage.setItem("theme", e);
  }
  serchArr: any = {};
  cateogryListArr: any;
  itemListEditArr: any;
  bundleListArr: any;
  firstUrlTitle:any;
  secondUrlTitle:any;
  minPrice: any;
  addDiscountsArr = [];
  addPriceArr = [];
  warehouseListArr: any;
  taxListArr: any;
  unitListArr: any;
  files: any;
  lengthChar = false;
  userBasicInfoSubmit = false;
  itemCodeSubmit = false;
  itemBundleSubmit = false;
  itemPriceSubmit = false;
  itemDiscountSubmit = false;
  itemPriceTaxSubmit = false;
  itemValueSubmit = false;
  itemWarehouseListSubmit = false;
  itemWarehouseSubmit = false;
  itemUnitSubmit = false;
  itemAddBundleSubmit = false;
  mainImageUrl:any;
  cateogryName: any;
  addItemPriceTaxArr = [];
  addEditItemCodeArr = [];
  itemAdditionalDetailsArr = [];
  addItemAdditionalDetailsArr = [];
  itemBundles = [];
  addWarehouseArr = [];
  warehouseNameListArr = [];
  itemAdditionalDetailsArrList = [];
  subCategoryListArr: any;
  mainImage: any;
  image:any = "";
  symbols:any;
  unit:any;
  symbolsList: any;
  passMust = false;
  isDeactivated: boolean= false;
  isChecked: boolean= false;
  getcateogryName: any;
  isCheckedItems:boolean=false
  filteredCategories: any;
  currenciesListArr: any;
  itemsEditButton: any;
  itemsImages: any;
  userCurrency:any
  sales_commission_type:any;
  saleTax:boolean=false
  merchent:any;
  editItemsImages = [];
  selectedBundle = [];
  user: string = "";
  pass1Checked = false;
  saveTexas:any=[];
  categoryItemFlag = false;
  itemImage = Array();
  customDataFieldViewData=[]
  itemPriceA:any;
  // for item labeling prices
  alphabetArray: any = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  // to calculate profit
  profit: number = 0
  // to add comments
  comments: any = []
  // used for material chips
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  unitList = [{name:'Area'}];
  filteredItems: any
  selectedItemId: number
  currentTabIndex: number = 0
  filteredCurrency: any
  filteredWareHouses: any
  userSearch: FormControl = new FormControl();
  itemsQuantity: FormControl = new FormControl();
  unitSelect: FormControl = new FormControl();
  itemType: FormControl = new FormControl();
  wareHouseSearch: FormControl = new FormControl();
  cateogrySearch: FormControl = new FormControl();
  subCateogrySearch: FormControl = new FormControl();
  searchItemCode: FormControl = new FormControl();
  public isUserSearching: boolean;
  filterValue = ''
  categorySearchHistory: any;
  infoItemBlock = false;
  hiddenInfo = false;
  categoryId:any
  languageName:any;
  itemPriceList:any;
  categoryName:any;
  allTaxesList:any;
  subCategoryName:any;
  newItemPriceList:any={pricesA: '', pricesB: '', pricesC: '', pricesD: ''} ;
  selectedPrice:any;
  newPrice = new FormControl('');
  ngOnInit(): void {
   
    const loginUserValue = JSON.parse(localStorage.getItem('loginUserValue'));
    this.languageName= loginUserValue?.languages?.name
    this.addToNgOninit();
    this.getCateogryList(
      
    ).then(() => {
      this._autoCompleteSearchControlForCategory();
      this._autoCompleteSearchControlForSubCategory();
    });
    
    this.listArr();
    this.itemBasicInfoFormVal();
    this.getCurrenciesList().then((val)=>{
      // this._autoCompleteSearchControlForCurrency()

    })
   this.getWarehouseList().then((item)=>{
      this._autoCompleteSearchControlForWareHouses()
    });
    this.userCustomUrl()
    this.salesCommType('2');
      this.getItemListEdit(this.inputData.categoryId).then((itemList)=>{
      this._autoCompleteSearchControlForItemsCode()
    });
    this.calculateProfit()
    if(this.inputData?.edit =='quote' || this.inputData?.edit =='quotes'){
      console.log(this.inputData?.name,'this.inputData?.name')
      this.userBasicInfoForm.get('name').setValue(this.inputData?.name)
      this.itemAddModel.name=this.inputData?.name

      this.isDeactivated=true
    }
}

ngOnChanges(changes: SimpleChanges): void {
  this.imageFilesAddedEdit(changes)
}
  /**
   * Actively listen to To field and filter items array on type or change value
   */

   _autoCompleteSearchControlForCurrency(): void {
    let currency= this._filterCurrency(this.userSearch.value);
    // console.log("currency ",currency); 
    this.filteredCurrency=currency;
    // this.filteredCurrency = this.userSearch.valueChanges.pipe(
    //   // startWith(''),
    //   map((currency:any) => {this._filterCurrency(currency);
    //   console.log("iiiiiiiiiiiiiiiiiiiiiiiii,currency",currency);
    //   } )
    //   )
  }
  /**
   * Filtered items on item codes
   */
  private _filterCurrency(filterValue: string): any[] {
    delete this.itemAddModel.currency_id
    if(filterValue) {return this.currenciesListArr?.filter(item => (item.currency_code?.toLowerCase()).includes(filterValue?.toLowerCase()) || item.currency_symbol == filterValue?.toLowerCase())}
    else{
      return this.currenciesListArr;
    }
  }
 /**
   * Actively listen to To field and filter items array on type or change value
   */

//  private _filterSubCateogry(): void {
//   this.filteredSubCateogry = this.subCateogrySearch.valueChanges.pipe(
//     startWith(''),
//     map((subCateogry:any) => this.filterSubCateogry(subCateogry))
//   )
//  }

 private _autoCompleteSearchControlForSubCategory(): void {
  this.filteredSubCategories = this.subCateogrySearch
  .valueChanges.pipe(
    startWith(''),
    map((subCategory: any) => this._filterSubCategory(subCategory))
   
  );
  this.cd.detectChanges()
}
private _filterSubCategory(value: string): SubCategory[] {
  const filterValue = value?.toLowerCase();
  if (filterValue)
    return this.subCategoryListArr?.filter((subCategory) =>
      subCategory.name?.toLowerCase().includes(filterValue)
    );
  return this.subCategoryListArr;
}

changeMyControl(event): void {
  const item = this.cateogryListArr?.find(
    (state) => state.name?.toLowerCase() === event.target.value?.toLowerCase()
  );
  if (!item) {
    this.selectedOption = false;
  }
}

selectCategoryModalFuc(){

}
getCatId(id){
this.categoryId = id
this._autoCompleteSearchControlForSubCategory();
this.getSubCategoryList()


}
// selectCategoryFuc(id =null) {

//   // Check User Basic Info Validation
//   if (this.userBasicInfoForm.get('categoryId').invalid) {
//     console.log('aaaaaa')
//     return;
//   } else {
//     // Check User Basic Info Validation
      
//       this.itemAddModel.categoryId = id?id: this.cateogryListArr.find((item=>item.name == this.userBasicInfoForm.get('categoryId').value.categoryId))?.id
//       console.log(this.itemAddModel.categoryId,'dddddddd')
//       this.getCateogryName(this.itemAddModel.categoryId);
//       this.nextItemBtn.nativeElement.click();
//       this.getSubCategoryList().then((res:any)=>{
//         this._autoCompleteSearchControlForSubCategory()
//       });
//   }
// }

getSubCategoryList() {
  return new Promise ((resolve, reject)=>{
    this.serchArr = {
      "filter": { "categoryId": this.categoryId }
    }
      const subCategorySearchSubs = this.itemsService.subCategorySearch(this.serchArr).subscribe(res => {      
      if (res.message === 'success' || res.status === 1) {
        this.subCategoryListArr = res.data;
        if(this.itemAddModel.subCategoryId){
          this.subCategoryListArr.map(x=>{
            if(x.id == this.itemAddModel.subCategoryId){
              this.subCategoryName = x.name
            }
            
          })
        }
        this._autoCompleteSearchControlForSubCategory()
        this.spinnerService.hide();
        resolve(this.subCategoryListArr)
      } else {
        this.spinnerService.hide();
        reject()
      }
    });
    this.subscriptions.push(subCategorySearchSubs);
  })
 
}

//  private filterSubCateogry(filterValue: string): any[] {
// if(filterValue) return this.subCategoryListArr?.filter(subCatList => (subCatList.name?.toLowerCase()).includes(filterValue?.toLowerCase()));
//  return this.subCategoryListArr;
// }

async getCateogryList(body: any = '') {
  return new Promise((resolve, reject) => {
  const cateogryListSubs = this.itemsService.cateogryList(body).subscribe((res) => {
      this.cateogryListArr = res.data;
      if(this.inputData.categoryId){
        this.cateogryListArr.map(x=>{
          if(x.id == this.inputData.categoryId){
            this.categoryName = x.name
            
          }
        })
      }
      this.allCategory = res.totalCount;
      this.spinnerService.hide();
      resolve(this.cateogryListArr);
    });
    this.subscriptions.push(cateogryListSubs);
  });
}

private _filterCateogry(): void {
  this.filteredCateogry = this.cateogrySearch.valueChanges.pipe(
    startWith(''),
    map((cateogry:any) => this._filterCategory(cateogry))
  )
 }

 private filterCateogry(filterValue: string): any {
if(filterValue) return this.cateogryListArr?.filter(obj => (obj.name?.toLowerCase()).includes(filterValue?.toLowerCase()));
 return this.cateogryListArr;
}
  private _autoCompleteSearchControlForWareHouses(): void {

    this.filteredWareHouses = this.wareHouseSearch.valueChanges.pipe(
      startWith(''),
      map((warehouse:any) => this._filterWareHouses(warehouse) )
      )
  }
  /**
   * Filtered items on item codes
   */
  private _filterWareHouses(filterValue: string): any[] {
    // delete this.itemAddModel.currency_id
    if(filterValue) return this.warehouseListArr?.filter(item => (item.name?.toLowerCase()).includes(filterValue?.toLowerCase()));
    return this.warehouseListArr;
  }

  
  onSelectCurrency(id,symbol){
    this.itemAddModel.currency_id = id
    this.selectedCurrency = symbol

  }
  async listArr() {
    await this.getBundleIdList();
  
    this.itemsEditButton = this.inputData.itemsEditButtonId;
    this.getSubCategoryList()
    await this.getTaxList();
    await this.getUnitList();
  }

  changeImage(index,id){
    this.image =  this.editItemsImages[index];
    this.mainImageUrl=id;
  }

  onEditSHowMainImage(image){
    this.image=image;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userBasicInfoForm.controls;
  }
  get itemCode(): { [key: string]: AbstractControl } {
    return this.editItemCodeForm.controls;
  }
  get itemBundle(): { [key: string]: AbstractControl } {
    return this.editItemBundleForm.controls;
  }
  get itemPrice(): { [key: string]: AbstractControl } {
    return this.itemPriceForm.controls;
  }
  get itemDiscount(): { [key: string]: AbstractControl } {
    return this.itemDiscountForm.controls;
  }
  get itemPriceTax(): { [key: string]: AbstractControl } {
    return this.itemPriceTaxForm.controls;
  }
  get itemValue(): { [key: string]: AbstractControl } {
    return this.itemValueForm.controls;
  }
  get itemWarehouses(): { [key: string]: AbstractControl } {
    return this.itemwarehouseListForm.controls;
  }
  get newWarehouses(): { [key: string]: AbstractControl } {
    return this.newWarehouseForm.controls;
  }
  get newUnit(): { [key: string]: AbstractControl } {
    return this.addNewUnitForm.controls;
  }
  get newBundle(): { [key: string]: AbstractControl } {
    return this.addNewItemBundle.controls;
  }
  get unitLists(): {[key: string]: AbstractControl } {
    return this.unitSelection.controls;
  }
  
  /**
   * Actively listen to To field and filter items array on type or change value
   */

  private _autoCompleteSearchControlForItemsCode(): void {

    this.filteredItems = this.searchItemCode.valueChanges.pipe(
      startWith(''),
      map((category:any) => this._filterItems(category) )
      )
  }
  /**
   * Filtered items on item codes
   */
  private _filterItems(filterValue: string): any[] {

    if(filterValue)    return this.itemListEditArr?.filter(item => item.name?.toLowerCase().includes(filterValue?.toLowerCase()) || item.code == filterValue);
    return this.itemListEditArr;
  }
  /**
   * To save selected item id
   */
 onSelectedOption(itemId){
  this.selectedItemId= itemId
 }
  getItemListEdit(id = null): Promise<any> {
    return new Promise((resolve,reject)=>{
   const itemSearchSubs = this.itemsService.itemSearch(this.serchArr).subscribe(val => {
        if (val.message === 'success' || val.status === 1) {
          this.itemList()
          this.itemListEditArr = val.data;
          resolve(this.itemListEditArr)
          this.spinnerService.hide();
        }
      });
    this.subscriptions.push(itemSearchSubs);
    })
   
  }
  increment() {
    this.itemwarehouseListForm.setValue({
      item_warehouses: this.itemwarehouseListForm.get('item_warehouses').value,
      items:this.itemwarehouseListForm.get('items').value + 1});
  }
  
  decrement() {
    if(this.itemwarehouseListForm.get('items').value > 0){
      this.itemwarehouseListForm.setValue({
        item_warehouses: this.itemwarehouseListForm.get('item_warehouses').value,
        items:this.itemwarehouseListForm.get('items').value - 1
      });
    }
    
  
  }
  async removeCategory(i, categoryId,obj) {
    const dialogData = new ModalModel(
      this.translateService.instant('PROFILE.CONFIRMDELETE')
    );
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '450px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        const removeCategoryValueSubs = this.itemsService
          .removeCategoryValue({ id: categoryId })
          .subscribe((val: any) => {
            if (obj == 'category') {
              this.cateogryListArr.splice(i, 1);
              this._autoCompleteSearchControlForCategory()
              this.getSearchHistory().then((res: any) => {
                this.categorySearchHistory = res;
                this.infoItemBlock = false;
                this.hiddenInfo = true;
              });

            }
          });
        this.subscriptions.push(removeCategoryValueSubs);
      }
    });
  }

  async editCustomCategory(obj) {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: "800px",
      data: obj
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if(dialogResult){
        this.userBasicInfoForm.patchValue({
          categoryId:dialogResult?.data?.name
        })
      }
      this.getCateogryList().then(()=>{
        this._autoCompleteSearchControlForCategory()

      })
    });
  }

  async getSearchHistory() {
    return new Promise ((resolve, reject)=>{
      const getSearchListSubs = this.itemsService.getSearchList().subscribe(res => {
        this.spinnerService.hide();
        resolve(res.data)
  
      });
      this.subscriptions.push(getSearchListSubs);
    })
    
   }

   private _autoCompleteSearchControlForCategory(): void {
    this.filteredCategories = this.cateogrySearch
      .valueChanges.pipe(
        startWith(''),
        debounceTime(1000),
        map((category: any) => this._filterCategory(category))
      );
  
    const filteredCategoriesSubs = this.filteredCategories.subscribe((item) => {
      if (item.length > 0 && this.filterValue) {
        let itemData=item[0]
        const searchFound = this.categorySearchHistory.find((historyItem) => historyItem.id === itemData.id);
        if (!searchFound) {
          const createCategorySearchHistorySubs = this.itemsService
            .createCategorySearchHistory({
              item,
            })
            .subscribe((res) => {
              this.categorySearchHistory = res.data;
            });
          this.subscriptions.push(createCategorySearchHistorySubs);
        }
      }
    });
    this.subscriptions.push(filteredCategoriesSubs);
  }

  private _filterCategory(value: string): Category[] {
    this.filterValue = value?.toLowerCase();
    if (this.filterValue)
      return this.cateogryListArr?.filter((state) =>
        state.name.toLowerCase().includes(this.filterValue)
      );
    return this.cateogryListArr;
  }

  increments() {
    this.numberField.setValue({
      numberValue:this.numberField.get('numberValue').value + 1});
  }  
  decrements() {
    if(this.numberField.get('numberValue').value > 0){
      this.numberField.setValue({
        numberValue:this.numberField.get('numberValue').value - 1
      });
    }
    
  
  }
  /**
   * To save first url title
   */
  changeAddField(event, type) {
    if (type == 'addTitle') {
      this.firstUrlTitle = event.target.value
    }
  }
  /**
   * To hide and show input field for urls title
   */
editAddField(num) {
  $("#editButton" +num).addClass("d-none");
  $("#AddressTitle" +num).addClass("d-none");
  $("#AddressTitleInput" +num).removeClass("d-none");
  $("#checkButton" +num).removeClass("d-none");
}
 /**
   * To hide and show input field for urls title
   */
saveAddField(num) {
  $("#editButton" +num).removeClass("d-none");
  $("#AddressTitle" +num).removeClass("d-none");
  $("#AddressTitleInput" +num).addClass("d-none");
  $("#checkButton" +num).addClass("d-none");
}
 /**
   * To save second url title
   */
changeSecondUrlField(event, type) {
  if (type == 'addTitle') {
      this.secondUrlTitle = event.target.value
  }
}

  itemList() {
    this.itemAddModel.categoryId = this.inputData.categoryId;
    if (this.inputData.subCategoryId) {
      this.itemAddModel.subCategoryId = this.inputData.subCategoryId;
    } else if (this.inputData.categoryId && this.inputData.id) {
      this.editItemList();
    }
  }

  getCurrenciesList() {
    return new Promise((resolve,reject)=>{
   const currenciesListSubs = this.itemsService.currenciesList().subscribe(val => {
        if (val.message === 'success' || val.status === 1) {
          this.currenciesListArr = val.data;
          this.filteredCurrency= val.data;
          this.sortOn(this.currenciesListArr, "currency_code");
          this.userMerchants = JSON.parse(localStorage.getItem('user_merchants'));
          this.merchantCurrency=this.userMerchants[0].merchant_default_currencies[0].currencies?.id
          this.userCurrency= this.currenciesListArr.find(value=>value.id==this.merchantCurrency)
          // this.userBasicInfoForm.get('currency_id')?.setValue(this.userCurrency.id)
          console.log("this.inputData,this.inputData",this.inputData);
          
          if(!this.inputData.id){
            this.addCurrencyDefault();
          }
          if(this.inputData?.edit =='quote'){
            this.addCurrencyDefault();

          }
          resolve(true)
        }
      });
      this.subscriptions.push(currenciesListSubs);
    })
    
  }

  addCurrencyDefault(){
    this.leadService.currencySymbal$.subscribe((res: any) => { this.crSymbol = res });
      this.leadService.currencyCode$.subscribe((res: any) => { this.crCode = res });
      const matchingCurrency = this.filteredCurrency.find(
        currency =>
          currency.currency_code === this.crCode 
      );
      this.selectedCurrency = { currency_code: matchingCurrency.currency_code, currency_symbol:matchingCurrency.currency_symbol };    
      if (this.filteredCurrency.length > 0) {
        this.selectedCurrency = this.filteredCurrency.find(x=>x.currency_code ==this.selectedCurrency?.currency_code );
        this.userBasicInfoForm.get('currency_id')?.setValue(this.selectedCurrency.id);
      } 
  }

// Sorts an array of objects "in place". (Meaning that the original array will be modified and nothing gets returned.)
 sortOn (arr, prop) {
  arr.sort (
      function (a, b) {
          if (a[prop] < b[prop]){
              return -1;
          } else if (a[prop] > b[prop]){
              return 1;
          } else {
              return 0;   
          }
      }
  );
}

  /**
   * remove values from chips
   */
  removeComment(comments){
    const index = this.comments.indexOf(comments);
    if (index >= 0) {
        this.comments.splice(index, 1);
    }
  }
  /**
   * add comments in comments array
   */
  addComment(event: MatChipInputEvent){
    const value = (event.value || '').trim();
    // Add comment
    if (value) {
      this.comments.push({title:value,status: 2});
    }
        // Clear the input value
        event.input.value = '';
  }
  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  subCategory(id = null) {
    this.getCateogryName(this.itemAddModel.categoryId);
    this.serchArr = {
      "filter": { "categoryId": this.itemAddModel.categoryId }
    }
    return new Promise((resolve,reject)=>{
    const subCategorySearchSubs = this.itemsService.subCategorySearch(this.serchArr).subscribe(res => {
      if (res.message === 'success' || res.status === 1) {
        this.subCategoryListArr = res.data;
      } else {

      }
      resolve(true)
    })
    this.subscriptions.push(subCategorySearchSubs);
    });
  }

  // getCateogryList(){
  //   return new Promise((resolve,reject)=>{
  //   const cateogryListSubs = this.itemsService.cateogryList().subscribe(res => {
  //     this.cateogryListArr = res.data;
  //     resolve(true)
  //   });
  //   this.subscriptions.push(cateogryListSubs);
  //  })
  // }
  updateSubcatId(id) {
    
    this.itemAddModel.subCategoryId = id;
  }

  getCateogryName(id) {
    if (this.cateogryListArr) {
      this.cateogryListArr.forEach((value) => {
        if (value.id == id) {
          this.getcateogryName = value.name;
        }
      });
    }
  }

  getBundleIdList() {
   const bundleListSubs = this.itemsService.bundleList().subscribe(res => {
      this.bundleListArr = res.data;
    });
    this.subscriptions.push(bundleListSubs);
  }

  addItemPriceTax(data:any='') {
    this.itemPriceTaxSubmit = true;
    let newItem
    if(data !==''){
      newItem = data
    }else{
      if (this.itemPriceTaxForm.invalid) {
        this.saleTax = true;
        return;
    }

     newItem = this.itemPriceTaxForm.value;

    }
    
    const existingIndex = this.addItemPriceTaxArr.findIndex(item => item?.name === newItem?.name);

    if (existingIndex !== -1) {
        // Update the existing item's taxe_value        
        this.addItemPriceTaxArr[existingIndex].taxe_value = newItem?.taxe_value;
    } else {
        // Add the new item if it doesn't already exist
        this.addItemPriceTaxArr.push(newItem);
    }

    // Sorting the array
    this.addItemPriceTaxArr.sort((a, b) => a.taxe_value - b.taxe_value); // b - a for reverse sort
    
    // Resetting the form and submit flag
    this.itemPriceTaxSubmit = false;
    if(data !==''){

    }else{
      this.itemPriceTaxForm.reset();
      this.enableInput=false

    }
}


  addWarehouseList() {
    this.itemWarehouseListSubmit = true;
    if (this.itemwarehouseListForm.invalid) {
      return;
    }
    
  const addNewWarehouseSubs = this.itemsService.addNewWarehouse({name: this.itemwarehouseListForm.get('item_warehouses').value,status: 1}).subscribe(
      res => {
        if(res.data){
          this.addWarehouseArr.push({warehouseId: res.data.id,items: this.itemwarehouseListForm.get('items').value,status:2,name:this.itemwarehouseListForm.get('item_warehouses').value})
          this.itemwarehouseListForm.reset();

        }
        // this.getWarehouseList()
        this.getWarehouseList().then((item)=>{
          this._autoCompleteSearchControlForWareHouses()
        });
      });
     
    this.subscriptions.push(addNewWarehouseSubs);
    this.closeWarehouse.nativeElement.click();

    this._autoCompleteSearchControlForWareHouses()
    this.warehouseNameListArr = this.addWarehouseArr;
    
  }

  addDiscounts() {
    this.itemDiscountSubmit = true;
    if (this.itemDiscountForm.invalid) {
      return;
    }
    if(!this.itemDiscountForm.get('discounts').value){
      return
    }
    const discountValue = parseFloat(this.itemDiscountForm.get('discounts').value);
    if (!isNaN(discountValue) && discountValue <= 100) {
      this.addDiscountsArr.push(this.itemDiscountForm.value);
    } else {
      this.snackbar.open('Discount should be equal or less than 100', 'Close', {
        duration: 3000, // duration in milliseconds
        verticalPosition: 'top', // or 'bottom'
        horizontalPosition: 'center' // or 'left', 'right'
      });
    }
    this.addDiscountsArr.sort((a,b) => a.discounts - b.discounts); // b - a for reverse sort

    this.itemDiscountSubmit = false;
    this.itemDiscountForm.reset();
  }

  // addPrice() {
  //   if (this.itemPriceForm.invalid) {
  //     return
  //   }
  //   if (Number(this.itemPriceForm.value.pricesA) < Number(this.minPrice)) {
  //     this.itemPriceForm.controls.pricesA.setErrors({ min: true });
  //     return
  //   }
  //   this.calculateProfit()
  //   this.itemPriceSubmit = false;
  //   this.itemPriceForm.reset();
  // }
  calculateProfit() {
    const fix_cost_per_unit = parseFloat(this.userBasicInfoForm.get('fix_cost_per_unit').value) || 0;
    const priceAString = this.itemPriceForm.get('pricesA').value; // Get the price as a string
    if (typeof priceAString === 'string') {
        const priceA = parseFloat(priceAString.replace(/,/g, '')) || 0; // Remove commas and parse as a float
        if (fix_cost_per_unit || priceA) {
            let profit = priceA - fix_cost_per_unit;
            let profitMargin = ((profit * 100) / priceA).toFixed(2);
            const sales_commission = parseFloat(this.userBasicInfoForm.get('sales_commission').value) || 0;
            const sales_commission_type = this.sales_commission_type; // Assuming sales_commission_type is defined somewhere
            if (sales_commission !== 0 && (sales_commission_type === '2' || sales_commission_type === '1')) {
              if (sales_commission_type === '2') {
                if(profit == sales_commission){
                  profit =0.00
                }else{
                  profit -= (profit * sales_commission / 100);  // Subtract as a percentage

                }
              } else {
                  profit -= sales_commission;  // Subtract as a direct value
              }
              profitMargin = ((profit * 100) / priceA).toFixed(2);
          }
          

            this.profit = profit;
            this.profitMargn = profitMargin;
        } else {
            this.profitMargn = 100;
            this.profit = 100;
        }
    } else {
        this.profitMargn = 100;
        this.profit = 100;
    }
}







  addBundle() {
    this.itemBundleSubmit = true;
    if (this.editItemBundleForm.invalid) {
      return;
    }
    this.selectedBundle.push(this.editItemBundleForm.value)
    this.itemBundleSubmit = false;
    this.editItemBundleForm.reset();
  }


  onBlur(inputValue: string,type) {
    const numericRegex = /^(\d*\.?\d*)$/;
    const numericValue = parseFloat(inputValue.replace(/[$,]/g, ''));
    if(isNaN(numericValue)){
      if(type == 'priceA'){
        this.itemPriceForm.get('pricesA').setValue('');
      }
      else if(type == 'priceB'){
        this.itemPriceForm.get('pricesB').setValue('');
      
         }
         else if(type == 'priceC'){
        this.itemPriceForm.get('pricesC').setValue('');
      
         }
         else if(type == 'priceD'){
        this.itemPriceForm.get('pricesD').setValue('');
      
         }
         else if(type == 'fix_cost_per_unit'){
          this.userBasicInfoForm.get('fix_cost_per_unit').setValue('');  
           }
    }
    if (!isNaN(numericValue)) {
    let formattedCurrency =this.currencyPipe.transform(numericValue, 'USD', '', '1.2-2');
   if(type == 'priceA'){
    if (!numericRegex.test(inputValue)) {
      // If the input value does not match the numeric regex, clear the value in the form control
      this.itemPriceForm.get('pricesA').setValue('');
    }
  this.itemPriceForm.get('pricesA').setValue(formattedCurrency);

   }
   else if(type == 'priceB'){
    if (!numericRegex.test(inputValue)) {
      // If the input value does not match the numeric regex, clear the value in the form control
      this.itemPriceForm.get('pricesB').setValue('');
    }
  this.itemPriceForm.get('pricesB').setValue(formattedCurrency);

   }
   else if(type == 'priceC'){
    if (!numericRegex.test(inputValue)) {
      // If the input value does not match the numeric regex, clear the value in the form control
      this.itemPriceForm.get('pricesC').setValue('');
    }
  this.itemPriceForm.get('pricesC').setValue(formattedCurrency);

   }
   else if(type == 'priceD'){
    if (!numericRegex.test(inputValue)) {
      // If the input value does not match the numeric regex, clear the value in the form control
      this.itemPriceForm.get('pricesD').setValue('');
    }
  this.itemPriceForm.get('pricesD').setValue(formattedCurrency);

   }
   else if(type == 'fix_cost_per_unit'){
    if (!numericRegex.test(inputValue)) {
      // If the input value does not match the numeric regex, clear the value in the form control
      this.userBasicInfoForm.get('fix_cost_per_unit').setValue('');
    }
    this.userBasicInfoForm.get('fix_cost_per_unit').setValue(formattedCurrency);  
     }
    } else {
      console.error('Invalid currency value:', inputValue);
    }
  }
  

//   onInputChange(event: any) {
//     const input = event.target as HTMLInputElement;
//     const inputValue = input.value;
// console.log(inputValue,'xxxxxxxxxxxx');
//     // Regular expression to allow only numbers (including decimals)
//     const numericRegex = /^(\d*\.?\d*)$/;

//     if (!numericRegex.test(inputValue)) {
//       // If the input value does not match the numeric regex, reset the value to empty
//       input.value = '';
//     }
//   }

  
  addEditItemcode() {
    this.itemCodeSubmit = true;
    if (!this.editItemCodeForm.get('item_code').value) {
      return;
    }
    this.addEditItemCodeArr.push({id: this.selectedItemId,code:this.editItemCodeForm.value.item_code});
    this.editItemCodeForm.value.item_code = '';
    this.itemCodeSubmit = false;
    this.editItemCodeForm.reset();
  }

  addItemAdditionaldetails() {
    this.itemValueSubmit = true;
    if (this.itemValueForm.invalid) {
      return;
    }
    let additionalValues = this.itemValueForm.value
    let value = {
      value: additionalValues.value,
      unit_id: additionalValues.unit_id ? additionalValues.unit_id.unit_id : '',
      unit_name: additionalValues.unit_id ? additionalValues.unit_id.unit_name : ''
    }
    this.addItemAdditionalDetailsArr.push(value);
    this.itemValueSubmit = false;
    this.itemValueForm.reset();
  }

  selecItemAdditionalDetailsList(i) {
    this.addItemAdditionalDetailsArr = this.itemAdditionalDetailsArr[i].item_additional_details_values;
    this.itemAddModel.variantName = this.itemAdditionalDetailsArr[i].variantName
    this.itemAdditionalDetailsArr.splice(i, 1);
  }

  itemAdditionaldetailsAdd() {
    let Item_AdditionalDetails_Arr = [];
    let item_AdditionalDetails_List;
    for (let i = 0; i < this.addItemAdditionalDetailsArr.length; ++i) {
      item_AdditionalDetails_List = { 'value': this.addItemAdditionalDetailsArr[i]['value'], 'unit_id': this.addItemAdditionalDetailsArr[i]['unit_id'] ? this.addItemAdditionalDetailsArr[i]['unit_id'] : '', 'unit_name': this.addItemAdditionalDetailsArr[i]['unit_name'] ? this.addItemAdditionalDetailsArr[i]['unit_name'] : '' }
      Item_AdditionalDetails_Arr.push(item_AdditionalDetails_List);
    }
    this.itemAdditionalDetailsArr.push({ 'variantName': this.itemAddModel.variantName, 'item_additional_details_values': Item_AdditionalDetails_Arr })
    this.addItemAdditionalDetailsArr = [];
  }


  updateCustomeFieldsOrder(customerCustomFieldArr) {
    const updateCustomeOrderSubs = this.itemsService.updateCustomeOrder(customerCustomFieldArr).subscribe((res) => {
      if (res.message == 'success' || res.status === 1) {
      }
    })
    this.subscriptions.push(updateCustomeOrderSubs);
  }


  updateItem(form?) {
    if (this.itemAddModel) {
      this.userBasicInfoSubmit = true;
      this.userBasicInfoSubmit = true;
      if (this.userBasicInfoForm.invalid) {
        return;
      }
      else {
        this.itemPriceAdd();
        // =====================  item_additional_details_values ===========================

        let itemAdditionalDetailsUpdateArr = [];
        let itemAdditionalDetailsUpdateList;

        for (let i = 0; i < this.itemAdditionalDetailsArr.length; ++i) {
          let itemAdditionalDetailsValues = this.itemAdditionalDetailsArr[i]['item_additional_details_values'];
          let itemAdditionalUpdateArr = [];
          for (let j = 0; j < itemAdditionalDetailsValues.length; ++j) {
            let item_additional_details_values_list = { 'value': itemAdditionalDetailsValues[j]['value'], 'unit_id': itemAdditionalDetailsValues[j]['unit_id'], 'unit_name': itemAdditionalDetailsValues[j]['unit_name'] }
            itemAdditionalUpdateArr.push(item_additional_details_values_list);
          }
          itemAdditionalDetailsUpdateList = { 'variantName': this.itemAdditionalDetailsArr[i]['variantName'], 'item_additional_details_values': itemAdditionalUpdateArr }
          itemAdditionalDetailsUpdateArr.push(itemAdditionalDetailsUpdateList);
        }

        this.itemAddModel.item_additional_details = itemAdditionalDetailsUpdateArr;
        // ===================== item_additional_details_values  ===========================

        // ===================== sub_items  ===========================
        let Item_Code_Arr = [];
        let subitemId_List;
        for (var i = 0; i < this.addEditItemCodeArr.length; ++i) {
          subitemId_List = { 'subitemId': this.addEditItemCodeArr[i]['id'] }
          Item_Code_Arr.push(subitemId_List);
        }
        this.itemAddModel.sub_items = Item_Code_Arr;
        // ===================== sub_items  ===========================

        // ===================== item_bundles  ===========================

        let Item_Bundles_Arr = [];
        let itemBundlesList;
        for (var i = 0; i < this.selectedBundle.length; ++i) {
          itemBundlesList = this.selectedBundle[i]['item_bundles']
          Item_Bundles_Arr.push(itemBundlesList);
        }
        this.itemAddModel.item_bundles = Item_Bundles_Arr;
        // ===================== item_bundles  ===========================

        // ===================== item_warehouses  ===========================
        this.itemAddModel.item_warehouses = this.addWarehouseArr;
        // ===================== item_warehouses  ===========================
        this.itemAddModel.item_images = this.editItemsImages;
        this.itemAddModel.comments = this.comments
        this.itemAddModel.unit_quantity=this.numberField.get('numberValue').value
        this.itemAddModel.categoryId = this.categoryId
        this.itemAddModel.subCategoryId = this.subCategoryId
        this.itemAddModel.subcategory_id=this.subCategoryId
        this.itemAddModel.ItemsDeactivated=this.isCheckedItems
        this.itemAddModel.first_url_title=this.firstUrlTitle
        this.itemAddModel.second_url_title=this.secondUrlTitle
        this.itemAddModel.unit=this.unitSelect.value
        this.itemAddModel.item_coming_from=this.itemType.value

        // ===================== item_warehouses  ===========================

 // working here 
 this.customerFielsDataSubmit=form.value
 this.customerCustomFieldArr.forEach((data, index) => {
   if (data.field_type_id == 2) {
     data.customer_custom_field_values.forEach(element => {
       if (this.customerFielsDataSubmit['value_' + data.id + '_' + element.value] == true) {
         this.customFieldDataArr.push({ 'value': element.value, 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id })
       }
     });
   }
   else if (data.field_type_id == 4) {
     data.customer_custom_field_values.forEach(element => {
       if (element.value == this.customerFielsDataSubmit['value_' + data.id + '_radio']) {
         this.customFieldDataArr.push({ 'value': element.value, 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id })
       }
     });
   }
   else if (data.field_type_id == 5) {
     data.customer_custom_field_values.forEach(element => {
       if (element.value == this.customerFielsDataSubmit['value_' + data.id + '_dropdown']) {
         this.customFieldDataArr.push({ 'value': element.value, 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id })
       }
     });
   }
   else if (data.field_type_id == 3) {
     this.customFieldDataArr.push(
       { 
         'value': this.customerFielsDataSubmit['value_' + data.id + '_date'], 
         'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id 
     })
   }
   else if (data.field_type_id == 6) {
    this.customFieldDataArr.push(
      { 
        'value': this.customerFielsDataSubmit['value_' + data.id + '_color'], 
        'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id 
    })
  }
   else {
     this.customFieldDataArr.push({ 'value': this.customerFielsDataSubmit['value_' + data.id], 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id });
   }
 })
 console.log("got all valueL ",this.customFieldDataArr);
 this.itemAddModel.items_custom_field_datas=this.customFieldDataArr;
 this.itemAddModel.isDeactivated=this.isChecked;
 const addItemSubss = this.itemsService.updateTexValues(this.itemAddModel?.item_prices_taxs).subscribe(res => {

 })
      const updateItemSubs =  this.itemsService.updateItem(this.itemAddModel).subscribe(
          res => {
            if (res.message === 'success' || res.status === 1) {
              if(typeof this.mainImageUrl === 'object' && Object.keys(this.mainImageUrl).length > 0){
                const uploadItemPhotoSubs = this.itemsService.uploadItemOrder(this.mainImageUrl).subscribe();
                this.subscriptions.push(uploadItemPhotoSubs); 

              }
              res.data.id=this.inputData.id
              this.dialogRef.close({...res?.data,tex:this.addItemPriceTaxArr,showTex:true});
              this.itemImage.length = 0;
            } else {
            }
          });
        this.subscriptions.push(updateItemSubs);
      }
    }
  }

  addSubCateogry(subcateogryForm) {
    if (subcateogryForm.form.valid) {
    const addSubCateogrySubs = this.itemsService.addSubCateogry(this.subCategoryAddModel).subscribe(
        res => {
          if (res.message === 'success' || res.status === 1) {
            this.subCategory();
          } else {
          }
        });
      this.subscriptions.push(addSubCateogrySubs);
    }
  }

  // WarehouseList ----------------------
  getWarehouseList(): Promise<any> {
    return new Promise((resolve,reject)=>{
     const warehouseList = this.itemsService.warehouseList().subscribe(res => {
        if (res.message === 'success' || res.status === 1) {
          this.warehouseListArr = res.data;
          resolve(true)
        } else {
          reject(false)
        }
      });
      this.subscriptions.push(warehouseList);
    })
   
  }
  // WarehouseList ----------------------

  // addNewTex ---------------------
  getTaxList() {
    const getTaxesListSubs = this.itemsService.getTaxesList({ module_name: 'items', dropdown_name: 'taxes' }).subscribe(res => {
      if (res.data?.length) {
        const getTaxCustomValuesSubs = this.itemsService.itemTexCustomValuess().subscribe(ress => {
          if(ress?.results?.length){
            res.data.forEach((item: any) => {
              // Find corresponding element in results array based on customValueId
              const resultItem = ress?.results.find((result: any) => result.customValueId === item.id);
          
              // If corresponding element is found, update taxe_value
              if (resultItem) {
                item.taxe_values = resultItem.taxe_value;
              }
            });
          }
          
        });
        this.subscriptions.push(getTaxCustomValuesSubs); // Push the subscription to subscriptions array
      }
      
      this.taxListArr = res.data;
    });
    
    this.subscriptions.push(getTaxesListSubs); // Push the subscription to subscriptions array
  }
  
  addNewTex() {
   const addNewTexSubs = this.itemsService.addNewTex(this.addNewTexListForm.value).subscribe(
      res => {
        this.getTaxList();
      });
    this.subscriptions.push(addNewTexSubs);
  }
  // addNewTex ----------------------

  // addNewBundle ----------------------
  addNewBundle() {
    this.itemAddBundleSubmit = true;
    if (this.addNewItemBundle.invalid) {
      return;
    }
    this.closeAddBundle.nativeElement.click();
    const addNewBundleSubs = this.itemsService.addNewBundle(this.addNewItemBundle.value).subscribe(
      res => {
        if (res.message === 'success' || res.status === 1) {
          this.getBundleIdList();
          this.addNewItemBundle.reset();
          this.itemAddBundleSubmit = false;
        }
      });
    this.subscriptions.push(addNewBundleSubs);
  }
  // addNewBundle ----------------------


  // addNewUnit Start ---------------------

  getUnitList() {
   const getTaxesListSubs = this.itemsService.getTaxesList({module_name:'items',dropdown_name:'unit'}).subscribe(res => {
      
      this.unitListArr = res.data;
    
  });
  this.subscriptions.push(getTaxesListSubs);
  }

  addNewUnit() {
    this.itemUnitSubmit = true;
    if (this.addNewUnitForm.invalid) {
      return;
    }
    this.closeAddUnithouse.nativeElement.click();
    const addNewUnitSubs = this.itemsService.addNewUnit(this.addNewUnitForm.value).subscribe(
      res => {
        this.getUnitList();
      });
    this.subscriptions.push(addNewUnitSubs);  
  }

  // addNewUnit End----------------------

  changeValueHide(event, index) {
    this.hidePrice = event.currentTarget.checked;
    this.selectedBundle[index]['item_bundles']['hide_price'] = this.hidePrice ? "1" : "0";
  }

  trackInventory(event) {
    this.trackInv = event.currentTarget.checked;
    this.itemAddModel.track_inventory = this.trackInv ? "1" : "0";
  }

  outOfStock(event) {
    this.outOfStoc = event.currentTarget.checked;
    this.itemAddModel.out_of_stock = this.outOfStoc ? "1" : "0";
  }

  advUnitCal(event) {
    this.advanceUnitCal = event.currentTarget.checked;
    this.itemAddModel.advance_unit_cal = this.advanceUnitCal ? "1" : "0";
  }

  addminPrice(event: any) {
    const numericRegex = /^(\d*\.?\d*)$/;
    const numericValue = parseFloat(event.target.value.replace(/[$,]/g, ''));
    if(isNaN(numericValue)){
      if (!numericRegex.test(event.target.value)) {
        // If the input value does not match the numeric regex, clear the value in the form control
        this.itemPriceForm.get('pricesC').setValue('');
      }
      this.userBasicInfoForm.get('min_price').setValue('');

    }
    if (!isNaN(numericValue)) {
    let formattedCurrency =this.currencyPipe.transform(numericValue, 'USD', '', '1.2-2');
    this.userBasicInfoForm.get('min_price').setValue(formattedCurrency);
    this.minPrice = formattedCurrency;
  }
}

async imageFilesAddedEdit(event: any) {
  const formData = new FormData();
  const maxFileSize = 2 * 1024 * 1024 * 1024; // 2 GB in bytes
  const allowedFileTypes = ['image/jpeg', 'image/png'];
  
  for (let i = 0; i < event.target.files.length; ++i) {
    const file = event.target.files[i];
    
    // Validate file type and size
    if (allowedFileTypes.includes(file.type) && file.size <= maxFileSize) {
      // Append valid file to FormData
      formData.append('files', file);
    } else {
      // Handle invalid files
      if (!allowedFileTypes.includes(file.type)) {
        alert(`Invalid file type: ${file.name}. Only JPEG/PNG are allowed.`);
      }
      if (file.size > maxFileSize) {
        alert(`File size exceeds 2GB: ${file.name}`);
      }
    }
  }

  if (formData.has('files')) {
    const uploadItemPhotoSubs = this.itemsService.uploadItemImages(formData).subscribe(
      res => {
        if (res.message === 'success' || res.status === 1) {
          this.editItemsImages.push({ 'image': res.data.image });
          this.image = { 'image': res.data.image };
        }
      },
      error => {
        console.error('Error uploading images:', error);
      }
    );
    this.subscriptions.push(uploadItemPhotoSubs);
  } else {
    alert('No valid files to upload.');
  }
}


  async userCustomUrl() {
    let merchantId = localStorage.getItem('merchant_id');
    const userCustom = this.itemsService.userCustomUrl({merchantId:merchantId}).subscribe(res => {
      this.firstUrlTitle=res[0]?.first_url_title
      this.secondUrlTitle=res[0]?.second_url_title
    });
      this.subscriptions.push(userCustom);  
    }
  
  

  salesCommType(val) {
    this.userBasicInfoForm?.controls?.sales_commission.setValue('') ;
    this.sales_commission_type=val
    this.itemAddModel.sales_comm_type = val;
  }

  // Item Add Edit ----------------------
  async editItemList() {
    const editItemListSubs = this.itemsService.iteamDataList(this.inputData.id).subscribe(async res => {
      this.customDataFieldViewData = res?.data?.items_custom_field_datas;
       $('.subitem_menu').css('display', 'block');
      $('.bundles_menu').css('display', 'block');
      this.isChecked=this.inputData?.isDeactivated
      this.itemAddModel = res.data;      
      this.isDeactivated=true
      this.editItemsImages = this.itemAddModel.item_images.sort((a, b) => {
        // Check if any images have a defined images_order
        const hasImagesOrder = this.itemAddModel.item_images.some(image => image.images_order !== 0);
        
        // Log the result of the check
    
        if (!hasImagesOrder) {
            // Sort by created_at (oldest date first) if no images_order is present
            const dateDifference = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            
            if (dateDifference !== 0) {
                return dateDifference;
            }
    
            // Fallback to a unique identifier (like id) if created_at is identical
            return (a.id || 0) - (b.id || 0);
        }
    
        // Existing sorting logic based on images_order
        if (a.images_order === null && b.images_order === null) {
            return 0; // No change in order
        }
        if (a.images_order === null) {
            return 1; // Put images with null order after others
        }
        if (b.images_order === null) {
            return -1; // Put images with null order before others
        }
        if (a.images_order === 1) {
            return -1; // Put images with images_order 1 at the top
        }
        if (b.images_order === 1) {
            return 1; // Put images with images_order 1 at the top
        }
    
        // Sort by images_order
        return (a.images_order || 0) - (b.images_order || 0);
    });
    
    
    const userImageData=this.editItemsImages[0]
    console.log(userImageData,'userImageDatauserImageData')
    this.onEditSHowMainImage(userImageData)
      // this.userBasicInfoForm.get('deactivate_item').valueChanges.subscribe((value)=>{
      //   if(value){
      //     const dialogData = new ModalModel(
      //       this.translateService.instant('ITEMS.DEACTIVATED')
      //     );
      //     const dialogRef = this.dialog.open(ModalComponent, {
      //       width: '450px',
      //       data: dialogData,
      //     });
      //     dialogRef.afterClosed().subscribe(async (dialogResult) => {
      //       if (dialogResult) {
      //         this.isCheckedItems=dialogResult

      //       }
      //     });
      //   }
      // })
      let selectedCat=this.cateogryListArr.find(item=>item.id==this.inputData.categoryId);
      if(this.inputData.categoryId){
        this.getCatId(this.inputData.categoryId)
        await this.subCategory()
      }
      let selectedSubCat  = this.subCategoryListArr.find(item=> {return item.id == this.itemAddModel.subcategory_id});
      this.unitSelect.patchValue(this.itemAddModel?.unit)
      this.itemType.patchValue(this.itemAddModel?.item_coming_from)
      this.userBasicInfoForm.patchValue({
        categoryId:selectedCat?.name,
        subCategoryId:selectedSubCat?.name

      })
      
      this.addEditItemCodeArr = this.itemAddModel.childitems;

      const bundles = this.itemAddModel.bundles;
      let bundlesListData;
      for (let i = 0; i < bundles?.length; ++i) {
        bundlesListData = { 'item_bundles': { 'bundleId': bundles[i]['id'], 'title': bundles[i]['title'], 'hide_price': bundles[i]['item_bundles']['hide_price'] } }
        this.selectedBundle.push(bundlesListData);
      }
      this.itemAdditionalDetailsArr = this.itemAddModel.item_additional_details
      const warehouses = this.itemAddModel.warehouses;
      let warehousesListData;
      for (let i = 0; i < warehouses?.length; ++i) {
        this.addWarehouseArr.push({status:1, 'warehouseId': warehouses[i]['id'] ,'name': warehouses[i]['name'],'items': warehouses[i]['item_warehouses']['items'] });
      }
      // ===================== Item_price_discounts  ===========================
      console.log(this.itemAddModel?.item_prices[0],'this.itemAddModel?.item_prices[0]')
      let discountValue =''
      if(this.itemAddModel?.item_prices[0]['discount'] !==null){
         discountValue = this.itemAddModel?.item_prices[0]['discount'].split(',');
      }
     
      let discountValueList;
      let discountValueArr = [];
      for (let i = 0; i < discountValue.length; ++i) {
        discountValueList = { 'discounts': discountValue[i] }
        discountValueArr.push(discountValueList);
      }
      this.addDiscountsArr = discountValueArr;
      this.addDiscountsArr.sort((a,b) => a.discounts - b.discounts); // b - a for reverse sort
      // ===================== Item_price_discounts  ===========================

      // ===================== Item_price_price  ===========================  
      // this.addPriceArr = JSON.parse(this.itemAddModel?.item_prices[0]['price']);
      // this.itemPriceForm.patchValue(JSON.parse(this.itemAddModel?.item_prices[0]['price']))

      const itemPrices = JSON.parse(this.itemAddModel?.item_prices[0]['price']);
      const sanitizedPrices = Object.keys(itemPrices).reduce((result, key) => {
        let formattedPrice = itemPrices[key];

        // Replace "NaN" with an empty string
        if (formattedPrice === "NaN") {
          formattedPrice = '';
        } else {
          // Remove commas if it's not NaN and is a valid string
          formattedPrice = formattedPrice.replace(',', '');
        }

        result[key] = formattedPrice;
        return result;
      }, {});

      this.itemPriceForm.patchValue(sanitizedPrices);

      
      this.numberField.get('numberValue').setValue(Number(this.itemAddModel.unit_quantity))

      // ===================== Item_price_price  ===========================
      // ===================== Item_price  ===========================
      this.itemAddModel.price = JSON.parse(this.itemAddModel?.item_prices[0]['price']);
      this.comments = this.itemAddModel.comments
      this.itemAddModel.min_price = this.itemAddModel?.item_prices[0]['min_price'] ?? 0;
      this.itemAddModel.currency_id = this.itemAddModel?.item_prices[0]['currency_id'];
      this.userBasicInfoForm.get('currency_id')?.patchValue(Number(this.itemAddModel?.item_prices[0]['currency_id']))
      this.itemAddModel.advance_unit_cal = this.itemAddModel?.item_prices[0]['advance_unit_cal'];
      this.itemAddModel.sales_commission = this.itemAddModel?.item_prices[0]['sales_commission'];
      this.itemAddModel.sales_comm_type = this.itemAddModel?.item_prices[0]['sales_comm_type'];
      this.itemAddModel.fix_cost_per_unit = this.itemAddModel?.item_prices[0]['fix_cost_per_unit'];
      this.itemAddModel.categoryId = this.inputData.categoryId
      // ===================== Item_price  ===========================

      const item_prices_tax = this.itemAddModel?.item_prices_taxs;
let itemPricesTaxListData;

if (item_prices_tax) {
  for (let i = 0; i < item_prices_tax.length; ++i) {
    if (
      item_prices_tax[i] &&
      item_prices_tax[i]['taxe_value'] &&
      item_prices_tax[i]['custom_value'] &&
      item_prices_tax[i]['custom_value']['name'] &&
      item_prices_tax[i]['customValueId']
    ) {
      itemPricesTaxListData = {
        'taxe_value': item_prices_tax[i]['taxe_value'],
        'taxData': {
          'name': item_prices_tax[i]['custom_value']['name'],
          'customValueId': item_prices_tax[i]['customValueId']
        }
      };
      this.addItemPriceTaxArr.push(itemPricesTaxListData);
    }
  }
}

      this.addItemPriceTaxArr.sort((a,b) => a.taxe_value - b.taxe_value); // b - a for reverse sort

    });
    this.subscriptions.push(editItemListSubs);  
  }

  itemPriceAdd() {
    let Item_Discount_Arr = [];
    var discount_List;
    for (var i = 0; i < this.addDiscountsArr.length; ++i) {
      discount_List = this.addDiscountsArr[i]['discounts']
      Item_Discount_Arr.push(discount_List);
    }


    this.itemAddModel.item_prices = [{
      "price": JSON.stringify(this.itemPriceForm.value),
      "min_price": this.itemAddModel.min_price,
      "currency_id": this.userBasicInfoForm.get('currency_id').value,
      "discount": Item_Discount_Arr,
      "advance_unit_cal": this.itemAddModel.advance_unit_cal,
      "sales_commission": this.itemAddModel.sales_commission || 0,
      "sales_comm_type": this.itemAddModel.sales_comm_type,
      "fix_cost_per_unit": this.itemAddModel.fix_cost_per_unit,
    }]

    // ===================== items_price_tax  ===========================
    let itemPriceList = [];
    for (let i = 0; i < this.addItemPriceTaxArr.length; ++i) {
      let customValueId = this.addItemPriceTaxArr[i]['taxData'] ? this.addItemPriceTaxArr[i]['taxData']['customValueId'] : this.addItemPriceTaxArr[i]['id'];
      let items_price_tax = { 'taxe_value': this.addItemPriceTaxArr[i]['taxe_value'], 'customValueId': customValueId, merchant_id: Number(localStorage.getItem('merchant_id'))};
      itemPriceList.push(items_price_tax);
    }
    this.itemAddModel.item_prices_taxs = itemPriceList;
    // ===================== items_price_tax  ===========================

  }
  subCatId(id){
    this.subCategoryId = id
  }
  

  addItem(form?) {
    // this.userBasicInfoSubmit = true

    if(this.itemPriceForm.invalid && this.currentTabIndex == 2){
      this.itemPriceSubmit = true
      this.userBasicInfoSubmit = true
      return
    }
    if(this.currentTabIndex == 0){
      if(!this.userBasicInfoForm.get('name').value) return
      this.currentTabIndex = 1

    }
    else if (this.currentTabIndex == 1){
      this.currentTabIndex = 2
    }else{
      if (this.itemAddModel) {
          this.userBasicInfoSubmit = true

        if (this.userBasicInfoForm.invalid) {
          return;
        }
        // else {
          this.itemPriceAdd();
          // ===================== item_additional_details  ===========================
  
          this.itemAddModel.item_additional_details = this.itemAdditionalDetailsArr;
  
          // ===================== item_additional_details  ===========================
  
          // ===================== sub_items  ===========================
  
          let Item_Code_Arr = [];
          let subitemId_List;
          for (let i = 0; i < this.addEditItemCodeArr?.length; ++i) {
            subitemId_List = { 'subitemId': this.addEditItemCodeArr[i]['id'] }
            Item_Code_Arr.push(subitemId_List);
          }
          this.itemAddModel.sub_items = Item_Code_Arr;
          // ===================== sub_items  ===========================
  
          // ===================== item_bundles  ===========================
          let Item_Bundles_Arr = [];
          let itemBundlesList;
          for (let i = 0; i < this.selectedBundle.length; ++i) {
            itemBundlesList = this.selectedBundle[i]['item_bundles']
            Item_Bundles_Arr.push(itemBundlesList);
          }
          this.itemAddModel.item_bundles = Item_Bundles_Arr;
  
          // ===================== item_bundles  ===========================
  
          // ===================== item_warehouses  ===========================

      
          this.itemAddModel.item_warehouses = this.addWarehouseArr;
          // ===================== item_warehouses  ===========================
  
          this.itemAddModel.item_images = this.editItemsImages;
          this.itemAddModel.comments = this.comments
          this.itemAddModel.categoryId = this.categoryId
          this.itemAddModel.subCategoryId = this.subCategoryId
          this.itemAddModel.unit=this.unitSelect.value
          this.itemAddModel.item_coming_from=this.itemType.value

          this.itemAddModel.item_service_type = this.itemType.value
          this.itemAddModel.unit_quantity=this.numberField.get('numberValue').value
          this.itemAddModel.first_url_title=this.firstUrlTitle
          this.itemAddModel.second_url_title=this.secondUrlTitle


          // Adding Custom Fields;
          this.customerFielsDataSubmit=form.value
          // console.log("this.customerCustomFieldArr: ",this.customerCustomFieldArr,form.value);
          this.customerCustomFieldArr.forEach((data, index) => {
            if (data.field_type_id == 2) {
              data.customer_custom_field_values.forEach(element => {
                if (this.customerFielsDataSubmit['value_' + data.id + '_' + element.value] == true) {
                  this.customFieldDataArr.push({ 'value': element.value, 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id })
                }
              });
            }
            else if (data.field_type_id == 4) {
              data.customer_custom_field_values.forEach(element => {
                if (element.value == this.customerFielsDataSubmit['value_' + data.id + '_radio']) {
                  this.customFieldDataArr.push({ 'value': element.value, 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id })
                }
              });
            }
            else if (data.field_type_id == 5) {
              data.customer_custom_field_values.forEach(element => {
                if (element.value == this.customerFielsDataSubmit['value_' + data.id + '_dropdown']) {
                  this.customFieldDataArr.push({ 'value': element.value, 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id })
                }
              });
            }
            else if (data.field_type_id == 3) {
              this.customFieldDataArr.push(
                { 
                  'value': this.customerFielsDataSubmit['value_' + data.id + '_date'], 
                  'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id 
              })
            }
            else if (data.field_type_id == 6) {
              this.customFieldDataArr.push(
                { 
                  'value': this.customerFielsDataSubmit['value_' + data.id + '_color'], 
                  'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id 
              })
            }
            else {
              this.customFieldDataArr.push({ 'value': this.customerFielsDataSubmit['value_' + data.id], 'customerCustomFieldId': data.id, 'field_type_id': data.field_type_id });
            }
          })
          // console.log("got all valueL ",this.customFieldDataArr);
          this.itemAddModel.items_custom_field_datas=this.customFieldDataArr;
          const addItemSubss = this.itemsService.updateTexValues(this.itemAddModel?.item_prices_taxs).subscribe(res => {

          })


         const addItemSubs = this.itemsService.addItem(this.itemAddModel).subscribe(
            res => {
              if (res.message === 'success' || res.status === 1) {
                this.dialogRef.close({...res,tex:this.addItemPriceTaxArr,showTex:true});
                this.itemImage.length = 0;
              } else {
              }
            });
          this.subscriptions.push(addItemSubs);  
        
      }
    }
   
  }



  // Item Add Edit ----------------------

  infoItem(id) {
    $('.hidden-info').css('display', 'none');
    $('.info-item').css('display', 'block');
    const iteamDataListSubs = this.itemsService.iteamDataList(id).subscribe(res => {
      this.itemViewModel = res.data;
      this.itemsImages = this.itemViewModel.item_images;
      this.mainImage = this.imageUrl + this.itemsImages[0].image;
    });
    this.subscriptions.push(iteamDataListSubs);
  }

  getImage(image) {
    return this.imageUrl + image;
  }

  changeMainImage(image) {
    this.mainImage = this.imageUrl + image;
  }

  async removeItem(i, type) {
    const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
    const dialogRef = this.dialog.open(ModalComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {

        if (type == 'subitem') {
          this.addEditItemCodeArr.splice(i, 1);
        }

        if (type == 'imgRemove') {
          const imageIndex = this.editItemsImages.indexOf(i)

          this.editItemsImages.splice(imageIndex, 1);
         this.image = this.editItemsImages[0]
        }

        if (type == 'bundleRemove') {
          this.selectedBundle.splice(i, 1);
        }

        if (type == 'discountRemove') {
          this.addDiscountsArr.splice(i, 1);
        }

        if (type == 'priceRemove') {
          this.addPriceArr.splice(i, 1);
        }

        if (type == 'itemPriceRemove') {
          this.addItemPriceTaxArr.splice(i, 1);
        }

        if (type == 'removeDetails') {
          this.addItemAdditionalDetailsArr.splice(i, 1);
        }

        if (type == 'additionalDetailsArrList') {
          this.itemAdditionalDetailsArr.splice(i, 1);
        }

        if (type == 'warehouseRemove') {
          this.addWarehouseArr.splice(i, 1);
        }


      }
    });
  }
  selectedTabIndex(event){
   this.currentTabIndex = event
  }
  
  
  
  itemBasicInfoFormVal() {
    this.userBasicInfoForm = this.formBuilder.group(
      {
        subCategoryId: [''],
        categoryId: ['',Validators.required],
        video_url: [''],
        website_url: [''],
        description: [''],
        item_coming_form: [''],
        currency_id: [''],
        sales_commission: [''],
        name: ['', Validators.required],
        code: [''],
        min_price: [0, Validators.required],
        variantName: [''],
        fix_cost_per_unit: [0, Validators.required],
        deactivate_item:[Boolean]
      }
    );

    this.editItemCodeForm = this.formBuilder.group(
      {
        item_code: ['']
      }
    );

    this.editItemBundleForm = this.formBuilder.group(
      {
        item_bundles: ['', Validators.required]
      }
    )

    this.itemPriceForm = this.formBuilder.group(
      {
        pricesA: ['', [Validators.required]],
        pricesB: ['', [Validators.min(1)]],
        pricesC: ['', [Validators.min(1)]],
        pricesD: ['', [Validators.min(1)]]
      }
    );

    this.itemDiscountForm = this.formBuilder.group(
      {
        discounts: ['']
      }
    );

    this.itemPriceTaxForm = this.formBuilder.group(
      {
        taxData: ['',Validators.required],
        taxe_value: ['',Validators.required]
      }
    );

    this.itemValueForm = this.formBuilder.group(
      {
        value: [''],
        unit_id: ['']
      }
    );

    this.addNewUnitForm = this.formBuilder.group(
      {
        name: ['', Validators.required]
      }
    );

    this.newWarehouseForm = this.formBuilder.group(
      {
        name: ['', Validators.required]
      }
    );

    this.addNewTexListForm = this.formBuilder.group(
      {
        name: ['']
      }
    );

    this.addNewItemBundle = this.formBuilder.group(
      {
        title: ['', Validators.required]
      }
    );

    this.itemwarehouseListForm = this.formBuilder.group(
      {
        item_warehouses: ['',Validators.required],
        items: [1]
      }
    );
    this.userBasicInfoForm.get('fix_cost_per_unit').valueChanges.subscribe((value)=>{
      if(value){
        this.calculateProfit()

      }
    })
    this.itemPriceForm.get('pricesA').valueChanges.subscribe((value)=>{
      if(value){
        this.calculateProfit()

      }
    })
    this.userBasicInfoForm.get('sales_commission').valueChanges.subscribe((value)=>{
      this.calculateProfit()
    })
  
    this.itemPriceForm.get('pricesA').valueChanges.subscribe((value) => {
      if (value) {
        this.itemPriceSubmit = true;
        this.calculateProfit();
       let minPriceValue
        if(typeof this.userBasicInfoForm.get('min_price').value === 'string'){
         minPriceValue = Number(this.userBasicInfoForm.get('min_price').value?.replace(/,/g, ''));
        }else{
           minPriceValue = Number(this.userBasicInfoForm.get('min_price').value?.toString().replace(/,/g, ''));

        }
        let enteredValue
        if(typeof value === 'string'){
           enteredValue = Number(value.replace(/,/g, ''));
        }else{
           enteredValue = Number(value?.toString().replace(/,/g, ''));
 
         }
        if (!isNaN(minPriceValue) && !isNaN(enteredValue)) {
          if (enteredValue > minPriceValue) {
            this.itemPrice['pricesA'].setErrors(null);
            this.userBasicInfoForm.get('min_price').setErrors(null);
            this.calculateProfit();
          } else {
            this.itemPrice['pricesA'].setErrors({ min: true });
          }
        } else {
          // Handle the case where the values are not valid numbers (e.g., show an error message)
          console.log('One or both values are not valid numbers');
        }
      }
    });
    
    this.userBasicInfoForm.get('min_price').valueChanges.subscribe((value) => {
      const pricesAValue = this.itemPrice['pricesA'].value;
  
      if (value) {
          const numericValue = parseFloat(value.replace(/,/g, ''));
  
          if (isNaN(numericValue)) {
              // Handle the case when value is not a valid number.
              return;
          }
  
          if (typeof pricesAValue === 'string') { // Check if pricesAValue is a string
              const numericPricesAValue = parseFloat(pricesAValue.replace(/,/g, ''));
  
              if (numericValue > numericPricesAValue) {
                  this.userBasicInfoForm.get('min_price').setErrors({ min: true });
              } else {
                  this.itemPrice['pricesA'].setErrors(null);
                  this.userBasicInfoForm.get('min_price').setErrors(null);
              }
          }
      }
  });
  
  
    
    
  }
/**
   * To remove value from dropdowns
   */

 async removeDropdownValues(i, itemId, unit) {
  const dialogData = new ModalModel(
    this.translateService.instant('PROFILE.CONFIRMDELETE')
  );
  const dialogRef = this.dialog.open(ModalComponent, {
    width: '450px',
    data: dialogData,
  });
  dialogRef.afterClosed().subscribe(async (dialogResult) => {
    if (dialogResult) {
    const removeCustomValueSubs = this.itemsService
        .removeCustomValue({ id: itemId })
        .subscribe((val: any) => {
          if (unit == 'tax') {
            this.taxListArr.splice(i, 1);
          }
          if (unit == 'unit') {
            this.unitListArr.splice(i, 1);
          }
          // this.SuccessSnackBar('Measurement Removed', 'Ok');
        });
        this.subscriptions.push(removeCustomValueSubs);
    }
  });
}


   /**
   * To add valuea in dropdowns.
   */
   addDropdownValue(addFormOfContact,categoryId) {
    console.log(this.itemAddModel.categoryId,'this.itemAddModel.categoryId')
    console.log(this.filterValue,'this.filterValue')
    if(!this.itemAddModel.categoryId && this.filterValue){
      this.itemsService.categoryAdd({name:this.filterValue,top_flag:0}).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        res => {
          this.itemAddModel.categoryId =  res.data.id
          this.categoryId = res.data.id
          this.userBasicInfoForm.get('categoryId').setValue(res.data.name)
          this.getCateogryList().then(()=>{
            this._autoCompleteSearchControlForCategory()
    
          })
        }
      )

    }else{
      const dialogData: any = {};
      dialogData.addFormOfContact = addFormOfContact;
       dialogData.itemsEditButtonId = categoryId;
      dialogData.id = categoryId;
      const dialogRef = this.dialog.open(AddCategoryComponent, {
        width: "800px",
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        console.log(dialogResult,'dialogResultdialogResult');
        if(dialogResult.data){
          this.itemAddModel.categoryId =  dialogResult.data.id
          this.categoryId = dialogResult.data.id
          this.userBasicInfoForm.get('categoryId').setValue(dialogResult.data.name)
        }
        this.getCateogryList().then(()=>{
          this._autoCompleteSearchControlForCategory()
  
        })
      });
    }
    
   
  }

  addDropdownValues(event, module, dropdown) {
    if (event == "addFormOfContact") {
        const dialogRef = this.dialog.open(AddValueInDropdownComponent, {
            width: "450px",
            data: { name: dropdown == 'taxes' ? 'Tax' : 'Unit' }
        });
        dialogRef.afterClosed().subscribe(async (dialogResult) => {
            if (dialogResult) {
                const nameExists = this.taxListArr.some(item => item.name?.toLowerCase() === dialogResult?.name?.toLowerCase());
                if (nameExists) {
                  this.snackbar.open(this.translateService.instant('QUOTE_TEMPLATE.Name_Exist'), this.translateService.instant('COMMON.OK'),  { duration: 5000 });
                   return;
                }
                const addCustomValueSubs = this.itemsService.addCustomValue({ name: dialogResult?.name,taxe_value:dialogResult?.percent , module_name: module, dropdown_name: dropdown }).subscribe((val: any) => {
                    if (dropdown == 'taxes') {
                        this.taxListArr.push(val);
                    } if (dropdown == 'unit') {
                        this.unitListArr.push(val);
                    }
                });
                this.subscriptions.push(addCustomValueSubs);
            }
        });
    }
}


  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Advane Unit Calculations 
  calculate(){
    // console.log("calculation data:",this.calculations);
    let result =0;
    if(this.calculations.sign=='+'){
      result=this.calculations.height+this.calculations.width
    }else if(this.calculations.sign=="-"){
      result=this.calculations.height-this.calculations.width
    }else if(this.calculations.sign=="x"){
      result=this.calculations.height*this.calculations.width
    }else if(this.calculations.sign=="/"){
      result=this.calculations.height/this.calculations.width
    }    
    if(result % 1 !== 0)this.calculations.result=result.toFixed(2);
    else this.calculations.result=result.toFixed(0);
  }
 
  //#region  Adding Custom Fields 
  editCustomFieldId = null;
  editCustomFieldIndex = null;
  valueFieldShow: any;
  addCustomFieldForm: FormGroup;
  fieldValueArr: any = [];
  customFieldListArr: any;
  checkedList = [];
  cusFieldFormSubmit = false;
  removable = true;
  customFieldDataArr:any=[];
  customerFielsDataSubmit:any;
  // customDataFieldViewData = [];
  @ViewChild('customFieldForm') customFieldForm;
  customerCustomFieldArr = [];

  get customField(): { [key: string]: AbstractControl } {
    return this.addCustomFieldForm?.controls;
  }

  addToNgOninit(){
    this.addCustomFieldForm = this.formBuilder.group(
      {
        field_type_id: ['', Validators.required],
        name: [''],
        customer_custom_field_values: [''],
        order: ['']
      },
    );

    this.getAllListing();
  }

  addSubcategories(addFormOfContact,categoryId) {
    if(this.subCateogrySearch.value && !this.itemAddModel.subCategoryId){
      if(this.categoryId) {this.subCategoryAddModel.categoryId = this.categoryId}
      if(!this.categoryId){
        this.snackbar.open('Please first select category', 'Close', {
          duration: 3000, // duration in milliseconds
          verticalPosition: 'top', // or 'bottom'
          horizontalPosition: 'center' // or 'left', 'right'
        });
        return
      }

      const addSubCateogrySubs = this.itemsService.addSubCateogry({categoryId:this.categoryId || '',name:this.subCateogrySearch.value}).subscribe(
          res => {
            if (res.message === 'success' || res.status === 1) {
              if(res.data){
                      this.itemAddModel.subCategoryId = res.data.id
                      this.subCategoryId = res.data.id
                      this.userBasicInfoForm.get('subCategoryId').setValue(res.data.name)
                    }
                    this.getSubCategoryList().then(()=>{
                      this._autoCompleteSearchControlForSubCategory()
              
                    })
                   
            } else {
              this.spinnerService.hide();
            }
          });
        this.subscriptions.push(addSubCateogrySubs);
    }else{
      const dialogData: any = {};
      dialogData.categoryList = this.cateogryListArr;
      dialogData.categoryId =  this.categoryId;
      const dialogRef = this.dialog.open(AddSubCategoryComponent, {
        width: "800px",
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        if(dialogResult){
          if(dialogResult.data){
            this.itemAddModel.subCategoryId = dialogResult.data.id
            this.subCategoryId = dialogResult.data.id
            this.userBasicInfoForm.get('subCategoryId').setValue(dialogResult.data.name)
          }
          this.getSubCategoryList().then(()=>{
            this._autoCompleteSearchControlForSubCategory()
    
          })
        }
     
      });
    }


   

   
  }

  updateSubcategories(obj) {
    const dialogRef = this.dialog.open(AddSubCategoryComponent, {
      width: "800px",
      data: obj
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if(dialogResult){
        this.userBasicInfoForm.patchValue({
          subCategoryId:dialogResult?.data?.name
        })
        this.getSubCategoryList().then(()=>{
          this._autoCompleteSearchControlForSubCategory()
  
        })
      }
   
    });
  }

  async removeSubCategory(i,subCategoryId,obj) {
    const dialogData = new ModalModel(
      this.translateService.instant('PROFILE.CONFIRMDELETE')
    );
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '450px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
       const removeSubCategoryValueSubs = this.itemsService
          .removeSubCategoryValue({ id: subCategoryId })
          .subscribe((val: any) => {
            if (obj == 'Subcategory') {
              this.subCategoryListArr.splice(i, 1);
              this._autoCompleteSearchControlForSubCategory()
              // this.getSearchHistory().then((res: any) => {
              //   this.categorySearchHistory = res;
              //   this.infoItemBlock = false;
              //   this.hiddenInfo = true;
              // });
            }
            // this.SuccessSnackBar('Measurement Removed', 'Ok');
          });
        this.subscriptions.push(removeSubCategoryValueSubs);
      }
    });
  }

  deleteCustomField(data) {
   
    // this.itemsService.itemcustomFieldList({ module: 'organization' }).subscribe(result => {
    //   this.fieldListingDataId = result.data[0] ? result.data[0].id : '';
    //   if (result.data.length > 0) {
    //     if (result.data[0]?.field.length < 1 || result.data[0]?.field == '[]') {
    //       this.fieldListArr = [];
    //     } else {
    //       this.fieldListArr = result.data[0]?.field;
    //     }
    //   }
    //   const index = this.fieldListArr.findIndex(val => val == data?.customField?.name);
    //   this.fieldListArr.splice(index, 1);
    //   this.filterDataJson = { field: this.fieldListArr, id: this.fieldListingDataId, module: 'organization' };
      this.itemsService.deleteCustomField({ id: data?.customField?.id }).subscribe((res) => {
        this.customerCustomFieldArr.splice(data.index, 1);
      })
  }

  getAllListing(){

    const customerCustomFieldListSubs = this.itemsService.customerCustomFieldList().subscribe((val: any) => {
      this.customerCustomFieldArr = val.data;

    });
    this.subscriptions.push(customerCustomFieldListSubs);
    
    const customFieldListSubs = this.editContactService.customFieldList().subscribe((val: any) => {
      this.customFieldListArr = val.data;
    });
    this.subscriptions.push(customFieldListSubs);
  }

  editCustomField(event) {
    this.editCustomFieldId = event.customField?.id;
    this.editCustomFieldIndex = event?.index;
    this.valueFieldShow = event.customField?.field_type_id;
    this.addCustomFieldForm.patchValue(event.customField)
    this.fieldValueArr = event.customField?.customer_custom_field_values || [];
    $("#add_custom_field").modal('show');
  }

  closeCustomFieldDialog() {
    this.valueFieldShow = null;
    this.fieldValueArr = [];
    this.editCustomFieldId = null;
    this.editCustomFieldIndex = null;
    this.addCustomFieldForm.reset();
  }

  chnageType(event: any) {
    this.valueFieldShow = event.value;
  }

  add1(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.checkedList.push({ 'value': '', 'customerCustomFieldId': '' })
      this.fieldValueArr.push({ value: value.trim() })
    }
    if (input) {
      input.value = '';
    }
  }

  remove1(fruit): void {
    const index = this.fieldValueArr.indexOf(fruit);
    if (index >= 0) {
      this.checkedList.splice(index, 1)
      this.fieldValueArr.splice(index, 1);
    }
  }

  addCustomFieldList() {
    this.cusFieldFormSubmit = true
    if (this.addCustomFieldForm.invalid) {
      return
    } else {
      this.addCustomFieldForm?.controls['customer_custom_field_values'].setValue(this.fieldValueArr)
      if (this.editCustomFieldId) {
        const updateCustomFieldSubs = this.itemsService.updateItemCustomfield(this.editCustomFieldId, this.addCustomFieldForm.value).subscribe((res) => {
          if (res.message == 'success' || res.status === 1) {
            this.fieldValueArr = [];
            this.addCustomFieldForm.reset();
            const _customerCustomFieldArr = [...this.customerCustomFieldArr];
            _customerCustomFieldArr[this.editCustomFieldIndex] = res.data;
            this.customerCustomFieldArr = _customerCustomFieldArr;
            this.cusFieldFormSubmit = false
            this.customFieldForm.nativeElement.click();
            this.editCustomFieldIndex = null;
            this.editCustomFieldId = null;
          }
        })
        this.subscriptions.push(updateCustomFieldSubs);
      } else {
        this.addCustomFieldForm?.controls['order'].setValue(this.customerCustomFieldArr.length + 1);
        const addCustomFieldSubs = this.itemsService.createCustomField(this.addCustomFieldForm.value).subscribe((res) => {
          if (res.message == 'success' || res.status === 1) {
            this.fieldValueArr = [];
            this.addCustomFieldForm.reset();
            const _customerCustomFieldArr = [...this.customerCustomFieldArr];
            _customerCustomFieldArr.push(res.data);
            this.customerCustomFieldArr = _customerCustomFieldArr;
            // this.getAllListing();          
            this.cusFieldFormSubmit = false
            this.customFieldForm.nativeElement.click();
          }
        })
        this.subscriptions.push(addCustomFieldSubs);
      }

    }
  }

  onOptionSelected(event: any) {
    const selectedValue = event.value;
    if(selectedValue){
      const addCustomFieldSubs = this.itemsService.itemTexValues({ customValueId: selectedValue?.customValueId }).subscribe((res) => {
          const newItem = res?.results[0]; // Extract the first item from the received array
          this.addItemPriceTax(selectedValue?.complete)
         
      });
      this.subscriptions.push(addCustomFieldSubs);
    }
}

  editDropdownValues(dropdownValue){
    const dialogRef = this.dialog.open(AddValueInDropdownComponent, {
      width: "450px",
      data: { name:'Tax',editValues:dropdownValue}
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
          const editCustomValueSubs = this.itemsService.addCustomValue({ id:dropdownValue?.id,name:dialogResult?.name,taxe_value:dialogResult?.percent}).subscribe((val: any) => {
            this.getTaxList()
          });
          this.subscriptions.push(editCustomValueSubs);
      }
    });
  }
  openBulkActionPopup(event,i){
    this.currentIndex = i
      event.stopPropagation();
     
        this.cateogryListArr.map((x, index) => {
          if (i === index) {
            this.openBulkAction = !this.openBulkAction;
          }
        });
      }
  
  closingbulkdialog(){
    
    this.openBulkAction = false;
  }

  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }
  
}
