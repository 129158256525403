<div class="filter-container">
    <div class="search-filter"><input type="text" placeholder="{{'COMMON.Search' | translate}}"></div>
    <div class="saved-filter">

        <span *ngIf="savedFilter?.deal_type?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.deal_type; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'deal_type')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.activity?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.activity; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'activity')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.organization?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.organization; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'organization')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.tags?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.tags; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'tags')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.deal_stage?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input "
                *ngFor="let sfilter of savedFilter?.deal_stage; let i = index;" [selectable]="selectable"
                [removable]="removable">
                <a href="javascript:void(0)"> {{sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'deal_stage')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.customFields" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input "
                *ngFor="let sfilter of savedFilter?.customFields; let i = index;" [selectable]="selectable"
                [removable]="removable">
                <a href="javascript:void(0)"> {{sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'customFields')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.singale_value"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.singale_value}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.singale_value, 'singale_value')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable"
                *ngIf="savedFilter?.slider_value && savedFilter?.slider_value !='0-0'" [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.slider_value}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.slider_value, 'slider_value')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.singale_date"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.singale_date}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.singale_date, 'singale_date')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.from_to_date"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.from_to_date}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.from_to_date, 'from_to_date')"> close </mat-icon>
            </mat-chip>
        </span>

    </div>
    <div class="acordion-filed">
        <mat-accordion>
            <mat-expansion-panel *ngIf="viewData==='list_view'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"COMMON.dealtype"|translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search Deal Type ...">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <mat-radio-group>
                                <p *ngFor="let item of deal_typeArr">
                                    <mat-radio-button [checked]="savedFilter?.deal_type ? item.id == savedFilter?.deal_type[0].id : false" [value]="item"
                                        (change)="getAccorValue($event, item, 'deal_type')">
                                        {{getTranslatedText ("DEAL_DETAILS." + item?.name) || item?.name}}
                                    </mat-radio-button>
                                </p>
                            </mat-radio-group>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="viewData==='list_view'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"COMMON.dealstage"|translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input"
                        placeholder="Select a deal type before selecting a deal stage...">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of deal_stageArr">
                                <mat-checkbox [checked]="checkValueExist(savedFilter?.deal_stage,item.id)" (change)="getAccorValue($event, item, 'deal_stage')">{{getTranslatedText
                                    ("DEAL_DETAILS." + item?.name) || item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel id="custom_fields">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="form-check-label text-6 font-weight-500" for="a">
                            {{"COMMON.CustomField" | translate}}</label>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="overflow-auto filter-section-height pb-3">
                    <section class="example-section fw-500" *ngFor="let customField of customerCustomFieldArr">
                        <mat-checkbox class="ms-3" *ngIf="!customField.customer_custom_field_values?.length"
                            [(ngModel)]="customField.checked" [color]="'primary'"
                            (change)="getAccorValue($event, customField, 'customFields')">{{customField.name}}
                        </mat-checkbox>
                        <div *ngIf="customField.customer_custom_field_values?.length">{{customField.name}}</div>
                        <ng-container *ngIf="customField.customer_custom_field_values?.length">
                            <span *ngFor="let option of customField.customer_custom_field_values"
                                class="d-inline-block ms-3">
                                <mat-checkbox [checked]="checkValueExistWithId(savedFilter?.customFields,customField.id + '--'+option.value)" [color]="'primary'" (change)="getAccorValue($event, {id: customField.id, name:option.value}, 'customFields')">{{option.value}}</mat-checkbox>
                            </span>
                        </ng-container>
                    </section>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"COMMON.activity"|translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search activity ...">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of activityArr">
                                <mat-checkbox [checked]="checkValueExist(savedFilter?.activity,item.id)" (change)="getAccorValue($event, item, 'activity')">{{item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"COMMON.Tags"|translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search tags ..." [(ngModel)]="searchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of tagsArr | search : searchTags">
                                <mat-checkbox 
                                    [checked]="checkValueExist(savedFilter?.tags,item.id)" (change)="getAccorValue($event, item, 'tags')">{{item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Organization"}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search organization ..." [(ngModel)]="searchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of allOrgArr | search : searchTags">
                                <mat-checkbox [checked]="checkValueExist(savedFilter?.organization,item.id)"
                                    (change)="getAccorValue($event, item, 'organization')">{{item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
    <div class="check_box">
        <mat-checkbox class="content_box mx-3" name="rotten days" (change)="toggle($event, 'rotten_days')"
            color="primary">
            {{"LEADS.rotted_Days"|translate}}
        </mat-checkbox>
    </div>
    <div class="check_box">
        <mat-checkbox class="content_box mx-3" name="in active deals" (change)="toggle($event, 'in_active_deals')"
            color="primary">
            {{"COMMON.Deactivated leads"|translate}}
        </mat-checkbox>
    </div>
    <div class="value-range-field">
        <div class="value-field">
            <label for="singale_value">{{"COMMON.Enter_a_Deal_Value"|translate}}</label>
            <input type="number" id="singale_value" class="value-input" [formControl]="singale_value"
                (change)="getAccorValue($event, singale_value?.value, 'singale_value')">
        </div>
        <div class="range-field">
            <label for="range_value">{{"COMMON.Select_Range"|translate}}</label>
            <div class="range-input" id="range_value" class="range-input">
                <ngx-slider [(value)]="sliderValue" [(highValue)]="sliderMaxValue" [options]="sliderOptions"
                    (valueChange)="getAccorValue($event, sliderMaxValue, 'slider_value')">
                </ngx-slider>
            </div>
        </div>
    </div>
    <div class="date-field">
        <div class="single-date-field">
            <label for="single_date">{{"COMMON.Date"|translate}}</label>
            <app-custom-date id="single_date" (updateValue)="getAccorValue($event, 'singaledate', 'singale_date')"
                dataType='4' inputType="date" fvalue="{{savedFilter.singale_date}}" class="single-date-input">
            </app-custom-date>
        </div>
        <div class="fromto-date-field">
            <div class="from-date-field">
                <label for="start_date">{{"COMMON.From"|translate}}</label>
                <app-custom-date id="start_date" (updateValue)="getAccorValue($event,'fromtodate', 'start_date')"
                    dataType='4' maxDate="{{savedFilter.end_date}}" inputType="date" fvalue="{{savedFilter.start_date}}"
                    class="from-date-input">
                </app-custom-date>
            </div>
            <div class="to-date-field">
                <label for="end_date">{{"COMMON.To"|translate}}</label>
                <app-custom-date id="end_date" (updateValue)="getAccorValue($event, 'fromtodate', 'end_date')"
                    dataType='4' minDate="{{savedFilter.start_date}}" inputType="date" fvalue="{{savedFilter.end_date}}"
                    class="to-date-input">
                </app-custom-date>
            </div>
        </div>
    </div>
    <div class="apply-filter-field">
        <div class="clear-filter-btn">
            <button type="button" (click)="resetFilter()">
                <app-custom-icon icon="round_close"></app-custom-icon> {{"COMMON.Cancel_Filter"|translate}}
            </button>
        </div>
        <div class="apply-filter-btn">
            <button type="button" (click)="saveFilter()">
                <app-custom-icon icon="round_correct"></app-custom-icon> {{"COMMON.Apply_Filter"|translate}}
            </button>
        </div>
    </div>
</div>