<div>
  <ng-container *ngIf="dailogData?.type!=='view' && dailogData?.type!=='preview'&& dailogData?.type!=='payment' && dailogData?.type !=='Quote preview' && dailogData?.type !=='Quote stage' && dailogData?.type !=='User Confirm'&& dailogData.type !== 'quote'">
      <div class="model-header position-relative">
          <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
              class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 mr-3 mb-5"></i>
      </div> 
      <div class="p-3">
          <form [formGroup]="newtemFrom" (ngSubmit)="newtemFormValue()">
              <div class="row">
                  <div class="col-lg-12 my-2">
                      <div class="form-group">
                          <label class="text-3 mb-2">{{"COMMON.TEMPLATE_NAME" | translate}}<span
                                  class="color-6">*</span></label>
                          <div class="d-flex align-items-center flex-column">
                              <input type="text" class="form-control"
                                  placeholder='{{"QUOTE_TEMPLATE.New Quote Template" | translate}}' name="template_name"
                                  formControlName="template_name"
                                  [ngClass]="{ 'is-invalid': newtemSubmit && newtemVal?.template_name?.errors}">
                              <div *ngIf="newtemSubmit && newtemVal.template_name.errors" class="invalid-feedback">
                                  <div *ngIf="newtemVal?.template_name?.errors?.required">
                                      {{"QUOTE_TEMPLATE.Quote form name is Required"  | translate}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- <div class="col-lg-12 my-2">
                      <div class="form-group">
                          <label class="text-3 mb-2">{{"LEADFRMODULE.Label_Name" | translate}}<span
                                  class="color-6">*</span></label>
                          <div class="d-flex align-items-center flex-column">
                              <input type="text" class="form-control"
                                  placeholder="{{'LEADFRMODULE.Agency_business' | translate}}" name="label"
                                  formControlName="lable"
                                  [ngClass]="{ 'is-invalid': newtemSubmit && newtemVal?.lable?.errors}">
                              <div *ngIf="newtemSubmit && newtemVal.lable.errors" class="invalid-feedback">
                                  <div *ngIf="newtemVal?.lable?.errors?.required"> {{"LEADFRMODULE.Label_Name_Error"
                                      | translate}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> -->
                  <!-- <div class="col-lg-12 my-2">
                      <div class="form-group">
                          <label class="text-3 my-2">{{"Tags"}}</label>
                      </div>
                      <mat-form-field class="p-0 m-0 d-block lead-temp-tag" appearance="outline">
                          <mat-chip class="m-1" *ngFor="let tags of leadTags" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(tags)">{{tags.name}}
                              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <mat-chip-list #chipList class="p-0">
                              <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                          </mat-chip-list>
                      </mat-form-field>
                  </div> -->
              </div>
              <div class="mt-3 text-center" style="display: flex;
              flex-wrap: wrap;
              align-content: center;
              justify-content: center;
              align-items: center;">
                  <button type="submit" (click)="submitType='createorduplicate'" *ngIf="dailogData.type === 'create' || dailogData.type === 'duplicate'"
                      class="btn btn-p-2 standard-btn">
                      <span>{{"COMMON.Get_started" |translate}}</span>
                  </button>
                  <button type="submit" (click)="submitType='clone'" *ngIf="dailogData.type === 'clone'" class="btn btn-p-2 standard-btn mx-2">
                      <span>{{"LEADFRMODULE.Clone" |translate}}</span>
                  </button>
                  <button type="submit" (click)="submitType='update'" *ngIf="dailogData.type === 'edit'" class="btn btn-p-2 standard-btn mx-2">
                      <span>{{"LEADFRMODULE.Update" |translate}}</span>
                  </button>
                  <button type="submit" (click)="submitType='edit'" *ngIf="dailogData.type === 'edit'" class="btn btn-p-2 standard-btn mx-2">
                      <span>{{"QUOTE_TEMPLATE.Edit Template" |translate}}</span>
                  </button>
              </div>
          </form>
      </div>
  </ng-container>
  <!-- <ng-container *ngIf="dailogData?.type=='view'" >
      <div style="height: 600px;">
    
      <div class="preview">
         
          <div class="model-header position-relative">
              <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
                  class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
          </div>
  
          <div [innerHtml]="getSafeHtml(dailogData.data.html)"></div>
      </div>
  </div>
  </ng-container> -->

  <!-- <ng-container *ngIf="dailogData?.type=='view'" >
      <div style="display: flex; flex-direction: column; height: 600px;">
        <div class="model-header flex justify-end">
          <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
            class="color-4 fs-2 far fa-times-circle m-3"></i>
        </div>
        <div class="preview flex justify-center" style="flex: 1;">
          <div [innerHtml]="getSafeHtml(dailogData.data.html)"></div>
        
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngIf="dailogData?.type=='Quote preview' || dailogData?.type== 'view'" >
      <div style="display: flex; flex-direction: column; height: 550px;">
        <div class="model-header flex justify-end">
          <!-- <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
            class="color-4 fs-2 far fa-times-circle m-3"></i> -->
            <mat-icon type="button" #closebutton class="color-4 fs-2 m-3" 
            data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"  style="
            height:fit-content;
            width:fit-content;
            border-radius: 50%;
            border: 2px solid #f85050;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px !important;
            font-size: 13px !important;
            font-weight: 600;
            padding: 1px 1px 1px 1px;" >close</mat-icon>
        </div>
        
        <div class="preview overflow-y-scroll flex justify-center" style="flex: 1;">
          <div class="element-area" #formContent  id="parentId" style="width:840px" >


              <div #dropField id="dropField" style=" width: 100% !important;" class=" borderColor mb-3 py-3 d-flex justify-content-center bg-00 rounded" [ngClass]="page_landscape? 'drop_field_landscape': 'drop-field inline-table'"  *ngFor="let dropPage of dropListData;let z = index" >
                  <!-- <div *ngIf="active_Tabs!=='submit_options'"> -->
          
                  <div #temp_ref id="temp_ref" class="d-flex justify-center" style="min-height: 500px;width:810px !important;" [ngClass]="{'resp_width':!hideSidenav || isEleHide && !page_landscape, 'drop_element_landscape':page_landscape}">
                      <div class="example-list d-flex justify-center" [id]="dropPage.page" cdkDropList 
                          (cdkDropListDropped)="itemDropped($event,z,dropPage.page)" style="height: 100%; width: 794px;padding-top:16px;">
                          <div class="d-flex flex-wrap w-full" [ngClass]="dropPage.page">
                          <ng-container >
                              <div class="droped-item mb-1 example-box" *ngFor="let item of dropPage.items;let i = index;" 
                              [ngClass]="{'col-md-4 col-4 col-sm-4 col-lg-4': item?.col === 'three', 'col-md-3 col-3 col-sm-3 col-lg-3': item?.col === 'four', 'col-md-6 col-6 col-sm-6 col-lg-6': item?.col === 'two', 'col-md-12 col-12 col-sm-12 col-lg-12': item?.col == 'one'}"   [ngStyle]="{
                                  'justify-content':item?.align,
                                  'display':'flex'
                                  
          
                              }"
                                  >
                                  <div class="w-full"
                                       [ngStyle]="{
                                          'justify-content':item?.align,
                                          'margin-bottom':item?.mrbottum+'px',
                                          'margin-right':item?.mrright+'px',
                                          'font-family':item?.fontFamily,
                                          'font-weight':item?.font_Weight,
                                          'padding-bottom':item?.padding+'px',
                                          'padding-top':item?.paddingT+'px',
                                          'padding-right':item?.paddingR+'px',
                                          'padding-left':item?.paddingL+'px',
                                          'color':item?.color,
                                          'margin-top':item?.mrtop+'px',
                                          'width' : item?.name == 'Space' || item?.name == 'Selectbox' || item?.name == 'Checkbox' || item?.name == 'Input Field' ||  item?.name == 'Divider' || item?.name == 'Text Area' || item?.name == 'Clauses' || item?.name == 'Textbox' || item?.name == 'Signature' || item?.name=== 'Organization Logo' || item?.name=== 'Image' || item?.name=== 'Workspace Logo' ? '100%':'fit-content',
                                          'margin-left':item?.mrleft+'px',
                                          'text-decoration':item?.textDecoration,
                                          'font-style':item?.font_italic
          
                                      }">
                            
          
                                      <div class="box-content" [ngStyle]="{ 'width': item?.name === 'Text Area' ? '100%' :item?.name === 'Divider'?'100%': '100%' }" >
                                          <div class="box-content-elements">
                                              <div   *ngIf="item?.name==='Heading'" [ngStyle]="{
                                                  'justify-content': item?.align
                                              
                                                }" class="d-flex heading-{{ headingCount }}" >
                                                  <h1 style="margin: 0 !important;"  class="f-responsive" [ngStyle]="{
                                                      'font-size':item?.style_font_size+'px',
                                                      'color': '#333',
                                                      'font-family': selectedFont ? selectedFont :item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'color':item?.color,
                                                      'margin':' 0 !important',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                    
                                                     
                                                  }" >  {{item?.value}} </h1>
                                              </div>
                                              <div *ngIf="item?.name === 'Payment'" class="d-flex gap-2" [ngStyle]="{
                                                'justify-content': item?.align,
                                                'font-size': item?.font_size + 'px',
                                                'color': item?.color || '#333',
                                                'font-family': item?.fontFamily,
                                                'font-weight': item?.font_Weight,
                                                'padding-bottom': item?.padding + 'px',
                                                'padding-top': item?.paddingT + 'px',
                                                'padding-right': item?.paddingR + 'px',
                                                'padding-left': item?.paddingL + 'px',
                                                'text-decoration': item?.textDecoration,
                                                'font-style': item?.font_italic
                                            }">
                                              <div class="d-flex flex-column justify-between mb-3 w-44">
                                                <label *ngIf="item?.Title || item?.Title ==''" for="selectbox" class="lebel_res mb-1" [ngStyle]="{
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size + 'px',
                                                    'color': item?.color || '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin': '0px 0px 4px 0px !important',
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align': item?.align
                                                }">{{ item?.Title }}</label>
                                                
                                                <div style="border:1px solid #cbdbe4 !important;border-radius: 5px !important;" [ngStyle]="{
                                                    'text-align': item?.align
                                                }" class="form-select txt-res">
                                                  <select class="focus:!outline-none" style="border:none!important" [ngStyle]="{
                                                      'text-align': item?.align
                                                  }" name="selectbox" id="selectbox"
                                                          [(ngModel)]="item.selectedPaymentTitle" 
                                                          disabled>
                                                    <option [value]="title.name" *ngFor="let title of item?.paymentTitles">
                                                      {{ title.name }}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                              
                                              <div id="date" class="d-flex flex-column justify-between mb-3 w-36">
                                                <label *ngIf="item?.Date || item?.Date ==''" for="dateInput" class="lebel_res mb-1" [ngStyle]="{
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size + 'px',
                                                    'color': item?.color || '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin': '0px 0px 4px 0px !important',
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align': item?.align
                                                }">{{ item?.Date }}</label>
                                            
                                                <mat-form-field [ngStyle]="{
                                                    'text-align': item?.align
                                                }" style="border:1px solid #cbdbe4 !important" appearance="outline" floatLabel="always">
                                                  <input [placeholder]="item?.placeholder" #input matInput [matDatepicker]="dateName" [value]="item.selectedDate" readonly>
                                                  <mat-datepicker-toggle matSuffix [for]="dateName"></mat-datepicker-toggle>
                                                  <mat-datepicker #dateName></mat-datepicker>
                                                </mat-form-field>
                                              </div>
                                              
                                              <div class="d-flex flex-column justify-between mb-3 w-40">
                                                <label *ngIf="item?.Method || item?.Method ==''" for="selectMethod" class="lebel_res mb-1" [ngStyle]="{
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size + 'px',
                                                    'color': item?.color || '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin': '0px 0px 4px 0px !important',
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align': item?.align
                                                }">{{ item?.Method }}</label>
                                                
                                                <div [ngStyle]="{
                                                    'text-align': item?.align
                                                }" style="border:1px solid #cbdbe4 !important;border-radius:5px!important" class="form-select txt-res">
                                                  <select class="focus:!outline-none" style="border:none !important" name="selectMethod" id="selectMethod"
                                                          [(ngModel)]="item.selectedPaymentMethod" disabled>
                                                    <option [value]="method.name" *ngFor="let method of item?.paymentMethods">
                                                      {{ method.name }}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            
                                              <div class="d-flex flex-column justify-between mb-3 w-32">
                                                <label *ngIf="item?.Amount || item?.Amount ==''" for="amountInput" class="lebel_res mb-1" [ngStyle]="{
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size + 'px',
                                                    'color': item?.color || '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin': '0px 0px 4px 0px !important',
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align': item?.align
                                                }">{{ item?.Amount }}</label>
                                                
                                                <div [ngStyle]="{
                                                    'text-align': item?.align
                                                }" style="border:1px solid #cbdbe4 !important;border-radius:none !important" class="form-control">
                                                  <input class="focus:!outline-none w-full" style="border:none!important" type="text" id="amountInput" name="amount"
                                                         [(ngModel)]="item.enteredAmount" placeholder="{{ item?.placeholder }}" readonly>
                                                </div>
                                              </div>
                                            
                                              <div class="d-flex flex-column justify-between mb-3 w-36">
                                                <label *ngIf="item?.Percentage || item?.Percentage ==''" for="percentageInput" class="lebel_res mb-1 w-[104px]" [ngStyle]="{
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size + 'px',
                                                    'color': item?.color || '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin': '0px 0px 4px 0px !important',
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align': item?.align
                                                }">{{ item?.Percentage }}</label>
                                            
                                                <div class="d-flex items-center gap-1">
                                                  <div class="relative">
                                                    <div [ngStyle]="{
                                                        'text-align': item?.align
                                                    }" style="border:1px solid #cbdbe4 !important;border-radius:none !important" class="form-control w-36 min-w-[100px]">
                                                      <input class="focus:!outline-none w-full" style="border:none!important" min="1" max="100" type="text" id="percentageInput" name="percentage"
                                                             [(ngModel)]="item.enteredPercentage" placeholder="{{ item?.placeholder }}" readonly>
                                                    </div>
                                                    <span matSuffix class="h-[12px] text-[#364F6B] font-semibold flex absolute right-2 top-[9px]">%</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            
          
                                              <div *ngIf="item?.name==='Signature'" class="d-flex flex-column" [ngStyle]="{
                                                  'align-items': item?.align,
                                                  'margin-bottom': item?.mrbottum + 'px'
                                                }">
                                                  <ng-container class="WidthMarginBottom">
                                                    <div class="properties-heading-size properties-ele my-3">
                                                      <div style="margin: 0 !important;">
                                                        <label for="textbox" class="lebel_res" [ngStyle]="{
                                                          'font-weight': '500',
                                                          'width': '80%',
                                                          'font-family': selectedFont || 'Arial, sans-serif',
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic,
                                                          'text-align': item?.align,
                                                          'margin': '0 !important',
                                                          'width': '100%'
                                                        }">{{ item?.label }}</label>
                                                       
                                                        <div class="ele">
                                                          <app-signature-pad-create [Border_Color]="item?.Bdr_Dvdr" (signatureSaved)="handleSignatureSaved($event)"></app-signature-pad-create>
                                                        </div>
                                                        <!-- Display the captured signature image -->
                                                        <img *ngIf="savedSignatureDataUrl" [src]="savedSignatureDataUrl" alt="Captured Signature">
                                                      </div>
                                                    </div>
                                                    <p class="m-0 label_res2" [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'margin-bottom': item?.mrbottum + 'px',
                                                      'font-size': item?.help_font_size + 'px',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'margin': '0 !important',
                                                      'color': item?.H_Txt_Notes ? item?.H_Txt_Notes : '#000000',
                                                      'margin-top': item?.mrtop + 'px',
                                                      'margin-right': item?.mrright + 'px',
                                                      'margin-left': item?.mrleft + 'px',
                                                      'text-decoration': item?.textDecoration,
                                                      'font-style': item?.font_italic,
                                                      'text-align': item?.align,
                                                      'width': '100%'
                                                    }">{{ item?.help_txt }}</p>
                                                  </ng-container>
                                                </div>
                                                
                                                
                                                
          
          
          
                                                  <div *ngIf="item?.name === 'Total Due'" class="d-flex" [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.font_size + 'px',
                                                      'color': '#333',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'color': item?.color,
                                                      'text-decoration': item?.textDecoration,
                                                   
                                                      'font-style': item?.font_italic
                                                     
                                                    }">
                                                      <p class="txt-res"  style="margin: 0 !important " [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic,
                                                          'margin':' 0 !important',
                                                          'text-align':item?.align,
                                                      'width':'100%'
                                                        }">
                                                      {{ item?.user_currency? item?.currency_symbol:crSymbol }}
                                                      <span [ngStyle]="{
                                                          'justify-content': item?.align
                                                         
                                                        
                                                        }" style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item.value }}</span>
                                                      {{item?.user_currency?item?.currency:selectedCurrency?.currency_code}}
                                                      
                                                      </p>
                                                    </div>
          
                                                    <div *ngIf="item?.name === 'Currency'" class="d-flex" [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'margin-bottom': item?.mrbottum + 'px',
                                                      'font-size': item?.font_size + 'px',
                                                      'color': '#333',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'padding-bottom': item?.padding + 'px',
                                                      'padding-top': item?.paddingT + 'px',
                                                      'padding-right': item?.paddingR + 'px',
                                                      'padding-left': item?.paddingL + 'px',
                                                      'margin-top': item?.mrtop + 'px',
                                                      'margin-right': item?.mrright + 'px',
                                                      'margin-left': item?.mrleft + 'px',
                                                      'text-decoration': item?.textDecoration,
                                                  
                                                      'font-style': item?.font_italic
                                                    }">
                                                      <p class="txt-res" [ngStyle]="{
                                                        'font-size': item?.font_size + 'px',
                                                        'color': '#333',
                                                        'margin-bottom': item?.mrbottum + 'px',
                                                        'font-family': item?.fontFamily,
                                                        'font-weight': item?.font_Weight,
                                                        'padding-bottom': item?.padding + 'px',
                                                        'padding-top': item?.paddingT + 'px',
                                                        'padding-right': item?.paddingR + 'px',
                                                        'padding-left': item?.paddingL + 'px',
                                                        'margin-top': item?.mrtop + 'px',
                                                        'margin-right': item?.mrright + 'px',
                                                        'margin-left': item?.mrleft + 'px',
                                                        'text-decoration': item?.textDecoration,
                                                        'font-style': item?.font_italic,
                                                        'text-align':item?.align,
                                                        'margin':' 0 !important',
                                                        'width':'100%'
                                                      }">
                                                      {{ item?.user_currency?item?.currency_symbol:crSymbol }}
                                                      <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item.value }}</span>
                                                      {{item?.user_currency?item?.currency:selectedCurrency?.currency_code}}
                                                      
                                                      </p>
                                                    </div>
                                     
                                               <div *ngIf="item?.name==='Date'"   class="d-flex align-items-center" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                  'font-family':item?.fontFamily,
                                                  'font-weight':item?.font_Weight,
                                                  'text-decoration':item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  
                                                  'width' : item?.name == 'Date'? '100%':'fit-content',
                                                  'font-style':item?.font_italic
                                              }">
                                                  <h1 class="txt-res " style= "margin: 0 !important;" [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'color': item?.title_color,
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic,
                                                      'line-height': '16px',
                                                      'text-align':item?.align,
                                                     
                                                      'font-size':item?.font_size+'px',
                                                      'font-size': item?.title_font_size + 'px'
                                              }">{{item?.value}}</h1>  
                                              
                                                  <div *ngIf="item?.dateTitle !=='Quote Creation Date'  && item?.dateTitle !=='Forecast Date'" class="d-flex gap-2 pe-2 py-1 pointer align-items-center ms-1" style=" border: 1px solid #CBDBE4;border-radius: 5px;" (click)="openDatePicker()" (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)">
                                                      <input matInput (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)" style="width: 0px; border: none;"  id="{{item?.id}}" [ngModel]="dateValue"
                                                      />
                                                      <div  style="min-width:70px;"  id="{{item?.id}}">{{item?.itemDate? item?.itemDate: ''}}</div>
                                                      <!-- <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                                                      <mat-datepicker #picker ></mat-datepicker> -->
                                                  </div>
                                                  <div *ngIf="item?.dateTitle =='Quote Creation Date'" class="d-flex gap-2 ps-2 pointer align-items-center ms-1"  >
                                                      <div  id="{{item?.id}}">{{item?.quoteCreationDate?item?.quoteCreationDate:getCurrentFormattedDate(item?.name,item?.id,z)}}</div>
                                                  </div>
                                                  <!-- <p>{{item?.dateTitle}}</p> -->
          
                                                  <!-- <div *ngIf="item?.dateTitle =='invoice date'" class="d-flex gap-2 ps-2 pointer align-items-center ms-1"  >
                                                      <div  id="{{item?.id}}">{{getCurrentFormattedDate(item?.name,item?.id,z)}}</div>
                                                  </div> -->
          
                                                  <div *ngIf="item?.dateTitle =='Forecast Date'" class="d-flex gap-2 ps-2 pointer align-items-center ms-1"  >
                                                      <div  id="{{item?.id}}">{{item?.dateTitleForecast?commonFormat(item?.dateTitleForecast):''}}</div>
                                                  </div>
                                           
                                                  
                                              </div>
          
                                              <div *ngIf="item?.name==='Text'" class="d-flex"  >
                                                  <p style="margin: 0 !important;"  class="txt-res" [ngStyle]="{
                                                      'font-size':item?.font_size+'px',
                                                      'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                  'font-weight':item?.font_Weight,
                                                  'margin':' 0 !important',
                                                  'color':item?.color,
                                                  'text-decoration':item?.textDecoration,
                                                  'font-style':item?.font_italic,
                                                  'text-align':item?.align,
                                                      'width':'100%'
                                              }">{{item?.value}} </p>
                                              </div>
                                              <div *ngIf="item?.name === 'Organization N.'" class="d-flex" [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size':item?.font_size+'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'text-decoration': item?.textDecoration,
                                                 
                                                  'font-style': item?.font_italic
                                                }">
                                                  <p class="txt-res"  style="margin: 0 !important " [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.title_font_size + 'px',
                                                      'color': item?.title_color,
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'text-decoration': item?.textDecoration,
                                                      'font-style': item?.font_italic,
                                                      'text-align':item?.align
                                                      
                                                    }">
                                                    {{ item?.value }}&nbsp;</p>
                                                    <span [ngStyle]="{
                                                      'justify-content': item?.align,
                                                   
                                                      'text-align':item?.align,
                                                      'color': item?.color
                                                      
                                                    }"  >{{ item?.tag }}</span>
                                                    
                                                </div>
                                                
                                                <div *ngIf="item?.name === 'Item code'" class="d-flex" [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'margin-bottom': item?.mrbottum + 'px',
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'margin-top': item?.mrtop + 'px',
                                                  'margin-right': item?.mrright + 'px',
                                                  'margin-left': item?.mrleft + 'px',
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                }">
                                                  <p class="txt-res" [ngStyle]="{
                                                  'font-size': item?.title_font_size + 'px',
                                                    'color': '#333',
                                                    'margin-bottom': item?.mrbottum + 'px',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'padding-bottom': item?.padding + 'px',
                                                    'padding-top': item?.paddingT + 'px',
                                                    'padding-right': item?.paddingR + 'px',
                                                    'padding-left': item?.paddingL + 'px',
                                                    'margin-top': item?.mrtop + 'px',
                                                    'margin-right': item?.mrright + 'px',
                                                    'margin-left': item?.mrleft + 'px',
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align':item?.align,
                                                    'margin':' 0 !important',
                                                      'width':'100%'
                                                  }">
                                                    {{ item?.value }}</p>
                                                    <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                    
                                                </div>
          
                                                <!-- <div *ngIf="item?.name === 'Number List'" class="d-flex" [ngStyle]="{
                                                  'justify-content': item?.align
                                                }">
                                                  <ol >
                                                    <li *ngFor="let listItem of numberList; let i = index">
                                                      <input type="text" [(ngModel)]="numberList[i]" class="borderless-input" [ngStyle]="{
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'margin-bottom': item?.mrbottum + 'px',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'padding-bottom': item?.padding + 'px',
                                                          'padding-top': item?.paddingT + 'px',
                                                          'padding-right': item?.paddingR + 'px',
                                                          'padding-left': item?.paddingL + 'px',
                                                          'margin-top': item?.mrtop + 'px',
                                                          'margin-right': item?.mrright + 'px',
                                                          'margin-left': item?.mrleft + 'px',
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic
                                                        }">
                                                    </li>
                                                  </ol>
                                                  
                                                </div> -->
          
          
                                                <div *ngIf="item?.name =='Number List'" class="d-flex" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                  'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'color':item?.color,
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                    
                                              }">
                                                      <p class="txt-res"   style="margin: 0 !important "   readonly  [ngStyle]="{
                                                          'font-size':item?.font_size+'px',
                                                          'font-family':item?.fontFamily,
                                                      'font-weight':'bolder',
                                                      'margin':' 0 !important',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic,
                                                      'text-align':item?.align,
                                                      'width':'100%',
                                                      ' list-style-position': 'inside'
          
                                                  }">{{ item?.label}}
                                                  <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                      <div *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue">
                                                          <ol style="margin: 0 !important " >
                                                            <li   style="margin: 0 !important "  *ngFor="let coun of item?.custom_value?.value; let i = index" [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'font-size': item?.font_size + 'px',
                                                                'color': '#333',
                                                                'font-family': item?.fontFamily,
                                                                'margin':' 0 !important',
                                                                'color': item?.color,
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align':item?.align,
                                                                    'width':'100%'
                                                            }">
                                                              <span style="font-weight: normal;" >{{ coun?.name }}</span>
                                                              <span style="color: #1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.value }}</span>
                                                            </li>
                                                          </ol>
                                                        </div>
                                                                                                          </ng-container>
                                                  <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span> -->
                                                  <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                                                  
                                       </p>
                                       <br>
                                       <!-- <div *ngIf="item?.custom_value?.value?.length">
                                          <span  [value]="coun?.name"
                                          *ngFor="let coun of item?.custom_value?.value"
                                          [selected]="coun?.selected">{{coun?.name}}</span>
                                      </div> -->
                                                  </div>
          
                                                
                                                  <div *ngIf="item?.name =='Order List'" class="d-flex" [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                         
                                                          'color':item?.color,
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                  }">
                                                          <p class="txt-res" style="margin: 0 !important "  readonly  [ngStyle]="{
                                                              'font-size':item?.font_size+'px',
                                                              'font-family':item?.fontFamily,
                                                          'font-weight':'bolder',
                                                          'margin':' 0 !important',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic,
                                                          'text-align':item?.align,
                                                      'width':'100%',
                                                     ' list-style-position': 'inside'
                                                      }">{{ item?.label}}
                                                      <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                          <div *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue">
                                                              <ul style="margin: 0 !important " 
                                                              [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'align-content':item?.align,
                                                                  'width':'100%'
                                                              }"
                                                              >
                                                                <li *ngFor="let coun of item?.custom_value?.value; let i = index" [ngStyle]="{
                                                                    'justify-content': item?.align,
                                                                    'font-size': item?.font_size + 'px',
                                                                    'color': '#333',
                                                                    'font-family': item?.fontFamily,
                                                                    'margin':' 0 !important',
                                                                    'color': item?.color,
                                                                    'text-decoration': item?.textDecoration,
                                                                    'font-style': item?.font_italic,
                                                                    'text-align':item?.align,
                                                                    'width':'100%'
                                                                }">
                                                                  <span style="font-weight: normal;">{{ coun?.name }}</span>
                                                                  <span style="color: #1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.value }}</span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                                                                              </ng-container>
                                                      <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span> -->
                                                      <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                                                      
                                           </p>
                                           <br>
                                           <!-- <div *ngIf="item?.custom_value?.value?.length">
                                              <span  [value]="coun?.name"
                                              *ngFor="let coun of item?.custom_value?.value"
                                              [selected]="coun?.selected">{{coun?.name}}</span>
                                          </div> -->
                                                      </div>
                                                    
                                                                                            
                                              <div *ngIf="item.name=='Table'">
                                                  <div style="width: 100%; overflow-x: auto;">
                                                  <table [ngStyle]="{
                                                      'border': '1px solid ' + item?.borderColor,
                                                      'border-collapse': 'collapse !important',
                                                      'justify-content': item?.align
                                                    }">
                                                      <tr >
                                                        <th [ngStyle]="{
                                                          'border': '1px solid ' + item?.borderColor,
                                                          'border-collapse': 'collapse !important'
                                                        }" *ngFor="let headerIndex of item?.tableDisplayedColumns; let i = index">
                                                          <input [ngStyle]="{
                                                          'align-items':item?.align,
                                                          'border':'none',
                                                          'margin-bottom':'0px',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'font-size': item?.font_size + 'px',
                                                          'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic,
                                                      'margin':' 0 !important',
                                                          'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                          'background-color':item?.backGroundColor,
                                                         
                                                          'justify-content': item?.align,
                                                          'text-align':item?.text_align,
                                                          'width':'100%'       
                                                           }" style="text-align: center;  font-weight: bold; width: 100% !important;" type="text" [(ngModel)]="headerData[i]" (change)="logTableData(item?.name,item?.id,'header')"
                                                            [(ngModel)]="headerIndex.name"  class="no-border"/>    
                                                 
                                                  </th>
                                                      </tr>
                                                      
                                                    <tr *ngFor="let row of item?.tableDisplayedRows; let rowIndex = index">
                                                 <td [ngStyle]="{
                                              'border': '1px solid ' + item?.borderColor,
                                              'border-collapse': 'collapse !important'
                                            }" *ngFor="let column of item?.tableDisplayedColumns; let colIndex = index">
                                              <input type="text" class="no-border"
                                                     [ngStyle]="{
                                                      'border':'none',
                                                      'margin-bottom':'0px',
                                                      'width':'185px',
                                                      'justify-content': item?.align,
                                                     
                                                      'width':'100%'
                                                     }"
                                                [(ngModel)]="item?.tableDisplayedRows[rowIndex][column?.name]"  (change)="logTableData(item?.name,item?.id,'rows')" />
                                                    </td>
                                                 </tr>
                                                    </table>
                                                    </div>
                                                    
                                                </div>
          
          
          
          
          
          
                                                <div *ngIf="item.name === 'Item Table'" class="d-flex">
                                                  <div style="margin-top: 65px;">
                                                     
                                                  </div>

                                                  <div id="tableDiv" style="width: 100%; overflow-x: auto;"
                                                  >
                                                  <div class="table-outer-border"  *ngFor="let table of item?.table ; let i = index">
                                                    <table class="mt-3" id="tableDiv" [ngClass]="item?.class_name ? item?.class_name  : tableStyleClass + ' ' + tableColorClass"
                                                    mat-table [dataSource]="getTableDataSource(i)" multiTemplateDataRows cdkDropList
                                                        [ngStyle]="{
                                                            'justify-content': item?.align
                                                           
                                    
                                                          }"
                                                        (cdkDropListDropped)="dropItem($event,i)"
                                                        >
                                                        <ng-container matColumnDef="{{column.name}}" *ngFor="let column of getFilteredColumns(columnsToDisplayWithExpand, 'column')"
                                    
                                                        >
                                                        <ng-container *ngIf="column.name == 'expand'"
                                                        >
                                                            <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width:0px;" class="th_bg border-start-0 border-bottom-0 rounded-start"
                                                            [ngStyle]="{
                                                                'font-size': item?.font_size + 'px',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'justify-content': item?.align,
                                                                'text-align':item?.text_align,
                                                                'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                    
                                                              }">&nbsp;</th>
                                                            <td mat-cell *matCellDef="let element" style="width:0px;" class="border_table border-end-0 rounded-start">
                                                                <span id="expand" style="position: relative;" role="button" aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                                                                  <span style="position: absolute;left: -2.3rem;top: -8px;">
                                                                    <svg width="22" height="16" viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{'fill':expandedElement === element?'#03C0FC':'grey'}">
                                                                    <rect x="0.970703" y="0.199219" width="20.0696" height="1.64544" rx="0.82272" />
                                                                    <rect x="0.970703" y="6.78125" width="20.0696" height="1.64544" rx="0.82272" />
                                                                    <rect x="0.970703" y="13.3633" width="20.0696" height="1.64544" rx="0.82272" />
                                                                    </svg>
                                                                  </span>
                                                               </span>
                                                               <span id="merge" class="removeInView" style="position: relative;" role="button" *ngIf="shouldDisplayMergeIcon(element)">
                                                                <span class="merge-icon" style="position: absolute;left: -2.3rem;top: -40px;">
                                                                   
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9.25713 0.322614C9.45687 0.124256 9.73529 0 10.042 0C10.6553 0 11.1516 0.492939 11.1516 1.1021V3.89141L12.6587 2.39456C13.0925 1.96374 13.7946 1.96374 14.2284 2.39456C14.6622 2.82538 14.6622 3.52271 14.2284 3.95353L10.8268 7.33196L10.8247 7.33398C10.6956 7.46221 10.5342 7.55841 10.3587 7.60851C10.3587 7.60851 10.3587 7.60851 10.3567 7.60851C10.1388 7.67261 9.90271 7.6666 9.68884 7.59646C9.52744 7.54236 9.37814 7.45018 9.25709 7.33196L5.85549 3.94952C5.42172 3.5187 5.42172 2.82137 5.85549 2.39055C6.28927 1.95973 6.99138 1.95973 7.42515 2.39055L8.93226 3.8874V1.10007C8.93232 0.797518 9.05741 0.520991 9.25713 0.322614Z" fill="#B6C9D5"/>
                                                                        <path d="M9.25713 19.678C9.45687 19.8763 9.73529 20.0006 10.042 20.0006C10.6553 20.0006 11.1516 19.5077 11.1516 18.8985V16.1112L12.6587 17.608C13.0925 18.0389 13.7946 18.0389 14.2284 17.608C14.6622 17.1772 14.6622 16.4799 14.2284 16.0491L10.8268 12.6727L10.8247 12.6706C10.6956 12.5424 10.5342 12.4462 10.3587 12.3961C10.3587 12.3961 10.3587 12.3961 10.3567 12.3961C10.1388 12.332 9.90271 12.338 9.68884 12.4081C9.52744 12.4622 9.37814 12.5544 9.25709 12.6727L5.85549 16.0511C5.42172 16.4819 5.42172 17.1792 5.85549 17.6101C6.28927 18.0409 6.99138 18.0409 7.42515 17.6101L8.93226 16.1112V18.9005C8.93232 19.2031 9.05741 19.4796 9.25713 19.678Z" fill="#B6C9D5"/>
                                                                        <path d="M18.5897 8.59896L1.41229 8.59896C0.631494 8.59896 0 9.22615 0 10.0016C0 10.7771 0.631494 11.4043 1.41229 11.4043L18.5877 11.4043C19.3685 11.4043 20 10.7771 20 10.0016C20 9.22615 19.3685 8.59896 18.5897 8.59896Z" fill="#B6C9D5"/>
                                                                    </svg>
                                                                        
                                                                </span>
                                                               
                                                              </span>
                                    
                                                            
                                                            </td>
                                                          </ng-container>
                                                          <ng-container *ngIf="column.key == 'Description'">
                                                            <th mat-header-cell *matHeaderCellDef class="th_bg border-bottom"
                                                            [ngStyle]="{
                                                                'font-size': item?.font_size + 'px',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'justify-content': item?.align,
                                                                'text-align':item?.text_align,
                                                                'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                    
                                                              }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                              <div [ngStyle]="{
                                                                'font-size': item?.font_size + 'px',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'justify-content': item?.align,
                                                                'text-align':item?.text_align,
                                                                'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass
                                    
                                                              }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                               {{getTranslatedText ("QUOTE_TEMPLATE." + column?.name ) || column?.name | uppercase}}
                                                              </div>
                                                            </th>
                                                            <td class="border_table border-start-0"  [ngStyle]="{
                                                              'font-size': item?.font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'justify-content': item?.align,
                                                              'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto')
                                    
                                                            }" mat-cell *matCellDef="let element">
                                                           
                                                           <div id="description" *ngIf=" column.key =='Description'" class="px-2">
                                                            {{element[column.name]}}
                                                        </div>
                                                        
                                                             
                                                            </td>
                                                            </ng-container>
                                                            <ng-container *ngIf="column.key == 'Comments'">
                                                                <th mat-header-cell *matHeaderCellDef class="th_bg border-bottom"
                                                                [ngStyle]="{
                                                                    'font-size': item?.font_size + 'px',
                                                                    'font-family': item?.fontFamily,
                                                                    'font-weight': item?.font_Weight,
                                                                    'justify-content': item?.align,
                                                                    'text-align':item?.text_align,
                                                                    'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                        
                                                                  }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                                  <div [ngStyle]="{
                                                                    'font-size': item?.font_size + 'px',
                                                                    'font-family': item?.fontFamily,
                                                                    'font-weight': item?.font_Weight,
                                                                    'justify-content': item?.align,
                                                                    'text-align':item?.text_align,
                                                                    'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass
                                        
                                                                  }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                                   {{getTranslatedText ("QUOTE_TEMPLATE." + column?.name ) || column?.name | uppercase}}
                                                                  </div>
                                                                </th>
                                                                <td class="border_table border-start-0"  [ngStyle]="{
                                                                  'font-size': item?.font_size + 'px',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'justify-content': item?.align,
                                                                  'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto'),
                                                                  'max-width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto')
                                    
                                        
                                                                }" mat-cell *matCellDef="let element">
                                                               
                                                            <div id="description" *ngIf=" column.key =='Comments'" class="px-2">
                                                             <div>              
                                                  
                                                                <div class="col-12 " id="comment">
                                                                  <mat-form-field class="example-chip-list " appearance="outline">
                                                                    <mat-chip-list #chipList aria-label="Fruit selection">
                                                                      <mat-chip *ngFor="let comment of element[column.name] ;let i = index" (removed)="removeComment(element,$event,'Comments',i,z,item,item?.name,item?.id,comment)">
                                                                        {{comment.title}}
                                                                        <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                                                                      </mat-chip>
                                                                      <input placeholder="Type to add a comment..." style="min-width: 555px;" [matChipInputFor]="chipList"
                                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"  [matChipInputAddOnBlur]="addOnBlur"
                                                                        (matChipInputTokenEnd)="addComment($event,element,'Comments')" >
                                                                    </mat-chip-list>
                                                                  </mat-form-field>
                                                                </div>
                                                              </div>                                
                                                                       
                                                            </div>
                                                                 
                                                                </td>
                                                                </ng-container>
                                    
                                                        <ng-container *ngIf="column.name !=='expand' && column.key !=='Description' && column.key !=='Comments'">
                                                            <th mat-header-cell *matHeaderCellDef class="th_bg border-bottom"
                                                            [ngStyle]="{
                                                                'font-size': item?.font_size + 'px',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'justify-content': item?.align,
                                                                'text-align':item?.text_align,
                                                                'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto'),
                                                                'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                                              }">
                                                              <div [ngStyle]="{
                                                                'font-size': item?.font_size + 'px',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'justify-content': item?.align,
                                                                'text-align':item?.text_align,
                                                                'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass
                                    
                                                              }" style="text-align: center; font-weight: bold;" class="th_width">
                                                                {{getTranslatedText ("QUOTE_TEMPLATE." + column?.name ) || column?.name | uppercase}}
                                                              </div>
                                                            </th>
                                                            <td class="border_table border-start-0"  [ngStyle]="{
                                                              'font-size': item?.font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'justify-content': item?.align,
                                                              'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto')
                                    
                                                            }" mat-cell *matCellDef="let element" >
                                    
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key !== 'Quantity' && column.key !=='Item_image' && column.key !== 'QTY'  && column.key !=='Item image' && column.key !=='Total' && column.key !=='Price' && column.key !=='Video URL' && column.key !=='Website URL'"
                                                            (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                            {{ element[column.name] }}  
                                                           
                                                            </div>
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key =='Price'"
                                                            (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                            {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ element[column.name]  | number:'1.2-2'}} 
                                                            </div>
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key === 'Video URL'" (click)="openLink(element[column.key])">
                                                                <i class="far fa-play-circle fs-4" style="cursor: pointer;"></i>
                                                            </div>
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key === 'Website URL'" (click)="openLink(element[column.key])">
                                                                <i class="fas fa-globe fs-4" style="cursor: pointer;"></i>
                                                            </div>
                                                            
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf=" column.key =='Total'"
                                                            (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                            <!-- | number:'1.2-2' -->
                                                            <!-- {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ element[column.name] | number:'1.2-2'}} -->
                                                            {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ element['priceAfterDiscount'] | number:'1.2-2'}}
                                                            </div>
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf=" column.key =='Item_image'  || column.key =='Item image'"
                                                            (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                                <img style="width:55px;height: 55px;border-radius: 50%;object-fit:cover" [src]="element[column.name]" alt="" />
                                                               
                                                            </div>
                                                            <div class="d-flex gap-2 align-item-start justify-content-center" style="color: #000000;" *ngIf="column.key == 'Quantity'  || column.key == 'QTY'">
                                                             <span *ngIf="Invoice_ids ==''" id="decrement" (click)="decrease(element[column.name],column.key,i,element,rowIndex)" class="d-flex pointer justify-content-center align-items-center removeInView" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
                                                               -                              
                                                             </span>
                                                             {{ element[column.name] }}
                                                             <span *ngIf="Invoice_ids ==''" id="increment" (click)="increase(element[column.name],column.key,i,element,rowIndex)" class="d-flex pointer justify-content-center align-items-center removeInView" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
                                                                +                             
                                                             </span>
                                                            </div>
                                                            <span id="rowDelete" class="d-flex w-100 justify-content-end removeInView" *ngIf="column.name === columnsToDisplayWithExpand[columnsToDisplayWithExpand.length - 1].name" style="position: relative;" role="button" aria-label="delete row">
                                                                <span *ngIf="Invoice_ids ==''" style="position: absolute;right: -16%;top: -30px;"  (click)="deleteElement(element, $event,i,z)">
                                                          
                                                                  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <circle cx="17.5" cy="17.5" r="17.5" fill="#ECF7FF"/>
                                                                      <path d="M19.6774 16.7494L26.2204 10.2061C26.4004 10.026 26.4997 9.78571 26.5 9.52947C26.5 9.27309 26.4007 9.0325 26.2204 8.85266L25.6471 8.27943C25.4668 8.09888 25.2265 8 24.97 8C24.7139 8 24.4736 8.09888 24.2933 8.27943L17.7503 14.8223L11.207 8.27943C11.027 8.09888 10.7866 8 10.5302 8C10.2741 8 10.0336 8.09888 9.85366 8.27943L9.28 8.85266C8.90667 9.226 8.90667 9.83323 9.28 10.2061L15.8232 16.7494L9.28 23.2924C9.09988 23.4728 9.00071 23.7132 9.00071 23.9694C9.00071 24.2256 9.09988 24.4659 9.28 24.6462L9.85352 25.2194C10.0335 25.3998 10.2741 25.4989 10.53 25.4989C10.7864 25.4989 11.0269 25.3998 11.2068 25.2194L17.7501 18.6764L24.2931 25.2194C24.4734 25.3998 24.7137 25.4989 24.9698 25.4989H24.9701C25.2263 25.4989 25.4666 25.3998 25.6469 25.2194L26.2203 24.6462C26.4003 24.4661 26.4996 24.2256 26.4996 23.9694C26.4996 23.7132 26.4003 23.4728 26.2203 23.2926L19.6774 16.7494Z" fill="#03C0FC"/>
                                                                      </svg>                                    
                                                                </span>
                                                             </span>
                                                            
                                                             
                                                            </td>
                                                            </ng-container>
                                                                                  <!-- <ng-container  
                                                          >
                                                            <th id="delete" mat-header-cell *matHeaderCellDef aria-label="row actions" class="border-bottom-0 border-end-0 " style="display:none;">&nbsp;</th>
                                                            <td id="delete" mat-cell *matCellDef="let element" style="width:0px;" class="border-0">
                                    
                                                            
                                                            </td>
                                                          </ng-container> -->
                                                          </ng-container>
                                                                                                      
                                    
                                                          <ng-container matColumnDef="expandedDetail">
                                                            <td mat-cell *matCellDef="let element" style="padding: 0px 0px 0px 0px !important;" [attr.colspan]="columnsToDisplay?.length">
                                                              <div >
                                                                <div *ngFor="let column of item?.displayedColumns11">
                                                                <div  *ngIf="column.orientation == 'row'">
                                                                    <div >
                                                                  <div [ngStyle]="{
                                                                    'font-size':item?.font_size+'px',
                                                                'font-family':item?.fontFamily,
                                                                'font-weight':item?.font_Weight,
                                                                  'width':'100%',
                                                                'padding': '10px 10px 10px 10px'
                                                                
                                                            }" *ngIf="column.orientation == 'row' && column.key !== 'Video URL' && column.key !== 'Website URL' && column.key !=='Description'&&column.key !=='Comments'">
                                                            <p>{{element[column.name]|json}}</p>
                                                                    {{element[column.name]}}
                                                                    
                                                                  </div>
                                                                  <div [ngStyle]="{
                                                                    'font-size':item?.font_size+'px',
                                                                'font-family':item?.fontFamily,
                                                                'font-weight':item?.font_Weight,
                                                                  'width':'100%',
                                                                'padding': '10px 10px 10px 10px'
                                                                
                                                            }" id="description" *ngIf="column.orientation == 'row' && column.key =='Description'" class="px-2" style="border: 1px solid #E3ECF1; border-bottom: none;">
                                                                    
                                                            {{element[column.name]}}
                                                                        
                                                                           
                                                                </div>
                                                                <div [ngStyle]="{
                                                                    'font-size':item?.font_size+'px',
                                                                'font-family':item?.fontFamily,
                                                                'font-weight':item?.font_Weight,
                                                                  'width':'100%',
                                                                'padding': '10px 10px 10px 10px'
                                                                
                                                            }" id="description" *ngIf="column.orientation == 'row' && column.key =='Comments'" class="px-2" style="border: 1px solid #E3ECF1; border-bottom: none;">
                                    
                                                            <div >              
                                                  
                                                                <div class="col-12 " id="comment">
                                    
                                                                    <div class="d-flex text-8 fw-500 align-items-center justify-center gap-3 mt-4">
                                    
                                                                        <mat-form-field class="example-chip-list" appearance="fill">
                                                                            <mat-label>{{ "QUOTE_TEMPLATE.search_comments" |translate}}</mat-label>
                                                                            <mat-chip-list #chipList aria-label="Fruit selection">
                                                                                <ng-container  *ngFor="let fruit of element?.fruits">
                                                                                    <ng-container *ngIf="fruit?.id ==element?.id">
                                                                              <mat-chip style="word-break: break-word !important;" (removed)="removeComment(element, $event, 'Comments', i, z, item, item?.name, item?.id, fruit)">
                                                                                <ng-container >
                                                                                    {{ fruit.title }}
                                                                                    <button matChipRemove>
                                                                                      <mat-icon>cancel</mat-icon>
                                                                                    </button>
                                                                                </ng-container>
                                                                              </mat-chip>
                                                                            </ng-container>
                                                                            </ng-container>
                                                                              <!-- <input
                                                                                (input)="itemInputChange($event, element,i)"
                                                                                placeholder="{{ 'QUOTE_TEMPLATE.add_comments' | translate }}"
                                                                                #fruitInput
                                                                                [formControl]="searchItem"
                                                                                [matAutocomplete]="auto"
                                                                                [matChipInputFor]="chipList"
                                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                                (matChipInputTokenEnd)="add($event, element,i)"
                                                                                (focus)="resetFilteredOptions(element)"
                                                                              /> -->
                                                                            </mat-chip-list>
                                                                            <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, element,i)">
                                                                              <mat-option *ngFor="let option of filteredOptions" [value]="option.title">
                                                                                {{ option.title }}
                                                                              </mat-option>
                                                                            </mat-autocomplete> -->
                                                                          </mat-form-field>
                                                                          
                                                                      </div>
                                                               
                                                                </div>
                                                              </div>
                                                                        
                                                                           
                                                                </div>
                                                                  <div class="d-flex gap-2 align-item-start " style="padding: 0px 0px 0px 15px;" *ngIf="column.key === 'Video URL'" (click)="openLink(element[column.key])">
                                                                    <i class="far fa-play-circle fs-4" style="cursor: pointer;"></i>
                                                                </div>
                                                                <div class="d-flex gap-2 align-item-start " style="padding: 0px 0px 0px 15px;" *ngIf="column.key === 'Website URL'" (click)="openLink(element[column.key])">
                                                                    <i class="fas fa-globe fs-4" style="cursor: pointer;"></i>
                                                                </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                              </div>
                                                            </td>
                                                          </ng-container>
                                                                                                                                                                                                                                                                                                                                                          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                                        <tr cdkDrag mat-row *matRowDef="let element; let rowIndex = index; columns: columnsToDisplay;" class="example-element-row cursor-move"
                                                            [class.example-expanded-row]="expandedElement === element"
                                                            (click)="decrease('','','','',rowIndex)">
                                                        </tr>
                                                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                                                               
                                                    </table>
                                                      <div class="d-flex flex-column justify-content-start " style="align-items: end;" *ngIf="item?.isTotalShow">

                 
              
                                                        <div *ngIf="item?.sub_total =='Subtotal' && item?.name =='Item Table'" class="d-flex" [ngStyle]="{
                                                            'justify-content':item?.align,
                                                            
                                                            'font-size':item?.font_size+'px',
                                                                'font-family':item?.fontFamily,
                                                                'font-weight':item?.font_Weight,
                                                               
                                                                'padding-top':item?.paddingT+'px',
                                                                'padding-right':item?.paddingR+'px',
                                                                'padding-left':item?.paddingL+'px',
                                                               
                                                               
                                                                'text-decoration':item?.textDecoration,
                                                                'font-style':item?.font_italic,
                                                                'min-width':'225px'
                                                        }">
                                                                <p readonly class="d-flex justify-content-between w-100"  [ngStyle]="{
                                                                    'font-size':item?.font_size+'px',
                                    
                                                                    'font-family':item?.fontFamily,
                                                                'font-weight':item?.font_Weight,
                                                             
                                                                'margin':' 0 !important',
                                                                'text-decoration':item?.textDecoration,
                                                                'font-style':item?.font_italic
                                                            }"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.sub_total) || item?.sub_total}}</span>
                                                            <!-- <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ item?.subTotalAmount?item?.subTotalAmount:getSubtotal(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span> -->
                                                            <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ getSubtotal(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                            <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                    <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>     -->
                                                                                                                            </p>
                                                            </div>
                                    
                                                            <div *ngIf="item?.discount =='Discount' && item?.name =='Item Table'" class="d-flex" [ngStyle]="{
                                                                'justify-content':item?.align,
                                                                
                                                                'font-size':item?.font_size+'px',
                                                                    'font-family':item?.fontFamily,
                                                                    'font-weight':item?.font_Weight,
                                                                   
                                                                    'padding-right':item?.paddingR+'px',
                                                                    'padding-left':item?.paddingL+'px',
                                                                    
                                                                    'text-decoration':item?.textDecoration,
                                                                    'font-style':item?.font_italic,
                                                                    'min-width':'225px'
                                                            }">
                                                                    <p readonly class="txt-res d-flex justify-content-between w-100" [ngStyle]="{
                                                                        'font-size':item?.font_size+'px',
                                                                        'margin':' 0 !important',
                                                                        'font-family':item?.fontFamily,
                                                                    'font-weight':item?.font_Weight,
                                                                  
                                                                   
                                                                    'text-decoration':item?.textDecoration,
                                                                    'font-style':item?.font_italic
                                                                }"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.discount) || item?.discount}}</span>
                                                                <span style="color:#1b8ef8">{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ getDiscount(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                    <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>                                           -->
                                                                                      </p>
                                                                </div>
                                    
                                                                <div *ngIf="item?.subtotalAfter =='Subtotal after Discount' && item?.name =='Item Table'" class="d-flex" [ngStyle]="{
                                                                    'justify-content':item?.align,
                                                                    
                                                                    'font-size':item?.font_size+'px',
                                                                        'font-family':item?.fontFamily,
                                                                        'font-weight':item?.font_Weight,
                                                                       
                                                                        'padding-right':item?.paddingR+'px',
                                                                        'padding-left':item?.paddingL+'px',
                                                                        
                                                                        'text-decoration':item?.textDecoration,
                                                                        'font-style':item?.font_italic,
                                                                        'min-width':'225px'
                                                                }">
                                                                        <p readonly class="txt-res d-flex justify-content-between w-100" [ngStyle]="{
                                                                            'font-size':item?.font_size+'px',
                                                                            'margin':' 0 !important',
                                                                            'font-family':item?.fontFamily,
                                                                        'font-weight':item?.font_Weight,
                                                                      
                                                                       
                                                                        'text-decoration':item?.textDecoration,
                                                                        'font-style':item?.font_italic
                                                                    }"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.subtotalAfter) || item?.subtotalAfter}}</span>
                                                                    <span style="color:#1b8ef8">{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ getSubtotalAfter(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                    <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                    <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>                        -->
                                                                                                         </p>
                                                                    </div>
                                    
                                                               
                                    
                                                            <div *ngIf="item?.taxes =='Taxes' && item?.name === 'Item Table'" class="d-flex" [ngStyle]="{
                                                                'justify-content':item?.align,
                                                              
                                                                'font-size':item?.font_size+'px',
                                                                    'font-family':item?.fontFamily,
                                                                    'font-weight':item?.font_Weight,
                                                                    
                                                                    'padding-right':item?.paddingR+'px',
                                                                    'padding-left':item?.paddingL+'px',
                                                                    
                                                                    'text-decoration':item?.textDecoration,
                                                                    'font-style':item?.font_italic,
                                                                    'min-width':'225px'
                                                            }">
                                                                    <p readonly class="txt-res d-flex flex-column justify-content-between w-100" [ngStyle]="{
                                                                        'font-size':item?.font_size+'px',
                                                                       
                                                                        'font-family':item?.fontFamily,
                                                                    'font-weight':item?.font_Weight,
                                                                    'margin':' 0 !important',
                                                                    'text-decoration':item?.textDecoration,
                                                                    'font-style':item?.font_italic
                                                                }">
                                                                
                                                                  
                                                                  
                                                                  
                                                                <span *ngIf="table?.data?.length" class="fw-600" style="padding-right:23px;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.taxes) || item?.taxes}}</span>
                                                                <ng-container *ngIf="table?.data">
                                                                    <ng-container *ngIf="aggregateTaxes(table.data,item?.multiplyTax) | keyvalue as taxes">
                                                                      <span style="display: flex;flex-direction: column;color:#000000!important;" *ngFor="let tax of taxes">
                                                                        {{ tax?.key }} ({{ tax?.value?.value }}%) {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ tax?.value?.amount | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}
                                                                      </span>
                                                                    </ng-container>
                                                                  </ng-container>
                                                                <span style="color:#1b8ef8;display: flex;justify-content: space-between;" >
                                                                     <span style="color:#000000!important;">{{getTranslatedText ("QUOTE_TEMPLATE." + item?.taxes) || item?.taxes}}</span>
                                                                {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{getTotalTax(table.data,item?.multiplyTax)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                    <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>                                       -->
                                                                    </p>
                                                                </div>
                                                    
                                                                
                                                                    <div  class="d-flex txt-res" [ngStyle]="{
                                                                        'justify-content':item?.align,
                                                                        
                                                                        'font-size':item?.font_size+'px',
                                                                            'font-family':item?.fontFamily,
                                                                            'font-weight':item?.font_Weight,
                                                                           
                                                                            'padding-top':item?.paddingT+'px',
                                                                            'padding-right':item?.paddingR+'px',
                                                                            'padding-left':item?.paddingL+'px',
                                                                           
                                                                           
                                                                            'text-decoration':item?.textDecoration,
                                                                            'font-style':item?.font_italic,
                                                                            'min-width':'225px'
                                                                    }">
                                                                        <p readonly class="txt-res d-flex justify-content-between w-100"  [ngStyle]="{
                                                                            'font-size':item?.font_size+'px',
                                                                            
                                                                            'font-family':item?.fontFamily,
                                                                        'font-weight':item?.font_Weight,
                                                                     
                                                                        'margin':' 0 !important',
                                                                        'text-decoration':item?.textDecoration,
                                                                        'font-style':item?.font_italic
                                                                    }">
                                                                    <span [ngStyle]="{
                                                                        'justify-content': item?.align
                                                                        
                                                                      }" *ngIf="item?.name =='Item Table'"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.total) || item?.total}}&nbsp;</span></span>
                                                                    <span *ngIf="item.name == 'Item Table' && (item?.total =='Total')" [ngStyle]="{
                                                                        'justify-content': item?.align
                                                                       
                                                                      }" style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{item?.dueAmount  | number:'1.2-2' }} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                    <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                    <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>           -->
                                                                                                                      </p>
                                                                    </div>
                                                    
                                                    
                                    
                                                    
                                                    
                                                    </div>
                                                      
                                                  </div>
                                                    
                                                  </div>
                                              
                                                 
                                              </div>
          
          
                                              <div *ngIf="item.name === 'Installment'">
                                                  <div style="width: 100%; overflow-x: auto;">
                                                      <table mat-table  [dataSource]="dataSource" class="mat-elevation-z8 ui-table" style="border: none !important;"
                                                      [ngStyle]="{
                                                          'justify-content': item?.align
                                                         
          
                                                        }"
                                                      >
                                                          <ng-container matColumnDef="position">
                                                            <th mat-header-cell *matHeaderCellDef style="text-align: center;"
                                                            [ngStyle]="{
                                                                    'font-size': item?.font_size + 'px',
                                                                    'font-family': item?.fontFamily,
                                                                    'font-weight': item?.font_Weight,
                                                                    'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                                    'background-color':item?.backGroundColor,
                                                                    'justify-content': item?.align,
                                                                 
                                                                    'text-align':item?.text_align
                                                                  
                                                                 
                                                                     }" class="th_width" style="text-align: center; font-weight: bold;">
                                                            {{item?.displayedColumns12[0].name}}</th>
                                                            <td mat-cell *matCellDef="let element" style="text-align: center;"
                                  
                                                            > </td>
                                                          </ng-container>
                                                          <ng-container matColumnDef="name">
                                                            <th mat-header-cell *matHeaderCellDef style="text-align: center;"
                                                            [ngStyle]="{
                                                              'font-size': item?.font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                              'background-color':item?.backGroundColor,
                                                              'text-align':item?.text_align,
                                                              
                                                              'justify-content': item?.align
                                                                  
                                                                 
                                                               }" class="th_width" style="text-align: center;  font-weight: bold;">
                                                            {{item?.displayedColumns12[1].name}}</th>
                                                            <td mat-cell *matCellDef="let element" style="text-align: center;"> </td>
                                                          </ng-container>
                                                          <ng-container matColumnDef="weight">
                                                            <th mat-header-cell *matHeaderCellDef style="text-align: center;"
                                                            [ngStyle]="{
                                                              'font-size': item?.font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                              'background-color':item?.backGroundColor,
                                                              'justify-content': item?.align,
                                                          
                                                              'text-align':item?.text_align
                                                                  
                                                                
                                                               }" class="th_width" style="text-align: center;  font-weight: bold;">
                                                            {{item?.displayedColumns12[2].name}}</th>
                                                            <td mat-cell *matCellDef="let element" style="text-align: center;"> </td>
                                                          </ng-container>
                                                        
                                                         
                                                        
                                                          <tr mat-header-row *matHeaderRowDef="displayedTableColumns"></tr>
                                                          <tr mat-row *matRowDef="let row; columns: displayedTableColumns;"></tr>
                                                        </table>
                                                  </div>
                                              
                                                 
                                              </div>
                                                                                                                                                                                                                                                                                                                                    
                                                                                              
                                                <div *ngIf="item?.name=== 'Organization Logo' || item?.name=== 'Image' || item?.name=== 'Workspace Logo' " class="d-flex" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                      'font-size':item?.font_size+'px',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                     
                                                      'color':item?.color,
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic,
                                                      'justify-content':item?.align,
                                                    
                                                      'text-align':item?.align,
                                                      'width':'100%'
                                              }">
                                              <img *ngIf="item?.name == 'Image'" [src]="item?.imageLogo?item?.imageLogo:staticLogo" alt="logo"
                                              [ngStyle]="{'width': item?.width+'px', 'height': item.name === 'Image' ? item?.height+'%' : 'auto', 'border-radius': item.name === 'Image' ? '0%' : '50%'}" >
                                              <img *ngIf="item?.name == 'Organization Logo'"  [src]="item?.imageLogo?item?.imageLogo:staticLogo" alt="logo"
                                              [ngStyle]="{'width': item?.width+'%', 'height': item.name === 'Image' ? item?.height+'%' : 'auto', 'border-radius': item.name === 'Image' ? '0%' : '50%'}">
                                              <img *ngIf="item?.name == 'Workspace Logo'" matTooltipClass="custom-tooltip" [matTooltip]="'This Logo Will Dynamically change.'" [src]="item?.imageLogo?item?.imageLogo:staticLogo" alt="logo"
                                              [ngStyle]="{'width': item?.width+'%', 'height': item.name === 'Image' ? item?.height+'%' : 'auto', 'border-radius': item.name === 'Image' ? '0%' : '50%'}">
          
                                              
                                                  </div>                                           
                                               <div *ngIf="item?.name==='Divider'" class="d-flex" [ngStyle]="{
                                              'justify-content':item?.align,
                                              'font-size':item?.font_size+'px',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                          }">
                                                  <hr style="height: 2px; margin: 0 !important ;" 
                                                      [ngStyle]="{
                                                      'width':item?.width+'%',
                                                      'background-color':item?.color,
                                                      'opacity':'1.25',
                                                      'height':item?.height+'px'
                                                  }">
                                                  
                                              </div>
                                              
                                              <div *ngIf="item?.name==='Name' || item?.name === 'First Name' || item?.name === 'Last Name' || item?.name === 'Commercial Name'" class="d-flex" [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size':item?.font_size+'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'text-decoration': item?.textDecoration,
                                                 
                                                  'font-style': item?.font_italic
                                                }">
                                                      <p readonly class="txt-res" style="margin: 0 !important "   [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.title_font_size + 'px',
                                                          'color': item?.title_color,
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                         'margin':' 0 !important',
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic,
                                                          'text-align':item?.align
                                                        }">{{ item?.value }}&nbsp;</p>
                                                  <span [ngStyle]="{
                                                      'justify-content': item?.align,
                                                   
                                                      'text-align':item?.align,
                                                      'color': item?.color
                                                      
                                                    }"  >{{ item?.tag }}</span>
                                                  </div>
                                                  
                                                      <div *ngIf="item?.name === 'Quote ID'"  class="d-flex txt-res" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'font-size':item?.font_size+'px',
                                                          'color':item?.color,
                                                          'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                             
                                                              'color':item?.color,
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic
                                                      }">
                                                              <p readonly class="txt-res" style="margin: 0 !important" [ngStyle]="{
                                                                  'font-size':item?.font_size+'px',
                                                                  'color':item?.color,
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'text-align':item?.align,
                                                              'width':'100%'
                                                          }">{{ item?.name }}
                                                          <span style="color:#1b8ef8"  matTooltipClass="custom-tooltip" [matTooltip]="'This ID Will Dynamically change.'">{{ item?.quoteId }} {{item?.Version?item?.Version:''}}</span>
                                                                                                            </p>
                                                          </div>
                                                      <div *ngIf="item?.name === 'Space'"  class="d-flex txt-res" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'font-size':item?.font_size+'px',
                                                          'color':item?.color,
                                                          'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              
                                                              'color':item?.color,
                                                              'min-height': '48px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic
                                                              }">
                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                  'font-size':item?.font_size+'px',
                                                                  'color':item?.color,
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
          
                                                              'margin':' 0 !important',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'text-align':item?.align,
                                                      'width':'100%'
                                                          }"> </p>
                                                          </div>
                                                      <div *ngIf="item?.name==='Item description'"  class="d-flex" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-size':item?.font_size+'px',
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                             
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic
                                                      }">
                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                 'font-size': item?.title_font_size + 'px',
                                                                 'color': item?.title_color,
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'margin':' 0 !important',
                                                              'text-align':item?.align,
                                                      'width':'100%'
                                                          }">{{ item?.value }}</p>
                                                          <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                          
                                                          </div>
                                                          <!-- <div *ngIf="item?.name==='Deal Stage'"  class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'color':item?.color,
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                              }">{{ item?.value }}
                                                              <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                                                                </p>
                                                              </div> -->
                                                          <div *ngIf="item?.name==='Item price'"  class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                     'font-size': item?.title_font_size + 'px',
                                                                     'color': item?.title_color,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'margin':' 0 !important',
                                                                  'text-align':item?.align,
                                                      'width':'100%'
                                                              }">{{ item?.value }}</p>
                                                              <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                               
                                                              </div>
                                                          <div *ngIf="item?.name==='Item Unit' || item?.name === 'Item quantity per click'"  class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.title_color,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'text-align':item?.align,
                                                                  'margin':' 0 !important',
                                                      'width':'100%'
                                                              }">{{ item?.value }}</p>
                                                              <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                               
                                                              </div>
                                                          <div *ngIf="item?.name==='Item comments'"  class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                 
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      'color': item?.title_color,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'text-align':item?.align,
                                                                  'margin':' 0 !important',
                                                      'width':'100%'
                                                              }">{{ item?.value }}
                                                              <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                                                                </p>
                                                              </div>
                                                      <div *ngIf="item?.name==='Member'"  class="d-flex" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-size':item?.font_size+'px',
                                                                  'color': '#333',
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                            
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic
                                                      }">
                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                 'font-size': item?.title_font_size + 'px',
                                                                 'color': item?.title_color,
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'text-align':item?.align,
                                                              'margin':' 0 !important',
                                                      'width':'100%'
                                                          }">{{ item?.value }}</p>
                                                          <span style="color:#1b8ef8"  matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                           
                                                          </div>
          
                                                      <div *ngIf="item?.name==='Email' || item?.name==='Email(s)'" class="d-flex" [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'text-decoration': item?.textDecoration,
                                                        
                                                          'font-style': item?.font_italic
                                                        }">
                                                              <p readonly  class="txt-res" style="margin: 0 !important" [ngStyle]="{
                                                                  'font-size': item?.title_font_size + 'px',
                                                                  'color': item?.title_color,
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                               
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'margin':' 0 !important',
                                                                  'text-align':item?.align
                                                                }">{{ item?.value }}&nbsp;</p>
                                                          <span  [ngStyle]="{
                                                             
                                                              'text-align':item?.align,
                                                              'color': item?.color
          
                                                            }" >  {{ item?.tag }}</span>
                                                          </div>
                                                     
                                                          <div *ngIf="item?.name === 'Phone number(s)' || item?.name=== 'Phone Number'" class="d-flex flex-column" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'text-decoration': item?.textDecoration,
                                                             
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <ng-container *ngIf="item.phone_number && item.phone_number.length > 0; else noPhoneNumbers">
                                                                <ng-container *ngFor="let phoneNumber of item.phone_number; let i = index">
                                                                  <p readonly class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.title_color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align,
                                                                      'margin':' 0 !important',
                                                                      'width':'100%'
                                                                    }">
                                                                    {{ item?.value }}&nbsp;</p>
                                                                     <span [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'text-align':item?.align,
                                                                       'width':'100%'
                                                                    }" style="color: #1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{item?.phone_number?.length?item.label + " " + phoneNumber?.name: item?.tag }}</span>
                                                                    
                                                                  
                                                                </ng-container>
                                                              </ng-container>
                                                              <ng-template #noPhoneNumbers>
                                                                  <p class="txt-res" style="margin: 0 !important " *ngIf="item?.value == '' " readonly [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.title_color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align,
                                                                      'margin':' 0 !important',
                                                                      'width':'100%'
                                                                    }"> 
                                                                      {{ item?.value}}&nbsp;</p>
                                                                      <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                           'text-align':item?.align,
                                                                           'width':'100%'
                                                                        }" style="color: #1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                      
                                                                  
                                                              </ng-template>
                                                            </div>
                                                            
                                                            <div *ngIf="item?.name === 'Phone'  || item?.name=== 'Workspace Phone'" class="d-flex flex-column" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <ng-container *ngIf="item.phone_number && item.phone_number.length > 0; else noPhoneNumbers">
                                                                <ng-container *ngFor="let phoneNumber of item.phone_number; let i = index">
                                                                  <p readonly class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'color': item?.title_color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                    }">
                                                                    {{ item?.value }}</p> <span [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                     
                                                                      'text-align':item?.align
                                                                    }" style="color: #1b8ef8" >{{phoneNumber?.name }}&nbsp;{{phoneNumber?.title}}</span>
                                                                  
                                                                </ng-container>
                                                              </ng-container>
                                                              <!-- <ng-template #noPhoneNumbers  >
                                                                  <p readonly class="txt-res" *ngIf="item?.value == '' " [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                    }">
                                                                      {{ item?.value}}</p>
                                                                       <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                        
                                                                          'text-align':item?.align
                                                                        }" style="color: #1b8ef8" >{{ item?.tag }}</span>
                                                                    
                                                              </ng-template> -->
                                                            </div>
                                                            
          
                                           
                                                            <div *ngIf="item?.name === 'Address' || item?.name === 'Workspace Address'" class="d-flex flex-column" [ngStyle]="{
                                                               'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                             
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
          
                                                              <ng-container *ngIf="item?.address_title && item?.address_title?.length > 0; else noPhoneNumbers">
                                                                <ng-container  *ngFor="let phoneNumber of item.address_title; let i = index">
                                                                  <p  style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                                                                     'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'color': item?.title_color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                    }">
                                                                    {{ item?.value }} </p>
                                                                    <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'margin-top':'0',
                                                                     
                                                                     'text-align':item?.align,
                                                                     'color': item?.color
          
                                                                    }"  >{{getAddress(phoneNumber)}}
                                                                    </span>
                                                                  
                                                                </ng-container>
                                                              </ng-container>
                                                              <!-- <ng-template #noPhoneNumbers >
                                                             
                                                                  <p readonly class="txt-res" *ngIf="item?.value == '' " style="margin: 0 !important "  [ngStyle]="{
                                                                        'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                     
                                                                    }">
                                                                      {{ item?.value}}</p>
                                                                       <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                        
                                                                          'text-align':item?.align
          
                                                                        }" style="color: #1b8ef8" >{{ item?.tag }}</span>
          
                                                                      
                                                               
                                                              </ng-template> -->
                                                            </div>
                                                            <!-- <div *ngIf="item?.name === 'Workspace Address'" class="d-flex flex-column" [ngStyle]="{
                                                               'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                             
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
          
                                                              <ng-container *ngIf="item?.address_title && item?.address_title?.length > 0; else noPhoneNumbers">
                                                                <ng-container *ngFor="let phoneNumber of item.address_title; let i = index">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                     'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'color': item?.color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                    }">
                                                                    {{ item?.value }}</p>
                                                                    <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                     
                                                                     'text-align':item?.align
                                                                    }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.address_title?.length?phoneNumber?.name:item?.tag }}</span>
                                                                    
                                                                  
                                                                </ng-container>
                                                              </ng-container>
                                                              <ng-template #noPhoneNumbers >
                                                             
                                                                  <p readonly class="txt-res" *ngIf="item?.value == '' " style="margin: 0 !important " [ngStyle]="{
                                                                        'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                     
                                                                    }">
                                                                      {{ item?.value}}</p>
                                                                       <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                        
                                                                          'text-align':item?.align
          
                                                                        }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
          
                                                                      
                                                               
                                                              </ng-template>
                                                            </div> -->
                                                            <div *ngIf="item?.name==='Source'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'text-decoration': item?.textDecoration,
                                                             
                                                              'font-style': item?.font_italic
                                                            }">
                                                                  <p readonly class="txt-res" style="margin: 0 !important "   [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.title_color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                     'margin':' 0 !important',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                    }">{{ item?.value }}&nbsp;</p>
                                                              <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                               
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
                                                                  
                                                                }"  >{{ item?.tag }}</span>
                                                              </div>
                                                                        <div *ngIf="item?.name === 'Full Name'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.title_font_size + 'px',
                                                                  'color': item?.title_color,
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                'text-align':item?.align
                                                                  }">
                                                                {{ item?.value }}&nbsp;</p>
                                                                <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                 
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
          
                                                                }">{{ item?.tag }}</span>
                                                            </div>
              
                                                            <div *ngIf="item?.name === 'Language'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'text-decoration': item?.textDecoration,
                                                             
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.title_font_size + 'px',
                                                                  'color': item?.title_color,
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align':item?.align
                                                                }">
                                                                {{ item?.value }}&nbsp; </p>
                                                                <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
          
                                                                }"  >{{ item?.tag }}</span>
                                                                
                                                            </div>
                                                            <div *ngIf="item?.name === 'Contact custom fields' || item?.name === 'Custom item fields'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <ng-container *ngIf="item?.address_title && item?.address_title?.length > 0; else noPhoneNumbers">
                                                                <ng-container *ngFor="let phoneNumber of item.address_title; let i = index">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                    'font-size': item?.font_size + 'px',
                                                                    'color': '#333',
                                                                    'font-family': item?.fontFamily,
                                                                    'font-weight': item?.font_Weight,
                                                                    'padding-bottom': item?.padding + 'px',
                                                                    'padding-top': item?.paddingT + 'px',
                                                                    'padding-right': item?.paddingR + 'px',
                                                                    'padding-left': item?.paddingL + 'px',
                                                                    'text-decoration': item?.textDecoration,
                                                                    'font-style': item?.font_italic,
                                                                    'text-align':item?.align,
                                                                    'margin':' 0 !important',
                                                                    'width':'100%'
                                                                  }">
                                                                    {{ phoneNumber?.module_name?phoneNumber?.module_name:item?.value}} <span [ngStyle]="{
                                                                      'padding-bottom': item?.padding + 'px',
                                                                      'padding-top': item?.paddingT + 'px',
                                                                      'padding-right': item?.paddingR + 'px',
                                                                      'padding-left': item?.paddingL + 'px',
                                                                      'text-align':item?.align,
                                                                      'width':'100%'
                                                                    }" style="color: #1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.address_title?.length?phoneNumber?.title:item?.tag }}</span>
                                                                    
                                                                  </p>
                                                                </ng-container>
                                                              </ng-container>
                                                              <ng-template #noPhoneNumbers>
                                                                  <p readonly [ngStyle]="{
                                                                      'font-size': item?.font_size + 'px',
                                                                      'color': '#333',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'padding-bottom': item?.padding + 'px',
                                                                      'padding-top': item?.paddingT + 'px',
                                                                      'padding-right': item?.paddingR + 'px',
                                                                      'padding-left': item?.paddingL + 'px',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'margin':' 0 !important',
                                                                      'text-align':item?.align,
                                                                    'width':'100%'
                                                                    }">
                                                                      {{ item?.value }} <span [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'padding-bottom': item?.padding + 'px',
                                                                          'padding-top': item?.paddingT + 'px',
                                                                          'padding-right': item?.paddingR + 'px',
                                                                          'padding-left': item?.paddingL + 'px',
                                                                          'text-align':item?.align,
                                                                          'width':'100%'
                                                                        }" style="color: #1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                      
                                                                    </p>
                                                              </ng-template>
                                                            </div>
          
                                                            <div *ngIf="item?.name==='Website' || item?.name === 'Video URL' || item?.name === 'Website URL'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p readonly class="txt-res" style="margin: 0 !important" [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'font-size': item?.title_font_size + 'px',
                                                                'color': item?.title_color,
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'margin':' 0 !important',
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align':item?.align
                                                              }">{{ item?.value }}&nbsp;</p>
                                                              <a *ngIf="item?.url" href="{{ item?.url }}" target="_blank" [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'text-align':item?.align,
                                                                'color': item?.color
                                                              }">{{ item?.url }}</a>
                                                              <span *ngIf="!item?.url" [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'text-align':item?.align,
                                                                'color': item?.color
                                                              }">{{ item?.tag }}</span>
                                                              
                                                            </div>
          
                                                      <div *ngIf="item?.name==='Social links'" class="d-flex flex-column" [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'text-decoration': item?.textDecoration,
                                                          'padding-bottom': item?.padding + 'px',
                                                                'padding-top': item?.paddingT + 'px',
                                                                'padding-right': item?.paddingR + 'px',
                                                                'padding-left': item?.paddingL + 'px',
                                                          'font-style': item?.font_italic
                                                        }">
                                                        <!-- .. -->
                                                        <ng-container *ngIf="item.social_link && item.social_link.length > 0; else noPhoneNumbers">
                                                          <ng-container *ngFor="let phoneNumber of item.social_link; let i = index">
                                                            <p readonly class="txt-res" [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'font-size': item?.title_font_size + 'px',
                                                                'color': item?.title_color,
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'margin':' 0 !important',
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align':item?.align
                                                              }">
                                                              {{ phoneNumber?.name}}</p>
                                                               <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
          
                                                                }"  >
                                                                  <!-- {{item?.social_link?.length?phoneNumber?.url: item?.tag }} -->
                                                               <a [attr.href]="item?.social_link?.length?phoneNumber?.url: item?.tag  " target="_blank">{{item?.social_link?.length?phoneNumber?.url: item?.tag }}</a></span>
                                                               
                                                            
                                                          </ng-container>
                                                        </ng-container>
                                                        <ng-template #noPhoneNumbers>
                                                            <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'font-size': item?.title_font_size + 'px',
                                                                'color': item?.title_color,
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'margin':' 0 !important',
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align':item?.align
                                                              }"> 
                                                                {{ item?.value }}</p>
                                                                <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
          
                                                                }"  >{{ item?.tag }}</span>
                                                                
                                                            
                                                        </ng-template>
                                                        <!-- .. -->
                                                              <!-- <p readonly class="txt-res"  [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'margin-bottom': item?.mrbottum + 'px',
                                                                  'font-size': item?.title_font_size + 'px',
          
                                                                  'color': item?.color,
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'padding-bottom': item?.padding + 'px',
                                                                  'padding-top': item?.paddingT + 'px',
                                                                  'padding-right': item?.paddingR + 'px',
                                                                  'padding-left': item?.paddingL + 'px',
                                                                
                                                                  'margin-top': item?.mrtop + 'px',
                                                                  'margin-right': item?.mrright + 'px',
                                                                  'margin-left': item?.mrleft + 'px',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic
                                                                }">{{ item?.value }}</p> -->
                                                          <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span> -->
                                                          
                                                          </div>
          
          
          
          
                                                          <!-- .. -->
                                                  <!-- <div *ngIf="item?.name==='Organization N.'" class="d-flex flex-column"
                                                  [ngStyle]="{
                                                  'align-items':item?.align,
                                                  'margin-bottom':item?.mrbottum+'px'
                                          }">
                                                  <label for="business" [ngStyle]="{
                                                  'font-size':bodying_Size+'px',
                                                  'font-weight':'500',
                                                  'font-family':selectedFont
                                              }">{{item?.label}} <small *ngIf="!item?.required" [ngStyle]="{
                                                  'color': '#92A0A8',
                                                  'font-size': bodying_Size+'px',
                                                  'font-weight': '500',
                                                  'font-family':selectedFont}">(Optional)</small></label>
                                                  <input type="url" class="form-control" id="website" name="website"
                                                  <input type="text" class="form-control" id="business" name="business"
                                                      [placeholder]="item?.placeholder" [ngStyle]="{
                                                      'font-size': bodying_Size+'px', 
                                                      'width': '80%',
                                                      'font-family':selectedFont }">
                                              </div>
                                            
                                              </div> -->
                                              <!-- <div *ngIf="item?.name==='Organization N.'" class="d-flex flex-column"
                                                  [ngStyle]="{
                                                  'align-items':item?.align,
                                                  'margin-bottom':item?.mrbottum+'px'
                                          }">
                                                  <label for="business" [ngStyle]="{
                                                  'font-size':bodying_Size+'px',
                                                  'font-weight':'500',
                                                  'font-family':selectedFont
                                              }">{{item?.label}} <small *ngIf="!item?.required" [ngStyle]="{
                                                  'color': '#92A0A8',
                                                  'font-size': bodying_Size+'px',
                                                  'font-weight': '500',
                                                  'font-family':selectedFont}">(Optional)</small></label>
                                                  <input type="text" [value]="item?.tag" readonly class="form-control" id="business" name="business"
                                                      [placeholder]="item?.placeholder" [ngStyle]="{
                                                      'font-size': bodying_Size+'px', 
                                                      'width': '80%',
                                                      'font-family':selectedFont }">
                                              </div> -->
                           
                                              <div *ngIf="item?.field_type==='Input'" class="flex flex-column textbox"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                }">
                                              <label class="fw5" for="input"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic,
                                                  'text-align':item?.align,
                                                  'margin':' 0 !important'
                                                }">{{item?.label}}
                                                  <small class="lbops" *ngIf="!item?.required"
                                                      [ngStyle]="{'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                  <small *ngIf="item?.required">*</small>
                                              </label>
                                              <input [type]="item?.required ? (item?.datatype || 'text') : 'text'"
                                              [ngStyle]="{
                                                  'border-color':item?.Bdr_Dvdr
                                              
                                              }" class="form-control w-1000" id="{{item?.id}}" name="input"
                                                  [placeholder]="item?.placeholder">
                                              <p class="m-0 font-normal"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.help_font_size + 'px',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                  'text-decoration': item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  'font-style': item?.font_italic
                                                }">
                                                  {{item?.help_txt}}</p>
                                          </div>
                                          <div *ngIf="item?.field_type==='Date'"  class="flex flex-column textbox"
                                          [ngStyle]="{
                                              'justify-content': item?.align
                                             
                                            }">
                                              <label class="fw5" for="date"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic,
                                                  'margin':' 0 !important',
                                                  'text-align':item?.align,
                                                      'width':'100%'
                                                }">{{item?.dateTitle == 'Custom'?item?.value:item?.label}}
                                                  <small class="lbops" *ngIf="!item?.required"
                                                      [ngStyle]="{'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                  <small *ngIf="item?.required">*</small>
                                              </label>
          
                                              <div  class="d-flex gap-2 pe-2 py-1 pointer align-items-center ms-1" style="border: 1px solid #CBDBE4;border-radius: 5px;" (click)="openDatePicker()" (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)">
                                                  <input matInput (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)" style="width: 0px; border: none;"  id="{{item?.id}}"  [ngModel]="dateValue"
                                                  />
                                                  <div style="min-width:70px;"  id="{{item?.id}}">{{item?.placeholder? item?.placeholder: ''}}</div>
                                                  <!-- <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                                                  <mat-datepicker #picker ></mat-datepicker> -->
                                              </div>
          
                                              <!-- <input [type]="item?.required ? (item?.datatype || 'date') : 'date'"
                                              [ngStyle]="{
                                                  'border-color':item?.Bdr_Dvdr
                                              
                                              }" class="form-control w-80" id="{{item?.id}}" name="date"
                                                  [placeholder]="item?.placeholder"> -->
                                              <p class="m-0 font-normal"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.help_font_size + 'px',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                  'text-decoration': item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  'font-style': item?.font_italic
                                                }">
                                                  {{item?.help_txt}}</p>
                                          </div>
                                          <!-- <div *ngIf="item?.field_type==='Color' || item?.name =='Color'" class="flex flex-column textbox"
                                          [ngStyle]="{
                                              'justify-content': item?.align,
                                              'font-size': item?.font_size + 'px',
                                              'color': '#333',
                                              'font-family': item?.fontFamily,
                                              'font-weight': item?.font_Weight,
                                              'color': item?.color,
                                              'text-decoration': item?.textDecoration,
                                              'font-style': item?.font_italic
                                            }">
                                              <label class="fw5" for="color"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  'font-style': item?.font_italic
                                                }">{{item?.label}}
                                                  <small *ngIf="item?.required">*</small>
                                              </label>
                                              <input [type]="item?.required ? (item?.datatype || 'color') : 'color'"
                                              [ngStyle]="{
                                                  'border-color':item?.Bdr_Dvdr
                                              
                                              }"  class="form-control w-80" id="{{item?.id}}" name="color"
                                                  [placeholder]="item?.placeholder">
                                                  <p *ngIf="item?.name !=='Color'" class="m-0 font-normal"
                                                  [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.help_font_size + 'px',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'padding-bottom': item?.padding + 'px',
                                                      'padding-top': item?.paddingT + 'px',
                                                      'padding-right': item?.paddingR + 'px',
                                                      'padding-left': item?.paddingL + 'px',
                                                      'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                      'text-decoration': item?.textDecoration,
                                                      'margin':' 0 !important',
                                                      'font-style': item?.font_italic
                                                    }">
                                                      {{item?.help_txt}}</p>
                                          </div> -->
                                          <div *ngIf="item?.field_type==='Dropdown'" class="flex flex-column"
                                          [ngStyle]="{
                                              'justify-content': item?.align,
                                              'font-size': item?.font_size + 'px',
                                              'color': '#333',
                                              'font-family': item?.fontFamily,
                                              'font-weight': item?.font_Weight,
                                              'color': item?.color,
                                              'text-decoration': item?.textDecoration,
                                              'font-style': item?.font_italic
                                            }">
                                              <label class="fw5" for="dropdown"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  'font-style': item?.font_italic
                                                }">{{item?.label}}
                                                  <small class="lbops" *ngIf="!item?.required"
                                                      [ngStyle]="{'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                  <small *ngIf="item?.required">*</small>
                                              </label>
                                              <select class="form-select w-80" id="{{item?.id}}"
                                              [ngStyle]="{
                                                  'border-color':item?.Bdr_Dvdr
                                              
                                              }" >
                                                  <option [value]="coun?.name"
                                                      *ngFor="let coun of item?.custom_value?.type?item?.value:item?.custom_value?.value;"
                                                      [selected]="coun?.selected">{{coun?.name}}</option>
                                              </select>
                                              <p class="m-0 font-normal"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.help_font_size + 'px',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                  'text-decoration': item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  'font-style': item?.font_italic
                                                }">
                                                  {{item?.help_txt}}</p>
                                          </div>
                                          <div *ngIf="item?.field_type==='Checkbox'"
                                              class="flex flex-column checkboxradio"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px'
                                                }">
                                              <p class="m-0 fw5"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'margin':' 0 !important',
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                }">
                                                  {{item?.label}} <small class="lbops" *ngIf="!item?.required"
                                                      [ngStyle]="{'font-size': bodying_Size+'px',
                                                      'font-family':selectedFont
                                                      
          
                                                      }">(Optional)</small>
                                                  <small *ngIf="item?.required">*</small>
                                              </p>
                                              <div class="flex flex-wrap checkboxradio-container">
                                                  <div class="checkboxradio-field"
                                                      *ngFor="let check of item?.custom_value?.type?item?.value:item?.custom_value?.value;">
                                                      <input type="checkbox" [checked]="check?.selected"
                                                          id="{{check?.name}}" name="{{check?.name}}"
                                                          value="{{check?.name}}"
                                                          [ngStyle]="{
                                                              'border-color':item?.Bdr_Dvdr
                                                             
                                                          
                                                          
                                                          }" >
                                                      <label class="fw5" style="margin: 0px 7px 0px 6px;" for="{{check?.name}}"
                                                      [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic
                                                         
                                                        }">
                                                         {{check?.name}} </label>
                                                  </div>
                                              </div>
                                              <p class="m-0 font-normal"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'margin-bottom': item?.mrbottum + 'px',
                                                  'font-size': item?.help_font_size + 'px',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'margin':' 0 !important',
                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                  'margin-top': item?.mrtop + 'px',
                                                  'margin-right': item?.mrright + 'px',
                                                  'margin-left': item?.mrleft + 'px',
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                  
                                                }">
                                                  {{item?.help_txt}}</p>
                                          </div>
                                          <div *ngIf="item?.field_type==='Radio'"
                                              class="flex flex-column checkboxradio"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic,
                                                  'text-align':item?.align,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'width':'100%'
                                                }">
                                              <p class="m-0 fw5"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'margin':' 0 !important',
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic,
                                                  'text-align':item?.align,
                                                  'width':'100%'
          
                                                  
                                                }">
                                                  {{item?.label}} <small class="lbops" *ngIf="!item?.required"
                                                      [ngStyle]="{ 'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                  <small *ngIf="item?.required">*</small>
                                              </p>
                                              <div class="flex flex-wrap checkboxradio-container">
                                                  <div class="checkboxradio-field"
                                                      *ngFor="let radio of item?.custom_value?.type?item?.value:item?.custom_value?.value;">
                                                      <input type="radio" [required]="item?.required"
                                                          [checked]="radio?.selected" id="{{radio?.name}}"
                                                          name="radio_button" value="{{radio?.name}}"
                                                          [ngStyle]="{
                                                              'border-color':item?.Bdr_Dvdr
                                                          
                                                          }" >
                                                      <label style="margin: 0px 7px 0px 6px;" class="fw5" for="{{radio?.name}}"[ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'margin':' 0 !important',
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic
                                                        }">
                                                          {{radio?.name}} </label>
                                                  </div>
                                              </div>
                                              <p class="m-0 font-normal"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.help_font_size + 'px',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'margin':' 0 !important',
                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                }">
                                                  {{item?.help_txt}}</p>
                                          </div>
          
                                              <div *ngIf="item?.name==='Input Field'" class="d-flex flex-column" [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'padding-bottom': item?.padding + 'px',
                                                      'padding-top': item?.paddingT + 'px',
                                                      'padding-right': item?.paddingR + 'px',
                                                      'padding-left': item?.paddingL + 'px',
                                                  'font-style': item?.font_italic
                                                  
                                                }">
                                                  <label for="company" [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.font_size + 'px',
                                                      'color': '#333',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'color': item?.color,
                                                      'text-decoration': item?.textDecoration,
                                                      'font-style': item?.font_italic,
                                                      'text-align':item?.align,
                                                       'width':'100%'
                                                      
                                                     
                                                    }">{{item?.label}} </label>
                                                    <textarea rows="1" style="margin: 0 !important " class="form-control" id="company" name="company"
                                                    [placeholder]="item?.placeholder" [ngStyle]="{
                                                        'justify-content': item?.align,
                                                        'font-family': item?.fontFamily,
                                                        'margin':' 0 !important',
                                                        'color': item?.color,
                                                        'text-decoration': item?.textDecoration,
                                                        'font-style': item?.font_italic,
                                                        'border-color':item?.Bdr_Dvdr,
                                                        'text-align':item?.align
                                                     
                                                       
                                                      }"></textarea> 
                                                  <!-- <input type="text" class="form-control" id="company" name="company"
                                                      [placeholder]="item?.placeholder" [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-family': item?.fontFamily,
                                                          'margin':' 0 !important',
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic,
                                                          'border-color':item?.Bdr_Dvdr,
                                                          'text-align':item?.align
                                                       
                                                         
                                                        }"> -->
                                                      
                                              </div>
                                              <div *ngIf="item?.name==='Industry'" class="d-flex flex-column" [ngStyle]="{
                                              'align-items':item?.align,
                                              'margin-bottom':item?.mrbottum+'px'
                                          }">
                                                  <label class="lebel_res" for="industry" [ngStyle]="{
                                                  'font-size':bodying_Size+'px',
                                                  'font-weight':'500',
                                                  'font-family':selectedFont }">{{item?.label}} <small
                                                          *ngIf="!item?.required" [ngStyle]="{
                                                  'color': '#92A0A8',
                                                  'font-size': bodying_Size+'px',
                                                  'font-weight': '500',
                                                  'font-family':selectedFont }">(Optional)</small></label>
                                                  <input  type="text" class="form-control lebel_res" id="industry" name="industry"
                                                      [placeholder]="item?.placeholder" [ngStyle]="{
                                                      'font-size': bodying_Size+'px', 
                                                      'width': '80%',
                                                      'font-family':selectedFont }">
                                                      
                                              </div>
                                              <div *ngIf="item?.name==='Textbox'" class="d-flex flex-column" [ngStyle]="{
                                              'align-items':item?.align
                                             
                                               }">
                                                  <label for="textbox" style="margin: 0 !important "  class="lebel_res" [ngStyle]="{
                                                      'color': '#92A0A8',
                                                      'font-size': bodying_Size+'px',
                                                      'font-weight': '500',
                                                      'width': '80%',
                                                      'font-family':selectedFont || 'Arial, sans-serif',
                                                      'justify-content':item?.align,
                                                      'font-size':item?.font_size+'px',
                                                      'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'margin':' 0 !important',
                                                          'color':item?.color,
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic,
                                                          'text-align':item?.align,
                                                      'width':'100%'
                                                  
                                                  }">{{item?.label}} </label>
                                                  <input type="text" style="margin: 0 !important "  class="form-control txt-res" id="textbox"
                                                      name="textbox" [placeholder]="item?.placeholder" [ngStyle]="{
                                                          'border-color':item?.Bdr_Dvdr,
                                                          'justify-content': item?.align,
                                                          'text-align':item?.align,
                                                          'width':'100%'
                                                      
                                                      }">
                                                      
                                                  <p class="m-0 label_res2" style="margin: 0 !important "   [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.help_font_size + 'px',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                      'text-decoration': item?.textDecoration,
                                                      'font-style': item?.font_italic,
                                                      'text-align':item?.align,
                                                      'width':'100%'
                                                    }">{{item?.help_txt}}</p>
                                              </div>
                                              <div *ngIf="item?.name==='Button'" class="d-flex" [ngStyle]="{
                                              'justify-content':item?.align,
                                              'margin-bottom':item?.mrbottum+'px'
                                          }">
                                                  <button class="txt-res" type="submit" [ngStyle]="{
                                              'width':item?.width+'%',
                                              'background': item?.backgroundcolor,
                                              'height':item?.height+'px',
                                              'border': 'none',
                                              'border-radius': '5px',
                                              'font-weight': '600',
                                              'font-size': bodying_Size+'px',
                                              'justify-content':item?.align,
                                              'margin-bottom':item?.mrbottum+'px',
                                              'font-size':item?.font_size+'px',
                                                  'color': '#333',
                                                  'font-family':item?.fontFamily,
                                                  'font-weight':item?.font_Weight,
                                                  'padding-bottom':item?.padding+'px',
                                                  'padding-top':item?.paddingT+'px',
                                                  'padding-right':item?.paddingR+'px',
                                                  'padding-left':item?.paddingL+'px',
                                                  'color':item?.color,
                                                  'margin-top':item?.mrtop+'px',
                                                  'margin-right':item?.mrright+'px',
                                                  'margin-left':item?.mrleft+'px',
                                                  'text-decoration':item?.textDecoration,
                                                  'font-style':item?.font_italic,
                                                  'text-align':item?.align,
                                                      'width':'100%'
                                          }">{{item?.value}}</button>
                                          
                                              </div>
                                              <div *ngIf="item?.name==='Selectbox'" class="d-flex flex-column" [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'padding-bottom': item?.padding + 'px',
                                                  'padding-top': item?.paddingT + 'px',
                                                  'padding-right': item?.paddingR + 'px',
                                                  'padding-left': item?.paddingL + 'px',
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                }">
                                                  <label for="selectbox" class="lebel_res" [ngStyle]="{
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size + 'px',
                                                    'color': '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin':' 0 !important',
                                                    'color': item?.color,
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align':item?.align
                                                  }">{{labelName? labelName : getTranslatedText ("QUOTE_TEMPLATE." + item?.label) || item?.label}}</label>
                                                  <select class="form-select txt-res" name="selectbox" id="selectbox"
                                                  [ngStyle]="{
                                                    'border-color':item?.Bdr_Dvdr,
                                                    'text-align':item?.align
                                                  }" [(ngModel)]="item.selectedValue">
                                            <!-- <option value="" class="txt-res">Select an option</option> Empty option -->
                                            <option [value]="coun?.name" class="txt-res"
                                                    *ngFor="let coun of item?.custom_value?.value?.length ? item?.custom_value?.value : item?.value;"
                                                    [selected]="coun?.selected">{{coun?.name}}</option>
                                          </select>
                                                  
                                                </div>
          
                                              <div *ngIf="item?.name==='Text Area'" class="d-flex flex-column" [ngStyle]="{
                                              'align-items':item?.align
                                              
          
                                            }">
                                                  <label  for="textarea" style="margin: 0 !important "  class="lebel_res" [ngStyle]="{
                                                      'color': '#92A0A8',
                                                      'font-size': bodying_Size+'px',
                                                      'font-weight': '500',
                                                      'width': '80%',
                                                      'font-family':selectedFont || 'Arial, sans-serif',
                                                      'justify-content':item?.align,
                                                      'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'margin':' 0 !important',
                                                          'color':item?.color,
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic,
                                                          'text-align':item?.align,
                                                      'width':'100%'
                                                  
                                                  }">{{item?.label}} 
                                          </label>
          
                                               <textarea rows="3" style="margin: 0 !important "  class="form-control txt-res" [ngStyle]="{
                                                 'width':'100%',
                                                  'justify-content':item?.align,
                                                  'font-family':item?.fontFamily,
                                                  'color':item?.color,
                                                  'font-style':item?.font_italic,
                                                  'font-weight': item?.font_Weight,
                                                  'text-align':item?.align,
                                                  'border-color': item?.Bdr_Dvdr,
                                                  'width':'100%',
                                                  'background-color':item?.backGroundColor
                             
                                             }" id="textbox" name="textbox"
                                                      [placeholder]="item?.placeholder" [(ngModel)]="item.textareaValue"
                                                      (change)="saveTextarea(item?.name, item?.id, item?.textareaValue)" ></textarea> 
                                              </div>
                                              <div
                          *ngIf="item?.name == 'Clauses'"
                          class="d-flex flex-column"
                          [ngStyle]="{
                            'justify-content': item?.align,
                            'font-size': item?.font_size + 'px',
                            color: '#333',
                            'font-family': item?.fontFamily,
                            'font-weight': item?.font_Weight,

                            color: item?.color,
                            'text-decoration': item?.textDecoration,
                            'font-style': item?.font_italic
                          }"
                        >
                          <p
                            class="txt-res"
                            style="margin: 0 !important"
                            readonly
                            [ngStyle]="{
                              'font-size': item?.font_size + 'px',
                              'font-family': item?.fontFamily,
                              'font-weight': 'bolder',
                              margin: ' 0 !important',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align': item?.align,
                              width: '100%',
                              ' list-style-position': 'inside'
                            }"
                          >
                            {{
                              getTranslatedText(
                                "QUOTE_TEMPLATE." + item.label
                              ) || item.label
                            }}
                            <textarea
                              rows="1"
                              style="margin: 0 !important"
                              class="form-control"
                              id="company"
                              name="company"
                              [placeholder]="item?.placeholder"
                              [ngStyle]="{
                                'justify-content': item?.align,
                                'font-family': item?.fontFamily,
                                margin: ' 0 !important',
                                color: item?.color,
                                'text-decoration': item?.textDecoration,
                                'font-style': item?.font_italic,
                                'border-color': item?.Bdr_Dvdr,
                                'text-align': item?.align,
                                height: item?.autoHeight
                                  ? item?.autoHeight
                                  : 'auto'
                              }"
                              [(ngModel)]="item.textareaValue"
                              (change)="
                                saveClauses(
                                  item?.name,
                                  item?.id,
                                  item?.textareaValue
                                )
                              "
                              (input)="
                                autoResize(
                                  $event.target,
                                  item?.name,
                                  item?.id
                                )
                              "
                            ></textarea>
                          </p>
                          <p
                            class="m-0 label_res2"
                            style="margin: 0 !important"
                            [ngStyle]="{
                              'justify-content': item?.align,
                              'font-size': item?.help_font_size + 'px',
                              'font-family': item?.fontFamily,
                              'font-weight': item?.font_Weight,
                              margin: ' 0 !important',
                              color: item?.H_Txt_Notes
                                ? item?.H_Txt_Notes
                                : '#000000',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align': item?.align,
                              width: '100%'
                            }"
                          >
                          {{
                            getTranslatedText(
                              "QUOTE_TEMPLATE." + item.help_txt
                            ) || item.help_txt
                          }}
                         
                          </p>
                          <br />
                          <!-- <div *ngIf="item?.custom_value?.value?.length">
                                                  <span  [value]="coun?.name"
                                                  *ngFor="let coun of item?.custom_value?.value"
                                                  [selected]="coun?.selected">{{coun?.name}}</span>
                                              </div> -->
                        </div>
                                              <div *ngIf="item?.name==='Time'" class="d-flex flex-column " id="selectTime" [ngStyle]="{
                                                'align-items': item?.align
                                            }">
                                                <label for="timepicker" style="margin: 0 !important" class="lebel_res" [ngStyle]="{
                                                    'color': '#92A0A8',
                                                    'font-size': bodying_Size+'px',
                                                    'font-weight': '500',
                                                    'width': '80%',
                                                    'font-family': selectedFont || 'Arial, sans-serif',
                                                    'justify-content': item?.align,
                                                    'font-size': item?.font_size+'px',
                                                    'color': '#333',
                                                    'font-family': item?.fontFamily,
                                                    'font-weight': item?.font_Weight,
                                                    'margin': '0 !important',
                                                    'color': item?.color,
                                                    'text-decoration': item?.textDecoration,
                                                    'font-style': item?.font_italic,
                                                    'text-align': item?.align,
                                                    'width': '100% !important'
                                                }">{{ getTranslatedText("QUOTE_TEMPLATE." +item?.label)|| item?.label }}</label>
                                                <mat-form-field appearance="outline" [ngStyle]="{
                                                  'border-color': item?.Bdr_Dvdr,
                                                  'border': '1px solid ' + item?.Bdr_Dvdr,
                                                  'border-radius': '5px'
                                                }">
                                                    <input
                                                      type="text"
                                                      matInput
                                                      [ngxMatTimepicker]="timepicker"
                                                      [format]="24"
                                                      [required]="required"
                                                      readonly
                                                      [(ngModel)]="item.value"
                                                    />
                                                  
                                                    <mat-icon
                                                      matSuffix
                                                      >schedule</mat-icon
                                                    >
                                                  </mat-form-field>
                                                  
                                                  <ngx-mat-timepicker
                                                  id="time-picker"
                                                    #timepicker
                                                    [enableKeyboardInput]="true"
                                                ></ngx-mat-timepicker>
                                                <span *ngIf="item?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                            </div> 
                                              <div *ngIf="item?.name==='Checkbox'" class="d-flex flex-column" [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'color': item?.color,
                                                  'text-decoration': item?.textDecoration,
                                                  'padding-bottom': item?.padding + 'px',
                                                      'padding-top': item?.paddingT + 'px',
                                                      'padding-right': item?.paddingR + 'px',
                                                      'padding-left': item?.paddingL + 'px',
                                                  'font-style': item?.font_italic
                                                }">
                                                  <p class="m-0 lebel_res" [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.font_size + 'px',
                                                      'color': '#333',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'color': item?.color,
                                                      'text-decoration': item?.textDecoration,
                                                      'font-style': item?.font_italic,
                                                      'text-align':item?.align,
                                                      'width':'100%'
                                                    }">{{item?.label}} </p>
                                                  <div class="d-flex flex-wrap" style="gap: 14px;">
                                                      <div style="
                                                  display: flex;
                                                  align-items: center;
                                                  " *ngFor="let check of item?.custom_value?.value?.length?item?.custom_value?.value:item?.value;">
                                                          <input type="checkbox" [checked]="check?.selected"
                                                              id="{{check?.name}}" name="{{check?.name}}"
                                                              value="{{check?.name}}" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.font_size + 'px',
                                                                  'font-family': item?.fontFamily,
                                                                
                                                                  'color': item?.color,
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'border-color':item?.Bdr_Dvdr
                                                                }">
                                                          <label class="lebel_res" for="{{check?.name}}" style="margin: 0px 7px 0px 6px;" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'margin-left':'10px',
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'margin':' 0 !important',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }"> {{check?.name}} </label>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div *ngIf="item?.name==='Radio button'" class="d-flex flex-column"
                                              [ngStyle]="{
                                                  'justify-content': item?.align,
                                                  'font-size': item?.font_size + 'px',
                                                  'color': '#333',
                                                  'font-family': item?.fontFamily,
                                                  'font-weight': item?.font_Weight,
                                                  'color': item?.color,
                                                  'padding-bottom': item?.padding + 'px',
                                                      'padding-top': item?.paddingT + 'px',
                                                      'padding-right': item?.paddingR + 'px',
                                                      'padding-left': item?.paddingL + 'px',
                                                  'text-decoration': item?.textDecoration,
                                                  'font-style': item?.font_italic
                                                }">
                                                  <p class="m-0 lebel_res" [ngStyle]="{
                                                      'justify-content': item?.align,
                                                      'font-size': item?.font_size + 'px',
                                                      'color': '#333',
                                                      'font-family': item?.fontFamily,
                                                      'font-weight': item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'color': item?.color,
                                                      'text-decoration': item?.textDecoration,
                                                      'font-style': item?.font_italic,
                                                      'text-align':item?.align,
                                                      'width':'100%'
                                                    }">{{item?.label}} </p>
                                                  <div class="d-flex flex-wrap" style="gap: 14px;">
                                                      <div style="
                                                  display: flex;
                                                  align-items: center;
                                                  " *ngFor="let radio of item?.custom_value?.value?.length?item?.custom_value?.value:item?.value;">
                                                          <input type="radio" [checked]="radio?.name" [(ngModel)]="item.selectedRadio"
                                                              id="{{radio?.name}}" name="radio_button"
                                                              value="{{radio?.name}}" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.font_size + 'px',
                                                                  'color': '#333',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                 
                                                                  'color': item?.color,
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'border-color':item?.Bdr_Dvdr
                                                                }">
                                                          <label class="lebel_res" for="{{radio?.name}}" style="margin: 0px 7px 0px 6px;" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'margin':' 0 !important',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }"> {{radio?.name}} </label>
                                                      </div>
                                                  </div>
                                              </div>
          
          
          
                                              <div *ngIf="item?.quote_currency =='Quote Currency'" class="d-flex" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                  'margin-bottom':item?.mrbottum+'px',
                                                  'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'padding-bottom':item?.padding+'px',
                                                      'padding-top':item?.paddingT+'px',
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px',
                                                      'color':item?.color,
                                                      'margin-top':item?.mrtop+'px',
                                                      'margin-right':item?.mrright+'px',
                                                      'margin-left':item?.mrleft+'px',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                              }">
                                                      <p readonly  [ngStyle]="{
                                                          'font-size':item?.font_size+'px',
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'padding-bottom':item?.padding+'px',
                                                      'padding-top':item?.paddingT+'px',
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px',
                                                      'margin-top':item?.mrtop+'px',
                                                      'margin-right':item?.mrright+'px',
                                                      'margin-left':item?.mrleft+'px',
                                                      'margin':' 0 !important',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                  }">{{item?.quote_currency}}
                                                  <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span>
                                                                                                </p>
                                                  </div>
          
          
          
          
          
                                              <div *ngIf="item?.sub_total =='Subtotal' && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                  
                                                  'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                     
                                                      'padding-top':item?.paddingT+'px',
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px',
                                                     
                                                      'color':item?.color,
                                                     
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                              }">
                                                      <p readonly  [ngStyle]="{
                                                          'font-size':item?.font_size+'px',
                                                          
                                                          'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                   
                                                      'margin':' 0 !important',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                  }">{{item?.sub_total}}
                                                  <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{item?.subTotalAmount?item?.subTotalAmount:item?.value }}</span>
                                                                                                </p>
                                                  </div>
          
          
          
          
                                               <div *ngIf="item?.discount =='Discount' && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                  
                                                  'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                     
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px',
                                                      'color':item?.color,
                                                      
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                              }">
                                                      <p readonly class="txt-res" [ngStyle]="{
                                                          'font-size':item?.font_size+'px',
                                                          'margin':' 0 !important',
                                                          'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                    
                                                     
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                  }">{{item?.discount}}
                                                  <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{item?.discountAmount?item?.discountAmount: item?.value }}</span>
                                                                                                </p>
                                                  </div>
                                                  <div *ngIf="item?.name =='Discount' && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'margin-bottom':item?.mrbottum+'px',
                                                      'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'padding-bottom':item?.padding+'px',
                                                          'padding-top':item?.paddingT+'px',
                                                          'padding-right':item?.paddingR+'px',
                                                          'padding-left':item?.paddingL+'px',
                                                          'color':item?.color,
                                                          'margin-top':item?.mrtop+'px',
                                                          'margin-right':item?.mrright+'px',
                                                          'margin-left':item?.mrleft+'px',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                  }">
                                                          <p readonly class="txt-res" [ngStyle]="{
                                                              'font-size':item?.font_size+'px',
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'padding-bottom':item?.padding+'px',
                                                          'padding-top':item?.paddingT+'px',
                                                          'padding-right':item?.paddingR+'px',
                                                          'padding-left':item?.paddingL+'px',
                                                          'margin-top':item?.mrtop+'px',
                                                          'margin-right':item?.mrright+'px',
                                                          'margin-left':item?.mrleft+'px',
                                                          'margin':' 0 !important',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                      }">{{item?.discount}}
                                                      <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.value }}</span>
                                                                                                    </p>
                                                      </div>
          
                                                      <div *ngIf="item?.additional_cost =='Additional Cost'" class="d-flex" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-size':item?.font_size+'px',
                                                                  'color': '#333',
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'color':item?.color,
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'margin-bottom':'20px',
                                                              'margin':' 0 !important',
                                                              'margin-top':'20px'
                                                      }">
                                                              <p readonly  [ngStyle]="{
                                                                  'color':'#1b8ef8',
                                                                  'justify-content':'center',
                                                                  'font-size':item?.font_size+'px',
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':'bolder',
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic
                                                          }">{{ item?.additional_cost}}
                                                          <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                              <div *ngFor="let coun of item?.custom_value?.value" style="text-align: center;">
                                                                <span style="font-weight: normal;color: black;" [value]="coun?.name" [selected]="coun?.selected">{{ coun?.name }}
                                                              </span>
                                                              <span style="color:#1b8ef8;font-weight: normal;" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.value }}</span>
          
                                                              </div>
                                                            </ng-container>
                                                          
                                               </p>
                                               <br>
                                                          </div>
          
          
                                                          <div *ngIf="item?.additional_fee =='Additional Fee'" class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'color':item?.color,
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'margin-bottom':'20px',
                                                                  'margin-top':'20px'
                                                          }">
                                                                  <p readonly  [ngStyle]="{
                                                                      'justify-content':'center',
                                                                      'font-size':item?.font_size+'px',
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':'bolder',
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'margin':' 0 !important',
                                                                  'font-style':item?.font_italic
                                                              }">{{ item?.additional_fee}}
                                                              <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                                  <div *ngFor="let coun of item?.custom_value?.value" style="text-align: center;">
                                                                    <span style="font-weight: normal" [value]="coun?.name" [selected]="coun?.selected">{{ coun?.name }}
                                                                  </span>
                                                                  <span style="color:#1b8ef8;font-weight: normal;" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ coun?.name?'0':'' }}</span>
          
                                                                  </div>
                                                                </ng-container>
                                                              
                                                   </p>
                                                   <br>
                                                              </div>
          
          
                                               
          
          
          
                                               <div *ngIf="item?.taxes =='Taxes'  && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                
                                                  'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px',
                                                      'color':item?.color,
                                                      
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                              }">
                                                      <p readonly class="txt-res" [ngStyle]="{
                                                          'font-size':item?.font_size+'px',
                                                         
                                                          'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                  }">{{item?.taxes}}
                                                  <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{item?.taxAmount?item?.taxAmount:item?.value }}</span>
                                                                                                </p>
                                                  </div>
          
                                                  <div *ngIf="item?.name =='Tax Exempt indicator' " class="d-flex" [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'margin-bottom':item?.mrbottum+'px',
                                                      'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'padding-bottom':item?.padding+'px',
                                                          'padding-top':item?.paddingT+'px',
                                                          'padding-right':item?.paddingR+'px',
                                                          'padding-left':item?.paddingL+'px',
                                                          'color':item?.color,
                                                          'margin-top':item?.mrtop+'px',
                                                          'margin-right':item?.mrright+'px',
                                                          'margin-left':item?.mrleft+'px',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                  }">
                                                          <p readonly class="txt-res" [ngStyle]="{
                                                              'font-size':item?.font_size+'px',
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'padding-bottom':item?.padding+'px',
                                                          'padding-top':item?.paddingT+'px',
                                                          'padding-right':item?.paddingR+'px',
                                                          'padding-left':item?.paddingL+'px',
                                                          'margin-top':item?.mrtop+'px',
                                                          'margin-right':item?.mrright+'px',
                                                          'margin-left':item?.mrleft+'px',
                                                          'margin':' 0 !important',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                      }">{{item?.taxes}}
                                                      <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.value }}</span>
                                                                                                    </p>
                                                      </div>
          
                                               <div *ngIf=" item?.name =='Total'" class="d-flex txt-res" [ngStyle]="{
                                                  'justify-content':item?.align,
                                                  'font-size':item?.font_size+'px',
                                                          'color': '#333',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'color':item?.color,
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic,
                                                      'padding-bottom':item?.padding+'px',
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px'
                                                      
                                              }">
                                                      <p readonly class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                          'font-size':item?.font_size+'px',
                                                          'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'margin':' 0 !important',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                                                  }">
                                                  <span [ngStyle]="{
                                                      'justify-content': item?.align
                                                      
                                                    }" *ngIf="item?.total =='Total'">{{item?.total}}&nbsp;</span>
                                                  <!-- <span [ngStyle]="{
                                                      'justify-content': item?.align
                                                      
                                                    }" *ngIf="item.name == 'Total' && (item?.total !=='Total' && item?.discount !=='Discount' && item?.due_now !=='Due Now' && item?.taxes !=='Taxes' && item?.additional_cost !=='Add Additional Cost' && item?.sub_total !=='Subtotal' && item?.quote_currency !=='Quote Currency')">please select values into properties</span> -->
                                                  <span *ngIf="item.name == 'Total' && (item?.total =='Total')" [ngStyle]="{
                                                      'justify-content': item?.align
                                                     
                                                    }" style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.totalAmount?item?.totalAmount:item?.value }}</span>
                                                                                                </p>
                                                  </div>
          
          
          
                                                      
          
          
          <!-- 
                                                  <div *ngIf="due_now =='Due Now'" class="d-flex" [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'margin-bottom':item?.mrbottum+'px',
                                                      'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'padding-bottom':item?.padding+'px',
                                                          'padding-top':item?.paddingT+'px',
                                                          'padding-right':item?.paddingR+'px',
                                                          'padding-left':item?.paddingL+'px',
                                                          'color':item?.color,
                                                          'margin-top':item?.mrtop+'px',
                                                          'margin-right':item?.mrright+'px',
                                                          'margin-left':item?.mrleft+'px',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                  }">
                                                          <p readonly  [ngStyle]="{
                                                              'font-size':item?.font_size+'px',
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'padding-bottom':item?.padding+'px',
                                                          'padding-top':item?.paddingT+'px',
                                                          'padding-right':item?.paddingR+'px',
                                                          'padding-left':item?.paddingL+'px',
                                                          'margin-top':item?.mrtop+'px',
                                                          'margin-right':item?.mrright+'px',
                                                          'margin-left':item?.mrleft+'px',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                      }">{{due_now}}
                                                      <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span>
                                                       <span *ngIf="!item?.tag">{{ item?.tag }}</span> -->
                                                    <!-- </p>
                                                      </div> -->
          
          
          
                                                  <div *ngIf="item?.name =='Due Now'" class="d-flex" [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                          'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'color':item?.color,
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                  }">
                                                          <p readonly  style="margin: 0 !important " [ngStyle]="{
                                                              'font-size':item?.font_size+'px',
                                                              'font-family':item?.fontFamily,
                                                          'font-weight':item?.font_Weight,
                                                          'margin':' 0 !important',
                                                          'text-decoration':item?.textDecoration,
                                                          'font-style':item?.font_italic
                                                      }">{{item?.label}}
                                                      <span *ngIf="!item?.user_currency" [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'padding-bottom': item?.padding + 'px',
                                                          'padding-top': item?.paddingT + 'px',
                                                          'padding-right': item?.paddingR + 'px',
                                                          'padding-left': item?.paddingL + 'px'
                                                        }" style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{0 + crSymbol}}</span>
                                                      <span  *ngIf="item?.user_currency" [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'padding-bottom': item?.padding + 'px',
                                                          'padding-top': item?.paddingT + 'px',
                                                          'padding-right': item?.paddingR + 'px',
                                                          'padding-left': item?.paddingL + 'px'
                                                        }" style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{0 + item?.currency_symbol}}</span>
          
                                                                                                        </p>
                                                      </div>
          
          
                                                      <div *ngIf="item?.name =='Additional Cost'" class="d-flex" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'color':item?.color,
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic
                                                      }">
                                                              <p readonly  [ngStyle]="{
                                                                  'font-size':item?.font_size+'px',
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style':item?.font_italic
                                                          }">{{item?.label}}
                                                          <span style="color:#1b8ef8" matTooltipClass="custom-tooltip" [matTooltip]="'This Value Will Dynamically change.'">{{0}}</span>
                                                                                                                </p>
                                                          </div>
          
          
          
          
          
          
          
          
          
                                              <div *ngIf="item?.name==='Attachment'" class="d-flex flex-column" [ngStyle]="{
                                              'align-items':item?.align,
                                              'margin-bottom':item?.mrbottum+'px'
                                              }">
                                                  <p class="m-0 txt-res" [ngStyle]="{
                                              'width': '80%',
                                              'justify-content':item?.align,
                                              'margin-bottom':item?.mrbottum+'px',
                                              'font-size':item?.font_size+'px',
                                                  'font-family':item?.fontFamily,
                                                  'font-weight':item?.font_Weight,
                                                  'padding-bottom':item?.padding+'px',
                                                  'padding-top':item?.paddingT+'px',
                                                  'padding-right':item?.paddingR+'px',
                                                  'padding-left':item?.paddingL+'px',
                                                  'color':item?.color,
                                                  'margin-top':item?.mrtop+'px',
                                                  'margin-right':item?.mrright+'px',
                                                  'margin-left':item?.mrleft+'px',
                                                  'text-decoration':item?.textDecoration,
                                                  'margin':' 0 !important',
                                                  'font-style':item?.font_italic
                                          }">{{item?.label}} 
                                                  </p>
                                                  <label for="attachment" class="attachment-label" [ngStyle]="{
                                              'display': 'flex',
                                              'border-radius': '5px',
                                              'border': '1px dashed #C1C1C1',
                                              'padding': bodying_Size+'px',
                                              'background': '#F4F4F4',
                                              'margin-top': '10px',
                                              'justify-content': 'center',
                                              'margin':' 0 !important',
                                              'font-family':selectedFont }">
                                                      <img src="assets/svg-icons/attachment_icon.svg"
                                                          alt="attachment_icon">
                                                      <div class="lebel_res" [ngStyle]="{
                                                  'display': 'flex',
                                                  'align-items': 'center',
                                                  'flex-direction': 'column',
                                                  'margin-left': '8px',
                                                  'font-family':selectedFont,
                                                  'font-size':bodying_Size+'px' }">
                                                          <p class="m-0"><b>Click to upload</b> or drag and drop</p>
                                                          <p class="m-0">
                                                              <span *ngFor="let file of item?.value">
                                                                  <span *ngIf="file?.value">{{file?.name}},</span>
                                                              </span>(MAX: 10 MB)
                                                          </p>
                                                      </div>
                                                  </label>
                                                  <input type="file" class="form-control" style="display: none;"
                                                      id="attachment" name="attachment">
                                                      
                                              </div>
          <!-- other element -->
          <div *ngIf=" item?.name === 'Member Name'  || item?.name === 'Country'  || item?.name === 'Business Name' || item?.name ==='TAX ID' " class="d-flex" 
          [ngStyle]="{
          'justify-content': item?.align
          
          
          }" 
          >
          <p readonly class="txt-res" style="margin: 0 !important" [ngStyle]="{
            'font-size': item?.title_font_size + 'px',
              'color': item?.title_color,
              'font-family':item?.fontFamily,
          'font-weight':item?.font_Weight,
          'text-decoration':item?.textDecoration,
          
          'font-style':item?.font_italic,
          'margin':' 0 !important',
          'text-align':item?.align
          
          }">{{item?.value}}&nbsp;</p>
          <span  [ngStyle]="{
           'text-align':item?.align,
          'font-size': item?.font_size + 'px',
          'color': item?.color
          
          }">{{item?.tag}}</span>
          
          </div>
          
          
          
          <div *ngIf="item?.name === 'Deal Description'" class="d-flex flex-column" [ngStyle]="{
          'justify-content': item?.align,
          'font-size': item?.font_size + 'px',
          'color': '#333',
          'font-family': item?.fontFamily,
          'font-weight': item?.font_Weight,
          'padding-bottom': item?.padding + 'px',
          'padding-top': item?.paddingT + 'px',
          'padding-right': item?.paddingR + 'px',
          'padding-left': item?.paddingL + 'px',
          
          'text-decoration': item?.textDecoration,
          'font-style': item?.font_italic
          }">
          
          <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
           <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
             <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                'justify-content': item?.align,
                 'font-size': item?.title_font_size + 'px',
                 'font-family': item?.fontFamily,
                 'font-weight': item?.font_Weight,
                 'margin':' 0 !important',
                 'color': item?.title_color,
                 'text-decoration': item?.textDecoration,
                 'font-style': item?.font_italic,
                 'text-align':item?.align
               }">
               {{ item?.value }} </p>
               <span [ngStyle]="{
                     'justify-content': item?.align,
                     'margin-top':'0',
                
                'text-align':item?.align
               }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
             
           </ng-container>
           <ng-template #noPhoneNumbers>
              <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                  'justify-content': item?.align,
                  'font-size': item?.title_font_size + 'px',
                  'color': item?.title_color,
                  'font-family': item?.fontFamily,
                  'font-weight': item?.font_Weight,
                  'margin':' 0 !important',
                  'text-decoration': item?.textDecoration,
                  'font-style': item?.font_italic,
                  'text-align':item?.align
                }"> 
                  {{ item?.value }}</p>
                  <span [ngStyle]="{
                    'justify-content': item?.align,
                  
                    'text-align':item?.align,
                    'color': item?.color
          
                  }"  >{{ item?.tag }}</span>
                  
              
          </ng-template>
          </ng-container>
          
          </div>
          
          <div *ngIf="item?.name === 'Deal Value'" class="d-flex flex-column" [ngStyle]="{
          'justify-content': item?.align,
          'font-size': item?.font_size + 'px',
          'color': '#333',
          'font-family': item?.fontFamily,
          'font-weight': item?.font_Weight,
          'padding-bottom': item?.padding + 'px',
          'padding-top': item?.paddingT + 'px',
          'padding-right': item?.paddingR + 'px',
          'padding-left': item?.paddingL + 'px',
          
          'text-decoration': item?.textDecoration,
          'font-style': item?.font_italic
          }">
          
          <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
           <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
             <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                'justify-content': item?.align,
                 'font-size': item?.title_font_size + 'px',
                 'font-family': item?.fontFamily,
                 'font-weight': item?.font_Weight,
                 'margin':' 0 !important',
                 'color': item?.title_color,
                 'text-decoration': item?.textDecoration,
                 'font-style': item?.font_italic,
                 'text-align':item?.align
               }">
               {{ item?.value }} </p>
               <span [ngStyle]="{
                     'justify-content': item?.align,
                     'margin-top':'0',
                
                'text-align':item?.align
               }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
             
           </ng-container>
           <ng-template #noPhoneNumbers>
              <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                  'justify-content': item?.align,
                  'font-size': item?.title_font_size + 'px',
                  'color': item?.title_color,
                  'font-family': item?.fontFamily,
                  'font-weight': item?.font_Weight,
                  'margin':' 0 !important',
                  'text-decoration': item?.textDecoration,
                  'font-style': item?.font_italic,
                  'text-align':item?.align
                }"> 
                  {{ item?.value }}</p>
                  <span [ngStyle]="{
                    'justify-content': item?.align,
                  
                    'text-align':item?.align,
                    'color': item?.color
          
                  }"  >{{ item?.tag }}</span>
                  
              
          </ng-template>
          </ng-container>
          
          </div>
          
          <div *ngIf="item?.name === 'Forecast Date'" class="d-flex flex-column" [ngStyle]="{
          'justify-content': item?.align,
          'font-size': item?.font_size + 'px',
          'color': '#333',
          'font-family': item?.fontFamily,
          'font-weight': item?.font_Weight,
          'padding-bottom': item?.padding + 'px',
          'padding-top': item?.paddingT + 'px',
          'padding-right': item?.paddingR + 'px',
          'padding-left': item?.paddingL + 'px',
          
          'text-decoration': item?.textDecoration,
          'font-style': item?.font_italic
          }">
          
          <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
           <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
             <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                'justify-content': item?.align,
                 'font-size': item?.title_font_size + 'px',
                 'font-family': item?.fontFamily,
                 'font-weight': item?.font_Weight,
                 'margin':' 0 !important',
                 'color': item?.title_color,
                 'text-decoration': item?.textDecoration,
                 'font-style': item?.font_italic,
                 'text-align':item?.align
               }">
               {{ item?.value }} </p>
               <span [ngStyle]="{
                     'justify-content': item?.align,
                     'margin-top':'0',
                
                'text-align':item?.align
               }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
             
           </ng-container>
           <ng-template #noPhoneNumbers>
              <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                  'justify-content': item?.align,
                  'font-size': item?.title_font_size + 'px',
                  'color': item?.title_color,
                  'font-family': item?.fontFamily,
                  'font-weight': item?.font_Weight,
                  'margin':' 0 !important',
                  'text-decoration': item?.textDecoration,
                  'font-style': item?.font_italic,
                  'text-align':item?.align
                }"> 
                  {{ item?.value }}</p>
                  <span [ngStyle]="{
                    'justify-content': item?.align,
                  
                    'text-align':item?.align,
                    'color': item?.color
          
                  }"  >{{ item?.tag }}</span>
                  
              
          </ng-template>
          </ng-container>
          
          </div>
          
          <div *ngIf="item?.name === 'Deal Title'" class="d-flex flex-column" [ngStyle]="{
          'justify-content': item?.align,
          'font-size': item?.font_size + 'px',
          'color': '#333',
          'font-family': item?.fontFamily,
          'font-weight': item?.font_Weight,
          'padding-bottom': item?.padding + 'px',
          'padding-top': item?.paddingT + 'px',
          'padding-right': item?.paddingR + 'px',
          'padding-left': item?.paddingL + 'px',
          
          'text-decoration': item?.textDecoration,
          'font-style': item?.font_italic
          }">
          
          <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
           <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
             <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                'justify-content': item?.align,
                 'font-size': item?.title_font_size + 'px',
                 'font-family': item?.fontFamily,
                 'font-weight': item?.font_Weight,
                 'margin':' 0 !important',
                 'color': item?.title_color,
                 'text-decoration': item?.textDecoration,
                 'font-style': item?.font_italic,
                 'text-align':item?.align
               }">
               {{ item?.value }} </p>
               <span [ngStyle]="{
                     'justify-content': item?.align,
                     'margin-top':'0',
                
                'text-align':item?.align
               }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
             
           </ng-container>
           <ng-template #noPhoneNumbers>
              <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                  'justify-content': item?.align,
                  'font-size': item?.title_font_size + 'px',
                  'color': item?.title_color,
                  'font-family': item?.fontFamily,
                  'font-weight': item?.font_Weight,
                  'margin':' 0 !important',
                  'text-decoration': item?.textDecoration,
                  'font-style': item?.font_italic,
                  'text-align':item?.align
                }"> 
                  {{ item?.value }}</p>
                  <span [ngStyle]="{
                    'justify-content': item?.align,
                  
                    'text-align':item?.align,
                    'color': item?.color
          
                  }"  >{{ item?.tag }}</span>
                  
              
          </ng-template>
          </ng-container>
          
          </div>
          
          <div *ngIf="item?.name === 'Deal Stage'" class="d-flex flex-column" [ngStyle]="{
          'justify-content': item?.align,
          'font-size': item?.font_size + 'px',
          'color': '#333',
          'font-family': item?.fontFamily,
          'font-weight': item?.font_Weight,
          'padding-bottom': item?.padding + 'px',
          'padding-top': item?.paddingT + 'px',
          'padding-right': item?.paddingR + 'px',
          'padding-left': item?.paddingL + 'px',
          
          'text-decoration': item?.textDecoration,
          'font-style': item?.font_italic
          }">
          
          <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
           <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
             <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                'justify-content': item?.align,
                 'font-size': item?.title_font_size + 'px',
                 'font-family': item?.fontFamily,
                 'font-weight': item?.font_Weight,
                 'margin':' 0 !important',
                 'color': item?.title_color,
                 'text-decoration': item?.textDecoration,
                 'font-style': item?.font_italic,
                 'text-align':item?.align
               }">
               {{ item?.value }} </p>
               <span [ngStyle]="{
                     'justify-content': item?.align,
                     'margin-top':'0',
                
                'text-align':item?.align
               }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
             
           </ng-container>
           <ng-template #noPhoneNumbers>
              <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                  'justify-content': item?.align,
                  'font-size': item?.title_font_size + 'px',
                  'color': item?.title_color,
                  'font-family': item?.fontFamily,
                  'font-weight': item?.font_Weight,
                  'margin':' 0 !important',
                  'text-decoration': item?.textDecoration,
                  'font-style': item?.font_italic,
                  'text-align':item?.align
                }"> 
                  {{ item?.value }}</p>
                  <span [ngStyle]="{
                    'justify-content': item?.align,
                  
                    'text-align':item?.align,
                    'color': item?.color
          
                  }"  >{{ item?.tag }}</span>
                  
              
          </ng-template>
          </ng-container>
          
          </div>
          
          <!-- other element -->
          
          
          
          
          
          
          
          
          
          
          
          
          
          
                                              <!-- <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                              <div class="d-flex flex-column my-1">
                                                  <label for="name">Name</label>
                                                  <input type="text" id="name" name="name" placeholder="Your name"
                                                      formControlName="name">
                                              </div>
                                          </form> -->
                                          </div>
          
          
          
          
          
          
          
          
          
          
                                      </div>
          
                                      <!-- <img src="assets/svg-icons/delete_icon.svg" (click)="removeField(i,j)"
                                          class="delete-btn" type="image/svg+xml"> -->
                                  </div>
                                  <div *cdkDragPreview class="d-flex input-field-preview">
                                      <span class="txt">{{item?.name}}</span>
                                  </div>
                              </div>
                          </ng-container>
                          </div>
                          <!-- <ng-container *ngIf="!dropPage?.items.length">
                              <div class="not-eny-item">
                                  <object data="assets/svg-icons/drop_icon.svg" type="image/svg+xml"></object>
                                  <p class="title mb-0">Drag your first element here from the elements list at the left.
                                  </p>
                              </div>
                          </ng-container> -->
                      </div>
                  </div>
                  <!-- <ng-container *ngIf="(active_Tabs==='submit_options')&&(droppedItem!==null)">
                      <div class="success-submit">
                          <ng-container *ngIf="false">
                              <img src="assets/svg-icons/success_icon.svg" alt="success icon">
                              <h1>Your message is sent successfully!</h1>
                              <p>Thank you for reaching out. We will get <br> in touch within 24 hours.</p>
                          </ng-container>
                          <ng-container *ngIf="true">
                              <h1>Form Submitted</h1>
                              <p>Your client will not see this message and be redirected <br>
                                  to <b>https://www.osmoscloud.com</b></p>
                          </ng-container>
                      </div>
                  </ng-container> -->
              </div>
              </div>
          <!-- <embedform2 [embedFromData]="getFormData(dailogData)"></embedform2> -->
        </div>
      </div>
    </ng-container>
    <div class="bg_grey" *ngIf="dailogData?.type=='preview'" >
    <ng-container >
      <div style="display: flex; flex-direction: column; height: 600px;">
        <div class="model-header flex justify-end">
          <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
            class="color-4 fs-2 far fa-times-circle m-3"></i>
        </div>
        <div class="preview flex justify-center" style="flex: 1;">
          <div [innerHtml]="getSafeHtml(dailogData.data)"></div>
          <!-- <embedform2 [embedFromData]="getFormData(dailogData)"></embedform2> -->
        </div>
      </div>
    </ng-container>
    </div>
    <ng-container *ngIf="dailogData?.type =='User Confirm'">
      <div *ngIf="!paymentPage">
          <div class="model-header position-relative">
              <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
                  class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 mr-3 mb-5"></i>
          </div> 
          <div class="d-flex align-items-center justify-start px-5 pt-5 pb-3 fw-600 fs-18 "><input style="width:19px;height:19px; outline:1px auto red !important; accent-color: red" type="checkbox" class="example-margin" [formControl]="agreementChecked" />
            <div class="ml-2">
              *{{"QUOTE_TEMPLATE.I have read and understand this agreement" | translate}}
            </div>
          </div>
          <span class="px-5 d-block pb-3">{{"QUOTE_TEMPLATE.I agree to the terms of this agreement and I agree that my typed name below can be use as a" | translate}}
            {{"QUOTE_TEMPLATE.digital representation of my signature to that fact" | translate}} </span>
          <div class="px-5 pb-3">
              <mat-tab-group #tabGroup style="border: 1px solid #E3ECF1;" [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="tabChanged($event)" >
                    <mat-tab >
                      <ng-template mat-tab-label>
                        {{'COMMON.Type' | translate}}
                      </ng-template>
                      <div>
                          <mat-form-field id="itemInput" class="example-full-width" appearance="fill" style="width: 65%;">
                              <!-- <mat-label>Assignee</mat-label> -->
                              <input type="text" [placeholder]="'QUOTE_TEMPLATE.Type your full name' | translate" matInput required [formControl]="userName">
                            </mat-form-field>
                      </div>
                    </mat-tab>
                    <mat-tab >
                      <ng-template mat-tab-label>
                        {{'QUOTE_TEMPLATE.Signature' | translate }}
                      </ng-template>
                      <div class="ele p-3" >
                          <app-signature-pad-create  #signaturePad  (signatureSaved)="handleSignatureSaved($event)" [type]="'invoice'"></app-signature-pad-create>
                        </div>
                      
                    </mat-tab>
                </mat-tab-group>
                <div class="d-flex justify-center py-2 text-8 fw-00 f-poppins" *ngIf="!agreementChecked.value">
                  {{"QUOTE_TEMPLATE.Confirm that you have read and accept the agreement" | translate}}
              </div>
          </div>
          <div class="d-flex justify-end gap-3 px-5">
            <button  class="btn btn-red btn-p-2 standard-btn" *ngIf="selectedTabIndex === 1" [ngClass]="{ 'disabled-button': isSignatureEmpty}"  [disabled]="isSignatureEmpty"  (click)="clearSignature()">{{"QUOTE_TEMPLATE.Clear Signature" | translate}}</button>
            <button class="btn btn-p-2 standard-btn"  [disabled]="savedSignatureDataUrl == null && userName.hasError('required') || !agreementChecked.value" (click)="paymentView()">{{"QUOTE_TEMPLATE.Accept" | translate}}</button>
          </div>
      </div>
      <div *ngIf="paymentPage">
          <div class="model-header position-relative">
              <i (click)="closeDialogThankYou()" type="button" data-bs-dismiss="modal" aria-label="Close"
                  class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 mr-3 mb-5"></i>
          </div> 


          <!-- Thankyou Div -->

          <div class="flex flex-col justify-center items-center w-100 ">
          <div style="height:6rem; width:5rem; 
          border-radius: 50%; margin-top: 3rem;">
          <img src="assets/img/home/success 1.png" alt="success-logo"  style="font-size: 7rem;">
          </div>
          
          <div style="height:2rem; margin-top: 1rem;">
          <span class="f-poppins thank-you text-8" style="font-size:2rem; "> Success! </span>
         
        </div>
        <span class="f-poppins fw-500 txt_3 fs-15 mt-3">Your request has been recieved. Thank you!</span>
        <button class="btn btn-p-2 standard-btn mt-3" (click)="closeDialogThankYou()">Done</button>
          </div>

          <!-- Amount to Pay -->
<!-- <ng-container *ngIf="paymentPage2">
            <div class="px-3 pt-3">
          <div class="pb-3 px-3 d-flex gap-3">
              <span class="fs-18 fw-600 f-poppins text-8">{{"QUOTE_TEMPLATE.Amount to pay" | translate}}</span>
              <span class="fs-18 fw-500 f-poppins text-3">{{dailogData?.data}}</span>
          </div>
          <div class="col-12 mb-3 px-5 py-3 div_bg shadow-xl">
              <div class="pb-3 text-3 fs-16 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Payment Method" | translate}}</div>
              <div class="d-flex gap-3">
                  <button class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center"
                      [ngClass]="paymentMethod == 'card' ? 'btn_active' : 'btn_notAcive'"
                      (click)="paymentMehtod('card')">
                      <mat-icon [ngStyle]="{'color': paymentMethod == 'debit' ? '#03C0FC' : ''}">{{"QUOTE_TEMPLATE.credit_card" | translate}}</mat-icon>
                      <div *ngIf="paymentMethod === 'card'" class="tick-mark d-flex justify-center align-items-center">
                          <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon></div>
                      {{"QUOTE_TEMPLATE.Card" | translate}}
                  </button>
                  <button class="py-2 text-3 f-poppins relative fw-400 fs-16  px-3 d-flex align-items-center"
                      [ngClass]="paymentMethod == 'bank' ? 'btn_active' : 'btn_notAcive'" (click)="paymentMehtod('bank')">
                      <mat-icon [ngStyle]="{'color': paymentMethod == 'bank' ? '#03C0FC' : ''}">account_balance</mat-icon>
                      <div *ngIf="paymentMethod === 'bank'" class="tick-mark d-flex justify-center align-items-center">
                          <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon></div>
                      {{"QUOTE_TEMPLATE.Bank Transfer" | translate}}
                  </button>

                  ye commit tha button
                  <button class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center"
                      [ngClass]="paymentMethod == 'credit' ? 'btn_active' : 'btn_notAcive'"
                      (click)="paymentMehtod('credit')">
                      <mat-icon [ngStyle]="{'color': paymentMethod == 'credit' ? '#03C0FC' : ''}">credit_card</mat-icon>
                      <div *ngIf="paymentMethod === 'credit'" class="tick-mark d-flex justify-center align-items-center">
                          <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon></div>
                      Credit Card
                  </button>
                  <button class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center"
                      [ngClass]="paymentMethod == 'cash' ? 'btn_active' : 'btn_notAcive'"
                      (click)="paymentMehtod('cash')">
                      <mat-icon [ngStyle]="{'color': paymentMethod == 'credit' ? '#03C0FC' : ''}">local_atm</mat-icon>
                      <div *ngIf="paymentMethod === 'cash'" class="tick-mark d-flex justify-center align-items-center">
                          <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon></div>
                      {{"QUOTE_TEMPLATE.Cash" | translate}}
                  </button>
                  <button class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center"
                      [ngClass]="paymentMethod == 'cheque' ? 'btn_active' : 'btn_notAcive'" (mouseover)="changeImage('mouseover')" 
                      (mouseout)="changeImage('mouseout')"
                      (click)="paymentMehtod('cheque')">
                      
                      ye commit tha icon
                      <mat-icon [ngStyle]="{'color': paymentMethod == 'credit' ? '#03C0FC' : ''}">credit_card</mat-icon>

                      <img class="me-1" style="width:24px;height:24px;" *ngIf="paymentMethod == 'cheque'" src="../../../assets/icons/cheque.png" alt="" />
                      <img class="me-1" style="width:24px;height:24px;" *ngIf="paymentMethod !== 'cheque'" [src]="chequeImage" alt="" />
                      <div *ngIf="paymentMethod === 'cheque'" class="tick-mark d-flex justify-center align-items-center">
                          <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon></div>
                      {{"QUOTE_TEMPLATE.Cheque" | translate}}
                  </button>
              </div>
          </div>
          <div class="col-12 px-5 py-3 div_bg shadow-xl">
  
              <div *ngIf="paymentMethod == 'card'" class="pb-2 text-3 fs-16 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Card" | translate}}</div>
              <div class="d-flex gap-3 pb-3" *ngIf="paymentMethod == 'card'">
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" viewBox="0 0 512 166" fill="none">
                          <path
                              d="M253.509 2.92064L219.303 162.84H177.929L212.138 2.92064H253.509ZM427.568 106.182L449.343 46.1295L461.874 106.182H427.568ZM473.744 162.84H512L478.578 2.92064H443.29C435.337 2.92064 428.632 7.53179 425.665 14.6428L363.587 162.84H407.037L415.662 138.957H468.734L473.744 162.84ZM365.742 110.632C365.921 68.4264 307.397 66.0884 307.789 47.2302C307.915 41.5004 313.382 35.3966 325.331 33.8355C331.255 33.073 347.603 32.4543 366.134 40.9932L373.381 7.0678C363.429 3.46736 350.62 0 334.683 0C293.783 0 265.012 21.7249 264.782 52.8592C264.519 75.8826 285.334 88.7198 300.984 96.3882C317.119 104.226 322.525 109.251 322.446 116.254C322.335 126.983 309.58 131.734 297.704 131.914C276.892 132.238 264.828 126.289 255.214 121.807L247.704 156.866C257.387 161.297 275.227 165.153 293.7 165.351C337.183 165.351 365.612 143.881 365.742 110.632ZM194.391 2.92064L127.357 162.84H83.6302L50.64 35.2131C48.6401 27.3648 46.8957 24.4801 40.8134 21.1638C30.8645 15.7614 14.4413 10.7078 0 7.56417L0.978353 2.92064H71.3758C80.3427 2.92064 88.4104 8.8878 90.4642 19.2179L107.891 111.765L150.927 2.92064H194.391Z"
                              fill="#1434CB" />
                      </svg>
                  </span>
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg width="45" height="30" viewBox="0 0 512 317" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M325.228 33.8198H186.781V282.612H325.228V33.8198Z" fill="#FF5F00" />
                          <path
                              d="M195.571 158.225C195.549 134.264 200.978 110.613 211.448 89.061C221.919 67.5091 237.155 48.6216 256.003 33.8285C232.662 15.4816 204.629 4.07198 175.11 0.903653C145.591 -2.26468 115.776 2.93617 89.0725 15.9117C62.3692 28.8873 39.8553 49.114 24.1041 74.28C8.35298 99.446 0 128.536 0 158.225C0 187.914 8.35298 217.004 24.1041 242.17C39.8553 267.336 62.3692 287.562 89.0725 300.538C115.776 313.513 145.591 318.714 175.11 315.546C204.629 312.378 232.662 300.968 256.003 282.621C237.155 267.828 221.919 248.941 211.449 227.389C200.979 205.837 195.549 182.185 195.571 158.225Z"
                              fill="#EB001B" />
                          <path
                              d="M512 158.225C512.001 187.913 503.649 217.003 487.899 242.169C472.149 267.335 449.636 287.562 422.933 300.538C396.23 313.513 366.415 318.714 336.896 315.546C307.378 312.378 279.346 300.968 256.005 282.621C274.837 267.813 290.061 248.922 300.529 227.374C310.998 205.825 316.437 182.181 316.437 158.225C316.437 134.268 310.998 110.624 300.529 89.0756C290.061 67.5271 274.837 48.6365 256.005 33.8285C279.346 15.4816 307.378 4.07192 336.896 0.903625C366.415 -2.26467 396.23 2.93627 422.933 15.912C449.636 28.8876 472.149 49.1145 487.899 74.2805C503.649 99.4465 512.001 128.536 512 158.225Z"
                              fill="#F79E1B" />
                          <path
                              d="M496.905 256.265V251.171H498.959V250.134H493.729V251.171H495.783V256.265H496.905ZM507.06 256.265V250.124H505.457L503.613 254.348L501.768 250.124H500.164V256.265H501.296V251.632L503.026 255.626H504.199L505.929 251.622V256.265H507.06Z"
                              fill="#F79E1B" />
                      </svg>
                  </span>
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg width="45" height="30" viewBox="0 0 512 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M511.998 103.272H485.365C477.328 103.272 471.49 105.191 467.355 108.175V103.272H427.961C421.662 103.272 414.267 104.829 410.77 108.175V103.272H340.423V108.175C334.825 104.148 325.378 103.272 321.018 103.272H274.617V108.175C270.188 103.898 260.338 103.272 254.335 103.272H202.404L190.52 116.094L179.39 103.272H101.817V187.047H177.93L190.175 174.022L201.71 187.047L248.627 187.089V167.381H253.24C259.465 167.477 266.807 167.228 273.284 164.436V187.045H311.982V165.211H313.849C316.231 165.211 316.466 165.308 316.466 167.682V187.043H434.023C441.487 187.043 449.288 185.139 453.608 181.682V187.043H490.897C498.656 187.043 506.234 185.959 512 183.182C512 -275.177 511.998 295.184 511.998 103.272Z"
                              fill="#006FCF" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M24.5898 83.717L30.1339 70.3846H42.5459L48.0755 83.717H96.4542V73.524L100.773 83.7605H125.887L130.205 73.3719V83.717H250.436L250.38 61.8318H252.706C254.335 61.8879 254.811 62.0382 254.811 64.7195V83.717H316.994V78.6223C322.01 81.3018 329.811 83.717 340.077 83.717H366.237L371.836 70.3846H384.248L389.723 83.717H440.136V71.0526L447.77 83.717H488.167V0H448.187V9.88708L442.589 0H401.565V9.88708L396.424 0H341.01C331.734 0 323.581 1.29088 316.994 4.88832V0H278.754V4.88832C274.563 1.18044 268.851 0 262.501 0H122.795L113.421 21.6227L103.794 0H59.7902V9.88708L54.9561 0H17.4277L0 39.8036V83.717H24.5898Z"
                              fill="#006FCF" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M253.877 155.028C266.456 155.028 278.937 151.585 278.937 134.907C278.937 118.279 266.106 115.127 254.685 115.127H208.702L190.397 134.807L172.691 115.127H114.812V175.166H171.814L190.23 155.291L207.95 175.166H235.921V155.028H253.877ZM235.921 142.835H254.518C260.048 142.835 263.487 140.099 263.487 134.907C263.487 129.657 259.881 127.546 254.685 127.546H235.921V142.835ZM164.529 162.655H129.147V150.709H160.741V138.459H129.147V127.544H165.226L180.967 145.04L164.529 162.655ZM221.588 169.706L199.493 145.25L221.588 121.571V169.706Z"
                              fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M340.482 152.305C339.254 150.557 336.635 148.444 333.432 147.265C337.22 145.766 343.63 140.876 343.632 131.293C343.632 124.444 340.776 120.696 336.234 117.96C331.511 115.448 326.205 115.127 318.919 115.127H285.694V175.166H300.155V153.238H315.534C320.784 153.238 323.989 153.752 326.092 155.903C328.5 158.416 328.458 163.001 328.425 166.525C328.422 166.916 328.418 167.294 328.418 167.655V175.166H342.866V163.264C342.808 157.972 342.514 155.237 340.482 152.305ZM324.741 139.794C322.816 140.932 320.435 141.03 317.635 141.03H300.153V127.546H317.873C320.435 127.546 322.999 127.6 324.741 128.63C326.606 129.602 327.721 131.462 327.721 134.031C327.721 136.6 326.606 138.669 324.741 139.794Z"
                              fill="white" />
                          <path
                              d="M454.596 143.501C457.398 146.392 458.9 150.042 458.9 156.222C458.9 169.138 450.806 175.166 436.293 175.166H408.264V162.292H436.18C438.91 162.292 440.846 161.932 442.059 160.806C443.048 159.877 443.758 158.528 443.758 156.89C443.758 155.141 442.99 153.752 442.002 152.919C440.902 152.003 439.384 151.587 436.878 151.587C435.996 151.557 435.101 151.532 434.196 151.508C421.285 151.158 406.566 150.759 406.566 133.004C406.566 124.295 412.054 115.129 427.141 115.129H455.991V127.907H429.592C426.975 127.907 425.274 128.005 423.826 128.992C422.25 129.964 421.665 131.407 421.665 133.312C421.665 135.577 423.004 137.117 424.814 137.784C426.332 138.31 427.963 138.464 430.415 138.464L438.162 138.672C445.974 138.863 451.336 140.21 454.596 143.501Z"
                              fill="white" />
                          <path
                              d="M512 127.904H485.772C483.154 127.904 481.414 128.001 479.948 128.988C478.43 129.96 477.846 131.403 477.846 133.308C477.846 135.573 479.128 137.113 480.993 137.78C482.511 138.307 484.141 138.46 486.538 138.46L494.338 138.669C502.208 138.862 507.462 140.211 510.665 143.501C511.157 143.888 511.484 144.314 511.815 144.747L511.904 144.864L511.962 144.938L512 144.987V167.487C508.504 172.583 501.693 175.167 492.471 175.167H464.68V162.292H492.359C495.104 162.292 497.026 161.932 498.182 160.806C499.185 159.877 499.884 158.528 499.884 156.89C499.884 155.141 499.185 153.752 498.126 152.919C497.082 152.003 495.562 151.587 493.056 151.587C492.172 151.557 491.274 151.533 490.367 151.508C477.41 151.158 462.686 150.76 462.686 133.004C462.686 124.296 468.232 115.129 483.333 115.129H511.998L512 127.904Z"
                              fill="white" />
                          <path
                              d="M351.612 115.127H399.642V127.543H365.943V138.459H398.819V150.709H365.943V162.654L399.642 162.709V175.166H351.612V115.127Z"
                              fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M46.1524 45.9372L36.3027 22.008L26.5093 45.9372H46.1524ZM263.14 36.4093C261.163 37.606 258.824 37.6458 256.022 37.6458H238.54V24.3134H256.26C258.768 24.3134 261.384 24.4256 263.084 25.3961C264.951 26.2705 266.106 28.1317 266.106 30.7026C266.106 33.326 265.007 35.437 263.14 36.4093ZM387.856 45.9372L377.898 22.008L367.993 45.9372H387.856ZM155.378 71.8382H140.625L140.571 24.8271L119.704 71.8382H107.068L86.1468 24.7854V71.8382H56.8773L51.3478 58.4497H21.3846L15.7987 71.8382H0.168823L25.939 11.8133H47.32L71.7954 68.6445V11.8133H95.2829L114.116 52.533L131.417 11.8133H155.376V71.8382H155.378ZM214.177 71.8382H166.103V11.8133H214.177V24.3129H180.495V35.1324H213.369V47.4365H180.495V59.4237H214.177V71.8382ZM281.959 27.9792C281.959 37.5494 275.552 42.4938 271.819 43.9784C274.968 45.1734 277.657 47.2844 278.937 49.0333C280.969 52.0188 281.32 54.6857 281.32 60.0465V71.8382H266.805L266.75 64.2686C266.75 60.6567 267.097 55.4624 264.479 52.5746C262.376 50.4636 259.171 50.0056 253.99 50.0056H238.541V71.8382H224.152V11.8133H257.251C264.606 11.8133 270.025 12.007 274.677 14.6883C279.23 17.3697 281.959 21.284 281.959 27.9792ZM304.989 71.8382H290.305V11.8133H304.989V71.8382ZM475.34 71.8382H454.947L427.669 26.786V71.8382H398.361L392.761 58.4497H362.867L357.434 71.8382H340.594C333.599 71.8382 324.743 70.2957 319.727 65.1992C314.67 60.1027 312.038 53.1993 312.038 42.2838C312.038 33.3816 313.611 25.2435 319.796 18.8126C324.449 14.0221 331.734 11.8133 341.651 11.8133H355.583V24.675H341.944C336.692 24.675 333.726 25.4535 330.87 28.2308C328.417 30.7583 326.733 35.5361 326.733 41.8276C326.733 48.2584 328.015 52.8951 330.69 55.924C332.906 58.2994 336.931 59.02 340.72 59.02H347.183L367.465 11.8151H389.027L413.392 68.5902V11.8151H435.303L460.6 53.6193V11.8151H475.34V71.8382Z"
                              fill="white" />
                      </svg>
                  </span>
                  <span class="d-flex justify-center align-items-center"
                  style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" viewBox="5.322 20.344 440.334 180.144" id="stripe"><path fill="#359ad5" d="M406.487 86.49c-8.87 0-7.944 10.902-8.312 22.541h14.779c0-13.119 0-22.541-6.467-22.541zm-8.312 41.572c0 10.53 5.172 12.562 13.856 12.562 9.978 0 23.463-2.958 28.637-3.881v25.499c-4.063 1.479-16.442 4.986-28.637 4.986-21.987 0-48.78-3.51-48.78-50.438 0-41.387 21.064-51.179 43.235-51.179 22.727 0 39.17 11.64 39.17 50.809v11.642h-47.481zm-86.669-36.584c-2.03 0-6.096 1.663-8.312 2.958v47.296c1.661.557 4.618.926 5.912.926 6.098 0 9.793-5.174 9.793-26.79-.002-21.988-1.666-24.39-7.393-24.39zm4.064 75.752c-4.434 0-9.053-1.293-12.378-2.033v35.29h-35.105V67.459h28.452l5.175 6.097c5.357-4.803 12.934-7.945 20.878-7.945 14.41 0 31.408 4.064 31.408 50.254 0 50.628-25.496 51.365-38.43 51.365zM235.747 55.45c-9.791 0-17.366-7.945-17.366-17.553s7.575-17.553 17.366-17.553c9.794 0 17.369 7.945 17.369 17.553s-7.575 17.553-17.369 17.553zm-17.551 109.933V67.459h35.105v97.924h-35.105zM196.94 94.989c-5.543 0-9.793 2.218-11.641 3.326v67.068h-35.104V67.459h27.899l5.173 9.422c2.032-6.097 7.575-11.271 15.705-11.271 5.543 0 9.238 1.109 10.899 2.032v29.191c-3.51-.921-8.128-1.844-12.931-1.844zm-66.707 45.636c2.217 0 9.978-1.107 12.562-1.479v24.943c-5.356 1.292-17.182 3.141-24.941 3.141-9.978 0-28.638-1.107-28.638-28.267V89.816H76.283V67.459h12.935l4.434-22.171 30.67-7.39V67.46h18.476l-4.618 22.357h-13.857v42.865c-.002 6.649 2.03 7.943 5.91 7.943zM39.505 95.728c0 3.88 2.588 5.173 8.684 7.944l3.696 1.664c8.683 3.88 20.691 10.162 20.691 28.639 0 29.561-21.987 33.256-37.691 33.256-10.162 0-20.878-2.402-28.084-4.617V137.3c6.281 1.666 17.184 4.249 21.986 4.249 5.358 0 9.423-.737 9.423-5.542 0-3.695-2.587-5.357-8.314-7.943l-4.435-2.033c-8.313-3.881-20.139-10.53-20.139-28.453 0-26.237 20.508-31.963 37.692-31.963 12.934 0 21.433 2.955 25.866 4.619v24.573c-5.174-1.662-15.703-3.88-21.985-3.88-4.249-.002-7.39 1.105-7.39 4.801z"></path></svg>
                  </span>

                  ye span commit tha
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg width="45" height="30" viewBox="0 0 434 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M123.28 494.644L132.153 438.281L112.387 437.823H18.0022L83.5949 21.9213C83.7985 20.6658 84.4602 19.4951 85.4273 18.6637C86.3944 17.8324 87.633 17.3743 88.9224 17.3743H248.069C300.903 17.3743 337.364 28.3686 356.4 50.0688C365.325 60.2488 371.009 70.8868 373.757 82.5938C376.641 94.8775 376.692 109.554 373.876 127.453L373.672 128.76V140.229L382.597 145.285C390.113 149.272 396.085 153.836 400.666 159.062C408.301 167.766 413.238 178.828 415.325 191.943C417.48 205.432 416.767 221.482 413.238 239.653C409.166 260.556 402.583 278.761 393.693 293.658C385.515 307.384 375.098 318.768 362.729 327.591C350.92 335.972 336.889 342.335 321.025 346.407C305.653 350.411 288.127 352.43 268.904 352.43H256.518C247.662 352.43 239.06 355.62 232.307 361.338C225.537 367.174 221.058 375.148 219.684 383.869L218.751 388.942L203.074 488.281L202.361 491.929C202.174 493.083 201.852 493.66 201.377 494.05C200.953 494.406 200.342 494.644 199.748 494.644H123.28Z"
                              fill="#253B80" />
                          <path
                              d="M391.048 130.082C390.573 133.119 390.03 136.224 389.419 139.414C368.431 247.169 296.629 284.393 204.924 284.393H158.233C147.018 284.393 137.567 292.537 135.82 303.599L111.914 455.213L105.144 498.189C104.007 505.451 109.606 512 116.936 512H199.75C209.556 512 217.887 504.874 219.431 495.203L220.245 490.995L235.838 392.046L236.839 386.617C238.366 376.912 246.713 369.786 256.52 369.786H268.905C349.14 369.786 411.951 337.21 430.308 242.944C437.977 203.564 434.007 170.683 413.715 147.558C407.573 140.585 399.955 134.799 391.048 130.082Z"
                              fill="#179BD7" />
                          <path
                              d="M369.089 121.329C365.882 120.396 362.574 119.547 359.18 118.784C355.77 118.037 352.275 117.376 348.678 116.799C336.089 114.763 322.295 113.796 307.517 113.796H182.779C179.708 113.796 176.79 114.491 174.177 115.747C168.425 118.512 164.15 123.959 163.115 130.626L136.579 298.697L135.815 303.601C137.563 292.538 147.013 284.394 158.228 284.394H204.92C296.625 284.394 368.427 247.153 389.415 139.415C390.042 136.225 390.568 133.121 391.044 130.084C385.733 127.267 379.981 124.858 373.789 122.805C372.262 122.296 370.684 121.804 369.089 121.329Z"
                              fill="#222D65" />
                          <path
                              d="M163.116 130.626C164.151 123.958 168.427 118.511 174.179 115.763C176.808 114.507 179.71 113.812 182.781 113.812H307.519C322.297 113.812 336.091 114.779 348.68 116.815C352.277 117.392 355.772 118.053 359.182 118.8C362.575 119.563 365.884 120.412 369.091 121.345C370.685 121.82 372.263 122.312 373.807 122.804C380 124.857 385.752 127.283 391.062 130.083C397.306 90.2621 391.011 63.1496 369.481 38.5989C345.745 11.5712 302.904 0 248.085 0H88.9386C77.7406 0 68.1884 8.14395 66.4579 19.2231L0.169476 439.4C-1.13695 447.714 5.27641 455.213 13.6579 455.213H111.911L136.581 298.696L163.116 130.626Z"
                              fill="#253B80" />
                      </svg>
                  </span> 


              </div>
              <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="transferForm" *ngIf="paymentMethod == 'bank'">
                  <div class="form-group col-6" >
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="transferType">{{"QUOTE_TEMPLATE.Transfer Type" | translate}}</label>
                      <input type="text" id="transferType" formControlName="transferType" class="form-control"
                          [ngClass]="{ 'is-invalid': transferType.invalid && (transferType.dirty || transferType.touched) }" />
                      <div *ngIf="transferType.invalid && (transferType.dirty || transferType.touched)" class="invalid-feedback">
                          <div *ngIf="transferType.errors.required">{{"QUOTE_TEMPLATE.Transfer type is required." | translate}}</div>
                      </div>
                  </div>
                  <div class="form-group" style="width: 43.66667%;">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="beneficiary">{{"QUOTE_TEMPLATE.Beneficiary" | translate}}</label>
                      <input type="text" id="beneficiary" formControlName="beneficiary" class="form-control"
                          [ngClass]="{ 'is-invalid': beneficiary.invalid && (beneficiary.dirty || beneficiary.touched) }" />
                      <div *ngIf="beneficiary.invalid && (beneficiary.dirty || beneficiary.touched)" class="invalid-feedback">
                          <div *ngIf="beneficiary.errors.required">{{"QUOTE_TEMPLATE.Beneficiary is required." | translate}}</div>
                      </div>
                  </div>
                  <div class="form-group" style="width: 96.333333%;">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="accountNumber">{{"QUOTE_TEMPLATE.Account Number" | translate}}</label>
                      <input type="text" id="accountNumber" formControlName="accountNumber" class="form-control"
                          [ngClass]="{ 'is-invalid': accountNumber.invalid && (accountNumber.dirty || accountNumber.touched) }" />
                      <div *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched)" class="invalid-feedback">
                          <div *ngIf="accountNumber.errors.required">{{"QUOTE_TEMPLATE.Account number is required." | translate}}</div>
                      </div>
                  </div>
                  <div class="form-group col-6">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="bankName">{{"QUOTE_TEMPLATE. | translateBank Name"}}</label>
                      <input type="text" id="bankName" formControlName="bankName" class="form-control"
                          [ngClass]="{ 'is-invalid': bankName.invalid && (bankName.dirty || bankName.touched) }" />
                      <div *ngIf="bankName.invalid && (bankName.dirty || bankName.touched)" class="invalid-feedback">
                          <div *ngIf="bankName.errors.required">{{"QUOTE_TEMPLATE.Bank name is required." |translate}}</div>
                      </div>
                  </div>
                  <div class="form-group" style="width: 43.66667%;">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="email">{{"COMMON.Email" |translate}}</label>
                      <input type="text" id="email" formControlName="email" class="form-control"
                          [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" />
                      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                          <div *ngIf="email.errors.required">{{"QUOTE_TEMPLATE.Email is required." |translate}}</div>
                          <div *ngIf="email.errors.email">{{"QUOTE_TEMPLATE.Invalid email format." |translate}}</div>
                      </div>
                  </div>
                 
                  
              </form>
              <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="transferForm" *ngIf="paymentMethod == 'cheque'">
                <div class="form-group col-6" >
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="transferType">{{"QUOTE_TEMPLATE.Transfer Type" |translate}}</label>
                    <input type="text" id="transferType" formControlName="transferType" class="form-control"
                        [ngClass]="{ 'is-invalid': transferType.invalid && (transferType.dirty || transferType.touched) }" />
                    <div *ngIf="transferType.invalid && (transferType.dirty || transferType.touched)" class="invalid-feedback">
                        <div *ngIf="transferType.errors.required">{{"QUOTE_TEMPLATE.Transfer type is required." |translate}}</div>
                    </div>
                </div>
                <div class="form-group" style="width: 43.66667%;">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="beneficiary">{{"QUOTE_TEMPLATE.Beneficiary" |translate}}</label>
                    <input type="text" id="beneficiary" formControlName="beneficiary" class="form-control"
                        [ngClass]="{ 'is-invalid': beneficiary.invalid && (beneficiary.dirty || beneficiary.touched) }" />
                    <div *ngIf="beneficiary.invalid && (beneficiary.dirty || beneficiary.touched)" class="invalid-feedback">
                        <div *ngIf="beneficiary.errors.required">{{"QUOTE_TEMPLATE.Beneficiary is required." |translate}}</div>
                    </div>
                </div>
                <div class="form-group" style="width: 96.333333%;">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="accountNumber">{{"QUOTE_TEMPLATE.Account Number" | translate}}</label>
                    <input type="text" id="accountNumber" formControlName="accountNumber" class="form-control"
                        [ngClass]="{ 'is-invalid': accountNumber.invalid && (accountNumber.dirty || accountNumber.touched) }" />
                    <div *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched)" class="invalid-feedback">
                        <div *ngIf="accountNumber.errors.required">{{"QUOTE_TEMPLATE.Account number is required." | translate}}</div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="bankName">{{"QUOTE_TEMPLATE. | translateBank Name"}}</label>
                    <input type="text" id="bankName" formControlName="bankName" class="form-control"
                        [ngClass]="{ 'is-invalid': bankName.invalid && (bankName.dirty || bankName.touched) }" />
                    <div *ngIf="bankName.invalid && (bankName.dirty || bankName.touched)" class="invalid-feedback">
                        <div *ngIf="bankName.errors.required">{{"QUOTE_TEMPLATE.Bank name is required." |translate}}</div>
                    </div>
                </div>
                <div class="form-group" style="width: 43.66667%;">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="email">{{"COMMON.Email" |translate}}</label>
                    <input type="text" id="email" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" />
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                        <div *ngIf="email.errors.required">{{"QUOTE_TEMPLATE.Email is required." |translate}}</div>
                        <div *ngIf="email.errors.email">{{"QUOTE_TEMPLATE.Invalid email format." |translate}}</div>
                    </div>
                </div>
               
            </form>
              
              <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="debitCardForm" *ngIf="paymentMethod == 'card'">
                  <div class="form-group" style="width: 96.333333%;">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="cardNumber">{{"QUOTE_TEMPLATE.Card Number" |translate}}</label>
                      <input
                          type="text"
                          id="cardNumber"
                          formControlName="cardNumber"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': cardNumber.invalid && (cardNumber.dirty || cardNumber.touched) }"
                          (input)="formatCardNumber($event)"
                          maxlength="19" 
                      />
                      <div *ngIf="cardNumber.invalid && (cardNumber.dirty || cardNumber.touched)" class="invalid-feedback">
                          <div *ngIf="cardNumber.errors.required">{{"QUOTE_TEMPLATE.Card number is required." |translate}}</div>
                          <div *ngIf="cardNumber.errors.pattern">{{"QUOTE_TEMPLATE.Invalid card number." |translate}}</div>
                      </div>
                  </div>
                  <div class="form-group col-5">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="name">{{"QUOTE_TEMPLATE.Name on Card" |translate}}</label>
                      <input type="text" id="name" formControlName="name" class="form-control"
                          [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" />
                      <div *ngIf="name.invalid && name.touched" class="invalid-feedback">
                          <div *ngIf="name.errors.required">{{"QUOTE_TEMPLATE.Expiry date is required." |translate}}</div>
                        ye div commit thi   <div *ngIf="name.errors.pattern">Invalid expiry date.</div> 
                      </div>
                  </div>
                  <div class="form-group col-3">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="expiryDate">{{"QUOTE_TEMPLATE.Exp Date" |translate}}</label>
                      <input type="text" id="expiryDate" placeholder="" formControlName="expiryDate"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': expiryDate.invalid && (expiryDate.dirty || expiryDate.touched) }" />
                      <div *ngIf="expiryDate.invalid && (expiryDate.dirty || expiryDate.touched)"
                          class="invalid-feedback">
                          <div *ngIf="expiryDate.errors.required">{{"QUOTE_TEMPLATE.Expiry date is required." |translate}}</div>
                          <div *ngIf="expiryDate.errors.pattern">{{"QUOTE_TEMPLATE.Invalid expiry date." |translate}}</div>
                      </div>
                  </div>
  
                  <div class="form-group col-3">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="cvc">{{"QUOTE_TEMPLATE.CVC Number" |translate}}</label>
                      <input type="text" id="cvc" formControlName="cvc" class="form-control"
                          [ngClass]="{ 'is-invalid': cvc.invalid && (cvc.dirty || cvc.touched) }" />
                      <div *ngIf="cvc.invalid && (cvc.dirty || cvc.touched)" class="invalid-feedback">
                          <div *ngIf="cvc.errors.required">{{"QUOTE_TEMPLATE.CVC number is required."  |translate}}</div>
                          <div *ngIf="cvc.errors.pattern">{{"QUOTE_TEMPLATE.Invalid CVC number." |translate}}</div>
                      </div>
                  </div>
  
                  
              </form>
              <div class="d-flex py-3 gap-3 justify-center" *ngIf="paymentMethod == 'cash'">
                <div class="fs-18 text-3 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Pay Cash:" |translate}} </div>
                <span class="fs-18 text-3 fw-400 f-poppins">{{dailogData?.data}}</span>
              </div>
              ye div b commit thi <div *ngIf="paymentMethod !== 'bank'"><mat-slide-toggle class="text-3 fs-16 fw-500 f-poppins" id="toggle" [(ngModel)]="isSlideToggled">Save Card For Future
                      Payments</mat-slide-toggle></div> 
              <div class="col-12 d-flex justify-content-center pt-3">
                  <button class="btn btn-p-2 standard-btn py-2 px-3 col-5 fs-16 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Pay" |translate}} {{receivedData?.payment}}</button>
              </div>
          </div>
          </div>
        </ng-container> -->
      </div>
    </ng-container>

    <ng-container *ngIf="dailogData?.type =='payment'">
      
      <div *ngIf="dailogData?.type =='payment'">
         


         

          <!-- Amount to Pay -->
<ng-container *ngIf="dailogData?.type =='payment'">
<div class="flex justify-end items-center">
  <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"
      (click)="closeDialog()" class=" cursor-pointer">
      <path
          d="M17.5 35C7.85038 35 0 27.1496 0 17.5C0 7.85038 7.85038 0 17.5 0C27.1496 0 35 7.85038 35 17.5C35 27.1496 27.1496 35 17.5 35ZM17.5 2.49992C9.22905 2.49992 2.49992 9.22905 2.49992 17.5C2.49992 25.771 9.22905 32.5001 17.5 32.5001C25.771 32.5001 32.5001 25.771 32.5001 17.5C32.5001 9.22905 25.771 2.49992 17.5 2.49992Z"
          fill="#F85050" />
      <path
          d="M23.7417 25.007C23.4218 25.007 23.1019 24.885 22.8579 24.6406L10.358 12.1407C9.8696 11.6523 9.8696 10.8614 10.358 10.3733C10.8461 9.88515 11.6373 9.88488 12.1255 10.3733L24.6253 22.8732C25.1137 23.3616 25.1137 24.1525 24.6253 24.6406C24.3813 24.885 24.0616 25.007 23.7417 25.007Z"
          fill="#F85050" />
      <path
          d="M11.2416 25.0071C10.9217 25.0071 10.6021 24.8851 10.358 24.6407C9.8696 24.1526 9.8696 23.3614 10.358 22.8733L22.8579 10.3734C23.3463 9.88498 24.1372 9.88498 24.6253 10.3734C25.1135 10.8615 25.1137 11.6527 24.6253 12.1408L12.1255 24.6407C11.8814 24.8851 11.5615 25.0071 11.2416 25.0071Z"
          fill="#F85050" />
  </svg>
</div>
            <div class="px-3 pt-3">
          <div class="pb-3 px-3 d-flex gap-3">
              <span class="fs-18 fw-600 f-poppins text-8">{{"QUOTE_TEMPLATE.Amount to pay" | translate}}</span>
              <span class="fs-18 fw-500 f-poppins text-3">{{dailogData?.currency}} {{dailogData?.data  | number:'1.2-2'}}</span>
          </div>
          <div class="col-12 mb-3 px-5 py-3 div_bg shadow-xl">
              <div class="mb-3 text-3 fs-16 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Payment Method" | translate}}</div>
              <div class="d-flex gap-3 overflow-x-auto my-3">
                <!-- Loop through unique payment methods -->
                <ng-container *ngFor="let payment of getUniquePaymentMethods()">
                  
                  <!-- Bank Transfer Button -->
                  <button *ngIf="payment.checked && payment.paymentMethod === 'Bank Account'" 
                          class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center min-w-fit my-[12px]"
                          [ngClass]="payment?.checked && payment?.paymentMethod === 'Bank Account' ? 'btn_active' : 'btn_notAcive'"
                          (click)="paymentMehtod('bank')">
                    <mat-icon [ngStyle]="{'color': paymentMethod == 'bank' ? '#03C0FC' : ''}">account_balance</mat-icon>
                    <div *ngIf="paymentMethod === 'bank'" class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon>
                    </div>
                    {{"QUOTE_TEMPLATE.Bank Transfer" | translate}}
                  </button>
                  
                  <!-- Visa Button -->
                  <button *ngIf="payment.checked && payment.paymentMethod === 'Visa'" 
                          class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center min-w-fit my-[12px]"
                          [ngClass]="payment?.checked && payment?.paymentMethod === 'Visa' ? 'btn_active' : 'btn_notAcive'"
                          (click)="paymentMehtod('Visa')">
                    <mat-icon [ngStyle]="{'color': paymentMethod == 'Visa' ? '#03C0FC' : ''}">credit_card</mat-icon>
                    <div *ngIf="paymentMethod === 'Visa'" class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon>
                    </div>
                    Visa
                  </button>
                  
                  <!-- Master Button -->
                  <button *ngIf="payment.checked && payment.paymentMethod === 'Mastercard'" 
                          class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center min-w-fit my-[12px]"
                          [ngClass]="payment?.checked && payment?.paymentMethod === 'Mastercard' ? 'btn_active' : 'btn_notAcive'"
                          (click)="paymentMehtod('Master')">
                    <mat-icon [ngStyle]="{'color': paymentMethod == 'Master' ? '#03C0FC' : ''}">credit_card</mat-icon>
                    <div *ngIf="paymentMethod === 'Master'" class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon>
                    </div>
                    Master
                  </button>
                  
                  <!-- Cash Button -->
                  <button *ngIf="payment.checked && payment.paymentMethod === 'Cash'" 
                          class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center min-w-fit my-[12px]"
                          [ngClass]="payment?.checked && payment?.paymentMethod === 'Cash' ? 'btn_active' : 'btn_notAcive'"
                          (click)="paymentMehtod('cash')">
                    <mat-icon [ngStyle]="{'color': paymentMethod == 'cash' ? '#03C0FC' : ''}">local_atm</mat-icon>
                    <div *ngIf="paymentMethod === 'cash'" class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon>
                    </div>
                    {{"QUOTE_TEMPLATE.Cash" | translate}}
                  </button>
                  
                  <!-- Stripe (Credit Card) Button -->
                  <button *ngIf="payment?.checked && payment?.paymentMethod === 'Stripe (Credit Card)'" 
                          class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center min-w-fit my-[12px]"
                          [ngClass]="payment?.checked && payment?.paymentMethod === 'Stripe (Credit Card)' ? 'btn_active' : 'btn_notAcive'"
                          (click)="paymentMehtod('credit')">
                    <mat-icon [ngStyle]="{'color': payment?.paymentMethod === 'Stripe (Credit Card)' ? '#03C0FC' : ''}">credit_card</mat-icon>
                    <div *ngIf="paymentMethod === 'credit'" class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff; height: 15px; width: 15px;">done</mat-icon>
                    </div>
                    Credit Card (Stripe)
                  </button>
                 
                  
                </ng-container>
                <div *ngFor="let payment of dailogData?.paymentsData?.paymentsData">
                  <button 
                    *ngIf="payment.checked && payment.paymentMethod !== 'Stripe (Credit Card)' 
                                     && payment.paymentMethod !== 'Cash' 
                                     && payment.paymentMethod !== 'Mastercard' 
                                     && payment.paymentMethod !== 'Visa' 
                                     && payment.paymentMethod !== 'Bank Account'" 
                    class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center min-w-fit my-[12px] h-[43px]"
                    [ngClass]="paymentMethod === payment.paymentMethod ? 'btn_active' : 'btn_active'"
                    (click)="paymentMehtod(payment.paymentMethod)">
                    
                    <!-- <mat-icon 
                      [ngStyle]="{'color': paymentMethod === payment.paymentMethod ? '#03C0FC' : ''}">
                      credit_card
                    </mat-icon> -->
                    <app-custom-icon class="mr-2" icon="view"></app-custom-icon>
                    
                    <div *ngIf="paymentMethod === payment.paymentMethod" 
                         class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon>
                    </div>
                    
                    {{ payment.paymentMethod }} 
                  </button>
                </div>
                
                <!-- <div *ngFor="let payment of dailogData?.paymentsData?.paymentsData">
                  <button 
                    *ngIf="payment.checked" 
                    class="py-2 text-3 f-poppins relative fw-400 fs-16 px-3 d-flex align-items-center"
                    [ngClass]="paymentMethod === payment.paymentMethod ? 'btn_active' : 'btn_notAcive'"
                    (click)="paymentMehtod(payment.paymentMethod)">
                    
                    <mat-icon 
                      [ngStyle]="{'color': paymentMethod === payment.paymentMethod ? '#03C0FC' : ''}">
                      credit_card
                    </mat-icon>
                    
                    <div *ngIf="paymentMethod === payment.paymentMethod" 
                         class="tick-mark d-flex justify-center align-items-center">
                      <mat-icon class="fs-14" style="color: #fff;height: 15px;width: 15px;">done</mat-icon>
                    </div>
                    
                    {{ payment.paymentMethod }} 
                  </button>
                </div> -->
              </div>
              
              
              
          </div>
          <div class="col-12 px-5 py-3 div_bg shadow-xl">
  
              <div *ngIf="paymentMethod == 'credit'" class="pb-2 text-3 fs-16 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Card" | translate}}</div>
              <div class="d-flex gap-3 pb-3" *ngIf="paymentMethod == 'credit'">
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" viewBox="0 0 512 166" fill="none">
                          <path
                              d="M253.509 2.92064L219.303 162.84H177.929L212.138 2.92064H253.509ZM427.568 106.182L449.343 46.1295L461.874 106.182H427.568ZM473.744 162.84H512L478.578 2.92064H443.29C435.337 2.92064 428.632 7.53179 425.665 14.6428L363.587 162.84H407.037L415.662 138.957H468.734L473.744 162.84ZM365.742 110.632C365.921 68.4264 307.397 66.0884 307.789 47.2302C307.915 41.5004 313.382 35.3966 325.331 33.8355C331.255 33.073 347.603 32.4543 366.134 40.9932L373.381 7.0678C363.429 3.46736 350.62 0 334.683 0C293.783 0 265.012 21.7249 264.782 52.8592C264.519 75.8826 285.334 88.7198 300.984 96.3882C317.119 104.226 322.525 109.251 322.446 116.254C322.335 126.983 309.58 131.734 297.704 131.914C276.892 132.238 264.828 126.289 255.214 121.807L247.704 156.866C257.387 161.297 275.227 165.153 293.7 165.351C337.183 165.351 365.612 143.881 365.742 110.632ZM194.391 2.92064L127.357 162.84H83.6302L50.64 35.2131C48.6401 27.3648 46.8957 24.4801 40.8134 21.1638C30.8645 15.7614 14.4413 10.7078 0 7.56417L0.978353 2.92064H71.3758C80.3427 2.92064 88.4104 8.8878 90.4642 19.2179L107.891 111.765L150.927 2.92064H194.391Z"
                              fill="#1434CB" />
                      </svg>
                  </span>
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg width="45" height="30" viewBox="0 0 512 317" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M325.228 33.8198H186.781V282.612H325.228V33.8198Z" fill="#FF5F00" />
                          <path
                              d="M195.571 158.225C195.549 134.264 200.978 110.613 211.448 89.061C221.919 67.5091 237.155 48.6216 256.003 33.8285C232.662 15.4816 204.629 4.07198 175.11 0.903653C145.591 -2.26468 115.776 2.93617 89.0725 15.9117C62.3692 28.8873 39.8553 49.114 24.1041 74.28C8.35298 99.446 0 128.536 0 158.225C0 187.914 8.35298 217.004 24.1041 242.17C39.8553 267.336 62.3692 287.562 89.0725 300.538C115.776 313.513 145.591 318.714 175.11 315.546C204.629 312.378 232.662 300.968 256.003 282.621C237.155 267.828 221.919 248.941 211.449 227.389C200.979 205.837 195.549 182.185 195.571 158.225Z"
                              fill="#EB001B" />
                          <path
                              d="M512 158.225C512.001 187.913 503.649 217.003 487.899 242.169C472.149 267.335 449.636 287.562 422.933 300.538C396.23 313.513 366.415 318.714 336.896 315.546C307.378 312.378 279.346 300.968 256.005 282.621C274.837 267.813 290.061 248.922 300.529 227.374C310.998 205.825 316.437 182.181 316.437 158.225C316.437 134.268 310.998 110.624 300.529 89.0756C290.061 67.5271 274.837 48.6365 256.005 33.8285C279.346 15.4816 307.378 4.07192 336.896 0.903625C366.415 -2.26467 396.23 2.93627 422.933 15.912C449.636 28.8876 472.149 49.1145 487.899 74.2805C503.649 99.4465 512.001 128.536 512 158.225Z"
                              fill="#F79E1B" />
                          <path
                              d="M496.905 256.265V251.171H498.959V250.134H493.729V251.171H495.783V256.265H496.905ZM507.06 256.265V250.124H505.457L503.613 254.348L501.768 250.124H500.164V256.265H501.296V251.632L503.026 255.626H504.199L505.929 251.622V256.265H507.06Z"
                              fill="#F79E1B" />
                      </svg>
                  </span>
                  <span class="d-flex justify-center align-items-center"
                      style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg width="45" height="30" viewBox="0 0 512 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M511.998 103.272H485.365C477.328 103.272 471.49 105.191 467.355 108.175V103.272H427.961C421.662 103.272 414.267 104.829 410.77 108.175V103.272H340.423V108.175C334.825 104.148 325.378 103.272 321.018 103.272H274.617V108.175C270.188 103.898 260.338 103.272 254.335 103.272H202.404L190.52 116.094L179.39 103.272H101.817V187.047H177.93L190.175 174.022L201.71 187.047L248.627 187.089V167.381H253.24C259.465 167.477 266.807 167.228 273.284 164.436V187.045H311.982V165.211H313.849C316.231 165.211 316.466 165.308 316.466 167.682V187.043H434.023C441.487 187.043 449.288 185.139 453.608 181.682V187.043H490.897C498.656 187.043 506.234 185.959 512 183.182C512 -275.177 511.998 295.184 511.998 103.272Z"
                              fill="#006FCF" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M24.5898 83.717L30.1339 70.3846H42.5459L48.0755 83.717H96.4542V73.524L100.773 83.7605H125.887L130.205 73.3719V83.717H250.436L250.38 61.8318H252.706C254.335 61.8879 254.811 62.0382 254.811 64.7195V83.717H316.994V78.6223C322.01 81.3018 329.811 83.717 340.077 83.717H366.237L371.836 70.3846H384.248L389.723 83.717H440.136V71.0526L447.77 83.717H488.167V0H448.187V9.88708L442.589 0H401.565V9.88708L396.424 0H341.01C331.734 0 323.581 1.29088 316.994 4.88832V0H278.754V4.88832C274.563 1.18044 268.851 0 262.501 0H122.795L113.421 21.6227L103.794 0H59.7902V9.88708L54.9561 0H17.4277L0 39.8036V83.717H24.5898Z"
                              fill="#006FCF" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M253.877 155.028C266.456 155.028 278.937 151.585 278.937 134.907C278.937 118.279 266.106 115.127 254.685 115.127H208.702L190.397 134.807L172.691 115.127H114.812V175.166H171.814L190.23 155.291L207.95 175.166H235.921V155.028H253.877ZM235.921 142.835H254.518C260.048 142.835 263.487 140.099 263.487 134.907C263.487 129.657 259.881 127.546 254.685 127.546H235.921V142.835ZM164.529 162.655H129.147V150.709H160.741V138.459H129.147V127.544H165.226L180.967 145.04L164.529 162.655ZM221.588 169.706L199.493 145.25L221.588 121.571V169.706Z"
                              fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M340.482 152.305C339.254 150.557 336.635 148.444 333.432 147.265C337.22 145.766 343.63 140.876 343.632 131.293C343.632 124.444 340.776 120.696 336.234 117.96C331.511 115.448 326.205 115.127 318.919 115.127H285.694V175.166H300.155V153.238H315.534C320.784 153.238 323.989 153.752 326.092 155.903C328.5 158.416 328.458 163.001 328.425 166.525C328.422 166.916 328.418 167.294 328.418 167.655V175.166H342.866V163.264C342.808 157.972 342.514 155.237 340.482 152.305ZM324.741 139.794C322.816 140.932 320.435 141.03 317.635 141.03H300.153V127.546H317.873C320.435 127.546 322.999 127.6 324.741 128.63C326.606 129.602 327.721 131.462 327.721 134.031C327.721 136.6 326.606 138.669 324.741 139.794Z"
                              fill="white" />
                          <path
                              d="M454.596 143.501C457.398 146.392 458.9 150.042 458.9 156.222C458.9 169.138 450.806 175.166 436.293 175.166H408.264V162.292H436.18C438.91 162.292 440.846 161.932 442.059 160.806C443.048 159.877 443.758 158.528 443.758 156.89C443.758 155.141 442.99 153.752 442.002 152.919C440.902 152.003 439.384 151.587 436.878 151.587C435.996 151.557 435.101 151.532 434.196 151.508C421.285 151.158 406.566 150.759 406.566 133.004C406.566 124.295 412.054 115.129 427.141 115.129H455.991V127.907H429.592C426.975 127.907 425.274 128.005 423.826 128.992C422.25 129.964 421.665 131.407 421.665 133.312C421.665 135.577 423.004 137.117 424.814 137.784C426.332 138.31 427.963 138.464 430.415 138.464L438.162 138.672C445.974 138.863 451.336 140.21 454.596 143.501Z"
                              fill="white" />
                          <path
                              d="M512 127.904H485.772C483.154 127.904 481.414 128.001 479.948 128.988C478.43 129.96 477.846 131.403 477.846 133.308C477.846 135.573 479.128 137.113 480.993 137.78C482.511 138.307 484.141 138.46 486.538 138.46L494.338 138.669C502.208 138.862 507.462 140.211 510.665 143.501C511.157 143.888 511.484 144.314 511.815 144.747L511.904 144.864L511.962 144.938L512 144.987V167.487C508.504 172.583 501.693 175.167 492.471 175.167H464.68V162.292H492.359C495.104 162.292 497.026 161.932 498.182 160.806C499.185 159.877 499.884 158.528 499.884 156.89C499.884 155.141 499.185 153.752 498.126 152.919C497.082 152.003 495.562 151.587 493.056 151.587C492.172 151.557 491.274 151.533 490.367 151.508C477.41 151.158 462.686 150.76 462.686 133.004C462.686 124.296 468.232 115.129 483.333 115.129H511.998L512 127.904Z"
                              fill="white" />
                          <path
                              d="M351.612 115.127H399.642V127.543H365.943V138.459H398.819V150.709H365.943V162.654L399.642 162.709V175.166H351.612V115.127Z"
                              fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M46.1524 45.9372L36.3027 22.008L26.5093 45.9372H46.1524ZM263.14 36.4093C261.163 37.606 258.824 37.6458 256.022 37.6458H238.54V24.3134H256.26C258.768 24.3134 261.384 24.4256 263.084 25.3961C264.951 26.2705 266.106 28.1317 266.106 30.7026C266.106 33.326 265.007 35.437 263.14 36.4093ZM387.856 45.9372L377.898 22.008L367.993 45.9372H387.856ZM155.378 71.8382H140.625L140.571 24.8271L119.704 71.8382H107.068L86.1468 24.7854V71.8382H56.8773L51.3478 58.4497H21.3846L15.7987 71.8382H0.168823L25.939 11.8133H47.32L71.7954 68.6445V11.8133H95.2829L114.116 52.533L131.417 11.8133H155.376V71.8382H155.378ZM214.177 71.8382H166.103V11.8133H214.177V24.3129H180.495V35.1324H213.369V47.4365H180.495V59.4237H214.177V71.8382ZM281.959 27.9792C281.959 37.5494 275.552 42.4938 271.819 43.9784C274.968 45.1734 277.657 47.2844 278.937 49.0333C280.969 52.0188 281.32 54.6857 281.32 60.0465V71.8382H266.805L266.75 64.2686C266.75 60.6567 267.097 55.4624 264.479 52.5746C262.376 50.4636 259.171 50.0056 253.99 50.0056H238.541V71.8382H224.152V11.8133H257.251C264.606 11.8133 270.025 12.007 274.677 14.6883C279.23 17.3697 281.959 21.284 281.959 27.9792ZM304.989 71.8382H290.305V11.8133H304.989V71.8382ZM475.34 71.8382H454.947L427.669 26.786V71.8382H398.361L392.761 58.4497H362.867L357.434 71.8382H340.594C333.599 71.8382 324.743 70.2957 319.727 65.1992C314.67 60.1027 312.038 53.1993 312.038 42.2838C312.038 33.3816 313.611 25.2435 319.796 18.8126C324.449 14.0221 331.734 11.8133 341.651 11.8133H355.583V24.675H341.944C336.692 24.675 333.726 25.4535 330.87 28.2308C328.417 30.7583 326.733 35.5361 326.733 41.8276C326.733 48.2584 328.015 52.8951 330.69 55.924C332.906 58.2994 336.931 59.02 340.72 59.02H347.183L367.465 11.8151H389.027L413.392 68.5902V11.8151H435.303L460.6 53.6193V11.8151H475.34V71.8382Z"
                              fill="white" />
                      </svg>
                  </span>
                  <span class="d-flex justify-center align-items-center"
                  style="width: 80px;height: 40px;border: 1px solid #CBDBE4;border-radius: 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" viewBox="5.322 20.344 440.334 180.144" id="stripe"><path fill="#359ad5" d="M406.487 86.49c-8.87 0-7.944 10.902-8.312 22.541h14.779c0-13.119 0-22.541-6.467-22.541zm-8.312 41.572c0 10.53 5.172 12.562 13.856 12.562 9.978 0 23.463-2.958 28.637-3.881v25.499c-4.063 1.479-16.442 4.986-28.637 4.986-21.987 0-48.78-3.51-48.78-50.438 0-41.387 21.064-51.179 43.235-51.179 22.727 0 39.17 11.64 39.17 50.809v11.642h-47.481zm-86.669-36.584c-2.03 0-6.096 1.663-8.312 2.958v47.296c1.661.557 4.618.926 5.912.926 6.098 0 9.793-5.174 9.793-26.79-.002-21.988-1.666-24.39-7.393-24.39zm4.064 75.752c-4.434 0-9.053-1.293-12.378-2.033v35.29h-35.105V67.459h28.452l5.175 6.097c5.357-4.803 12.934-7.945 20.878-7.945 14.41 0 31.408 4.064 31.408 50.254 0 50.628-25.496 51.365-38.43 51.365zM235.747 55.45c-9.791 0-17.366-7.945-17.366-17.553s7.575-17.553 17.366-17.553c9.794 0 17.369 7.945 17.369 17.553s-7.575 17.553-17.369 17.553zm-17.551 109.933V67.459h35.105v97.924h-35.105zM196.94 94.989c-5.543 0-9.793 2.218-11.641 3.326v67.068h-35.104V67.459h27.899l5.173 9.422c2.032-6.097 7.575-11.271 15.705-11.271 5.543 0 9.238 1.109 10.899 2.032v29.191c-3.51-.921-8.128-1.844-12.931-1.844zm-66.707 45.636c2.217 0 9.978-1.107 12.562-1.479v24.943c-5.356 1.292-17.182 3.141-24.941 3.141-9.978 0-28.638-1.107-28.638-28.267V89.816H76.283V67.459h12.935l4.434-22.171 30.67-7.39V67.46h18.476l-4.618 22.357h-13.857v42.865c-.002 6.649 2.03 7.943 5.91 7.943zM39.505 95.728c0 3.88 2.588 5.173 8.684 7.944l3.696 1.664c8.683 3.88 20.691 10.162 20.691 28.639 0 29.561-21.987 33.256-37.691 33.256-10.162 0-20.878-2.402-28.084-4.617V137.3c6.281 1.666 17.184 4.249 21.986 4.249 5.358 0 9.423-.737 9.423-5.542 0-3.695-2.587-5.357-8.314-7.943l-4.435-2.033c-8.313-3.881-20.139-10.53-20.139-28.453 0-26.237 20.508-31.963 37.692-31.963 12.934 0 21.433 2.955 25.866 4.619v24.573c-5.174-1.662-15.703-3.88-21.985-3.88-4.249-.002-7.39 1.105-7.39 4.801z"></path></svg>
                  </span>

                 


              </div>
              <ng-container *ngIf="paymentMethod === 'bank'">
                <ng-container *ngFor="let payment of updatedPaymentData">
                  <ng-container *ngIf="payment.paymentMethod === 'Bank Account' && payment?.methods?.length > 0">
                    <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="debitCardForm">
                      
                      <!-- Iterate through methods and display fields -->
                      <ng-container *ngFor="let method of payment?.methods">
                        <ng-container *ngIf="method.fields?.length > 0 ; else noFieldsMsg">
                          <div *ngFor="let field of method.fields" class="form-group col-12">
                            <label class="pb-2 text-3 fs-16 fw-500 f-poppins">{{field.title}}</label>
                            <input type="text" class="form-control" [value]="field.value" readonly />
                          </div>
                        </ng-container>
                        <ng-template #noFieldsMsg>
                          <div class="alert alert-warning" role="alert">
                            Please contact the merchant.
                          </div>
                        </ng-template>
                      </ng-container>
                    </form>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!-- <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="transferForm" *ngIf="paymentMethod == 'cheque'">
                <div class="form-group col-6" >
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="transferType">{{"QUOTE_TEMPLATE.Transfer Type" |translate}}</label>
                    <input type="text" id="transferType" formControlName="transferType" class="form-control"
                        [ngClass]="{ 'is-invalid': transferType.invalid && (transferType.dirty || transferType.touched) }" />
                    <div *ngIf="transferType.invalid && (transferType.dirty || transferType.touched)" class="invalid-feedback">
                        <div *ngIf="transferType.errors.required">{{"QUOTE_TEMPLATE.Transfer type is required." |translate}}</div>
                    </div>
                </div>
                <div class="form-group" style="width: 43.66667%;">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="beneficiary">{{"QUOTE_TEMPLATE.Beneficiary" |translate}}</label>
                    <input type="text" id="beneficiary" formControlName="beneficiary" class="form-control"
                        [ngClass]="{ 'is-invalid': beneficiary.invalid && (beneficiary.dirty || beneficiary.touched) }" />
                    <div *ngIf="beneficiary.invalid && (beneficiary.dirty || beneficiary.touched)" class="invalid-feedback">
                        <div *ngIf="beneficiary.errors.required">{{"QUOTE_TEMPLATE.Beneficiary is required." |translate}}</div>
                    </div>
                </div>
                <div class="form-group" style="width: 96.333333%;">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="accountNumber">{{"QUOTE_TEMPLATE.Account Number" | translate}}</label>
                    <input type="text" id="accountNumber" formControlName="accountNumber" class="form-control"
                        [ngClass]="{ 'is-invalid': accountNumber.invalid && (accountNumber.dirty || accountNumber.touched) }" />
                    <div *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched)" class="invalid-feedback">
                        <div *ngIf="accountNumber.errors.required">{{"QUOTE_TEMPLATE.Account number is required." | translate}}</div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="bankName">{{"QUOTE_TEMPLATE.Bank Name" | translate}}</label>
                    <input type="text" id="bankName" formControlName="bankName" class="form-control"
                        [ngClass]="{ 'is-invalid': bankName.invalid && (bankName.dirty || bankName.touched) }" />
                    <div *ngIf="bankName.invalid && (bankName.dirty || bankName.touched)" class="invalid-feedback">
                        <div *ngIf="bankName.errors.required">{{"QUOTE_TEMPLATE.Bank name is required." |translate}}</div>
                    </div>
                </div>
                <div class="form-group" style="width: 43.66667%;">
                    <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="email">{{"COMMON.Email" |translate}}</label>
                    <input type="text" id="email" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" />
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                        <div *ngIf="email.errors.required">{{"COMMON.emailAddressRequired" |translate}}</div>
                        <div *ngIf="email.errors.email">{{"QUOTE_TEMPLATE.Invalid email format." |translate}}</div>
                    </div>
                </div>
               
            </form> -->
            <!-- <div class="row" *ngIf="paymentMethod == 'credit'">
              <div class="col-sm-6">
                  <div class="mt-3">
                      <div id="card-element" autocomplete="off">
                      </div>
                  </div>
              </div>
          </div> -->
              
          <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="debitCardForm" *ngIf="paymentMethod === 'credit'">
            <ng-container *ngIf="!infoMsg; else showInfoMsg">
              <div class="form-group" style="width: 96.333333%;">
                <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="cardNumber">
                  {{"QUOTE_TEMPLATE.Card Number" | translate}}
                </label>
                <div id="card-number" class="form-control">
                  <!-- Stripe card input will be inserted here -->
                </div>
              </div>
              
              <div class="form-group col-3">
                <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="expiryDate">
                  {{"QUOTE_TEMPLATE.Exp Date" | translate}}
                </label>
                <div id="card-expiry" class="form-control">
                  <!-- Stripe card input will be inserted here -->
                </div>
              </div>
          
              <div class="form-group col-3">
                <label class="pb-2 text-3 fs-16 fw-500 f-poppins" for="cvc" style="width: 105% !important;">
                  {{"QUOTE_TEMPLATE.CVC Number" | translate}}
                </label>
                <div id="card-cvc" class="form-control">
                  <!-- Stripe card input will be inserted here -->
                </div>
              </div>
            </ng-container>
          
            <ng-template #showInfoMsg>
              <div class="alert alert-warning" role="alert">
                {{ infoMsg }}
              </div>
            </ng-template>
          </form>
          
              <ng-container *ngIf="paymentMethod === 'Visa'">
                <ng-container *ngFor="let payment of updatedPaymentData">
                  <ng-container *ngIf="payment.paymentMethod === 'Visa' && payment?.methods?.length > 0">
                    <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="debitCardForm">
                      
                      <!-- Iterate through methods and display fields -->
                      <ng-container *ngFor="let method of payment?.methods">
                        <ng-container *ngIf="method.fields?.length > 0 ; else noFieldsMsg">
                          <div *ngFor="let field of method.fields" class="form-group col-12">
                            <label class="pb-2 text-3 fs-16 fw-500 f-poppins">{{field.title}}</label>
                            <input type="text" class="form-control" [value]="field.value" readonly />
                          </div>
                        </ng-container>
                        <ng-template #noFieldsMsg>
                          <div class="alert alert-warning" role="alert">
                            Please contact the merchant.
                          </div>
                        </ng-template>
                      </ng-container>
                    </form>
                  </ng-container>
                </ng-container>
              </ng-container>

             
              
            <ng-container *ngIf="paymentMethod === 'Master'">
            <ng-container *ngFor="let payment of updatedPaymentData">
              <ng-container *ngIf="payment.paymentMethod === 'Mastercard' && payment?.methods?.length > 0">
                <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="debitCardForm">
                  
                  <!-- Iterate through methods and display fields -->
                  <ng-container *ngFor="let method of payment?.methods">
                    <ng-container *ngIf="method.fields?.length > 0 ; else noFieldsMsg">
                      <div *ngFor="let field of method.fields" class="form-group col-12">
                        <label class="pb-2 text-3 fs-16 fw-500 f-poppins">{{field.title}}</label>
                        <input type="text" class="form-control" [value]="field.value" readonly />
                      </div>
                    </ng-container>
                    <ng-template #noFieldsMsg>
                      <div class="alert alert-warning" role="alert">
                        Please contact the merchant.
                      </div>
                    </ng-template>
                  </ng-container>
                </form>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let payment of updatedPaymentData">
            <ng-container *ngIf="paymentMethod === payment?.paymentMethod 
                                && payment?.methods?.length > 0
                                && payment.paymentMethod !== 'Stripe (Credit Card)' 
                                && payment.paymentMethod !== 'Cash' 
                                && payment.paymentMethod !== 'Mastercard' 
                                && payment.paymentMethod !== 'Visa' 
                                && payment.paymentMethod !== 'Bank Account'">
              
              <form class="col-12 gap-3 d-flex flex-wrap" [formGroup]="debitCardForm">
                
                <!-- Iterate through methods and display fields -->
                <ng-container *ngFor="let method of payment?.methods">
                  <ng-container *ngIf="method.fields?.length > 0 ; else noFieldsMsg">
                    <div *ngFor="let field of method.fields" class="form-group col-12">
                      <label class="pb-2 text-3 fs-16 fw-500 f-poppins">{{field.title}}</label>
                      <input type="text" class="form-control" [value]="field.value" readonly />
                    </div>
                  </ng-container>
                  <ng-template #noFieldsMsg>
                    <div class="alert alert-warning" role="alert">
                      Please contact the merchant.
                    </div>
                  </ng-template>
                </ng-container>
              </form>
            </ng-container>
          </ng-container>
          


              <div class="d-flex py-3 gap-3 justify-center" *ngIf="paymentMethod == 'cash'">
                <div class="fs-18 text-3 fw-500 f-poppins">{{"QUOTE_TEMPLATE.Pay Cash:" |translate}} </div>
                <span class="fs-18 text-3 fw-400 f-poppins"> {{dailogData?.currency}} {{dailogData?.data}}</span>
              </div>
              <!--  <div *ngIf="paymentMethod !== 'bank'"><mat-slide-toggle class="text-3 fs-16 fw-500 f-poppins" id="toggle" [(ngModel)]="isSlideToggled">Save Card For Future
                      Payments</mat-slide-toggle></div>  -->
              <div class="col-12 d-flex justify-content-center pt-3" *ngIf="paymentMethod=='credit'" >
                  <button class="btn btn-p-2 standard-btn py-2 px-3 col-5 fs-16 fw-500 f-poppins" [disabled]="buttonDisabled" (click)="activate(card?.id)">{{"COMMON.PAY" |translate}} {{receivedData?.payment}}</button>
              </div>
          </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
      

    <ng-container *ngIf="dailogData?.type=='Quote stage'" >
      <div class="px-10 py-0">
<div style="position: relative;">
<h2 class="text-1 fs-4 text-center mb-4">{{"QUOTE_TEMPLATE.Quote Stages" |translate}}</h2>
<i type="button" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"
class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-0" #closebutton></i>

</div>
        <form [formGroup]="addQuoteStageForm">
              <div class="form-group my-2" id="stages">
                <label>{{"COMMON.NAME"|translate}}<span class="color-4">*</span></label>
                <mat-form-field appearance="outline" floatLabel="always">
                  <div class="flex items-center">
                  <input matInput type="text" formControlName="name"
                    [ngClass]="{ 'is-invalid': submited&& dStageValid?.name?.touched && dStageValid?.name?.errors , 'is-valid': dStageValid?.name?.touched && dStageValid?.name?.valid }">
                    <mat-icon class="vertical-align-baseline" >
                      <img role="button" class="vertical-align-baseline" style="height: 24px;"
                        src="assets/img/home/delete.svg" (click)="removeQuoteStage(dailogData.data)"
                     >
                   </mat-icon>
                  </div>
                  
                  <mat-error
                    *ngIf="submited && dStageValid?.name?.errors && dStageValid?.name?.errors?.required"
                    class="invalid-feedback validate_msg">
                    {{"COMMON.ERRORDEALSTAGEREQUIRED"|translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group mt-4">
                <label>{{ "COMMON.Color" |translate}} <span class="color-4">*</span></label>
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput type="text" formControlName="color"
                    (input)="changeColor($event)">
                  <input matInput class="p-0 position-absolute end-0 top-0 m-2 text-1 color_deal" formControlName="color" 
                    (input)="changeColor($event)" type="color" id="colorPicker" />
                  <mat-error
                    *ngIf="submited && dStageValid?.name?.errors && dStageValid?.color?.errors?.required"
                    class="invalid-feedback validate_msg">
                    {{"COMMON.ERRORDEALSTAGECOLORREQUIRED"|translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="text-center" style="margin-top: 3rem;">
                <button class="btn btn-skyblue-s-1 standard-btn mx-2" id="" (click)="closeDialog()">
                  <i class="fas fa-arrow-left me-2"></i>
                  {{"COMMON.Back" |translate}}
                </button>
                <button class="btn btn-p-2 standard-btn mx-2" (click)="saveQuoteStage()" type="submit">{{"COMMON.Save changes" |translate}}</button>
              </div>
           
            </form>
      </div>

    </ng-container>
    <ng-container *ngIf="dailogData.type == 'quote'">
      <div style="position: relative;">
<!-- <h2 class="text-1 fs-4 text-center mb-4">{{"QUOTE_TEMPLATE.Update Item" |translate}}</h2>
<i type="button" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"
class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-0" #closebutton></i>-->
<div class="d-flex justify-content-end">
<mat-icon type="button" #closebutton class="color-4 fs-2 m-3  " 
            data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"  style="
            height:fit-content;
            width:fit-content;
            border-radius: 50%;
            border: 2px solid #f85050;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px !important;
            font-size: 13px !important;
            font-weight: 600;
            padding: 1px 1px 1px 1px;" >close</mat-icon>
          </div>

</div>
  <div class="d-flex gap-3 px-4">
    <div class="d-flex col-8 flex-column">
      <div class="d-flex align-items-center gap-1">
          <span class="text-8 fs-14 fw-500 f-poppins">{{categoryName}}</span>
          <span class="text-8 fs-14 fw-500 f-poppins" *ngIf="subCategoryName"><svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.11451 12.0799L5.69065 7.50371L1.11451 2.92757C0.654538 2.4676 0.654538 1.72456 1.11451 1.26459C1.57448 0.804617 2.31752 0.804617 2.77749 1.26459L8.19102 6.67812C8.65099 7.13809 8.65099 7.88113 8.19102 8.3411L2.77749 13.7546C2.31752 14.2146 1.57448 14.2146 1.11451 13.7546C0.666332 13.2947 0.654538 12.5398 1.11451 12.0799Z" fill="#B6C9D5"/>
            </svg>
            {{subCategoryName}}</span>
          <span class="text-8 fs-14 fw-500 f-poppins"><svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.11451 12.0799L5.69065 7.50371L1.11451 2.92757C0.654538 2.4676 0.654538 1.72456 1.11451 1.26459C1.57448 0.804617 2.31752 0.804617 2.77749 1.26459L8.19102 6.67812C8.65099 7.13809 8.65099 7.88113 8.19102 8.3411L2.77749 13.7546C2.31752 14.2146 1.57448 14.2146 1.11451 13.7546C0.666332 13.2947 0.654538 12.5398 1.11451 12.0799Z" fill="#B6C9D5"/>
            </svg>
            {{itemAddModel?.name}}</span>
        </div>
        <div class="pt-3 ps-3 d-flex gap-3" style="overflow-x: hidden;">
          <div class="d-flex flex-column align-items-center mt-3 gap-3" style="min-width:200px" *ngIf="permission.includes('change_item_price')">
            <mat-form-field id="mat-form" class="col-12">
              <div class="row d-flex gap-2 w-full">
                <span class="text-3 text-nowrap p-0 fw-400"> {{"COMMON.Price" | translate}}</span>
               
                <div class="d-block col-12 p-0">
                  <mat-select id="price" #select class="form-select-mat form-select text-1" [(value)]="selectedPriceA" style="min-width:150px">
                    <mat-option *ngFor="let price of pricesArrayNew" [value]="price?.price" (click)="updatePrice(price)">
                      <div class="d-flex justify-content-between">
                        <span>{{crSymbol}} {{ price?.price }}</span> <span> {{price?.priceName?price?.priceName:''}}</span>
                      </div>
                    </mat-option>
                    <mat-option class="my-2">
                      <div class="d-flex gap-2 align-items-baseline pb-2">
                        <input matInput class="form-control mt-2 p-2" [formControl]="newPrice" type="text" 
                          (click)="$event.stopPropagation()"
                          placeholder="New Price ..." style="width: 65% !important;height: 40px;">
                        <button class="btn btn-p-2 py-1 mx-2" (click)="addPrice($event)">Add</button>
                      </div>
                    </mat-option>
                  </mat-select>
                </div>
                
                                
             
              
              </div>
              
             
            </mat-form-field>
       
          </div>
         
         
          <div class="d-flex flex-column align-items-center mt-3" style="min-width:200px">
              <mat-form-field id="mat-form"  class="col-12">
                <div class="d-flex flex-column gap-2">
                  <span class="text-3 text-wrap col-12 fw-400"> {{"ITEMS.Discounts available for this item" |
                    translate}}</span>
                    <div class="d-flex gap-3 col-12">
                      <mat-select id="salesTax" class="form-select-mat form-select text-1" [(value)]="applyDiscount" [compareWith]="compareFn" style="min-width:150px">
                        <mat-option *ngIf="showUnselect" style="color:#b3b3b3;" (click)="updateIndex({ discounts: '0' }, i)" [value]="{ discounts: '0' }">
                          {{ "Unselect" }}
                        </mat-option>
                        
                        <mat-option *ngFor="let addDiscountsList of addDiscountsArr; let i = index" 
                                    (click)="updateIndex(addDiscountsList,i)" [value]="addDiscountsList">
                          {{ addDiscountsList.discounts ? addDiscountsList.discounts + '%' : '' }}
                        </mat-option>
                      </mat-select>
                    </div>
                    
                </div>   
              </mat-form-field>
             
          </div>
          <div class="d-flex flex-column align-items-center mt-3" style="width:35%">
            <form  class="col-12"  >
              <div class="d-flex gap-2 flex-column">
                <span class="text-3 text-nowrap col-12 fw-400"> </span>
                <div class="col-12">

                  
<!--   
                  <mat-select id="salesTax" class="form-select-mat form-select text-1">
                    <mat-option *ngIf="addItemPriceTaxArr.length > 0" (click)="clearValue()">Clear value</mat-option>
                    <mat-option *ngFor="let addItemPriceTaxList of allTaxesList;let i = index" (click)="updateTaxIndex(addItemPriceTaxList,i)" [value]="addItemPriceTaxList">
                      {{addItemPriceTaxList.taxData.name || 'Sales tax'}}     {{addItemPriceTaxList.taxe_value}}%
                    </mat-option>
                  </mat-select> -->
                  
                </div>     
               
              </div>
            </form>
            
          </div>
        </div>  
    </div>
    <div class="d-flex gap-4">
      <!-- <div class="d-flex flex-column gap-2 ms-4 pt-3">
            <div class="d-flex gap-2">
          <span class="text-3 fs-14 fw-500 f-poppins">{{'QUOTE_TEMPLATE.Product available in:' | translate}}</span>
             
            </div>
            <div class="d-flex gap-2">
              <span class="text-3 fs-14 fw-500 f-poppins" >Mexico:</span>
              <span class="text-8 fs-14 fw-400 f-poppins">15 items</span>
            </div>
            <div class="d-flex gap-2">
              <span class="text-3 fs-14 fw-500 f-poppins">Canada:</span>
              <span class="text-9 fs-14 fw-400 f-poppins">10 items</span>
            </div>
             <div class="d-flex gap-2 flex-wrap">
              <span  class="text-3 fs-14 fw-500 f-poppins">USA:</span>
              <span>9 items</span>
            </div>
          </div> -->
      <div class="d-flex flex-column gap-2 ms-4 pt-3">
            <div class="d-flex gap-2">
              <span class="text-3 fs-14 fw-500 f-poppins">{{'QUOTE_TEMPLATE.Total cost' | translate}}:</span>
              <span class="text-8 fs-14 fw-400 f-poppins">{{crSymbol }} {{fixCostUnit}} {{dailogData?.currencyName?.currency_code}}</span>
            </div>
            <div class="d-flex gap-2">
              <span class="text-3 fs-14 fw-500 f-poppins" >{{'QUOTE_TEMPLATE.Profit margin:' | translate}}</span>
              <span class="text-8 fs-14 fw-400 f-poppins">{{profitMargn}}%</span>
            </div>
            <div class="d-flex gap-2">
              <span class="text-3 fs-14 fw-500 f-poppins">{{'COMMON.Commission' | translate}}</span>
              <span class="text-9 fs-14 fw-400 f-poppins">{{crSymbol }} {{sales_commission}} {{dailogData?.currencyName?.currency_code}}</span> 
            </div>
             <div class="d-flex gap-2 flex-wrap">
              <span  class="text-3 fs-14 fw-500 f-poppins">{{'QUOTE_TEMPLATE.Taxes:' | translate}}</span>
              <span *ngFor="let tax of addItemPriceTaxArr">{{tax?.taxData?.name}} {{tax?.taxe_value}}%</span>
             </div>
          </div>

       
      </div>
      
    </div>  
    <!-- <div class="d-flex pt-4 gap-3 px-4">
        <div class="d-flex gap-2 align-items-center">
          <span class="text-3 fs-14 fw-500 f-poppins" >{{'QUOTE_TEMPLATE.Length:' | translate}}</span>
          <div class="d-flex pb-1 gap-2 align-item-start justify-content-center" style=" border-bottom: 1px solid #364F6B;">
            <span id="decrement" (click)="decrease(lenghtValue,'length')" class="d-flex pointer justify-content-center align-items-center" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
              -                              
            </span>
            {{lenghtValue}}
            <span id="increment" (click)="increase(lenghtValue,'length')" class="d-flex pointer justify-content-center align-items-center" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
              +                             
            </span>
          </div>
          <span class="text-8 ">m²</span>
        </div>
        <div class="d-flex gap-2 align-items-center">
          <span class="text-3 fs-14 fw-500 f-poppins" >{{'QUOTE_TEMPLATE.Width' | translate}}</span>
          <div class="d-flex pb-1 gap-2 align-item-start justify-content-center" style=" border-bottom: 1px solid #364F6B;">
            <span id="decrement" (click)="decrease(width_Value,'width')" class="d-flex pointer justify-content-center align-items-center" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
              -                              
            </span>
            {{width_Value}}
            <span id="increment" (click)="increase(width_Value,'width')" class="d-flex pointer justify-content-center align-items-center" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
              +                             
            </span>
          </div>
          <span class="text-8 ">m²</span>
        </div>  
        <span class="text-8 fw-600 ">X</span>   
        <div class="d-flex gap-3">
          <span class="text-8">=</span><span class="text-8">50m²</span>
        </div>  
    </div> -->
    <div class="d-flex px-4 pt-3 align-items-center justify-content-between">
      <!-- <div class="d-flex align-items-center gap-3">
        <div class="d-flex gap-1 align-items-center text-3 fs-14 fw-500 f-poppins">Optional Item:<mat-slide-toggle class="text-3 fs-15 fw-400 f-poppins" id="toggle" [(ngModel)]="isSlideToggled">
        </mat-slide-toggle></div>
        <div class="d-flex gap-1 align-items-center text-3 fs-14 fw-500 f-poppins">{{'QUOTE_TEMPLATE.Deleteable Item:' | translate}}<mat-slide-toggle class="text-3 fs-15 fw-400 f-poppins" id="toggle2" [(ngModel)]="isItemDisable">
        </mat-slide-toggle></div>
        <div class="d-flex align-items-center gap-1 text-3 fs-14 fw-500 f-poppins">{{'QUOTE_TEMPLATE.Modify Quantity:' | translate}}<mat-slide-toggle class="text-3 fs-15 fw-400 f-poppins" id="toggle3" [(ngModel)]="isSlideQuantity">
        </mat-slide-toggle></div>
      </div> -->
      <div class="d-flex align-items-center gap-3">
        <div class="d-flex gap-1 align-items-center" style="cursor: pointer;" (click)="editItemListNew()" *ngIf="permission.includes('items_edit')">
          <span>
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 12.2192V15.3446H3.12543L12.3475 6.1225L9.2221 2.99707L0 12.2192Z" fill="#03C0FC"/>
              <path d="M14.7571 2.5346L12.811 0.58851C12.486 0.263465 11.9567 0.263465 11.6317 0.58851L10.1064 2.11372L13.2319 5.23915L14.7571 3.71394C15.0822 3.3889 15.0822 2.85964 14.7571 2.5346Z" fill="#03C0FC"/>
            </svg>
          </span>
          <span class="ligh-main fw-500 fs-15">{{'COMMON.Edit Item' | translate}}</span>
        </div>
        <!-- <div class="d-flex gap-1 align-items-center">
          <span>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.9219 17.4453C18.6199 17.4453 18.375 17.6901 18.375 17.9922V21.7656C18.375 23.2734 17.1484 24.5 15.6406 24.5H9.73612C9.43419 24.5 9.18947 24.7446 9.18925 25.0465L9.18848 26.1338L7.8832 24.8286C7.79871 24.6346 7.60534 24.5 7.38166 24.5H3.82812C2.32039 24.5 1.09375 23.2734 1.09375 21.7656V13.3437C1.09375 11.836 2.32039 10.6094 3.82812 10.6094H9.35156C9.65355 10.6094 9.89844 10.3645 9.89844 10.0625C9.89844 9.76046 9.65355 9.51562 9.35156 9.51562H3.82812C1.7173 9.51562 0 11.2329 0 13.3437V21.7656C0 23.8765 1.7173 25.5937 3.82812 25.5937H7.10161L9.34768 27.8398C9.4523 27.9444 9.59213 28 9.73448 28C9.80487 28 9.87591 27.9864 9.94344 27.9584C10.1478 27.8739 10.2811 27.6746 10.2812 27.4535L10.2826 25.5937H15.6406C17.7515 25.5937 19.4687 23.8765 19.4687 21.7656V17.9922C19.4687 17.6901 19.2239 17.4453 18.9219 17.4453Z" fill="#03C0FC"/>
              <path d="M20.0514 0H18.6674C14.2845 0 10.7188 3.56573 10.7188 7.94861C10.7188 12.3315 14.2845 15.8972 18.6673 15.8972H20.0514C20.7355 15.8972 21.4126 15.8103 22.0688 15.6385L24.1134 17.682C24.218 17.7865 24.3578 17.8421 24.5001 17.8421C24.5706 17.8421 24.6417 17.8285 24.7093 17.8004C24.9137 17.7157 25.0469 17.5163 25.0469 17.2952V14.1312C25.9125 13.4302 26.6316 12.5446 27.1363 11.5553C27.7094 10.4317 28 9.21823 28 7.94861C28 3.56573 24.4343 0 20.0514 0ZM24.1709 13.4277C24.0338 13.531 23.9531 13.6928 23.9531 13.8645V15.9755L22.6149 14.6379C22.5107 14.5339 22.3711 14.4779 22.2282 14.4779C22.1746 14.4779 22.1205 14.4858 22.0677 14.502C21.4167 14.7021 20.7384 14.8035 20.0514 14.8035H18.6674C14.8875 14.8035 11.8125 11.7284 11.8125 7.94861C11.8125 4.16883 14.8876 1.09375 18.6674 1.09375H20.0514C23.8312 1.09375 26.9062 4.16883 26.9062 7.94861C26.9062 10.1214 25.9093 12.1185 24.1709 13.4277Z" fill="#03C0FC"/>
              <path d="M21.8222 5.98392C21.7433 4.83904 20.8204 3.91614 19.6755 3.83722C19.0265 3.79265 18.4071 4.01157 17.933 4.45415C17.4654 4.89061 17.1973 5.5076 17.1973 6.14695C17.1973 6.44899 17.4422 6.69382 17.7441 6.69382C18.0461 6.69382 18.291 6.44899 18.291 6.14695C18.291 5.80466 18.4289 5.48742 18.6793 5.25374C18.9294 5.02028 19.2564 4.90472 19.6003 4.92846C20.2034 4.97002 20.6896 5.45614 20.7311 6.05917C20.7731 6.66823 20.3735 7.20646 19.781 7.33903C19.301 7.44643 18.9657 7.86359 18.9657 8.35342V9.66729C18.9657 9.96933 19.2106 10.2142 19.5126 10.2142C19.8147 10.2142 20.0595 9.96933 20.0594 9.66729V8.39712C21.1614 8.1309 21.9007 7.12257 21.8222 5.98392Z" fill="#03C0FC"/>
              <path d="M19.8993 11.4434C19.7976 11.3417 19.6565 11.2832 19.5127 11.2832C19.3689 11.2832 19.2278 11.3417 19.1261 11.4434C19.0243 11.5452 18.9658 11.6863 18.9658 11.8301C18.9658 11.9745 19.0244 12.1155 19.1261 12.2173C19.2278 12.319 19.3689 12.377 19.5127 12.377C19.6565 12.377 19.7976 12.319 19.8993 12.2173C20.0011 12.115 20.0596 11.9745 20.0596 11.8301C20.0596 11.6863 20.001 11.5452 19.8993 11.4434Z" fill="#03C0FC"/>
              <path d="M15.0391 16.9531H3.5C3.19802 16.9531 2.95312 17.198 2.95312 17.5C2.95312 17.802 3.19802 18.0469 3.5 18.0469H15.0391C15.3411 18.0469 15.5859 17.802 15.5859 17.5C15.5859 17.198 15.341 16.9531 15.0391 16.9531Z" fill="#03C0FC"/>
              <path d="M15.4256 20.1759C15.324 20.0741 15.1829 20.0156 15.0391 20.0156C14.8952 20.0156 14.7541 20.0741 14.6524 20.1759C14.5506 20.2776 14.4922 20.4187 14.4922 20.5625C14.4922 20.7063 14.5507 20.8474 14.6524 20.9491C14.7541 21.0509 14.8952 21.1094 15.0391 21.1094C15.1829 21.1094 15.324 21.0509 15.4256 20.9491C15.5274 20.8474 15.5859 20.7069 15.5859 20.5625C15.5859 20.4187 15.5274 20.2776 15.4256 20.1759Z" fill="#03C0FC"/>
              <path d="M12.888 20.0156H3.5C3.19802 20.0156 2.95312 20.2605 2.95312 20.5625C2.95312 20.8645 3.19802 21.1094 3.5 21.1094H12.888C13.1901 21.1094 13.4349 20.8645 13.4349 20.5625C13.4349 20.2605 13.19 20.0156 12.888 20.0156Z" fill="#03C0FC"/>
              <path d="M11.4844 13.8906H3.5C3.19802 13.8906 2.95312 14.1355 2.95312 14.4375C2.95312 14.7395 3.19802 14.9844 3.5 14.9844H11.4844C11.7864 14.9844 12.0312 14.7395 12.0312 14.4375C12.0312 14.1355 11.7864 13.8906 11.4844 13.8906Z" fill="#03C0FC"/>
            </svg>
          </span>
          <span class="ligh-main fw-500 fs-15">Questions</span>
        </div> -->
      </div>
    </div>
    <!-- <div class="d-flex gap-1 px-4 align-items-center text-3 fs-14 fw-500 f-poppins">Modify price:<mat-slide-toggle class="text-3 fs-15 fw-400 f-poppins" id="toggle4" [(ngModel)]="modifyPrice">
    </mat-slide-toggle></div> -->
    <div class="d-flex justify-content-center mt-4 gap-3">
         
        <button class="btn btn-p-2 standard-btn" data-bs-toggle="modal" data-bs-target="#exampleModal4_3" data-bs-dismiss="modal" 
        (click)="updateItem()">{{"COMMON.UPDATE" | translate}}</button>
        </div>
    </ng-container>  
