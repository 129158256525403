<div class="container-fluid p-0">
    <a class="d-flex align-items-center position-absolute backbtn" *ngIf="embedFromData1" routerLink="/dash/lead-form">
        <mat-icon>arrow_back_ios</mat-icon>
        <span class="txt">Back to Form Template</span>
    </a>
    <ng-container *ngFor="let embed of embedFromData?.data;">
        <div class="box-content-st mx-auto">

            <div class="box-content-elements">

                <div *ngIf="embed?.form_label==='Heading'" class="d-flex"
                    [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                    <h1 class="fw5 mb-0"
                        [ngStyle]="{ 'font-size':getFsize?.head+'px', 'color': getColor?.mn_color, 'font-family':getFmly }">
                        {{embed?.value}}
                    </h1>
                </div>

                <div *ngIf="embed?.form_label==='Text'" class="d-flex"
                    [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                    <p class="font-normal mb-0"
                        [ngStyle]="{'font-size':getFsize?.body+'px','color': getColor?.mn_color, 'font-family':getFmly }">
                        {{embed?.value}}
                    </p>
                </div>
                <div *ngIf="embed?.form_label==='Image'" class="d-flex"
                    [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                    <img [src]="embed?.url?embed?.url:staticLogo" alt="logo" class="logo-img"
                        [ngStyle]="{'width':embed?.width+'%', 'border-radius':embed?.brradius+'px'}">
                </div>

                <div *ngIf="embed?.form_label==='Divider'" class="d-flex"
                    [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                    <hr [ngStyle]="{'color': getColor?.bd_color, 'width':embed?.width+'%' }">
                </div>

                <form [formGroup]="createForm(embed)" (ngSubmit)="onSubmit()">
                    <div *ngIf="embed?.form_name === 'Phone'" class="d-flex flex-column"
                    [ngStyle]="{'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                    <label class="fw5" for="{{embed?.form_name}}"
                        [ngStyle]="{ 'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{embed?.form_label}}
                        <small class="lbops" *ngIf="!embed?.required"
                            [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly }">(Optional)
                        </small>
                        <small *ngIf="embed?.required">*</small>
                    </label>
                        <div class="w-80">
                            <div class="d-flex" *ngFor="let contact of phonenumber; let i = index">
                                <div class="mobile_number w-100">
                                    <input disabled oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                                        [type]="embed?.required ? (embed?.datatype || 'text') : 'text'"
                                        class="form-control" id="{{embed?.id}}" [placeholder]="embed?.placeholder"
                                        [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px', 'font-family': getFmly, 'color': getColor?.mn_color }">
                                </div>
                            </div>
                        </div>
                        <span class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</span>
                    </div>
                    <div *ngIf="conorglead(embed?.form_name)" class="d-flex flex-column"
                        [ngStyle]="{'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                        <label class="fw5" for="{{embed?.form_name}}"
                            [ngStyle]="{ 'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{embed?.form_label}}
                            <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly }">(Optional)
                            </small>
                            <small *ngIf="embed?.required">*</small>
                        </label>
                        <ng-container *ngIf="embed?.required; else defaultInput">
                            <ng-container *ngIf="embed?.form_name!=='Address'">
                                <input disabled [formControlName]="embed?.form_name" id="{{embed?.id}}"
                                    [type]="embed?.datatype || 'text'" class="form-control w-80"
                                    [placeholder]="embed?.placeholder"
                                    [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px', 'font-family': getFmly, 'color': getColor?.mn_color }">
                            </ng-container>
                            <ng-container *ngIf="embed?.form_name==='Address'">
                                <address-comp class="w-80 lead-address-comp" [isDisabled]="true" [customerAddressArr]="customerAddressArr"
                                    [customerAddressStyle]="{bordercolor: getColor?.bd_color,fontsize: getFsize?.body+'px', fontfamily: getFmly, color: getColor?.mn_color }"></address-comp>
                            </ng-container>
                        </ng-container>
                        <ng-template #defaultInput>
                            <input disabled [formControlName]="embed?.form_name" id="{{embed?.id}}" type="text"
                                class="form-control w-80" [placeholder]="embed?.placeholder"
                                [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px', 'font-family': getFmly, 'color': getColor?.mn_color }">
                        </ng-template>

                        <span class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</span>
                    </div>
                    <div *ngIf="embed?.form_name === 'Package'" class="d-flex flex-column" [ngStyle]="{'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                        <label class="fw5" for="{{embed?.form_name}}"
                        [ngStyle]="{ 'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{embed?.form_label}}
                        <small class="lbops" *ngIf="!embed?.required"
                            [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly }">(Optional)
                        </small>
                        <small *ngIf="embed?.required">*</small>
                    </label>
                    </div>

                    <ng-container *ngIf="embed?.form_name == 'Package'">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 d-flex flex-wrap ml-4">
                                <div class="col-12 col-sm-9 col-md-6 col-lg-4 col-xl-4 col-xxl-6 package_div "   *ngFor="let packageItem of embed?.package_item; let i = index">
                                    <div class="d-flex gap-2 mt-3 justify-content-center">
                                        <div class="col-10">
                                            <div class="card card-margin">
                                                <div class="card-header d-flex justify-content-between">
                                                    <span class="d-flex align-items-center">
                                                    <p class="card-title m-0" [id]="'packageItem_title_' + i" [value]="packageItem.package_title" [ngStyle]="{ 'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{packageItem.package_title}}</p>
                                                    </span>
                                                    
                                                </div>
                                                <div class="card-body">
                                                    <div class="widget-49">
                                                        <div class="widget-49-title-wrapper d-flex">
                                                            <div class="widget-49-date-primary">
                                                               <img [src]="packageItem.url?packageItem.url:staticLogo" [id]="'packageItem_img_' + i" alt="logo" width="75" height="50" class="logo-img"/>
                                                            </div>
                                                            <div class="widget-49-meeting-info ml-2" style="max-width: 150px;font-size: 15px;margin: 0px 15px;">
                                                                <span [id]="'packageItem_description_' + i" [value]="packageItem.package_description" class="widget-49-pro-title" [ngStyle]="{ 'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{packageItem.package_description}}</span>
                                                            </div>
                                                        </div>                                                       
                                                    </div>
                                                </div>
                                                 <div class="card-footer widget-49-price" [ngStyle]="{ 'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">
                                                    <span>Price :</span>&nbsp;&nbsp;<span  [id]="'packageItem_price_' + i" [value]="packageItem.package_price">{{packageItem.package_price}}</span>
                                                        </div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="embed?.field_type==='Input'" class="d-flex flex-column textbox"
                        [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                        <label class="fw5" for="{{embed?.form_name}}"
                            [ngStyle]="{'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{embed?.form_label}}
                            <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small>
                        </label>
                        <input disabled [type]="embed?.datatype || 'text'" [formControlName]="embed?.form_name"
                            id="{{embed?.id}}"
                            [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly}"
                            class="form-control w-80" [placeholder]="embed?.placeholder">
                        <p class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</p>
                    </div>
                    
                    <div *ngIf="embed?.field_type==='Color'" class="d-flex flex-column textbox"
                        [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                        <label class="fw5" for="{{embed?.form_name}}"
                            [ngStyle]="{'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{embed?.form_label}}
                            <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small>
                        </label>
                        <input disabled [type]="embed?.datatype || 'color'" [formControlName]="embed?.form_name"
                        id="{{embed?.custom_field_id}}"
                            [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly}"
                            class="form-control w-80" [placeholder]="embed?.placeholder">
                        <span class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</span>
                    </div>
                    <div *ngIf="embed?.field_type==='Date'" class="d-flex flex-column textbox"
                        [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                        <label class="fw5" for="{{embed?.form_name}}"
                            [ngStyle]="{'font-size':getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly }">{{embed?.form_label}}
                            <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small>
                        </label>
                       

                        <input disabled [type]="embed?.datatype || 'date'" [formControlName]="embed?.form_name"
                        id="{{embed?.custom_field_id}}"
                            [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly}"
                            class="form-control w-80" [placeholder]="embed?.placeholder">
                        <span class="m-0 font-normal"
                            [ngStyle]="{'font-size form': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</span>
                    </div>

                    <!-- <div *ngIf="embed?.form_name==='Textarea'" class="d-flex flex-column"
                        [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                        <label class="fw5" for="{{embed?.form_name}}"
                            [ngStyle]="{'font-size': getFsize?.body+'px', 'color':getColor?.mn_color,'font-family':getFmly } ">{{embed?.form_label}}
                            <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small></label>
                        <textarea rows="3" class="form-control fw5 w-80" [formControlName]="embed?.form_name"
                            id="{{embed?.id}}"
                            [ngStyle]="{'border-color': getColor?.bd_color,'color':getColor?.mn_color,'font-size': getFsize?.body+'px','font-family':getFmly}"
                            [placeholder]="embed?.placeholder"></textarea>
                        <p class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</p>
                    </div> -->

                    <div *ngIf="embed?.field_type==='Dropdown'" class="d-flex flex-column"
                        [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px'}">
                        <label class="fw5" for="{{embed?.form_name}}"
                            [ngStyle]="{'font-size': getFsize?.body+'px', 'color':getColor?.mn_color,'font-family':getFmly } ">{{embed?.form_label}}
                            <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small></label>
                        <select disabled class="form-select w-80" [formControlName]="embed?.form_name" id="{{embed?.id}}"
                            [ngStyle]="{'border-color': getColor?.bd_color,'color':getColor?.mn_color,'font-family':getFmly}">
                            <option [value]="coun?.name"
                                *ngFor="let coun of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;"
                                [selected]="coun?.selected">{{coun?.name}}</option>
                        </select>
                        <p class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</p>
                    </div>

                    <div *ngIf="embed?.field_type==='Checkbox'" class="d-flex flex-column checkboxradio"
                        [ngStyle]="{ 'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                        <p class="m-0 fw5"
                            [ngStyle]="{'font-size': getFsize?.body+'px',  'color':getColor?.mn_color,'font-family':getFmly }">
                            {{embed?.form_label}} <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'border-color': getColor?.bd_color,'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small>
                        </p>
                        <div class="d-flex flex-wrap checkboxradio-container">
                            <div class="checkboxradio-field flex items-center mr-3" *ngFor="let check of embed?.value;">
                                <input disabled type="checkbox" class="mr-1" [checked]="check?.selected"
                                    (change)="onCheckboxChange($event, check)" name="{{check?.name}}"
                                    value="{{check?.name}}" id="{{check?.name}}"
                                    [ngStyle]="{'border-color': getColor?.bd_color,'color':getColor?.mn_color, 'font-family':getFmly}">
                                <label class="fw5" for="{{check?.name}}"
                                    [ngStyle]="{ 'font-size': getFsize?.body+'px', 'color':getColor?.mn_color,'font-family':getFmly}">
                                    {{check?.name}} </label>
                            </div>
                        </div>
                        <p class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</p>
                    </div>

                    <div *ngIf="embed?.field_type==='Radio'" class="d-flex flex-column checkboxradio"
                        [ngStyle]="{ 'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px'}">
                        <p class="m-0 fw5"
                            [ngStyle]="{'font-size': getFsize?.body+'px',  'color':getColor?.mn_color, 'font-family':getFmly } ">
                            {{embed?.form_label}} <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{ 'font-size': getFsize?.body+'px','font-family':getFmly}">(Optional)</small>
                            <small *ngIf="embed?.required">*</small>
                        </p>
                        <div class="d-flex flex-wrap checkboxradio-container">
                            <div class="checkboxradio-field flex items-center mr-3"
                                *ngFor="let radio of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;">
                                <input disabled type="radio" class="mr-1" [checked]="radio?.selected" id="{{embed?.custom_field_id}}"
                                    name="radio_button" value="{{radio?.name}}" [formControlName]="embed?.form_name"
                                    [ngStyle]="{'border-color': getColor?.bd_color,'font-family':getFmly,'font-size':getFsize?.body+'px'}">
                                <label class="fw5" for="{{radio?.name}}"
                                    [ngStyle]="{ 'color':getColor?.mn_color,'font-size': getFsize?.body+'px', 'font-family':getFmly}">
                                    {{radio?.name}} </label>
                            </div>
                        </div>
                        <span class="m-0 font-normal"
                            [ngStyle]="{'font-size form': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</span>
                    </div>

                    <!-- <div *ngIf="embed?.form_name==='Attachment'" class="d-flex flex-column attachment"
                        [ngStyle]="{ 'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                        <p class="m-0 fw5"
                            [ngStyle]="{ 'font-size': getFsize?.body+'px','color':getColor?.mn_color,'font-family':getFmly} ">
                            {{embed?.form_label}} <small class="lbops" *ngIf="!embed?.required"
                                [ngStyle]="{'font-size': getFsize?.body+'px', 'font-family':getFmly }">(Optional)</small>
                            <small *ngIf="embed?.required">*</small>
                        </p>
                        <label for="{{embed?.form_name}}"
                            class="attachment-label flex rounded-md mt-2 justify-center w-80"
                            [ngClass]="isDragOver?'drag-over':''" id="attachment_icon"
                            [ngStyle]="{ 'padding': getFsize?.body+'px','color':getColor?.mn_color, 'font-family':getFmly }">
                            <img src="assets/svg-icons/attachment_icon.svg" alt="attachment_icon">
                            <div class="attachment-txt" class="d-flex items-center flex-col ml-2"
                                [ngStyle]="{'color':getColor?.mn_color, 'font-family':getFmly,'font-size':getFsize?.body+'px' }">
                                <p class="m-0"><b>Click to upload</b> or drag and drop</p>
                                <p class="m-0">
                                    <span *ngFor="let file of embed?.value">
                                        <span *ngIf="file?.value">{{file?.name}},</span>
                                    </span>(MAX: 10 MB)
                                </p>
                            </div>
                        </label>
                        <input type="file" (change)="onFileSelected($event)" [accept]="acceptFile" multiple
                            class="form-control hidden op-0" id="{{embed?.id}}" name="attachment">
                        <span class="selected-files"
                            *ngFor="let filename of selectedFiles;let fin = index;"><span>{{filename?.name}}</span><span
                                (click)="removeFile(fin)" class="delete">X</span></span>
                        <p class="m-0 font-normal"
                            [ngStyle]="{'font-size': getFsize?.helptxt+'px','color': getColor?.htn_color,'font-family':getFmly}">
                            {{embed?.help_txt}}</p>
                    </div> -->

                    <div *ngIf="embed?.form_name==='Submit'" class="d-flex button"
                        [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                        <button type="submit"
                            class="h-40 border-none w-80 font-semibold rounded-lg text-white submit-btn my-2"
                            [ngStyle]="{'background': getColor?.mn_color,'font-size': getFsize?.body+'px','font-family':getFmly }"
                            >{{getTranslatedText ("QUOTE_TEMPLATE." + embed?.value ) || embed?.value}}</button>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>

    <div class="preview-wrapper" *ngIf="embedFromData1">
        <div class="preview-temp">
            <ng-container *ngFor="let embed of embedFromData1?.data;">
                <div class="box-content-st mx-auto">
                    <div class="box-content-elements">

                        <div *ngIf="embed?.form_label==='Heading'" class="d-flex"
                            [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                            <h1 class="fw5 mb-0"
                                [ngStyle]="{ 'font-size':getFsize1?.head+'px', 'color': getColor1?.mn_color, 'font-family':getFmly1 }">
                                {{embed?.data}}
                            </h1>
                        </div>

                        <div *ngIf="embed?.form_label==='Text'" class="d-flex"
                            [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                            <p class="font-normal mb-0"
                                [ngStyle]="{'font-size':getFsize1?.body+'px','color': getColor1?.mn_color, 'font-family':getFmly1 }">
                                {{embed?.data}}
                            </p>
                        </div>
                        <div *ngIf="embed?.form_label==='Image'" class="d-flex"
                            [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                            <img [src]="embed?.url?embed?.url:staticLogo" alt="logo" class="logo-img"
                                [ngStyle]="{'width':embed?.width+'%', 'border-radius':embed?.brradius+'px'}">
                        </div>

                        <div *ngIf="embed?.form_label==='Divider'" class="d-flex"
                            [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                            <hr [ngStyle]="{'color': getColor1?.bd_color, 'width':embed?.width+'%' }">
                        </div>

                        <form [formGroup]="createForm(embed)" (ngSubmit)="onSubmit()">
                            <div *ngIf="embed?.form_name === 'Phone'" class="flex flex-column"
                                [ngStyle]="{'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{ 'font-size':getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1 }">{{embed?.form_label}}
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1 }">(Optional)
                                    </small>
                                    <small *ngIf="embed?.required">*</small>
                                </label>
                                <div class="w-80">
                                    <div class="d-flex phone_div_rsp" *ngFor="let contact of phonenumber; let i = index">
                                        <div class="countryId mr-3" >
                                            <select disabled class="form-select w-100 select2" id="countryId" value={{embed?.data}} (input)="getValue(countryId?.form_name)"
                                                [ngStyle]="{'border-color': getColor1?.bd_color,'font-size': getFsize1?.body+'px','font-family':getFmly1,'color':getColor1?.mn_color }">
                                                <option *ngFor="let country of countryListArr" [value]="'+' + country.phone_code" >
                                                    {{country.name}} (+{{country.phone_code}})
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mobile_number">
                                            <input disabled oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                                                [type]="embed?.required ? (embed?.datatype || 'text') : 'text'"
                                                class="form-control" id="{{embed?.id}}" value={{embed?.data}} (input)="getValue(embed?.form_name)"
                                                [placeholder]="embed?.placeholder"
                                                [ngStyle]="{'border-color': getColor1?.bd_color,'font-size': getFsize1?.body+'px','font-family':getFmly1,'color':getColor1?.mn_color }">
                                        </div>
                                    </div>
                                </div>
                                <span class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</span>
                            </div>
                            <div *ngIf="conorglead(embed?.form_name)" class="d-flex flex-column"
                                [ngStyle]="{'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{ 'font-size':getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1 }">{{embed?.form_label}}
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1 }">(Optional)
                                    </small>
                                    <small *ngIf="embed?.required">*</small>
                                </label>
                                <ng-container *ngIf="embed?.form_name!=='Address'">
                                    <input disabled  id="{{embed?.id}}"
                                        [type]="embed?.datatype || 'text'" class="form-control w-80"
                                        [placeholder]="embed?.placeholder" value={{embed?.data}} (input)="getValue(embed?.form_name)"
                                        [ngStyle]="{'border-color': getColor1?.bd_color,'font-size': getFsize1?.body+'px', 'font-family': getFmly1, 'color': getColor1?.mn_color }">
                                </ng-container>
                                <ng-container *ngIf="embed?.form_name==='Address'">
                                    <address-comp class="w-80 lead-address-comp" [isDisabled]="true" [customerAddressArr]="customerAddressArr"
                                        [customerAddressStyle]="{bordercolor: getColor1?.bd_color,fontsize: getFsize1?.body+'px', fontfamily: getFmly1, color: getColor1?.mn_color }"></address-comp>
                                </ng-container>
                                <span class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</span>
                            </div>

                            <div *ngIf="embed?.field_type==='Input'" class="d-flex flex-column textbox"
                                [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{'font-size':getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1 }">{{embed?.form_label}}wwww
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small></label>
                                <input disabled type="text"  id="{{embed?.custom_field_id}}" value={{embed?.data}} (input)="getValue(embed?.form_name)"
                                    [ngStyle]="{'border-color': getColor1?.bd_color,'font-size': getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1}"
                                    class="form-control w-80" [placeholder]="embed?.placeholder">
                                <span class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</span>
                            </div>
                            <!-- <div *ngIf="embed?.field_type==='Date'" class="d-flex flex-column textbox"
                                [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{'font-size':getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1 }">{{embed?.form_label}}
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small></label>
                                <input disabled [type]="embed?.required ? (embed?.datatype || 'date') : 'date'" [formControlName]="embed?.form_name" id="{{embed?.form_name}}"
                                    [ngStyle]="{'border-color': getColor1?.bd_color,'font-size': getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1}"
                                    class="form-control w-80" [placeholder]="embed?.placeholder">
                                <p class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</p>
                            </div> -->
                            <!-- <div *ngIf="embed?.field_type==='Color'" class="d-flex flex-column textbox"
                                [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{'font-size':getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1 }">{{embed?.form_label}}
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small></label>
                                <input disabled [type]="embed?.required ? (embed?.datatype || 'color') : 'color'" [formControlName]="embed?.form_name" id="{{embed?.form_name}}"
                                    [ngStyle]="{'border-color': getColor1?.bd_color,'font-size': getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1}"
                                    class="form-control w-80" [placeholder]="embed?.placeholder">
                                <p class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</p>
                            </div> -->

                            <!-- <div *ngIf="embed?.form_name==='Textarea'" class="d-flex flex-column"
                                [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{'font-size': getFsize1?.body+'px', 'color':getColor1?.mn_color,'font-family':getFmly1 } ">{{embed?.form_label}}
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small></label>
                                <textarea rows="3" class="form-control fw5 w-80" [formControlName]="embed?.form_name"
                                    id="{{embed?.form_name}}"
                                    [ngStyle]="{'border-color': getColor1?.bd_color,'color':getColor1?.mn_color,'font-size': getFsize1?.body+'px','font-family':getFmly1}"
                                    [placeholder]="embed?.placeholder"></textarea>
                                <p class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</p>
                            </div> -->

                            <div *ngIf="embed?.field_type==='Dropdown'" class="d-flex flex-column"
                                [ngStyle]="{'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px'}">
                                <label class="fw5" for="{{embed?.form_name}}"
                                    [ngStyle]="{'font-size': getFsize1?.body+'px', 'color':getColor1?.mn_color,'font-family':getFmly1 } ">{{embed?.form_label}}
                                    <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small></label>
                                <select disabled class="form-select w-80"  value={{embed?.data}} (input)="getValue(embed?.form_name)"
                                    id="{{embed?.custom_field_id}}"
                                    [ngStyle]="{'border-color': getColor1?.bd_color,'color':getColor1?.mn_color,'font-family':getFmly1}">
                                    <option [value]="coun?.name"
                                        *ngFor="let coun of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;"
                                        [selected]="coun?.selected">{{coun?.name}}</option>
                                </select>
                                <span class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</span>
                            </div>

                            <div *ngIf="embed?.field_type==='Checkbox'" class="d-flex flex-column checkboxradio"
                                [ngStyle]="{ 'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px' }">
                                <p class="m-0 fw5"
                                    [ngStyle]="{'font-size': getFsize1?.body+'px',  'color':getColor1?.mn_color,'font-family':getFmly1 }">
                                    {{embed?.form_label}} <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small>
                                </p>
                                <div class="d-flex flex-wrap checkboxradio-container">
                                    <div class="checkboxradio-field flex items-center mr-3"
                                        *ngFor="let check of getCheckbox1;">
                                        <input disabled type="checkbox" class="mr-1" [checked]="check?.selected" value={{embed?.data}} (input)="getValue(embed?.form_name)"
                                            (change)="onCheckboxChange($event, check)" name="{{check?.name}}"
                                            value="{{check?.name}}" id="{{embed?.custom_field_id}}"
                                            [ngStyle]="{'border-color': getColor1?.bd_color,'color':getColor1?.mn_color, 'font-family':getFmly1}">
                                        <label class="fw5" for="{{check?.name}}"
                                            [ngStyle]="{ 'font-size': getFsize1?.body+'px', 'color':getColor1?.mn_color,'font-family':getFmly1}">
                                            {{check?.name}} </label>
                                    </div>
                                </div>
                                <span class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</span>
                            </div>

                            <!-- <div *ngIf="embed?.field_type==='Radio'" class="d-flex flex-column checkboxradio"
                                [ngStyle]="{ 'align-items':embed?.align, 'margin-bottom':embed?.mrbottum+'px'}">
                                <p class="m-0 fw5"
                                    [ngStyle]="{'font-size': getFsize1?.body+'px',  'color':getColor1?.mn_color, 'font-family':getFmly1 } ">
                                    {{embed?.form_label}} <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{ 'font-size': getFsize1?.body+'px','font-family':getFmly1}">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small>
                                </p>
                                <div class="d-flex flex-wrap checkboxradio-container">
                                    <div class="checkboxradio-field flex items-center mr-3"
                                        *ngFor="let radio of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;">
                                        <input disabled type="radio" class="mr-1" [checked]="radio?.selected"
                                            id="{{radio?.name}}" name="radio_button" value="{{radio?.name}}"
                                            [formControlName]="embed?.form_name"
                                            [ngStyle]="{'border-color': getColor1?.bd_color,'font-family':getFmly1,'font-size':getFsize1?.body+'px'}">
                                        <label class="fw5" for="{{radio?.name}}"
                                            [ngStyle]="{ 'color':getColor1?.mn_color,'font-size': getFsize1?.body+'px', 'font-family':getFmly1}">
                                            {{radio?.name}} </label>
                                    </div>
                                </div>
                                <p class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</p>
                            </div> -->

                            <div *ngIf="embed?.form_name==='Attachment'" class="d-flex flex-column attachment"
                                [ngStyle]="{ 'align-items':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                                <p class="m-0 fw5"
                                    [ngStyle]="{ 'font-size': getFsize1?.body+'px','color':getColor1?.mn_color,'font-family':getFmly1} ">
                                    {{embed?.form_label}} <small class="lbops" *ngIf="!embed?.required"
                                        [ngStyle]="{'font-size': getFsize1?.body+'px', 'font-family':getFmly1 }">(Optional)</small>
                                    <small *ngIf="embed?.required">*</small>
                                </p>
                                <label for="{{embed?.form_name}}"
                                    class="attachment-label flex rounded-md mt-2 justify-center w-80"
                                    [ngClass]="isDragOver?'drag-over':''" id="attachment_icon"
                                    [ngStyle]="{ 'padding': getFsize1?.body+'px','color':getColor1?.mn_color, 'font-family':getFmly1 }">
                                    <img src="assets/svg-icons/attachment_icon.svg" alt="attachment_icon">
                                    <div class="attachment-txt" class="d-flex items-center flex-col ml-2"
                                        [ngStyle]="{'color':getColor1?.mn_color, 'font-family':getFmly1,'font-size':getFsize1?.body+'px' }">
                                        <p class="m-0"><b>Click to upload</b> or drag and drop</p>
                                        <p class="m-0">
                                            <span *ngFor="let file of embed?.value">
                                                <span *ngIf="file?.value">{{file?.name}},</span>
                                            </span>(MAX: 10 MB)
                                        </p>
                                    </div>
                                </label>
                                <input disabled type="file" (change)="onFileSelected($event)" [accept]="acceptFile" multiple
                                    class="form-control hidden" id="{{embed?.form_name}}" id="attachment" value={{embed?.data}} (input)="getValue(embed?.form_name)"
                                    name="attachment">
                                <span class="selected-files"
                                    *ngFor="let filename of selectedFiles;let fin = index;"><span>{{filename?.name}}</span><span
                                        (click)="removeFile(fin)" class="delete">X</span></span>
                                <span class="m-0"
                                    [ngStyle]="{'font-size': getFsize1?.helptxt+'px','color': getColor1?.htn_color,'font-family':getFmly1}">
                                    {{embed?.help_txt}}</span>
                            </div>

                            <div *ngIf="embed?.form_name==='Submit'" class="d-flex button"
                                [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px'}">
                                <button type="submit" class="h-40 border-none w-80 font-semibold rounded-lg text-white my-2"
                                    [ngStyle]="{'background': getColor1?.mn_color,'font-size': getFsize1?.body+'px','font-family':getFmly1 }">{{embed?.value}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>