import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/pages/contacts/services/contact.service';
import { ComSerService } from '../../commanservice/com-ser.service';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import * as Papa from 'papaparse';
import { Subject, Subscription } from 'rxjs';
import { ItemsService } from 'src/app/pages/items/services/items.service';
import { ModalComponent, ModalModel } from '../../components/modal/modal.component';
import { UploadImagesPreviewModelComponent } from '../upload-images-preview/upload-images-preview.component';
import { SocketService } from '../../socket/socketio.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UploadCsvComponent implements OnInit, AfterViewInit, OnChanges {
  downloadcsv = environment.API_URL + '/assets/osmos_contactcsv.csv';
  downloadItemcsv = environment.API_URL + '/assets/osmos_itemcsv.csv';

  // table

  // @ViewChild('singleSelect') searching: ElementRef;
  // wareHouseSearch: FormControl = new FormControl();
  // public c: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  showData: any = [];
  headingdata: any = [];
  csvAllKey: any = [];
  index: number = 1;
  fileUploading: any;
  allcsvlisting: any = [];
  emailInsert = [];
  emailcheckeking = [];
  itemsCsvFile:any;
  dataarryCsc = [];
  csvFile = new FormControl('');

  csvform: FormGroup;
  emaildata = [];
  sub: boolean = false;
  showCsvDropdown:boolean = false
  selectItem: any = {};
  records: 0;
  checkbox1: any;
  csvFileName:any;
  remainingContact : any;
  customPanelClass:boolean=false;
  serchArr: any = {}; // hanldes the pagination and filters
  private itemCount = 10; // items per page 
  public itemActivePagination = Array(); // handles the pagination of table
  filesize: string;
  searchListing: any = [];
  // @Input() ngTemplateOutletContext:any;
  // @Output() senddataDetails: EventEmitter<any> = new EventEmitter();
  multpleOptionFields: any[] = [
    'Email',
    'Phone Number',
    'Country',
    'Phone Name',
    'Street Number',
    'Interior Number',
    'Street Name',
    'City',
    'State',
    'Postal Code',
    'Phone Country Code',
    'Address Name',
  ];
  // multpleOptionFieldsItems: any[] = [
  //   "Items Name",
  //   "Video Url",
  //   "Website Url",
  //   "Item Code",
  //   "Unit Quantity",
  //   "Unit",
  //   "Description",
  //   "Status",
  //   "Deactivated",
  //   "Category",
  //   "Subcategory",
  //   "Images",
  //   "Item Discount",
  //   "GST",
  //   "Items Comments",
  //   "Fix Cost Per Unit",
  //   "Sales Commission",
  //   "Minimum Price",
  //   "Price A",
  //   "Price B",
  //   "Price C",
  //   "Price D",
  // ];
  previousValue: any;
  selectedMultiOptions: any;
  subscriptions: Subscription[] = [];
  files: any;
  editItemsImages = [];
  newItemsData:any;
  uploadItemsPages:any;
  private socket: any;
  progressData: { jobId: string; progress: number,name:string }[] = [];
  image:any = "";
  uploadImagesData=[]
  public imageUrl = environment.IMAGE_BACKET_URL;
  mainImageUrl: any;
  showOption:boolean = true;
  defaultsPage: any = {
    titles: {
      pageSize: 'Items per page'
    },
    pageSizes: [{
      value: 10,
      display: '10'
    }, {
      value: 20,
      display: '20'
    }, {
      value: 30,
      display: '30'
    }, {
      value: 40,
      display: '40'
    }, {
      value: 50,
      display: '50'
    }]
  };
  pageDetail:string;
  remaining: any;
  constructor(
    public dialogRef: MatDialogRef<UploadCsvComponent>,
    private comSerService: ComSerService,
    private contactService: ContactService,
    private fb: FormBuilder,
    private itemsService: ItemsService,
    public snackbar: MatSnackBar,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private srv :SocketService,
    private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public Inputdata: any
  ) {
    const jobIdsWithProgress100 = new Set();

this.socket = io(environment.API_URL);
this.socket.on('jobProgress', (data) => {
  // Check if progress is 100 and the job ID is not in the set
  if (data.progress === 25 && !jobIdsWithProgress100.has(data.jobId.id)) {
    this.uploadImagesData.push(data);
    jobIdsWithProgress100.add(data.jobId.id); // Add the job ID to the set
  }
        // Find the index of the progress data with the matching job ID
      const index = this.progressData.findIndex((res) => res?.jobId === data?.jobId?.id);

      if (index !== -1) {
        // Update the existing progress data
        this.progressData[index].progress = data?.progress;
      } else {
        // Add a new progress data entry
        this.progressData.push({jobId:  data?.jobId?.id, progress: data?.progress,name: data?.jobId?.data.selected_column});
      }

    });
    console.log(this.Inputdata.hideOption,'<<<<this.Inputdata.hideOption')
    if(this.Inputdata.hideOption != undefined) {
      this.showOption = this.Inputdata.hideOption;
    }
  }

  ngOnInit(): void {
    this.newItemsData=this.Inputdata?.itemsData
    this.itemActivePagination = new Array(this.Inputdata?.itemsData?.totalPages);
    this.pageDetail=this.Inputdata?.itemsData?.pageDetail

    this.headingType();
    this.initializecsvformForm();
  }
  ngAfterViewInit(): void {
    // const searchTerm = fromEvent<any>(this.searching.nativeElement, 'keyup').pipe(map(event => event.target.value),
    //   debounceTime(1000),
    //   distinctUntilChanged()
    // )
    // searchTerm.subscribe(res => {
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.imageFilesAddedEdit(changes)
  }

  initializecsvformForm() {
    this.csvform = this.fb.group({
      tag: ['', Validators.required],
      csv: [''],
      Confirm: [false, Validators.required],
      status: ['create', Validators.required],
      data: [''],
    });
  }

  get f() {
    return this.csvform.controls;
  }

  closebutton() {
    this.closeModel();
  }
  csvUpload(){
    this.sub = true;
    if (this.csvform.invalid) {
      this.index = 4;
      return;
    }
    const payload = this.csvform.value;

    this.csvsubmit(payload);
  }

  closeModel() {
    this.dialogRef.close({ status: true, systempopupClose: 'contact_listing' });
  }

  emailExitlisting(data: any) {
    let filterData: any[] = data?.filter(function (value) {
      return typeof value !== 'undefined' && value;
    });
    let pay = [...new Set(filterData)];
    let payload = {
      emails: pay,
    };
    const emailexitsSubs = this.contactService.emailexits(payload).subscribe(
      (res) => {
        if (res.status) {
          this.emaildata = res.data;
          // let Jsonheading = res.data;
          // for (const [key, value] of Object.entries(Jsonheading)) {
          //   this.headingdata.push({ name: value, id: key, valueData: '' });
          // }
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.subscriptions.push(emailexitsSubs);
  }

  headingType() {
    this.dialogRef.updateSize('700px');
    if(this.Inputdata.checkingItems == 'value'){
      const headercsvfileSubs = this.contactService.headerItemscsvfile().subscribe(
        (res) => {
          if (res.status) {
            let Jsonheading = res.data;
            for (const [key, value] of Object.entries(Jsonheading)) {
              const isStar = key === 'name' || key === 'category' || key === 'priceA';

              this.headingdata.push({
                name: value,
                id: key,
                valueData:
                  this.multpleOptionFields.indexOf(value) != -1 ? [value] : value,
                star: isStar,
                multiple: this.multpleOptionFields,
                options:
                  this.multpleOptionFields.indexOf(value) != -1 ? [value] : value,
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
      this.subscriptions.push(headercsvfileSubs);
    }else{
      const headercsvfileSubs = this.contactService.headercsvfile().subscribe(
        (res) => {
          if (res.status) {
            this.remaining = res?.validAdd;
            if (this.remaining.limit && this.remaining.limit > 999999){
              this.remaining.limit = false
            }
            let Jsonheading = res.data;
            for (const [key, value] of Object.entries(Jsonheading)) {
              this.headingdata.push({
                name: value,
                id: key,
                valueData:
                  this.multpleOptionFields.indexOf(value) != -1 ? [value] : value,
                star: key === 'first_name' ? true : false,
                multiple: this.multpleOptionFields,
                options:
                  this.multpleOptionFields.indexOf(value) != -1 ? [value] : value,
              });
              // if(key.startsWith("additional_value_")){
              //   this.headingdata.push({ name: value, id: key, valueData:value, star: key === "first_name" ? true : false, multiple: this.multpleOptionFields });
              // }else{
              //   this.headingdata.push({ name: value, id: key, valueData:value, star: key === "first_name" ? true : false, multiple: this.multpleOptionFields  });
              // }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
      this.subscriptions.push(headercsvfileSubs);
  
    }

  }
  datssa(ev) {
    this.searchListing = this.allcsvlisting?.filter((item) =>
      item.id?.toLowerCase().includes(ev.target.value?.toLowerCase())
    );
  }

  // size change dialog box
  sizechange() {
    this.dialogRef.updateSize('80%');
  }

  tabledatashow() {
    let mainarra = [];
    this.dataarryCsc.forEach((value, key) => {
      if (key == 0) {
        this.headingdata.forEach((h, i) => {
          // headarray.push(h.name);
        });
        // mainarra.push(headarray);
      }

      let valarray = [];
      this.headingdata.forEach((head, i) => {
        if (head.valueData != '') {
          for (const [key, values] of Object.entries(value)) {
            if (key == head.valueData) {
              valarray.push(values);
            }
          }
        } else {
          valarray.push(null);
        }
      });
      mainarra.push(valarray);
    });
    this.showData = mainarra;
  }
  contiineItems(){
    if(this.itemsCsvFile.length>0){
      if(this.csvFile.value?.length>0 && this.editItemsImages?.length>0){
        for(let i of this.csvFile.value){
          for(let j of this.itemsCsvFile){
            if(i==j.id){
              for(let k of this.editItemsImages){
                if(j.item_images?.length>0){
                  j.item_images.push({image:k.image});
                }else j.image=[{image:k.image}];
              }
              break;
            }
          }
        }        
      }
    }
    if(this.itemsCsvFile.length ){
      this.contactService.uploadItemsCsv(this.itemsCsvFile).subscribe(
        (res) => {
          if (res.status) {
            this.dialogRef.close(true);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  continue() {
    this.spinnerService.show();

    setTimeout(() => {
      console.log('checking wheather it is runnig');
      this.index = this.index + 1;
      if (this.index === 3) {
        const first_name = this.headingdata.find((x) => {
          if (x.id === 'first_name') {
            if (x.valueData != '') {
              let valueData: string = '';
              return this.dataarryCsc.find(function (element) {
                x?.valueData?.includes('string') == false
                  ? (valueData = x.valueData.toString())
                  : (valueData = x.valueData);
                return (
                  element[x.valueData] === '' ||
                  element[x.valueData] === undefined
                );
              });
            } else {
              return 'abc';
            }
          }
          if(this.Inputdata.checkingItems == 'value'){
            if (this.dataarryCsc.length) {
              this.dataarryCsc = this.dataarryCsc.filter((element) => {
                const keys = Object.keys(element);
                return keys.some((key) => {
                  const trimmedKey = key.trim();
                  return trimmedKey !== '' && trimmedKey !== '\r';
                });
              });
            }  
            if (this.dataarryCsc.length) {
              this.dataarryCsc = this.dataarryCsc.filter((element) => {
                const values = Object.values(element);
                return values.some(value => typeof value === 'string' && value.trim() !== '');
              });
            } 
       if (x.id === 'name' || x.id === 'priceA' || x.id === 'category') {
            if (x.valueData != '') {
              let valueData: string = '';
              return this.dataarryCsc.find(function (element) {
                x?.valueData?.includes('string') == false
                  ? (valueData = x.valueData.toString())
                  : (valueData = x.valueData);
                return (
                  element[x.valueData] === '' ||
                  element[x.valueData] === undefined
                );
              });
            } else {
              return 'abc';
            }
          }
        }
        });
  
        if (first_name) {
          this.index = 2;
          const message =
            this.Inputdata?.checkingItems === 'value'
              ? this.translateService.instant('ON_BOARDING.please_select_required_fields')
              : this.translateService.instant('ON_BOARDING.please_select_first_name');
        
          this.SuccessSnackBar(message, this.translateService.instant('COMMON.Cancel'));
          this.spinnerService.hide();
          return;
        }
        this.dialogRef.updateSize('930px');
        this.tabledatashow();
        let data = this.headingdata.find((x) => x.valueData != '');
        if (!data) {
          this.index = 2;
          this.SuccessSnackBar(
            this.translateService.instant('COMMON.Select_option'),
            this.translateService.instant('COMMON.Cancel')
          );
        }
  
        this.headingdata.forEach((ele, i) => {
          let data: any[] = [],
            valueData: string = '';
          ele?.valueData?.includes('string') == false
            ? (valueData = ele.valueData.toString())
            : (valueData = ele.valueData);
          if (valueData?.toLowerCase()?.startsWith('email')) {
            ele['emailcheked'] = true;
          } else {
            ele['emailcheked'] = false;
          }
  
          if (valueData != '') {
            this.selectItem[ele.id] = valueData;
          } else {
          }
          let newValue = [];
          this.dataarryCsc.forEach((element, index) => {
            // for (const [key, value] of Object.entries(element)) {
            //   if (valueData?.startsWith(key?.trim())) {
            //     data.push(value);
            //   }
            // }
            Object.keys(element).forEach((key) => {
              //let valueDataString = valueData.split(" ")[0];
  
              if (Array.isArray(ele.valueData) && ele.valueData.length > 0) {
                let c = ele.valueData
                  .map(function (k) {
                    return [element[k]];
                  })
                  .join(', ')
                  .replace(/(\s*,?\s*)*$/, '');
  
                // data.push(c);
                newValue = c;
              } else if (valueData?.includes(key) && valueData == key) {
                if (key.indexOf('Date') != -1) {
                  if (valueData === key) {
                    newValue = element[key];
                  }
                } else {
                  newValue = element[key];
                }
              }
  
              //if (valueData.indexOf(key) > -1) {
              //console.log(valueData + "----" + key + "======" + element[key]);
            });
            data.push(newValue);
          });
          ele.valueData = data;
        });
        this.headingdata.forEach((element) => {
          if (element.valueData.length === 0) {
            for (let j = 0; j < this.headingdata[0]?.valueData?.length; j++) {
              element.valueData.push(null);
            }
          }
        });
      } else if (this.index === 4) {
        this.headingdata.forEach((ele) => {
          // if (ele.emailcheked) {
          //   this.emailcheckeking = [...this.emailcheckeking, ...ele.valueData];
          // }
          // this.emailInsert.forEach(element => {
          //   if (ele.valueData === element) {
          //     this.emailcheckeking.push(element);
          //   }
          // });
        });
        // this.emailExitlisting(this.emailcheckeking);
        this.dialogRef.updateSize('650px');
      }
      this.spinnerService.hide();
      // this.headingdata = this.sortHeadingData(this.headingdata);
    }, 100)


  }

  csvsubmit(payload: any) {
    this.spinnerService.show();
    const merchant_id = localStorage.getItem('merchant_id');
    const userData = localStorage.getItem('loginUserValue') && JSON.parse(localStorage.getItem('loginUserValue'));
    const formData = new FormData();
    formData.append(`merchant_id`, merchant_id);
    formData.append(`tag`, payload.tag);
    formData.append(`csv`, this.fileUploading);
    formData.append(`Confirm`, payload.Confirm);
    formData.append(`status`, payload.status);
    formData.append(
      `module_name`,
      this.Inputdata?.value === 'addLead'
        ? 'lead'
        : this.Inputdata?.checkingItems === 'value'
        ? 'item_module'
        : 'contact'
    );
    formData.append(`data`, JSON.stringify(this.emaildata));
    formData.append(`selectedItems`, JSON.stringify(this.selectItem));
    formData.append(`lang`, userData?.languages?.locale);

    this.contactService.uploadCsv(formData).subscribe(
      (res) => {
        this.spinnerService.hide();
        if (res.status) {
          this.dialogRef.close(true);
          // this.emaildata = res.data;
        }
      },
      (error) => {
        this.spinnerService.hide();
      }
    );
  }
  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }

  handleFileInput(event) {
    this.spinnerService.show();
    setTimeout(() => {
    let reader = new FileReader();
    let _size = event.target.files[0].size;
    let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    let exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i];
    this.filesize = exactSize;
    if (event.target.files && event.target.files.length) {
      const files = event.target.files[0];
      let condition: any = event.target.files[0].name.split('.');
      this.f.tag.setValue(condition.slice(0)[0]);
      const value = condition.slice(-1)[0];
      if (value === 'csv') {
        this.fileUploading = files;
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          const filebase64 = reader.result as string;
          this.readcsv(filebase64);
          
        };
      } else {
        this.fileUploading = '';
        this.spinnerService.hide();
        alert('please csv file Upload');
      }
    }
    }, 100)
  }

  readcsv(csv: string) {
    const readcsvSubs = this.comSerService.readcsv(csv).subscribe((res) => {
      if (res) {
        let arr: any = [];
        let recordArray: any = [];
        const list =res.split('\n');
        list.slice(1).forEach((res)=>{
          if (res != '') {
            recordArray.push(res);
          }
        })
        this.records = recordArray?.length;
        for (let a=0; a < 5; a++ ) {
          if(list[a]) {
            arr.push(list[a]);
          }          
        }

        // Split the header line using a custom parser
        const headerLine = arr[0];
        let keyValue: any[] = this.parseHeaderLine(headerLine);
    
        let keyvalueData: any[] = [];
    
        for (let i5 = 1; i5 < arr.length; i5++) {
          if (arr[i5] && arr[i5].length && arr[i5] != '') {
            keyvalueData.push(arr[i5].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/));
          }
        }
        for (let j6 = 0; j6 < keyvalueData.length; j6++) {
          for (let i7 = 0; i7 < keyvalueData[j6].length; i7++) {
            keyvalueData[j6][i7] = keyvalueData[j6][i7].replace('"', '');
            keyvalueData[j6][i7] = keyvalueData[j6][i7].replace('"', '');
          }
        } 
    
        let dataarry: any[] = [];
        keyvalueData.forEach((element) => {
          let allcsvData: any = {};
          element.forEach((ele, i9) => {
            // Remove extra double quotes from the key
            const key = keyValue[i9];
            allcsvData[key] = ele;
          });
          dataarry.push(allcsvData);
        });
        
        this.dataarryCsc = dataarry;        
            // const findname = this.dataarryCsc.find(ele => {return ele.first_name ==''});
            // if(findname)return
    
            let allEmail: any = [];
            for (let i3 = 0; i3 < dataarry.length; i3++) {
              for (const [key, value] of Object.entries(dataarry[i3])) {
                if (key.startsWith('Email_')) {
                  allEmail.push(value);
                }
              }
            }

            this.emailInsert = allEmail;
            if (dataarry.length) {
              for (const [key, value] of Object.entries(dataarry[0])) {
                this.allcsvlisting.push({ name: key, id: key, disable: false });
              }
            }
    
            let newarray = [];
            newarray = this.headingdata
              .map((x) => (x.valueData || x.valueData[0] != '' ? x.valueData : ''))
              .filter((val) => val != '');
            const newData = [].concat(...newarray);
            this.dataarryCsc.find((element) => {
              for (const [key, values] of Object.entries(element)) {
                if (typeof values === 'string') {
                  element[key] = values.replace(/\r/g, ''); // Replace all occurrences of "\r"
                }
                this.headingdata.map((el) =>
                  Array.isArray(el.valueData) &&
                  key.trim().startsWith(el.name.trim()) &&
                  el.valueData.indexOf(key) == -1
                    ? el.valueData.push(key)
                    : el            
                );
              }
            });
            this.allcsvlisting.forEach((ele) => {
              let id = ele.id.trim().replace(/\d$/, '');
              newData.includes(id.trim())
                ? (ele.disable = true)
                : (ele.disable = false);
            });
            this.headingdata.forEach((x, i) => {
              x['searchTxt'] = undefined;
            });
            this.index = 2;
            // this.dialogRef.updateSize('80%');
            this.spinnerService.hide();
      }
    });
    this.subscriptions.push(readcsvSubs);
  }

  parseHeaderLine(headerLine: string): string[] {
    const keys = [];
    let inQuotes = false;
    let currentKey = '';
    
    for (let i = 0; i < headerLine.length; i++) {
      const char = headerLine[i];
      
      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        keys.push(currentKey.trim());
        currentKey = '';
      } else {
        currentKey += char;
      }
    }
    
    if (currentKey) {
      keys.push(currentKey.trim());
    }
    
    return keys;
  }


  headingDataMap(x) {
    return x.valueData || x.valueData[0] != '' ? x.valueData : '';
  }
  // listingdata(item:any){
  //   return
  // }

  SuccessSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass =this.customPanelClass?'notif-success2': 'notif-success'; // Default success style class
    this.snackbar.open(msg, tag, config);
  }
  noOptionSelection(event, value) {
    let index: number = 0;
    index = this.allcsvlisting.findIndex((object) => {
      return object.id === value;
    }); // 👉️ 1

    if (index !== -1) {
      this.allcsvlisting[index].disable = false;
    }
  }
  isOptionDisabled(optionValue: string): boolean {
    return this.headingdata.some(item => item.valueData === optionValue);
  }
  onClickChangeOptions(event, value) {
    //value = value.trim() .replace(/\d$/, '');
    if (Array.isArray(value) && value?.length > 0) {
      if (value != undefined) {
        //value = value.trim() .replace(/\d$/, '');
        this.allcsvlisting.forEach((ele) => {
          //let id = ele.id.trim() .replace(/\d$/, '');
          // if(Array.isArray(value) && value?.length > 0){
          ele.id?.trim()?.indexOf(value) != -1
            ? (ele.disable = false)
            : (ele.disable = true);
          // }// }else{
          //   value?.includes(id?.trim()) ? ele.disable = false : ele.disable = true;
          // }
        });
      }
    } else {
      this.allcsvlisting.forEach((ele) => {
        let id = ele.id.trim().replace(/\d$/, '');
        let index: number = 0;
        index = this.allcsvlisting.findIndex((object) => {
          return object.id.trim() === ele.id.trim();
        });

        if (index !== -1) {
          if (this.Inputdata.checkingItems == 'value'?this.multpleOptionFields.indexOf(id.trim()) != -1:this.multpleOptionFields.indexOf(id.trim()) != -1) {
            this.allcsvlisting[index].disable = true;
            //console.log(index +"---"+this.allcsvlisting[index].id +"---"+this.allcsvlisting[index].disable);
          }
        }

        // this.multpleOptionFields.indexOf(id.trim()) != -1? ele.disable = true : ele.disable = false;
      });

      //value = value.trim() .replace(/\d$/, '');
      let index: number = 0;
      index = this.allcsvlisting.findIndex((object) => {
        return object.id === value;
      });
      if (index !== -1) {
        this.allcsvlisting[index].disable = false;
      }
    }

    // if(value != undefined){
    //   value = value.trim() .replace(/\d$/, '');
    //   this.allcsvlisting.forEach(ele => {
    //     let id = ele.id.trim() .replace(/\d$/, '');
    //     if(Array.isArray(value) && value?.length > 0){
    //       value?.includes(id?.trim()) ? ele.disable = false : ele.disable = true;
    //     }else{
    //       value?.includes(id?.trim()) ? ele.disable = false : ele.disable = true;
    //     }
    //   });
    // }
  }
  optionSelection(event, value, arrayValue, optionName, label) {
    // if(value != undefined){
    //   value = value.trim() .replace(/\d$/, '');
    //   this.allcsvlisting.forEach(ele => {
    //     let id = ele.id.trim() .replace(/\d$/, '');
    //     if(Array.isArray(value) && value?.length > 0){
    //       value?.includes(id?.trim()) ? ele.disable = true : ele.disable = false;
    //     }else{
    //       value?.includes(id?.trim()) ? ele.disable = true : ele.disable = false;
    //     }
    //   });
    // }
    let index: number = 0;
    index = this.allcsvlisting.findIndex((object) => {
      return object.id === value;
    }); // 👉️ 1

    if (index !== -1) {
      if (
        Array.isArray(this.selectedMultiOptions) &&
        this.selectedMultiOptions?.length > 0
      ) {
        this.allcsvlisting.forEach((ele) => {
          let id = ele.id.trim().replace(/\d$/, '');
          ele.id.indexOf(label) != -1
            ? (ele.disable = false)
            : (ele.disable = true);
        });

        //this.allcsvlisting[index].disable = this.selectedMultiOptions.indexOf(optionName) != -1
        //this.allcsvlisting[index].disable = false
      } else {
        this.allcsvlisting[index].disable = true;
      }
    }
    this.previousValue = value;

    // value = value.trim() .replace(/\d$/, '');
    // this.allcsvlisting.forEach(ele => {
    //   let id = ele.id.trim() .replace(/\d$/, '');
    //   console.log(value + "====" + id);
    //   value?.includes(id.trim()) ? ele.disable = true : ele.disable = false;
    // });
  }
  onOptionsSelected(event: MatSelectChange, value) {
    this.selectedMultiOptions = event.value;

    //const matSelect: MatSelect = event.source;
    //matSelect.writeValue(null);
    //console.log(this.headingdata);
    // let newarray = [];
    // newarray = this.headingdata.map((x) => x.valueData != "" ? x.valueData : "").filter(val => val != "");
    // if(value != undefined){
    //   value = value.trim() .replace(/\d$/, '');
    //   this.allcsvlisting.forEach(ele => {
    //     let id = ele.id.trim() .replace(/\d$/, '');
    //     if(Array.isArray(value) && value?.length > 0){
    //       value?.includes(id?.trim()) ? ele.disable = true : ele.disable = false;
    //     }else{
    //       value?.includes(id?.trim()) ? ele.disable = true : ele.disable = false;
    //     }
    //   });
    // }
  }

  sortHeadingData(headingData) {
    if (headingData?.length > 0) {
      const sortedArr = headingData.sort((a, b) =>
        a.valueData < b.valueData ? 1 : -1
      );
      return sortedArr;
    } else {
      return headingData;
    }
  }

  //#region UPload CSV
  onFileSelected(event: any): void {

    this.csvFileName = event.target.value
    let pathParts = event.target.value.split("\\");

// Get the last part, which is the filename
this.csvFileName = pathParts[pathParts.length - 1];

    const file: File = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          this.createData(result.data);
        },
      });
    }
  }

  createData(csvData) {
    const propertyNames = csvData[0];
    const arrayOfObjects = [];
  
    for (let i = 1; i < csvData.length; i++) {
      const rowData = csvData[i];
      const obj = {};
      for (let j = 0; j < propertyNames.length; j++) {
        const propertyName = propertyNames[j];
        const value = rowData[j];
        obj[propertyName] = value;
      }
      arrayOfObjects.push(obj);
    }
  
    let uniqueArray = [];
    let duplicatesArray = [];
  
    for (let mainEle of arrayOfObjects) {
      let isDuplicate = false;
      for (let elem of uniqueArray) {
        if (mainEle.Id === elem.Id) {
          isDuplicate = true;
          duplicatesArray.push(mainEle);
          break;
        }
      }
      if (!isDuplicate) {
        uniqueArray.push(mainEle);
      }
    }
  
    let finalArray = [];
  
    for (let unique of uniqueArray) {
      let imagesArray = [];
  
      if (unique.Images) {
        const imageFilenames = unique.Images.split(',').map(filename => filename.trim());
        imageFilenames.forEach(filename => {
          imagesArray.push({ image: filename });
        });
      }
      let itemsComments = [];
      if (unique['Items Comments']) {
        const commentTexts = unique['Items Comments'].split(',').map(comment => comment.trim());
        commentTexts.forEach(comment => {
          itemsComments.push({ title: comment });
        });
      }
      
  
      for (let duplicate of duplicatesArray) {
        if (duplicate.Id === unique.Id) {
          imagesArray.push({ image: duplicate.Images });
        }
      }
  
      let taxData = [];

      try {
        const taxValue = unique['Tax Values'];
      
        // Check if taxValue is defined and is a non-empty string
        if (typeof taxValue === 'string' && taxValue.trim() !== '') {
          const parsedTaxValue = JSON.parse(taxValue);
          taxData.push({ taxe_value: parsedTaxValue.taxe_value, customValueId: parsedTaxValue.customValueId });
        } else {
          console.error("Tax data is not a valid JSON string:", taxValue);
        }
      } catch (error) {
        console.error("Error parsing tax data:", error);
      }
      finalArray.push({
        id:unique.Id,
        name:unique['Items Name'],
        item_prices: [{discount:unique['Item Discount'],price:JSON.stringify({pricesA:unique['Price A'],pricesB:unique['Price B'],pricesC:unique['Price C'],pricesD:unique['Price D']}),fix_cost_per_unit:unique['Fix Cost Per Unit'],sales_commission:unique['Sales Commission'],min_price:unique['Minimum Price']}],
        item_images: imagesArray,
        isDeactivated:unique.Deactivated == "null"?false:unique.Deactivated,
        code:unique['Item Code'],
        comments:itemsComments,
        description:unique.Description,
        video_url:unique['Video Url'],
        website_url:unique['Website Url'],
        unit_quantity:unique['Unit Quantity'],
        unit:unique.Unit,
        item_prices_taxs:taxData,
        subcategoryName:unique.Subcategory,
        categoryName:unique.Category
      });
    }
   this.itemsCsvFile=finalArray
  }

  openItemCsv(){
this.showCsvDropdown = true
  }

  async imageFilesAddedEdit(event: any) {
    const formData = new FormData();
    for (var i = 0; i < event.target.files.length; ++i) {
      this.files = event.target.files[i]
      if (this.files !== null || this.files !== undefined) {
        formData.append('files', this.files);
      }
    const uploadItemPhotoSubs = this.itemsService.uploadItemPhoto(formData).subscribe(
        res => {
          if (res.message === 'success' || res.status === 1) {
            this.editItemsImages.push({ 'image': res.data.image });
            this.image = { 'image': res.data.image }
            
          }
        });
      this.subscriptions.push(uploadItemPhotoSubs);  
    }
  }


  matchedItem:any={}
  async uploadItemImages(item,index) {
    if (!this.uploadImagesData.length) {
      // If the uploadImagesData array is empty, open the UploadImagesPreviewModelComponent
      const dialogRef = this.dialog.open(UploadImagesPreviewModelComponent, {
        maxHeight: '500px',
        height: '500px',
        width: '600px',
        data: item
      });
  
      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult) {
          // Handle the result if needed
          this.newItemsData.data[index].disabled = true;

        }
      });
    } else {
      let foundMatch = false;
      for (const responseItem of this.uploadImagesData) {
        if (responseItem.jobId.data.file_link == item.id) {
          this.customPanelClass=true
          this.SuccessSnackBar('Images already uploaded for this item', 'Ok');
          foundMatch = true;
          break; // Exit the loop since we found a match
        }
      }
  
      if (!foundMatch) {
        const dialogRef = this.dialog.open(UploadImagesPreviewModelComponent, {
          maxHeight: '500px',
          height: '500px',
          width: '600px',
          data: item
        });
  
        dialogRef.afterClosed().subscribe(async (dialogResult) => {
          if (dialogResult) {
            // Handle the result if needed
            this.newItemsData.data[index].disabled = true;
          }
        });
      }
    }
  }
  

  async removeItem(i, type) {
    const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
    const dialogRef = this.dialog.open(ModalComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        if (type == 'imgRemove') {
          const imageIndex = this.editItemsImages.indexOf(i)

          this.editItemsImages.splice(imageIndex, 1);
         this.image = this.editItemsImages[0]
        }

      }
    });
  }
  
  changeImage(index,id){
    this.image =  this.editItemsImages[index];
    this.mainImageUrl=id;
  }

  closeDialog(){
    this.dialogRef.close()
  }


  getUploadedItems(payload){
    this.serchArr = {
      pagination: true,
      limit:payload.pageLimit? payload.pageLimit:this.itemCount,
      page: payload.page ? payload.page : 1,
      // filter: { "categoryId": this.catId }
    }
    if(this.Inputdata?.value == 'allItems'){
      const itemSearchSubs = this.itemsService.itemSearch(this.serchArr).subscribe(res => {
        if(res?.data?.length){
          this.newItemsData=res
          this.pageDetail=res?.pageDetail
          this.itemActivePagination = new Array(res.totalPages);
  
        }
      })

    }else{
      const itemSearchSubs = this.itemsService.itemSearchUploaded(this.serchArr).subscribe(res => {
        if(res?.data?.length){
          this.newItemsData=res
          this.pageDetail=res?.pageDetail
          this.itemActivePagination = new Array(res.totalPages);
  
        }
      })
    }
    
  }
  paginationPageSize(pageSize) {
    this.itemCount=pageSize,
    this.getUploadedItems({pageLimit:pageSize})
  }
  async paginationPage(i) {
    this.getUploadedItems({ page: i })
  }
}





